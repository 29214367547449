import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { add as amplitudeAdd, init, identify, Identify, setUserId, track } from '@amplitude/analytics-browser';
import { autocapturePlugin } from '@amplitude/plugin-autocapture-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { EnrichmentPlugin } from '@amplitude/analytics-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AMPLITUDE_PUBLIC_API_KEY, SEND_AMPLITUDE_EVENT } from '@/utils/constants';

interface EventProperties {
    [key: string]: string | number | boolean | null,
}

interface UserProperties {
    [key: string]: string | number | boolean | null,
}

export const AmplitudeEventActions = {
    manualInvoiceNotificationSent: 'Manual Invoice Notification sent',
    signIn: 'Sign In',
    pageViewed: 'Page Viewed',
    buttonClick: 'Button click',
    signUp: 'Sign Up',
    pushCharge: 'Push charge',
    charged: 'Charged',
    pageChargeViewed: 'Page charge viewed'
} as const;

export const AmplitudeCharge = {
    individualCharge: 'Individual charge',
    bulkCharge: 'Bulk charge'
} as const;

export enum AmplitudeNotificationType {
    IndividualNotification = 'individual_notification',
    BulkNotifications = 'bulk_notifications',
}

export enum InvoiceNotificationDispatchPage {
    InvoicesPage = 'invoices_page',
    InvoiceDetailsPage = 'invoice_details_page',
}

export type AmplitudeChargeType = typeof AmplitudeCharge[keyof typeof AmplitudeCharge];

export type AmplitudeEventType = typeof AmplitudeEventActions[keyof typeof AmplitudeEventActions];

interface AmplitudeContextProps {
    trackAmplitudeEvent: (eventName: AmplitudeEventType, eventProperties?: EventProperties)=>void,
    setAmplitudeUserId: (userId: string)=>void,
    setAmplitudeUserProperties: (properties: UserProperties)=> void,
}

const AmplitudeContext = createContext<AmplitudeContextProps | undefined>(undefined);

interface AmplitudeProviderProps {
    children: ReactNode,
}

export const AmplitudeProvider: React.FC<AmplitudeProviderProps> = ({ children }) => {
    const [canUseAmplitude, setCanUseAmplitude] = useState(false);
    const flags = useFlags();

    const setAmplitudeUserProperties = (properties: UserProperties) => {
        if (!canUseAmplitude) {
            return;
        }
        const identifyObj = new Identify();
        Object.entries(properties).forEach(([key, value]) => {
            identifyObj.set(key, value);
        });

        identify(identifyObj);
    };

    const pageViewTrackingEnrichment = (): EnrichmentPlugin => ({
        name: 'page-view-tracking-enrichment',
        type: 'enrichment',
        setup: async () => undefined,
        execute: async (event) => {
            if (event.event_type === '[Amplitude] Page Viewed') {
                const lastPageViewedString = localStorage.getItem('last_page_viewed');
                const lastPageViewed = lastPageViewedString ? JSON.parse(lastPageViewedString) : { data: Date.now(), page: 'no any page' };
                const newLastPage = { data: Date.now(), page: event.event_properties['[Amplitude] Page URL'] };
                // don't allow send same event
                if (newLastPage.page !== lastPageViewed.page || (newLastPage.data - lastPageViewed.data > 10000)) {
                    localStorage.setItem('last_page_viewed', JSON.stringify(newLastPage));
                    return event;
                }
                return null;
            }
            return event;
        },
    });

    useEffect(() => {
        const currentStateUsingAmplitude = SEND_AMPLITUDE_EVENT && flags.amplitudeEnabled;
        setCanUseAmplitude(currentStateUsingAmplitude);

        if (!currentStateUsingAmplitude) {
            init(AMPLITUDE_PUBLIC_API_KEY, {
                defaultTracking: false,
            });
            amplitudeAdd(pageViewTrackingEnrichment());
            amplitudeAdd(autocapturePlugin());
            return;
        }

        init(AMPLITUDE_PUBLIC_API_KEY, {
            defaultTracking: true,
        });

        amplitudeAdd(pageViewTrackingEnrichment());
        amplitudeAdd(autocapturePlugin());
        if (flags.amplitudeSessionReplayTrackingEnabled) {
            const sessionReplayTracking = sessionReplayPlugin({
                sampleRate: flags.amplitudeSessionReplayTrackingSampleRate
            });
            amplitudeAdd(sessionReplayTracking);
        }
    }, [flags.amplitudeEnabled,
        flags.amplitudeSessionReplayTrackingEnabled,
        flags.amplitudeSessionReplayTrackingSampleRate,
        useFlags]);

    const trackAmplitudeEvent = (eventName: AmplitudeEventType, eventProperties: EventProperties) => {
        if (!canUseAmplitude) {
            return;
        }
        track(eventName, eventProperties);
    };

    const setAmplitudeUserId = (userId: string) => {
        if (!canUseAmplitude) {
            return;
        }
        setUserId(userId);
    };

    if (!canUseAmplitude) {
        return <>{children}</>;
    }

    return (
        <AmplitudeContext.Provider value={{ trackAmplitudeEvent, setAmplitudeUserId, setAmplitudeUserProperties }}>
            {children}
        </AmplitudeContext.Provider>
    );
};

export const useAmplitude = (): AmplitudeContextProps => {
    const context = useContext(AmplitudeContext);
    if (!context) {
        return {
            trackAmplitudeEvent: () => {},
            setAmplitudeUserId: () => {},
            setAmplitudeUserProperties: () => {},
        };
    }
    return context;
};
