import React from 'react';
import { Transaction } from '@ar/network/AccountsReceivable.network';
import PaymentMethodBadge from '../../shared/PaymentMethodBadge/PaymentMethodBadge';

export interface InvoicePaymentMethodColumnProps {
    transaction: Transaction,
}

export const InvoicePaymentMethodColumn : React.FC<InvoicePaymentMethodColumnProps> = ({ transaction }) => {
    if (!transaction.brand || !transaction.last4 || (!transaction.payment_method_id && transaction.status !== 'failed')) return <></>;

    return <PaymentMethodBadge brand={transaction.brand} last4={transaction.last4} autoCollection={transaction.auto_collection} />;
};
