import React, { useContext } from 'react';
import { Button, GridColDef, GridRenderCellParams, Popover, toast } from '@notch-ordering/ui-components';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import { CustomerDetailsContext } from '@ar/pages/CustomerDetailsWrapperPage/CustomerDetailsContext';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import CheckIcon from '@icons/check-icon.svg';
import { TrackingEvents } from '@ar/constants/TrackingConstants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cx from 'classnames';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { sendCustomersWelcomeEmail } from '@/ar/network/AccountsReceivable.network';

interface Props {
    param: GridRenderCellParams<GridColDef>,
}

const CustomersRowMoreButton = ({ param }: Props): JSX.Element => {
    const {
        setCustomerId,
        setIsCustomerPopupOpen,
    } = useContext(CustomerDetailsContext);
    const { supplierLoginData } = useSupplierStore();
    const flags = useFlags();
    const isSendPortalInviteButtonHidden = !flags.customerPortalInvites || !param.row.email || param.row.has_account || param.row.account_with_email_exists;

    /**
     * Handle clicking the button which opens the modal prompt
     */
    function handleClick(): void {
        setCustomerId(param.row.id);
        setIsCustomerPopupOpen(true);
        sendTrackingEvent(TrackingEvents.editCustomerClicked);
    }

    const sendCustomersAWelcomeEmail = ():void => {
        const body = { supplier_id: supplierLoginData.supplier_id, customer_ids: [param.row.id] };
        sendCustomersWelcomeEmail({
            supplierID: supplierLoginData?.supplier_id,
            body
        }).then(() => {
            toast.show({
                icon: <CheckIcon/>,
                message: 'Payment portal invitation successfully sent!'
            });
        }).catch((error) => {
            toast.show({
                message: `${error}`,
            });
        });
    };

    return (
        <Popover
            className={cx({ 'w-44': !flags.customerPortalInvites,
                'w-60': flags.customerPortalInvites })}
            button={
                <Button
                    variant="TERTIARY_TABLE"
                    className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                    size="SMALL"
                    stopPropagation={false}>
                    <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                        <EllipsisIcon className="h-4 w-4" />
                    </div>
                </Button>
            }
            items={
                [
                    {
                        label: 'Edit details',
                        onClick: handleClick,
                    },
                    {
                        label: 'Send Payment Portal Invitation',
                        onClick: (): void => sendCustomersAWelcomeEmail(),
                        hidden: isSendPortalInviteButtonHidden
                    },
                ]
            }/>
    );
};

export default CustomersRowMoreButton;
