import React from 'react';
import { Button, Modal, Separator, toast, Typography } from '@notch-ordering/ui-components';
import CheckIcon from '@icons/check-icon.svg';
import { CSVLink } from 'react-csv';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useGetPayoutById } from '@ar/hooks/queries/PayoutQueries.hook';
import { mergeClassNames } from '@v2/utils/Helpers';
import { PayoutExportFormatsPopover } from '@ar/components/Payouts/PayoutExportFormatsPopover';
import { getGpoId } from '@v2/utils/GPOUtils';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';
import { centsToDollars } from '@/utils/Formatters';

export interface SinglePayoutTransactionExportModalProps {
    isOpen: boolean,
    close: () => void,
    payoutID: string,

}

// using the exact same columns as the ones used in retool
const CSV_COLUMNS = [
    {
        label: 'Category',
        key: 'reporting_category',
    },
    {
        label: 'Invoice Number',
        key: 'invoice_number',
    },
    {
        label: 'Customer Name',
        key: 'customer_name',
    }, {
        label: 'Customer ID',
        key: 'external_id',
    },
    {
        label: 'Payment Method',
        key: 'type_of_payment',
    },
    {
        label: 'Last 4',
        key: 'last4',
    },
    {
        label: 'Payment Source',
        key: 'payment_source',
    },
    {
        label: 'Gross',
        key: 'gross',
    },
    {
        label: 'Fee',
        key: 'fee',
    },
    {
        label: 'Net',
        key: 'net',
    },
    {
        label: 'Created At',
        key: 'created_at',
    },

];

export const SinglePayoutTransactionExportModal : React.FC<SinglePayoutTransactionExportModalProps> = ({
    isOpen,
    close,
    payoutID,
}) => {
    const [selectedFormat, setSelectedFormat] = React.useState<string>('CSV');
    const { supplierLoginData } = useSupplierStore();
    const gpoId = getGpoId(supplierLoginData);
    const { data: payout, isLoading: isLoadingPayload } = useGetPayoutById({
        payoutID,
        supplierID: supplierLoginData?.supplier_id,
        gpoId
    });
    const handleOnClose = (): void => {
        close();
    };
    // using the same file name as the one used in retool
    const FILE_NAME = `${supplierLoginData.supplier_name}_payout_${payout?.received_at ? formatIsoStringToUtcDate(new Date(payout?.received_at)?.toISOString(), 'Y-MM-dd') : ''}.csv`;

    // converting the cents to dollars
    const csvTransactionsData = payout?.transactions?.map((transaction) => ({
        ...transaction,
        fee: centsToDollars(transaction.fee),
        gross: centsToDollars(transaction.gross),
        net: centsToDollars(transaction.net),
    })) ?? [];

    const handleDownload = (): void => {
        toast.show({
            message: 'File downloaded successfully',
            icon: <CheckIcon/>,
        });
        close();
    };
    return <Modal isOpen={isOpen}
        desktopModalWidth="lg:w-[348px]"
        title={
            <><Typography className="m-0" weight="font-semibold" variant="LG-2" >
                          Export data
            </Typography>

            </>
        }
        headerPadding="mt-0 mb-5 px-0"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL" >
        <div className="flex gap-2 justify-between items-center p-5">
            <Typography weight="font-medium">File format</Typography>
            <div />
            <div><PayoutExportFormatsPopover selectedFormat={selectedFormat} setSelectedFormat={setSelectedFormat}/></div>
        </div>
        <Separator/>
        <div className="pt-5 flex justify-end pr-5">
            <Button className="mr-3 cursor-pointer"
                type="button"
                as="div"
                onClick={handleOnClose}
                variant="TERTIARY_FILLED"
                size="SMALL"
                minWidth="w-auto">
                Cancel
            </Button>
            <CSVLink data={csvTransactionsData}
                className={mergeClassNames({
                    'pointer-events-none': isLoadingPayload,
                })}
                headers={CSV_COLUMNS}
                filename={FILE_NAME}>
                <Button
                    disabled={isLoadingPayload}
                    loading={isLoadingPayload}
                    variant={'SECONDARY'}
                    size="SMALL"
                    minWidth="w-auto"
                    onClick={handleDownload}>
                    Download
                </Button>
            </CSVLink>
        </div>
    </Modal>;
};
