export const ChargeOnLabels = {
    dueDate: 'Due date',
    invoiceDate: 'Issue date',
    weekly: 'Weekly',
    biweekly: 'Biweekly',
    monthly: 'Monthly',
} as const;

export const ChargeOnValues = {
    dueDate: 'due date',
    invoiceDate: 'invoice date',
    weekly: 'weekly',
    biweekly: 'bi-weekly',
    monthly: 'monthly',
} as const;

export enum WeekDays {
    MONDAY = 'Monday',
    TUESDAY = 'Tuesday',
    WEDNESDAY = 'Wednesday',
    THURSDAY = 'Thursday',
    FRIDAY = 'Friday',
}
