import React from 'react';
import { Outlet } from 'react-router-dom';
import { CustomerDetailsProvider } from '@ar/pages/CustomerDetailsWrapperPage/CustomerDetailsContext';
import { CustomerGeneralPopup } from '@ar/components/CustomerDetails/CustomerGeneralPopup/CustomerGeneralPopup';

/**
 * The AR Customer Wrapper page
 *
 * @returns JSX Element
 */
export const CustomerDetailsWrapperPage = (): JSX.Element => (
    <CustomerDetailsProvider>
        <Outlet/>
        <CustomerGeneralPopup />
    </CustomerDetailsProvider>
);
