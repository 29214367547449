import React from 'react';
import { Button, Loading } from '@notch-ordering/ui-components';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';

export const InvoiceFilterLoadingButton : React.FC = () => <Button variant="TERTIARY_FILLED"
    size="MEDIUM"
    className="px-2.5 py-1 rounded-lg w-28"
    stopPropagation={false}>
    <div className="flex gap-3 items-center text-center">
        <Loading isDark/>
        <ChevronDownIcon className="w-4 h-4 text-gray-600 ml-auto shrink-0"/>
    </div>
</Button>;
