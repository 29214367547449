import { environments } from '../../webpack/environments';

/**
 * Builds the appropriate Payment Link URL for the given invoice
 *
 * @param featureFlagEnabled boolean value of the feature flag that controls the Payment Link URL setting
 * @param supplierID string representing the supplier ID for this invoice
 * @param invoiceNumber string representing the invoice number
 * @param longPaymentLinkURL string representing the long-form Payment Link URL associated with this invoice
 * @returns The Payment Link URL string, in either long or short form depending on the feature flag
 */
export const getPaymentLink = (featureFlagEnabled: boolean, supplierID: string, invoiceNumber: string, longPaymentLinkURL: string): string => {
    const envVars = process.env.DEPLOY_ENV === 'development' ? environments.develop : environments[process.env.DEPLOY_ENV];
    const paymentLinkDomain = envVars?.BASE_URLS?.paymentLinkUrl?.replace(/^"|"$/g, '') ?? undefined;
    const shortPaymentLink = (paymentLinkDomain && supplierID && invoiceNumber) ? `${paymentLinkDomain}/l/${supplierID}/${invoiceNumber}` : undefined;
    return featureFlagEnabled && shortPaymentLink ? shortPaymentLink : longPaymentLinkURL;
};
