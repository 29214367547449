/**
 * Count number of decimal places in result.
 */
export function countDecimals(n: number): number {
    return n % 1 === 0 ? 0 : (`${n}`).length - (`${n}`).lastIndexOf('.') - 1;
}

/**
 * Takes a string or number and returns the number representation.
 */
export function valueToNumber(value: string | number): number {
    let num: number;
    if (typeof value === 'string') {
        num = parseFloat(value);
    } else {
        num = value;
    }

    return num;
}

/**
 * @returns rounded number with specified fixed decimals, or 0 if value is undefined
 */
export function valueToFixedNumber(value: string | number, fixedAmount: number = 2): number {
    if (!value || Number.isNaN(value)) {
        return 0;
    }

    const num: number = valueToNumber(value);
    // Reference: https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
    const p = fixedAmount > 0 ? 10 ** fixedAmount : 1;
    const n = (num * p) * (1 + Number.EPSILON);
    const result = Math.round(n) / p;
    return result;
}

/**
 * @returns rounded number as a string with specified fixed decimals, or 0 if value is undefined
 */
export function valueToFixedString(value: string | number, fixedAmount: number = 2, minDecimals: number = 2): string {
    const num = valueToFixedNumber(value, fixedAmount);

    let result: string;
    if (fixedAmount > 0) {
        const numDecimals: number = countDecimals(num);
        // eslint-disable-next-line no-nested-ternary
        result = num.toLocaleString('en', { useGrouping: false, minimumFractionDigits: Math.max(numDecimals, minDecimals) });
    } else {
        result = `${num}`;
    }

    return result;
}

/**
 * A fuzzier check that will do things like have an empty string and null be equal, or 0 and null, etc.
 *
 * @param a value to check if is equal to b
 * @param b value to check if is equal to a
 * @returns True if they're equal in a fuzzier sense
 */
export function checkInputFieldEquality(a: string | number | Date | boolean, b: string | number | Date | boolean): boolean {
    let aAsNum = null;
    let bAsNum = null;

    if (typeof a === 'string') {
        aAsNum = parseFloat(a);
    } else if (typeof a === 'number') {
        aAsNum = a;
    }

    if (typeof b === 'string') {
        bAsNum = parseFloat(b);
    } else if (typeof b === 'number') {
        bAsNum = b;
    }

    // special cases - if either is null and the matching values is 0 we count that as the same thing in this case
    if ((!a && bAsNum === 0) || (aAsNum === 0 && !b)) {
        return true;
    }

    if (!Number.isNaN(aAsNum) && !Number.isNaN(bAsNum)) {
        return aAsNum === bAsNum;
    } if (typeof a === 'string' && typeof b === 'string') {
        return (a as string).trim().toLocaleLowerCase() === (b as string).trim().toLocaleLowerCase();
    }

    // finally do a straight equality as a last effort
    return a === b;
}

/**
 * Returns true if the given string can be parsed as a date
 */
export function isStringValidDate(stringData: string): boolean {
    return !Number.isNaN(Date.parse(stringData));
}
