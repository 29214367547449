import '../../../styles/dataPage.scss';
import React, { DragEvent, useEffect, useRef, useState } from 'react';
import { PageTitle } from '@ar/components/PageTitle';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { FETCH_SUPPLIER_QUERY_KEY, useGetSupplier } from '@ar/hooks/queries/SupplierQueries.hook';
import { Button, DragAndDropUpload, Input, Loading, Separator, toast, Typography } from '@notch-ordering/ui-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteSupplierBrandingLogo,
    updateSupplier,
    uploadSupplierBrandingLogo } from '@ar/network/AccountsReceivable.network';
import CheckIcon from '@icons/check-icon.svg';
import AddIcon from '@icons/add-icon.svg';
import { clone } from 'lodash';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { CORE_API_GOOGLE_CLOUD_BUCKET } from '@/utils/constants';

interface IFormData {
    logo_url?: string,
    secondary_logo_url?: string,
    primary_color?: string,
    secondary_color?: string,
}

interface IImagesData {
    logo_url?: File | null,
    secondary_logo_url?: File | null,
}

/**
 *
 */
export const BrandingPage = function (): JSX.Element {
    useDocumentTitle('Branding - Notch');
    const { supplier, supplierLoginData } = useSupplierStore();
    const supplierID = supplier.id;
    const [logoFiles, setLogoFiles] = useState<IImagesData>();
    const [formData, setFormData] = useState<IFormData>(
        {
            logo_url: supplier.logo_url,
            secondary_logo_url: supplier.secondary_logo_url,
            primary_color: supplier.primary_color || '#',
            secondary_color: supplier.secondary_color || '#',
        },
    );

    const uploadMutation = useMutation(uploadSupplierBrandingLogo);
    const deleteMutation = useMutation(deleteSupplierBrandingLogo);

    const updateSupplierMutation = useMutation(updateSupplier);

    const { data: supplierData, isLoading } = useGetSupplier({
        supplierID: supplierLoginData?.supplier_id,
    });

    const deleteImage = (imageUrl?: string) => {
        if (!imageUrl) {
            return;
        }
        if (imageUrl && !imageUrl.includes(CORE_API_GOOGLE_CLOUD_BUCKET)) {
            return;
        }
        const fileName = imageUrl.split('/').pop();
        deleteMutation.mutate({ supplierID, fileName });
    };

    const inputLogoRef = useRef(null);
    const inputSecondaryLogoRef = useRef(null);

    const queryClient = useQueryClient();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const cloneFormData = clone(formData);
        if (logoFiles?.logo_url) {
            const defaultLogo = await uploadMutation.mutateAsync(
                {
                    supplierID,
                    image: logoFiles.logo_url,
                },
                {
                    onSuccess: () => {
                        deleteImage(formData?.logo_url);
                        setLogoFiles((prevState) => ({
                            ...prevState,
                            logo_url: null,
                        }));
                    },
                    onError: () => {
                        toast.show({
                            message: 'Failed to upload default logo',
                        });
                    },
                },
            );
            cloneFormData.logo_url = defaultLogo.logoUrl;
        }
        if (logoFiles?.secondary_logo_url) {
            const defaultLogo = await uploadMutation.mutateAsync(
                {
                    supplierID,
                    image: logoFiles.secondary_logo_url,
                },
                {
                    onSuccess: () => {
                        deleteImage(formData?.secondary_logo_url);
                        setLogoFiles((prevState) => ({
                            ...prevState,
                            secondary_logo_url: null,
                        }));
                    },
                    onError: () => {
                        toast.show({
                            message: 'Failed to upload secondary logo',
                        });
                    },
                },
            );
            cloneFormData.secondary_logo_url = defaultLogo.logoUrl;
        }
        cloneFormData.primary_color = formData.primary_color === '#' ? '' : formData.primary_color;
        cloneFormData.secondary_color = formData.secondary_color === '#' ? '' : formData.secondary_color;

        await updateSupplierMutation.mutate({
            supplierID: supplierLoginData?.supplier_id,
            body: { ...cloneFormData },
        }, {
            onSuccess: () => {
                toast.show({
                    message: 'Successfully updated settings.',
                    icon: <CheckIcon/>,
                    showClose: false,
                });
                queryClient.invalidateQueries([FETCH_SUPPLIER_QUERY_KEY]);
            },
            onError: () => {
                toast.show({
                    message: 'Failed to update',
                });
            },
        });
    };

    const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string): void => {
        const { files: filesList } = event.target;
        if (filesList[0]) {
            setLogoFiles((prevState) => ({
                ...prevState,
                [fieldName]: filesList[0],
            }));
        }
    };

    const onClickUploadLogo = (): void => {
        inputLogoRef.current.click();
    };

    const onClickUploadSecondaryLogo = (): void => {
        inputSecondaryLogoRef.current.click();
    };

    const onDropFiles = (event: DragEvent<HTMLDivElement>, fieldName: string): void => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files[0]) {
            setLogoFiles((prevState) => ({
                ...prevState,
                [fieldName]: event.dataTransfer.files[0],
            }));
        }
    };

    const onDragFiles = (event: DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleChangeColor = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { value, name } = e.target;
        if (value[0] !== '#' || value.length > 6) {
            return;
        }

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (supplierData) {
            setFormData({
                logo_url: supplierData.logo_url,
                secondary_logo_url: supplierData.secondary_logo_url,
                primary_color: supplierData.primary_color || '#',
                secondary_color: supplierData.secondary_color || '#',
            });
        }
    }, [supplierData]);

    return (
        <>
            <PageTitle className="mb-12">Branding</PageTitle>

            {!isLoading && <>
                <form className="mt-10 font-body" onSubmit={handleSubmit}>
                    <Typography variant="LG-2" className="font-semibold mb-8">
                        Logos
                    </Typography>

                    <div className="flex mb-8">
                        <div className="w-8/12">
                            <Typography variant="LG-1" className="font-medium mb-1">
                                Default logo
                            </Typography>
                            <Typography variant="LG-1" className="text-gray-600 w-6/12">
                                Used across all branded assets representing your brand. For example, outgoing invoices and emails.
                            </Typography>
                        </div>
                        <DragAndDropUpload
                            wrapClassName="w-auto"
                            className="!p-0 border-grey-300 !border-solid h-20"
                            icon={(formData.logo_url || logoFiles?.logo_url)
                                ? <img src={logoFiles?.logo_url ? URL.createObjectURL(logoFiles.logo_url) : formData.logo_url} className="w-[60px] m-2.5" />
                                : <AddIcon className="text-gray-600 m-[31px] w-4 h-4" />}
                            onInputChange={(e) => handleFilesChange(e, 'logo_url')}
                            onClick={onClickUploadLogo}
                            onDrop={(e) => onDropFiles(e, 'logo_url')}
                            onDragOver={onDragFiles}
                            fileTypes={'image/png, image/jpg'}
                            inputRef={inputLogoRef}
                            uploadDesc=""/>
                    </div>

                    <Separator />

                    <div className="flex mt-8 mb-10">
                        <div className="w-8/12">
                            <Typography variant="LG-1" className="font-medium mb-1">
                                Secondary logo
                            </Typography>
                            <Typography variant="LG-1" className="text-gray-600 w-6/12">
                                Used in smaller instances where a full width logo might not be fully visible. For example, browser tab icons.
                            </Typography>
                        </div>
                        <DragAndDropUpload
                            wrapClassName="w-auto"
                            className="!p-0 border-grey-300 !border-solid h-20"
                            icon={(formData.secondary_logo_url || logoFiles?.secondary_logo_url)
                                ? <img src={logoFiles?.secondary_logo_url ? URL.createObjectURL(logoFiles.secondary_logo_url) : formData.secondary_logo_url} className="w-[60px] m-2.5" />
                                : <AddIcon className="text-gray-600 m-[31px] w-4 h-4" />}
                            onInputChange={(e) => handleFilesChange(e, 'secondary_logo_url')}
                            onClick={onClickUploadSecondaryLogo}
                            onDrop={(e) => onDropFiles(e, 'secondary_logo_url')}
                            onDragOver={onDragFiles}
                            fileTypes={'image/png, image/jpg'}
                            inputRef={inputSecondaryLogoRef}
                            uploadDesc=""/>
                    </div>

                    <Separator />

                    <Typography variant="LG-2" className="font-semibold mt-10 mb-8">
                        Colors
                    </Typography>

                    <div className="flex items-center mb-8">
                        <div className="w-8/12">
                            <Typography variant="LG-1" className="font-medium mb-1">
                                Primary color
                            </Typography>
                            <Typography variant="LG-1" className="text-gray-600 w-6/12">
                                Your brand’s primary color that will be used on all branded assets. For example, buttons.
                            </Typography>
                        </div>
                        <div className="relative">
                            <div style={{ backgroundColor: `${formData.primary_color}` }}
                                className={'absolute t-0 l-0 h-full w-10 rounded-l-lg z-10'} />
                            <Input
                                value={formData.primary_color}
                                width="w-[132px]"
                                inputClassName="pl-14"
                                name="primary_color"
                                onChange={handleChangeColor}/>
                        </div>
                    </div>

                    <Separator />

                    <div className="flex items-center mt-8 mb-10">
                        <div className="w-8/12">
                            <Typography variant="LG-1" className="font-medium mb-1">
                                Secondary color
                            </Typography>
                            <Typography variant="LG-1" className="text-gray-600 w-6/12">
                                Your brand’s supporting color that will be used as an accent on branded assets. For example, links.
                            </Typography>
                        </div>
                        <div className="relative">
                            <div style={{ backgroundColor: `${formData.secondary_color}` }}
                                className={'absolute t-0 l-0 h-full w-10 rounded-l-lg z-10'} />
                            <Input
                                value={formData.secondary_color}
                                width="w-[132px]"
                                inputClassName="pl-14"
                                name="secondary_color"
                                onChange={handleChangeColor}/>
                        </div>
                    </div>

                    <div className="flex mt-16">
                        <Button
                            variant="SECONDARY"
                            size="SMALL"
                            loading={updateSupplierMutation.isLoading || uploadMutation.isLoading}
                            className="px-3.5 py-1.5">
                            Save
                        </Button>
                    </div>
                </form>

            </>}

            {isLoading && (<Loading isDark/>)}

        </>
    );
};
