import { useEffect } from 'react';
import { FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY } from '@ar/hooks/queries/CustomerQueries.hook';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { GetPaymentMethodResponse } from '@ar/network/AccountsReceivable.network';
import { ActivePaymentMethods } from '@/utils/constants';
import { queryClient } from '@/containers/app/Root';

// Default interval for refetching payment methods
export const DEFAULT_INTERVAL_RE_FETCH_PAYMENT_METHODS = 2000;

/**
 * Checks if a payment method is incomplete
 *
 * @param {boolean} isAdyenEnabled - Flag indicating if Adyen is enabled
 * @param {GetPaymentMethodResponse} paymentMethod - The payment method to check
 * @returns {boolean} - Returns true if the payment method is incomplete, false otherwise
 */
export const isPaymentMethodIncomplete = (isAdyenEnabled:boolean, paymentMethod:GetPaymentMethodResponse): boolean => isAdyenEnabled && paymentMethod && !paymentMethod.last4;

/**
 * Checks if the payment methods array contains any incomplete payment methods
 *
 * @param {boolean} isAdyenEnabled - Flag indicating if Adyen is enabled
 * @param {GetPaymentMethodResponse[]} paymentMethods - The array of payment methods to check
 * @returns {boolean} - Returns true if there are any incomplete payment methods, false otherwise
 */
export const containsIncompletePaymentMethods = (isAdyenEnabled:boolean, paymentMethods:GetPaymentMethodResponse[]): boolean => paymentMethods.some((paymentMethod) => isPaymentMethodIncomplete(isAdyenEnabled, paymentMethod));

// Type definition for the useRefetchIncompletePaymentMethods hook props
export type UseRefetchIncompletePaymentMethodsProps = {
    paymentMethods?: GetPaymentMethodResponse[],
    refetchInterval?: number,
    onComplete?: () => void,
    onCleanup?: () => void,
};

/**
 * Custom hook to refetch incomplete payment methods at a specified interval
 *
 * @param {UseRefetchIncompletePaymentMethodsProps} props - The hook props
 * @returns {boolean} - Returns true if there are any incomplete payment methods, false otherwise
 */
export const useRefetchIncompletePaymentMethods = ({
    paymentMethods = [],
    refetchInterval = DEFAULT_INTERVAL_RE_FETCH_PAYMENT_METHODS,
    onComplete,
    onCleanup,
}:UseRefetchIncompletePaymentMethodsProps): boolean => {
    const flags = useFlags();
    const enableAddPaymentMethodsWithAdyen = flags?.arPaymentProvider?.card === ActivePaymentMethods.adyen;
    const hasIncompletePaymentMethods = containsIncompletePaymentMethods(enableAddPaymentMethodsWithAdyen, paymentMethods ?? []);
    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (hasIncompletePaymentMethods && enableAddPaymentMethodsWithAdyen) {
            // create interval to invalidate cache and refetch payment methods every 2 seconds
            intervalId = setInterval(() => {
                queryClient.invalidateQueries([FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY]);
            }, refetchInterval);
        } else {
            if (onComplete) {
                onComplete();
            }
            if (intervalId) {
                clearInterval(intervalId);
            }
        }

        // Cleanup function to clear the interval
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
            if (onCleanup) {
                onCleanup();
            }
        };
    }, [hasIncompletePaymentMethods]);
    return hasIncompletePaymentMethods;
};
