import React from 'react';
import { Typography } from '@notch-ordering/ui-components';
import PayoutsEmptyStateImg from '@ar/assets/images/payouts-empty-state.svg';

export const PayoutsEmptyState : React.FC = () => <div className="w-[373px] mx-auto flex flex-col text-center h-full justify-center">
    <PayoutsEmptyStateImg className="w-[213px] h-[196px mb-10 mx-auto"/>
    <Typography variant="2XL" weight="font-bold" className="mb-2">You have no payouts, yet.</Typography>
    <Typography className="text-gray-600">
        Collect payments from your customers to start receiving payouts. Once those invoices start to be paid through Notch, your collected balance will show here.
    </Typography>
</div>;
