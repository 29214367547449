import create from 'zustand';
import { GetPayoutsParams } from '@ar/network/AccountsReceivable.network';
import { isEqual, omit, omitBy } from 'lodash';

type SearchParams = Partial<Omit<GetPayoutsParams, 'supplier_id'>> & { page?: number };

type PayoutsStoreState = {
    searchParams: SearchParams,
};

type PayoutsStoreActions = {
    updateSearchParams: (params: SearchParams) => void,
    clearFilter: (filter: keyof SearchParams) => void,
    reset: () => void,
    haveSearchParamsChanged?: () => boolean,
};

type PayoutsStore = PayoutsStoreState & PayoutsStoreActions;

const PAYOUTS_STORE_INITIAL_STATE: PayoutsStoreState = {
    searchParams: {
        limit: 20,
        offset: 0,
        page: 0,
    },
};

export const usePayoutsStore = create<PayoutsStore>(
    (set): PayoutsStore => ({
        ...PAYOUTS_STORE_INITIAL_STATE,

        updateSearchParams: (params: SearchParams) => set(
            (state) => ({
                searchParams: {
                    ...state.searchParams,
                    ...params,
                },
            }),
        ),

        clearFilter: (filterKey: keyof SearchParams) => set(
            (state) => ({
                searchParams: {
                    ...state.searchParams,
                    [filterKey]: PAYOUTS_STORE_INITIAL_STATE.searchParams[filterKey],
                },
            }),
        ),

        reset: () => set(PAYOUTS_STORE_INITIAL_STATE),
        haveSearchParamsChanged: (): boolean => {
            const { searchParams } = usePayoutsStore.getState();
            const ignoredKeys: (keyof SearchParams)[] = ['limit', 'offset', 'page'];
            // removing from searchParams the ignored keys and undefined values for an accurate comparison
            const currentSearchParams = omitBy(omit(searchParams, ignoredKeys), (value) => value === undefined);
            const initialSearchParams = omit(PAYOUTS_STORE_INITIAL_STATE.searchParams, ignoredKeys);
            return !isEqual(currentSearchParams, initialSearchParams);
        },
    }),
);
