import { Modal, onMobileSetLarge, Typography, Button, toast, } from '@notch-ordering/ui-components';
import { tCommon, tNamespace } from '@v2/i18n';
import React, { useContext, useState } from 'react';
import InfoIcon from '@icons/info-icon.svg';
import AlertIcon from '@icons/alert-icon.svg';
import { useTranslation } from 'react-i18next';
import { NotificationsData, UpdateCustomerData, updateCustomer, updateCustomerNotificationRules } from '@/ar/network/AccountsReceivable.network';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { CustomerDetailsContext } from '../CustomerDetailsWrapperPage/CustomerDetailsContext';

type ManageIntegrationProps = {
    isOpen: boolean,
    close: () => void,
    refetch: () => void,
    onSave?: () => void,
};

const onErrorSavingNotifications = (error):void => {
    console.info(error, 'error');
    toast.show({
        message: 'Could not restore default settings.',
        icon: <AlertIcon className="text-red-300"/>,
    });
};

/**
 * Modal that appears when resetting AR notifications from custom to default global settings.
 */
export const ResetToDefaultNotificationsModal: React.FC<ManageIntegrationProps> = ({
    isOpen = false,
    close,
    refetch,
    onSave,
}) => {
    const { customer, customerID } = useContext(CustomerDetailsContext);
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Notifications' });
    const { supplierLoginData } = useSupplierStore();
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const customerName = customer.name;

    const resetToDefault = (notificationsBody: NotificationsData, customerBody: UpdateCustomerData):void => {
        setSaveLoading(true);
        const updateNotificationsPromise = updateCustomerNotificationRules({
            supplierID: supplierLoginData?.supplier_id,
            customerID,
            body: notificationsBody,
        });
        const updateCustomerPromise = updateCustomer({
            supplierID: supplierLoginData?.supplier_id,
            customerID,
            invoice_notifications: customerBody.invoice_notifications,
            disable_reminders: customerBody.disable_reminders
        });

        Promise.all([
            updateNotificationsPromise,
            updateCustomerPromise
        ]).then(() => {
            toast.show({
                message: 'Restored to default global notifications.',
            });
            setSaveLoading(false);
            if (onSave) {
                onSave();
            }
            refetch();
            close();
        }).catch((error) => {
            onErrorSavingNotifications(error);
            setSaveLoading(false);
        });
    };

    return <Modal
        modalSize={onMobileSetLarge('SMALL')}
        title={<div className="flex justify-center items-center w-8 h-8 bg-red-100 rounded-lg">
            <InfoIcon className="w-4 h-4 text-red-300"/>
        </div>}
        desktopModalWidth="lg:w-[450px]"
        isOpen={isOpen}
        close={close}>
        <div className="w-full flex flex-col px-5">
            <Typography className="m-0 mb-2" variant="LG-2" weight="font-semibold">{t('resetDefault')}</Typography>
            <Typography className="m-0 text-gray-600">{t('resetDefaultDescription', { customerName })}</Typography>
        </div>
        <div className="flex flex-row justify-end gap-x-3 px-5 mt-10">
            <Button
                variant="TERTIARY_FILLED"
                size="SMALL"
                disabled={saveLoading}
                onClick={close}>
                {tCommon('Buttons.cancel')}
            </Button>
            <Button
                variant="DESTRUCTIVE"
                size="SMALL"
                loading={saveLoading}
                onClick={():void => {
                    resetToDefault({ rules: [] }, { invoice_notifications: null, disable_reminders: false });
                }}>
                {tCommon('Buttons.save')}
            </Button>
        </div>
    </Modal>;
};
