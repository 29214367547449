import React, { FC, useEffect, useState } from 'react';
import { Badge,
    Button,
    Input,
    Loading,
    Modal,
    NONE_SELECTED,
    Select,
    toast,
    Toggle,
    Typography } from '@notch-ordering/ui-components';
import { useIntegrationsContext } from '@ar/pages/IntegrationsPage/IntegrationsContext';
import { AuthenticateSpireConfigurationBodyDto,
    AuthenticateSpireConfigurationBodySchema,
    EConfigurationType,
    SpireConfigurationDataDto,
    spireLogin,
    updateIntegrationConfiguration } from '@ar/network/Bushwhack.network';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { FETCH_SUPPLIER_CONFIGURATION_QUERY_KEY,
    useGetSpireCompanies,
    useGetSpireCompanyPaymentMethods } from '@ar/hooks/queries/IntegrationsQuery.hook';
import CheckIcon from '@icons/check-icon.svg';
import { IntegrationsTabs } from '@ar/pages/IntegrationsPage/IntegrationsPageConstants';

import { isValid, minutesToSeconds, parse, secondsToMinutes } from 'date-fns';
import { NumericFormat } from 'react-number-format';
import AlertIcon from '@icons/alert-icon.svg';
import z from 'zod';
import { UserAuthenticationProvider } from '@/auth/UserAuthenticationProvider';
import { BUSHWHACK_API_KEY } from '@/utils/constants';
import { EIntegrationType } from '@/ar/network/AccountsReceivable.network';

// const CASH_APPLICATION_WEBHOOK_URL = `${BUSHWHACK_API}/fidelio/ar-webhook?key=${BUSHWHACK_API_KEY}`;
type FormData = SpireConfigurationDataDto & {
    pollDelayMinutes?: number,
};

type LoginFormData = AuthenticateSpireConfigurationBodyDto;

const loginFormValidationSchema = AuthenticateSpireConfigurationBodySchema.omit({
    arSupplierToken: true,
    arSupplierID: true,
});

const spireConfigurationFormValidationSchema = z.object({
    companyName: z.string().trim().min(1),
    spirePaymentMethodID: z.number().int().gt(0),
    invoiceSyncStartDate: z.string().refine((arg) => isValid(parse(arg, 'yyyy-MM-dd', new Date()))),
    pollDelaySeconds: z.number().gt(0).default(900), // Poll every 15 min by default
});

enum SpireFormSteps {
    Login,
    Configuration,
}

export const SpireConnectModal: FC = () => {
    const { modals, setModal, supplierIntegrationConfiguration, setIntegrationsTab } = useIntegrationsContext();
    const { supplierLoginData } = useSupplierStore();
    const { updateSupplierIntegration } = useIntegrationsContext();
    const spireIntegrationConfiguration = supplierIntegrationConfiguration?.find((integration) => integration.type === EConfigurationType.Spire);
    const queryClient = useQueryClient();
    const SPIRE_FORM_CONFIG_INITIAL_STATE: FormData = {
        arApiToken: '',
        companyName: '',
        credentials: '',
        invoiceSyncStartDate: '',
        nonce: '',
        pollDelayMinutes: 0,
        pollDelaySeconds: 0,
        spirePaymentMethodID: 0,
        url: '',
    };
    const LOGIN_FORM_DATA_INITIAL_STATE: LoginFormData = {
        arSupplierID: '', arSupplierToken: '', password: '', url: '', username: '',
    };
    const [spireConfigForm, setSpireConfigForm] = useState<FormData>(SPIRE_FORM_CONFIG_INITIAL_STATE);
    const [loginFormData, setLoginFormData] = useState<LoginFormData>(LOGIN_FORM_DATA_INITIAL_STATE);
    const [isAuthenticationFailed, setIsAuthenticationFailed] = useState(false);
    const [isConfigurationFailed, setIsConfigurationFailed] = useState(false);
    const [formStep, setFormStep] = useState<SpireFormSteps>(SpireFormSteps.Login);
    const [isEnabled, setIsEnabled] = useState<boolean>(true);
    const result = spireConfigurationFormValidationSchema.safeParse(spireConfigForm);
    // const updateSupplierMutation = useMutation(updateSupplier);
    const cleanUp = (): void => {
        setModal(EIntegrationType.Spire, false);
        setSpireConfigForm(SPIRE_FORM_CONFIG_INITIAL_STATE);
        setFormStep(SpireFormSteps.Login);
        setIsAuthenticationFailed(false);
        setLoginFormData(LOGIN_FORM_DATA_INITIAL_STATE);
    };

    const isSubmitButtonDisabled = (): boolean => {
        if (formStep === SpireFormSteps.Login) {
            return loginFormValidationSchema.safeParse(loginFormData).success === false;
        }
        if (formStep === SpireFormSteps.Configuration) {
            return result.success === false;
        }
        return true;
    };

    const updateSupplierIntegrationMutation = useMutation(updateIntegrationConfiguration, {
        onSuccess: () => {
            toast.show({
                message: 'Integration updated successfully',
                icon: <CheckIcon/>,
            });
        },
        onError: (error) => {
            setIsConfigurationFailed(true);
            console.error(error, 'error while updating spire configuration');
        },
    });

    const spireLoginMutation = useMutation(spireLogin, {
        onSuccess: (response) => {
            // updating the supplier integration in the ar db
            updateSupplierIntegration({
                integration_name: EConfigurationType.Spire,
            });
            const data = response?.data as SpireConfigurationDataDto;
            setSpireConfigForm({
                ...data,
                pollDelayMinutes: secondsToMinutes(data.pollDelaySeconds),
            });
            setFormStep(SpireFormSteps.Configuration);
            setIsAuthenticationFailed(false);
            queryClient.invalidateQueries([FETCH_SUPPLIER_CONFIGURATION_QUERY_KEY]);
        },
        onError: (error) => {
            setIsAuthenticationFailed(true);
            console.error(error, 'error while loging to spire ');
        },
    });

    const updateSpireConfigForm = (key: keyof FormData, value: string | number): void => setSpireConfigForm((prev) => ({
        ...prev,
        [key]: value,
    }));

    const updateLoginFormData = (key: keyof LoginFormData, value: string | number): void => setLoginFormData((prev) => ({
        ...prev,
        [key]: value,
    }));

    const handleOnClose = (): void => {
        setModal(EIntegrationType.Spire, false);
        cleanUp();
    };

    const { data: spireCompanies, isLoading: isLoadingCompanies } = useGetSpireCompanies({
        enabled: formStep === SpireFormSteps.Configuration && !!supplierLoginData?.supplier_id,
        supplierID: supplierLoginData?.supplier_id,
    });

    const {
        data: spireCompanyPaymentMethods,
        isLoading: isLoadingCompanyPaymentMethods,
    } = useGetSpireCompanyPaymentMethods({
        enabled: !!spireConfigForm.companyName,
        supplierID: supplierLoginData?.supplier_id,
        companyName: spireConfigForm.companyName,
    });

    const handleSpireLogin = async (): Promise<void> => {
        const accessToken: string = await UserAuthenticationProvider.getAuthenticateStrategy()
            ?.getAccessToken();
        spireLoginMutation.mutate({
            ...loginFormData,
            arSupplierToken: accessToken,
        });
    };

    const handleConnect = async ({
        enabled,
        shouldCloseModal,
    }:{
        enabled?: boolean,
        shouldCloseModal?: boolean,
    } = {
        enabled: isEnabled,
        shouldCloseModal: false,
    }): Promise<void> => {
        if (result.success) {
            updateSupplierIntegrationMutation.mutate({
                ownerID: supplierLoginData?.supplier_id,
                type: EConfigurationType.Spire,
                data: spireConfigForm,
                isEnabled: enabled,
                id: spireIntegrationConfiguration?.id,
            }, {
                onSuccess: () => {
                    queryClient.invalidateQueries([FETCH_SUPPLIER_CONFIGURATION_QUERY_KEY]);
                    if (shouldCloseModal) {
                        cleanUp();
                        setIntegrationsTab(IntegrationsTabs.Active);
                    }
                    setIsConfigurationFailed(false);
                },
            });
        }
    };

    const isMutating = updateSupplierIntegrationMutation.isLoading || spireLoginMutation.isLoading;

    useEffect(() => {
        if (spireIntegrationConfiguration) {
            const data = spireIntegrationConfiguration.data as SpireConfigurationDataDto;
            setSpireConfigForm({
                ...data,
                pollDelayMinutes: secondsToMinutes(data.pollDelaySeconds),
            });
            setFormStep(SpireFormSteps.Configuration);
            setIsEnabled(spireIntegrationConfiguration.isEnabled);
        }
    }, [spireIntegrationConfiguration]);

    useEffect(() => {
        setLoginFormData({
            ...loginFormData,
            arSupplierID: supplierLoginData?.supplier_id,
            arSupplierToken: BUSHWHACK_API_KEY,
        });
    }, []);

    return <><Modal isOpen={modals.spire}
        title={
            <><Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="XL">
                {spireIntegrationConfiguration ? 'Manage connection' : 'Set up connection'}
            </Typography>
            <Typography variant="LG-1"
                weight="font-regular"
                className="text-gray-600">
                                Spire
            </Typography>
            </>
        }
        headerPadding="mt-0 mb-5 px-3"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL">
        <div className="p-8">
            {formStep === SpireFormSteps.Login
                && <> <div className="space-y-6">
                    {isAuthenticationFailed && <Badge variant="RED" className="py-3 px-4 mb-8">
                        <div className="w-full gap-5 flex items-center">
                            <AlertIcon className="w-5 h-5"/>
                            <div className="space-y-0.5">
                                <Typography weight="font-medium" className="text-gray-700">Unable to connect</Typography>
                                <Typography className="text-gray-600">Your login credential is invalid</Typography></div>
                        </div>

                    </Badge>}

                    <Input
                        required
                        value={loginFormData.url}
                        onChange={(e): void => updateLoginFormData('url', e.target.value)}
                        placeholder="https://"
                        label="Access URL"
                        helperText="Your Spire login URL"/>
                    <Input
                        required
                        value={loginFormData.username}
                        onChange={(e): void => updateLoginFormData('username', e.target.value)}
                        label="Username"/>
                    <Input
                        required
                        value={loginFormData.password}
                        onChange={(e): void => updateLoginFormData('password', e.target.value)}
                        type={'PASSWORD'}
                        label="Password"/>

                </div>
                </>
            }
            {formStep === SpireFormSteps.Configuration
                && <div className="space-y-6">
                    {isConfigurationFailed && <Badge variant="RED" className="py-3 px-4  mb-8">
                        <div className="w-full gap-5 flex items-center">
                            <AlertIcon className="w-5 h-5"/>
                            <div className="space-y-0.5">
                                <Typography weight="font-medium" className="text-gray-700">Unable to connect</Typography>
                                <Typography className="text-gray-700">We can’t connect to Spire right now, try again later</Typography></div>
                        </div>

                    </Badge>}

                    {!isLoadingCompanies && <Select value={spireConfigForm.companyName}
                        onChange={(e): void => {
                            if (e.target.value === NONE_SELECTED) {
                                updateSpireConfigForm('companyName', undefined);
                            } else {
                                updateSpireConfigForm('companyName', e.target.value);
                            }
                        }}
                        required
                        label="Company Name"
                        options={spireCompanies?.companies?.map((company) => ({
                            value: company.name,
                            label: company.description,
                        })) ?? []}
                        helperText="The account you want Notch to sync invoices from"
                        placeholder="Select a company"/>}
                    {isLoadingCompanies && <Loading isDark/>}
                    {isLoadingCompanyPaymentMethods && <Loading isDark/>}
                    {!isLoadingCompanyPaymentMethods && <Select value={spireConfigForm.spirePaymentMethodID}
                        required
                        disabled={!spireConfigForm.companyName}
                        onChange={(e): void => {
                            if (e.target.value === NONE_SELECTED) {
                                updateSpireConfigForm('spirePaymentMethodID', undefined);
                            } else {
                                updateSpireConfigForm('spirePaymentMethodID', +e.target.value);
                            }
                        }}
                        label="Payment Method"
                        options={spireCompanyPaymentMethods?.paymentMethods?.map((paymentMethod) => ({
                            value: paymentMethod.id,
                            label: paymentMethod.description,
                        })) ?? []}
                        helperText="The payment method which Notch will attribute payments processed via the platform to"
                        placeholder="Select a payment method"/>}

                    <Input required
                        value={spireConfigForm.invoiceSyncStartDate}
                        onChange={(e): void => updateSpireConfigForm('invoiceSyncStartDate', e.target.value)}
                        label="Sync start date"
                        inputProps={{
                            type: 'date',
                        }}
                        helperText="The issue date from which Notch will sync invoices from"/>
                    <NumericFormat customInput={Input}
                        suffix={spireConfigForm.pollDelayMinutes > 1 ? ' minutes' : ' minute'}
                        required
                        value={spireConfigForm.pollDelayMinutes}
                        onValueChange={(values): void => {
                            updateSpireConfigForm('pollDelaySeconds', minutesToSeconds(values.floatValue));
                            updateSpireConfigForm('pollDelayMinutes', values.floatValue);
                        }}
                        label="Syncing frequency"
                        helperText="How often you want to Notch to sync to your system"/>
                </div>
            }
            {formStep === SpireFormSteps.Configuration && isLoadingCompanies && <Loading isDark/>}
        </div>
        <div className="flex gap-3 items-center ">
            <div className="pt-5 px-8 flex  gap-3 w-full">
                {!spireIntegrationConfiguration && formStep === SpireFormSteps.Login
                && <Button
                    loading={isMutating}
                    className="ml-auto"
                    size="SMALL"
                    variant="SECONDARY"
                    disabled={isSubmitButtonDisabled() || isMutating}
                    onClick={handleSpireLogin}>
                    <Typography as="span" weight="font-medium">
                        Continue
                    </Typography>
                </Button>
                }

                {spireIntegrationConfiguration && formStep === SpireFormSteps.Configuration && <>
                    <Button
                        loading={isMutating}
                        size="SMALL"
                        disabled={isSubmitButtonDisabled() || isMutating}
                        onClick={
                            async ():Promise<void> => {
                                await handleConnect({
                                    shouldCloseModal: true,
                                });
                            }

                        }>
                        <Typography as="span" weight="font-medium">
                        Save changes
                        </Typography>
                    </Button>
                    <Button
                        className="ml-auto"
                        size="SMALL"
                        disabled={isSubmitButtonDisabled()}
                        onClick={async ():Promise<void> => {
                            setIsEnabled(!isEnabled);
                            await handleConnect({
                                enabled: !isEnabled,
                            });
                        }}>
                        <Typography as="span" weight="font-medium" className="flex items-center gap-2">
                            <span>{isEnabled ? 'Enabled' : 'Disabled'}</span>
                            <Toggle
                                isEnabled={isEnabled}
                                size="SMALL"
                                onChange={():void => {
                                }}/>
                        </Typography>
                    </Button>

                </>}
            </div>

        </div>
    </Modal>
    </>;
};
