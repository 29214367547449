import { z } from 'zod';

const caPostalCodeRegex = /^[ABCEGHJKLMNPRSTVXY]\d[A-Z]\s\d[A-Z]\d$/;
const usZipCodeRegex = /^\d{5}(-\d{4})?$/;
export const postalCodeSchema = z.string().trim().toUpperCase()
    .refine((code) => caPostalCodeRegex.test(code) || usZipCodeRegex.test(code), 'Please provide a valid postal code');

export const stateFieldValidation = z.string().trim().length(2, { message: 'Please provide a valid state' });

export const addressValidationSchema = z.object({
    line1: z.string().trim().min(1, { message: 'Please provide a valid address' }),
    line2: z.string().optional(),
    city: z.string().trim().min(1, { message: 'Please provide a valid city' }),
    postal_code: z.string().trim().min(1, { message: 'Please provide a valid postal code' }),
    state: stateFieldValidation,
    country: z.enum(['us', 'ca']),
});

export const validationSchema = z.object({
    first_name: z.string().optional(),
    last_name: z.string().optional(),
    address: addressValidationSchema
});
