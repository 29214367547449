import React from 'react';
import { CreatePaymentMethod } from '@ar/components/shared/modals/CreatePaymentMethod/CreatePaymentMethod';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { Button } from '@notch-ordering/ui-components';
import cx from 'classnames';
import CardIcon from '@icons/card-icon.svg';
import { useGetCustomerSupplierMappings } from '@ar/hooks/queries/CustomerQueries.hook';
import { useGetSupplier } from '@ar/hooks/queries/SupplierQueries.hook';

export interface CreditCardButtonProps {
    onClose?: () => void,
}

export const CreditCardButton : React.FC<CreditCardButtonProps> = ({ onClose }: CreditCardButtonProps) => {
    const [isAddPaymentOpen, setIsAddPaymentOpen] = React.useState<boolean>(false);
    const { supplierLoginData } = useSupplierStore();

    const { data: customerSupplierMappingsResponse } = useGetCustomerSupplierMappings({
        customerID: supplierLoginData.customer_id
    });
    const supplierID = customerSupplierMappingsResponse?.suppliers[0]?.id ?? '';
    useGetSupplier({
        supplierID,
        customerId: supplierLoginData?.customer_id
    });
    if (!supplierID) return null;
    return <>
        <Button
            className={cx('text-left flex gap-2 items-center px-4 py-3 text-gray-700 w-full')}
            variant="TERTIARY_OUTLINED"
            size="MEDIUM"
            onClick={(): void => {
                setIsAddPaymentOpen(true);
            }}>
            <div className="flex gap-3 items-center">
                <CardIcon
                    className="h-4 w-4"/> Setup a Credit Card
            </div>
        </Button>
        <CreatePaymentMethod supplierID={supplierID}
            isOpen={isAddPaymentOpen}
            handleClose={(): void => {
                if (onClose) {
                    onClose();
                }
                setIsAddPaymentOpen(false);
            }}
            customerID={supplierLoginData.customer_id} />
    </>;
};
