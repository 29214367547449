import React from 'react';
import { Button, Input, Typography } from '@notch-ordering/ui-components';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Link } from 'react-router-dom';
import { EPaths } from '@v2/constants/EPaths';
import { forgotPassword } from '@ar/network/AccountsReceivable.network';
import { useMutation } from '@tanstack/react-query';

export const forgotPasswordSchema = z.object({
    email: z.string().email({ message: 'Invalid email address' }),
});

export type ForgotPassword = z.infer<typeof forgotPasswordSchema>;

export const ForgotPasswordPage : React.FC = () => {
    const { handleSubmit, control, watch } = useForm<ForgotPassword>({
        resolver: zodResolver(forgotPasswordSchema),
        mode: 'onBlur',
        defaultValues: {
            email: ''
        }
    });

    const { isSuccess, isLoading, mutate } = useMutation(forgotPassword, {});
    const showFormView = !isSuccess;

    const { errors } = useFormState({
        control,
    });
    const onSubmit = async (data:ForgotPassword) => {
        mutate({
            body: {
                email: data.email
            }
        });
    };
    return <div className="h-screen bg-plum-500 overflow-auto">
        <div className="bg-gray-700 opacity-75 w-full h-full absolute"/>

        <div className="w-full h-full flex items-center px-2">
            <div
                className="bg-white rounded-xl sm:p-10 sm:pt-5 sm:pb-8 p-6  m-auto flex flex-col w-full md:max-w-[430px] items-center z-10">

                {showFormView && <> <Typography variant="3XL" desktopSize="m-0 text-5 pb-10 text-center">Enter your email to reset password</Typography>
                    <form className="flex flex-col gap-5 w-full" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => <Input
                                    label="Email"
                                    variant="SMALL"
                                    isInvalid={!!errors.email}
                                    invalidMessage={errors.email?.message}
                                    required
                                    inputProps={{
                                        ...field,
                                    }}/>}/>
                        </div>
                        <div className="flex flex-col gap-4">
                            <Button variant="SECONDARY"
                                className="w-full "
                                loading={isLoading}
                                type="submit">Reset password</Button>
                            <Link to={EPaths.SIGN_IN} className="text-teal-300 text-center"> Back to Log in</Link>

                        </div>
                    </form></>}
                {!showFormView && <div className="flex flex-col gap-5">
                    <Typography className="text-gray-600 m-0">If an account exists for {watch('email')}, you will get an email with instructions on resetting your password. If it doesn&apos;t arrive, be sure to check your spam folder.</Typography>
                    <Link to={EPaths.SIGN_IN} className="text-teal-300 text-center"> Back to Log in</Link>

                </div>}
            </div>
        </div>
    </div>;
};
