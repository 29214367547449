import { Separator, SeparatorProps } from '@notch-ordering/ui-components';
import { mergeClassNames } from '@v2/utils/Helpers';

import React from 'react';

export interface SeparatorInternalPageFullWidthProps {
    separatorClassName?: string,
    className?: string,
    separatorProps?: SeparatorProps,
}

export const SeparatorInternalPageFullWidth : React.FC<SeparatorInternalPageFullWidthProps> = ({
    separatorClassName = '-ml-10 w-[calc(100%+80px)] absolute bottom-0',
    className,
    separatorProps,
}) => <div className={mergeClassNames('relative', className)}> <Separator className={mergeClassNames(separatorClassName)} {...separatorProps} /></div>;
