import React, { useMemo } from 'react';
import { PopoverItem } from '@notch-ordering/ui-components';
import { useInvoiceStore } from '@ar/stores/InvoicesStore';
import { FilterLabel } from '@ar/components/shared/FilterLabel/FilterLabel';
import { FilterButton } from '@/components/shared/FilterButton';

export const InvoiceAutoPayFilterButton : React.FC = () => {
    const autoPayStatus = [{
        enabled: true,
        label: 'Enabled',
    },
    {
        enabled: false,
        label: 'Disabled',
    },
    ];
    const { searchParams, updateSearchParams, clearSelectedInvoices } = useInvoiceStore();
    const isChecked = searchParams?.has_auto_collection !== undefined;
    const autoPayItems = useMemo<PopoverItem[]>(() => autoPayStatus.map((status) => ({
        label: <FilterLabel label={status.label} isChecked={searchParams?.has_auto_collection === status.enabled}/>,
        onClick: (): void => {
            if (searchParams?.has_auto_collection !== undefined && searchParams?.has_auto_collection === status.enabled) {
                updateSearchParams({ has_auto_collection: undefined, page: 0 });
            } else {
                updateSearchParams({ has_auto_collection: status.enabled, page: 0 });
            }
            clearSelectedInvoices();
        },
    })), [searchParams?.has_auto_collection, autoPayStatus]);
    return (
        <FilterButton
            onClearFilter={():void => {
                updateSearchParams({ has_auto_collection: undefined, page: 0 });
                clearSelectedInvoices();
            }}
            isChecked={isChecked}
            items={autoPayItems}
            label={isChecked ? `Auto Pay is ${autoPayStatus.find((status) => status.enabled === searchParams?.has_auto_collection)?.label}` : 'Auto Pay'}
            closePanelOnClick={false}/>
    );
};
