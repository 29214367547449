import React from 'react';
import { ConfirmModal, ConfirmModalProps } from '@ar/components/shared/ConfirmModal';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useInvoiceStore } from '@ar/stores/InvoicesStore';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { refundPayment } from '@ar/network/AccountsReceivable.network';
import { toast, Typography } from '@notch-ordering/ui-components';
import CheckIcon from '@icons/check-icon.svg';
import { FETCH_TRANSACTION_ACTIVITY_QUERY_KEY, FETCH_TRANSACTION_QUERY_KEY,
    FETCH_TRANSACTIONS_QUERY_KEY } from '@ar/hooks/queries/InvoicesQueries.hook';
import { CurrencyInput } from '@ar/components/shared/InputCurrency';
import { getGpoId } from '@v2/utils/GPOUtils';
import { EPaymentType } from '@v2/network/CoreAPI';
import { InfoModal } from '@/ar/components/shared/InfoModal';
import { centsToDollars, dollarsToCents, formatAsCurrency } from '@/utils/Formatters';

export type RefundCustomerModalProps = Partial<ConfirmModalProps>;

type RefundAmountValue = {
    amountDollars: number,
    amountCents: number,
};
export const RefundCustomerModal : React.FC<RefundCustomerModalProps> = (props) => {
    const { modals, setModal } = useInvoiceStore();
    const { supplierLoginData } = useSupplierStore();
    const gpoId = getGpoId(supplierLoginData);
    const queryClient = useQueryClient();
    const transaction = modals?.transaction;
    const [amount, setAmount] = React.useState<RefundAmountValue>({
        amountCents: 0,
        amountDollars: 0,
    });
    const [isFailed, setIsFailed] = React.useState<boolean>(false);
    const updateAmount = (valueDollars: number): void => {
        setAmount({
            amountDollars: valueDollars,
            amountCents: dollarsToCents(valueDollars),
        });
    };
    const isAmountValid = transaction?.paid ? (amount.amountCents <= transaction?.paid) : true;
    const isValidForm = isAmountValid && amount.amountCents > 0;

    const handleOnClose = ():void => {
        if (props?.onClose) props.onClose();
        setModal('refundCustomer', false);
    };

    const handleChangeAmount = (values): void => {
        updateAmount(values.floatValue);
        setIsFailed(false);
    };

    const refundCustomerMutation = useMutation(refundPayment, {
        onSuccess: () => {
            setIsFailed(false);
            toast.show({
                message: `${formatAsCurrency(amount.amountDollars)} refund processed for Invoice ${transaction?.invoice_number}`,
                icon: <CheckIcon/>,
            });
            queryClient.invalidateQueries([FETCH_TRANSACTIONS_QUERY_KEY]);
            queryClient.invalidateQueries([FETCH_TRANSACTION_ACTIVITY_QUERY_KEY]);
            queryClient.invalidateQueries([FETCH_TRANSACTION_QUERY_KEY]);
            handleOnClose();
        },
        onError: () => {
            setIsFailed(true);
        },
    });

    const handleOnConfirm = ():void => {
        if (props?.onConfirm) props.onConfirm();
        if (isValidForm) {
            refundCustomerMutation.mutate({
                supplierID: supplierLoginData?.supplier_id,
                gpoId,
                body: {
                    transaction_id: transaction?.id,
                    amount: amount.amountCents,
                },
            });
        }
    };

    React.useEffect(() => {
        if (!modals.refundCustomer) return;
        updateAmount(0);
    }, [modals.refundCustomer]);

    if ([EPaymentType.PRE_AUTHORIZED_DEBIT, EPaymentType.US_BANK_ACCOUNT].includes(transaction?.payment_method_type as EPaymentType)) {
        return <InfoModal
            onClose={handleOnClose}
            headerPadding="mt-0 mb-6"
            isLoading={refundCustomerMutation.isLoading}
            isOpen={modals.refundCustomer}
            title={`Refund Request for Invoice #${transaction?.invoice_number}!`}
            description= {`Please email support@notch.financial with the amount you'd like to refund from this invoice. 
        We will begin to process it shortly after.`}
            {...props}/>;
    }
    return <ConfirmModal
        onClose={handleOnClose}
        headerPadding="mt-0 mb-6"
        isLoading={refundCustomerMutation.isLoading}
        isOpen={modals.refundCustomer}
        title={`Confirm refund to be issued for invoice #${transaction?.invoice_number}`}
        prompt={<div>
            <CurrencyInput
                required
                variant="SMALL"
                isInvalid={!isAmountValid}
                onChange={handleChangeAmount}
                value={amount.amountDollars}
                label={'Amount'}/>
            <Typography className="mt-1">
                Paid amount {formatAsCurrency(centsToDollars(transaction?.paid))}
            </Typography>
            {!isAmountValid && <Typography className="mt-1 text-red-500">
                Refund amount cannot be greater than the total paid amount.
            </Typography>}
            {isFailed && <Typography className="mt-1 text-red-500">
                Failed to process refund
            </Typography>}
        </div>}
        onConfirm={handleOnConfirm}
        {...props}/>;
};
