import '../../../styles/dataPage.scss';
import React, { useState } from 'react';
import { Button, Tooltip } from '@notch-ordering/ui-components';
import TrashIcon from '@icons/trash-icon.svg';

export interface Props {
    onClick: () => void,
}

/**
 * Tooltip for deleting a payment reminder
 *
 * @returns JSX Element
 */
export const DeletePaymentReminderTooltip: React.FC<Props> = ({ onClick }) => {
    const [showDeleteTooltip, setShowDeleteTooltip] = useState<boolean>(false);

    return (

        <Tooltip show={showDeleteTooltip}
            placement="top"
            className="flex"
            tooltipClassName={'py-1 px-2 rounded-lg'}
            showArrow={false}
            onShow ={(): void => { setShowDeleteTooltip(true); }}
            onHide ={(): void => { setShowDeleteTooltip(false); }}
            trigger={<Button variant="ICON"
                size="ICON_SMALL"
                as="div"
                className="ml-2 cursor-pointer"
                onClick= {(): void => { onClick(); }}>
                <div className="h-4 w-4 text-gray-600"><TrashIcon/></div>
            </Button>}>
            {'Delete'}
        </Tooltip>

    );
};
