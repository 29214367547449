import React, { useEffect } from 'react';
import { Button, Input, Modal, Separator, Toggle, Typography, toast } from '@notch-ordering/ui-components';
import { format } from 'date-fns';
import { NumericFormat } from 'react-number-format';
import CheckIcon from '@icons/check-icon.svg';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FETCH_SUPPLIER_CONFIGURATION_QUERY_KEY } from '@/ar/hooks/queries/IntegrationsQuery.hook';
import { EConfigurationType, authenticateSage300Configuration, deleteIntegrationConfiguration, updateIntegrationConfiguration } from '@/ar/network/Bushwhack.network';
import { IntegrationsTabs } from '@/ar/pages/IntegrationsPage/IntegrationsPageConstants';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { useIntegrationsContext } from '@/ar/pages/IntegrationsPage/IntegrationsContext';
import { EIntegrationType, getSupplierToken } from '@/ar/network/AccountsReceivable.network';
import { IntegrationDisconnectConfirmModal } from './IntegrationDisconnectConfirmModal';

type FormData = {
    url?: string,
    invoiceSyncStartDate?: string,
    pollDelaySeconds?: number,
    refundRevenueAccount?: string,
    refundBankCode?: string,
    syncAllCustomers?: boolean,
};

const DEFAULT_FORM_STATE = {
    url: '',
    username: '',
    password: '',
    invoiceSyncStartDate: format(new Date(), 'yyyy-MM-dd'),
    pollDelaySeconds: 900,
    refundRevenueAccount: undefined,
    refundBankCode: undefined,
    syncAllCustomers: false,
};

export const Sage300ConnectModal : React.FC = () => {
    const { modals, setModal, supplierIntegrationConfiguration, setIntegrationsTab, updateSupplierIntegration, removeSupplierIntegration } = useIntegrationsContext();
    const queryClient = useQueryClient();
    const { supplierLoginData } = useSupplierStore();
    const sageIntegrationConfiguration = supplierIntegrationConfiguration?.find((integration) => integration.type === EConfigurationType.Sage300);

    const [isEnabled, setIsEnabled] = React.useState<boolean>(true);
    const [formData, setFormData] = React.useState<FormData>(DEFAULT_FORM_STATE);
    const [userData, setUserData] = React.useState<{ username: string, password: string }>({ username: '', password: '' });
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = React.useState<boolean>(false);

    const cleanUp = ():void => {
        queryClient.invalidateQueries([FETCH_SUPPLIER_CONFIGURATION_QUERY_KEY]);
        setModal(EIntegrationType.Sage300, false);
        setFormData(DEFAULT_FORM_STATE);
    };

    const handleOnClose = (): void => {
        setModal(EIntegrationType.Sage300, false);
        cleanUp();
    };

    const deleteSupplierIntegrationMutation = useMutation(deleteIntegrationConfiguration, {
        onSuccess: () => {
            toast.show({
                message: 'Integration disconnected successfully',
                icon: <CheckIcon />,
            });
            cleanUp();
            removeSupplierIntegration();
            setIntegrationsTab(IntegrationsTabs.Active);
        },
    });

    const updateSupplierIntegrationMutation = useMutation(updateIntegrationConfiguration, {
        onSuccess: () => {
            toast.show({
                message: 'Integration updated successfully',
                icon: <CheckIcon/>,
            });
            cleanUp();
            setIntegrationsTab(IntegrationsTabs.Active);
        },
    });
    const createSageConfigurationMutation = useMutation(authenticateSage300Configuration, {
        onSuccess: () => {
            toast.show({
                message: 'Integration connected successfully',
                icon: <CheckIcon/>,
            });
            updateSupplierIntegration({
                integration_name: 'sage_300',
            });
            cleanUp();
            setIntegrationsTab(IntegrationsTabs.Active);
        },
    });

    const handleDisconnect = (): void => {
        if (sageIntegrationConfiguration) {
            deleteSupplierIntegrationMutation.mutate(sageIntegrationConfiguration.id);
        }
        setIsConfirmDeleteModalOpen(false);
    };

    const handleConnect = async () => {
        const token = await getSupplierToken(supplierLoginData.supplier_id)
            .catch(() => {
                toast.show({
                    message: 'Failed to load supplier info',
                });
                return null;
            });
        if (!token) {
            return;
        }

        if (!sageIntegrationConfiguration) {
            createSageConfigurationMutation.mutate({
                ...formData,
                ...userData,
                arSupplierID: supplierLoginData?.supplier_id,
                arSupplierToken: token,
                isEnabled,
            });
            return;
        }

        updateSupplierIntegrationMutation.mutate({
            data: {
                ...sageIntegrationConfiguration.data,
                ...formData,
            },
            ownerID: supplierLoginData?.supplier_id,
            isEnabled,
            id: sageIntegrationConfiguration.id,
        });
    };

    const isMutating = updateSupplierIntegrationMutation.isLoading || createSageConfigurationMutation.isLoading;

    useEffect(() => {
        if (sageIntegrationConfiguration) {
            const data = sageIntegrationConfiguration.data as unknown as Record<string, unknown>;
            setFormData({
                url: data.url as string,
                invoiceSyncStartDate: data.invoiceSyncStartDate as string,
                pollDelaySeconds: data.pollDelaySeconds as number,
                refundRevenueAccount: data.refundRevenueAccount as string,
                refundBankCode: data.refundBankCode as string,
                syncAllCustomers: data.syncAllCustomers as boolean ?? false,
            });
            setUserData({ username: '', password: '' });
            setIsEnabled(sageIntegrationConfiguration.isEnabled);
        }
    }, [sageIntegrationConfiguration]);

    return <>
        <Modal
            isOpen={modals.sage_300}
            title={
                <><Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="XL" >
                    Set up connection
                </Typography>
                <Typography variant="LG-1"
                    weight="font-regular"
                    className="text-gray-600" >
                        Sage 300
                </Typography>
                </>
            }
            headerPadding="mt-0 mb-5 px-3"
            titleSeparatorDesktop={true}
            close={handleOnClose}
            modalSize="SMALL">
            <div className="p-8">
                <div className="flex justify-between gap-2 items-center mb-6">
                    <Typography className="font-medium">
                    Enable connection
                    </Typography>
                    <div>
                        <Toggle
                            isEnabled={isEnabled}
                            size="SMALL"
                            onChange={(): void => {
                                setIsEnabled(!isEnabled);
                            }}/>
                    </div>
                </div>
                <Separator />
                <div className="mt-6 space-y-6" >
                    <Input
                        required
                        value={formData.url}
                        onChange={(e): void => setFormData({ ...formData, url: e.target.value })}
                        label="Sage 300 Web API"
                        helperText="The URL for the Sage 300 Web API url. This should contain the company name as well"/>
                    <Input
                        required={!!sageIntegrationConfiguration}
                        disabled={!!sageIntegrationConfiguration}
                        value={userData.username}
                        onChange={(e): void => setUserData({ ...userData, username: e.target.value })}
                        label="Username"
                        helperText="The username of the account to pull invoices and push payments with"/>
                    <Input
                        required={!sageIntegrationConfiguration}
                        disabled={!!sageIntegrationConfiguration}
                        value={userData.password}
                        onChange={(e): void => setUserData({ ...userData, password: e.target.value })}
                        label="Password"
                        helperText="The password of the account to pull invoices and push payments with"
                        type="PASSWORD" />
                    <NumericFormat customInput={Input}
                        suffix={formData.pollDelaySeconds > 60 ? ' minutes' : ' minute'}
                        required
                        value={Math.floor(formData.pollDelaySeconds / 60)}
                        onValueChange={(values): void => setFormData({ ...formData, pollDelaySeconds: Math.floor(values.floatValue * 60) })}
                        label="Syncing frequency"
                        helperText="This is how often you want to Notch to sync to your system"/>
                    <div className="flex justify-between gap-2 items-center mb-6">
                        <div className="flex flex-col gap-2">
                            <Typography className="font-medium">
                                Sync all Customers
                            </Typography>
                            <Typography as="span" variant="LG-1" className="text-gray-600">Sync all customer data from Sage 300 to Notch</Typography>
                        </div>
                        <div>
                            <Toggle
                                isEnabled={formData.syncAllCustomers}
                                size="SMALL"
                                onChange={(): void => {
                                    setFormData({ ...formData, syncAllCustomers: !formData.syncAllCustomers });
                                }} />
                        </div>
                    </div>
                    <Input
                        required
                        value={formData.invoiceSyncStartDate}
                        onChange={(e): void => setFormData({ ...formData, invoiceSyncStartDate: e.target.value })}
                        label="Invoice Sync Start Date"
                        helperText="The earliest date to pull invoices from. Leave blank for all invoices. Format must be yyyy-mm-dd"/>
                    <Input
                        value={formData.refundRevenueAccount}
                        onChange={(e): void => setFormData({ ...formData, refundRevenueAccount: e.target.value })}
                        label="Refund Revenue Account"
                        helperText="The Revenue Account to use when creating Credit Notes for refunds"/>
                    <Input
                        value={formData.refundBankCode}
                        onChange={(e): void => setFormData({ ...formData, refundBankCode: e.target.value })}
                        label="Refund Bank Code"
                        helperText="The Refund Bank Code to use when creating refunds"/>
                </div>
            </div>
            <div className="pt-5 px-8 flex  gap-3">
                {sageIntegrationConfiguration && <Button variant="DESTRUCTIVE"
                    size="SMALL"
                    loading={deleteSupplierIntegrationMutation.isLoading}
                    disabled={deleteSupplierIntegrationMutation.isLoading}
                    onClick={():void => setIsConfirmDeleteModalOpen(true)}>
                    <Typography as="span" weight="font-medium">
                        Disconnect
                    </Typography>
                </Button>}
                <div className="flex gap-3 items-center ml-auto">
                    <Button variant="TERTIARY_FILLED"
                        size="SMALL"
                        onClick={handleOnClose}>
                        <Typography as="span" weight="font-medium">
                        Cancel
                        </Typography>
                    </Button>
                    <Button variant="SECONDARY"
                        disabled={isMutating}
                        loading={isMutating}
                        size="SMALL"
                        onClick={handleConnect}>
                        <Typography as="span" weight="font-medium">
                            {sageIntegrationConfiguration ? 'Update' : 'Connect'}
                        </Typography>
                    </Button>
                </div>
            </div>
        </Modal>
        <IntegrationDisconnectConfirmModal integrationName={'Sage 300'} onConfirm={handleDisconnect} isOpen={isConfirmDeleteModalOpen} onClose={(): void => setIsConfirmDeleteModalOpen(false)}/>
    </>;
};
