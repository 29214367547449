import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getSpireCompanies,
    getSpireCompanyPaymentMethods,
    getSupplierConfiguration,
    GetSupplierConfigurationResponse,
    SpireCompaniesResponse,
    SpireCompanyPaymentMethodParams,
    SpirePaymentMethodsResponse,
    getRutterAccounts,
    GetRutterAccountsResponse,
    RutterAccountType,
    EConfigurationType } from '@ar/network/Bushwhack.network';

export const FETCH_SUPPLIER_CONFIGURATION_QUERY_KEY = 'FETCH_SUPPLIER_CONFIGURATION_QUERY_KEY';

export const useGetSupplierConfiguration = (ar_supplier_id: string): UseQueryResult<GetSupplierConfigurationResponse> => useQuery(
    [FETCH_SUPPLIER_CONFIGURATION_QUERY_KEY, ar_supplier_id],
    async () => getSupplierConfiguration(ar_supplier_id),
    { enabled: Boolean(ar_supplier_id) },
);

export const useRutterSetupStatus = (supplierId) => {
    const { data: supConfig, isLoading } = useGetSupplierConfiguration(supplierId);

    const supplierConfiguration = supConfig?.results?.find((i) => i.ownerID === supplierId);

    const isRutterSetupNotComplete = !isLoading
      && !!supplierConfiguration
      && supplierConfiguration.type === EConfigurationType.ArRutter
      && supplierConfiguration.isEnabled
      && !supplierConfiguration.data.rutterPaymentAccountID;

    return { isRutterSetupNotComplete, isLoading, supplierConfiguration };
};

export const FETCH_RUTTER_ACCOUNTS_QUERY_KEY = 'FETCH_RUTTER_ACCOUNTS_QUERY_KEY';

export const useGetRutterAccounts = (ar_supplier_id: string, syncComplete: boolean, filterByAccountType?: Set<RutterAccountType>): UseQueryResult<GetRutterAccountsResponse> => useQuery(
    [FETCH_RUTTER_ACCOUNTS_QUERY_KEY, ar_supplier_id],
    async () => getRutterAccounts(ar_supplier_id, filterByAccountType),
    { enabled: Boolean(ar_supplier_id) && syncComplete },
);

export const FETCH_SPIRE_COMPANIES_QUERY_KEY = 'FETCH_SPIRE_COMPANIES_QUERY_KEY';

export type GetSpireCompaniesParams = {
    supplierID: string,
    enabled: boolean,
};
export const useGetSpireCompanies = (params: GetSpireCompaniesParams): UseQueryResult<SpireCompaniesResponse> => {
    const { supplierID, enabled } = params;
    return useQuery(
        [FETCH_SPIRE_COMPANIES_QUERY_KEY, supplierID],
        async () => getSpireCompanies(supplierID),
        { enabled },
    );
};

export const FETCH_SPIRE_COMPANY_PAYMENT_METHODS_QUERY_KEY = 'FETCH_SPIRE_COMPANY_PAYMENT_METHODS_QUERY_KEY';
export const useGetSpireCompanyPaymentMethods = (params: SpireCompanyPaymentMethodParams): UseQueryResult<SpirePaymentMethodsResponse> => {
    const { supplierID, enabled, companyName } = params;
    return useQuery(
        [FETCH_SPIRE_COMPANY_PAYMENT_METHODS_QUERY_KEY, supplierID, companyName],
        async () => getSpireCompanyPaymentMethods(params),
        { enabled },
    );
};
