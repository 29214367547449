import React from 'react';
import { Input, InputProps } from '@notch-ordering/ui-components';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

export type CurrencyInputProps = Partial<Omit<InputProps, 'type' | 'onChange'>> & {
    prefix?: string,
    suffix?: string,
    onChange?: (value: NumberFormatValues) => void,
    defaultValue?: number,
    decimalScale?: number,
    decimalSeparator?: string,
    allowNegative?: boolean,
    thousandSeparator?: boolean | string,
    fixedDecimalScale?: boolean,
};

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
    value,
    prefix = '$',
    suffix = '',
    allowNegative = false,
    defaultValue = 0.00,
    decimalSeparator = '.',
    decimalScale = 2,
    fixedDecimalScale = true,
    thousandSeparator = ',',
    onChange = ():void => {},
    ...props
}) => <NumericFormat customInput={Input}
    {...props}
    thousandSeparator={thousandSeparator}
    fixedDecimalScale={fixedDecimalScale}
    onValueChange={onChange}
    prefix={prefix}
    allowNegative={allowNegative}
    suffix={suffix}
    defaultValue={defaultValue}
    decimalSeparator={decimalSeparator}
    decimalScale={decimalScale}
    value={value}/>;
