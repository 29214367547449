import React, { useState } from 'react';
import { Button, Separator, Tooltip, Typography } from '@notch-ordering/ui-components';
import { CreatePaymentMethod } from '@ar/components/shared/modals/CreatePaymentMethod/CreatePaymentMethod';
import AddIcon from '@icons/add-icon.svg';
import InfoIcon from '@icons/info-outline-icon.svg';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { getGPOSupplierId } from '@v2/utils/GPOUtils';
import { useGetCustomerPaymentMethods } from '@ar/hooks/queries/CustomerQueries.hook';
import { useRefetchIncompletePaymentMethods } from '@ar/hooks/useRefetchPaymentMethods';
import { LoadingPaymentMethodBanner } from '@ar/components/Invoices/Modals/BulkManualChargeModal/LoadingPaymentMethodBanner';
import { PADModal } from '@ap/components/PADModal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PaymentMethod } from './PaymentMethod/PaymentMethod';
import { PaymentMethodsEmpty } from './PaymentMethodsEmpty';

export const PaymentMethods = (): JSX.Element => {
    const { supplierLoginData } = useSupplierStore();
    const { supplier_id: supplierID, customer_id: customerID } = supplierLoginData;
    const gpoSupplierID = getGPOSupplierId(supplierID);
    const [isAddPaymentOpen, setIsAddPaymentOpen] = useState(false);
    const [shouldShowPaymentMethodsTooltip, setShouldShowPaymentMethodsTooltip] = useState(false);
    const paymentMethodsTooltipText = 'Set a default payment method for each supplier in the Suppliers tab. Override it per supplier as needed.';

    const { data: paymentMethodsResponse, refetch: refetchPaymentMethods } = useGetCustomerPaymentMethods({
        customerID,
        supplierID: gpoSupplierID
    });

    const hasPaymentMethods = paymentMethodsResponse?.payment_methods.length > 0;
    const hasIncompletePaymentMethods = useRefetchIncompletePaymentMethods({ paymentMethods: paymentMethodsResponse?.payment_methods });
    const hasOnlyOnePaymentMethod = paymentMethodsResponse?.payment_methods.length === 1;
    const flags = useFlags();

    const methods = paymentMethodsResponse?.payment_methods.map((paymentMethod) => <PaymentMethod
        key={paymentMethod.id}
        paymentMethod={paymentMethod}
        paymentMethods={paymentMethodsResponse?.payment_methods}
        customerID={customerID}
        supplierID={gpoSupplierID}
        hasOnlyOnePaymentMethod={hasOnlyOnePaymentMethod}
        onMakeDefault={refetchPaymentMethods} />);

    return <div className="w-[500px]">
        <span className="flex flex-row items-center gap-1 mt-10 mb-1">
            <Typography as="div" variant="LG-2" weight="font-semibold">Stored Payment Methods</Typography>
            <Tooltip
                show={shouldShowPaymentMethodsTooltip}
                trigger={<InfoIcon className="w-4 h-4 align-middle" onMouseEnter={(): void => setShouldShowPaymentMethodsTooltip(true)} onMouseLeave={(): void => setShouldShowPaymentMethodsTooltip(false)} />}
                placement="top"
                tooltipClassName="py-1.5 px-2 rounded-md"
                className="text-left"
                showArrow>
                <Typography className="w-60 mb-0">{paymentMethodsTooltipText}</Typography>
            </Tooltip>
        </span>
        <Typography variant="BASE" className="text-gray-600 mb-4">Manage and pre-authorize payment methods for supplier bills, ensuring one default payment method as a backup.</Typography>
        <Separator className="my-4" />
        {hasPaymentMethods ? <>
            <div className="mb-5 flex flex-col">{methods}</div>
            {hasIncompletePaymentMethods && <LoadingPaymentMethodBanner className="p-0 mb-6" />}
            <Button
                onClick={(): void => { setIsAddPaymentOpen(true); }}
                className="-ml-4 py-0"
                size="MEDIUM"
                variant="TEXT">
                <div className="flex">
                    <AddIcon width={16} className="mr-3.5 text-teal-500" />
                    <span className="text-teal-500">Add payment method</span>
                </div>
            </Button>
            {flags.customerPortalPaymentMethodsSettings ? <PADModal isOpen={isAddPaymentOpen} handleClose={(): void => setIsAddPaymentOpen(false)} fromSettingsPage/>
                : <CreatePaymentMethod isOpen={isAddPaymentOpen} handleClose={(): void => setIsAddPaymentOpen(false)} customerID={customerID} />
            }
        </> : <PaymentMethodsEmpty />}
    </div>;
};
