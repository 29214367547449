import create from 'zustand';
import { GetTransactionParams, Transaction, TransactionStatus } from '@ar/network/AccountsReceivable.network';
import { isEqual, omit, omitBy } from 'lodash';
import { DO_NOT_CHARGE_ENABLED_STATUSES,
    MANUAL_CHARGE_ENABLED_STATUSES } from '@ar/components/Invoices/InvoiceActionButtonColumn/InvoiceActionButtonColumn';
import { getUrlSearchParamsAsObject, updateUrlSearchParams } from '@/utils/UrlUtils';

type InvoiceModals = 'doNotChargeInvoice' | 'refundCustomer' | 'manualCharge' | 'addPaymentMethod' | 'bulkDoNotChargeInvoice' | 'bulkManualCharge' | 'payBill' | 'uploadInvoices';
type SearchParams = GetTransactionParams & { page?: number };
export type SelectedInvoiceMap = Record<string, Transaction>;

export type InvoiceStoreState = {
    searchParams: SearchParams,
    modals: Partial<Record<InvoiceModals, boolean>> & {
        transaction?: Transaction,
    },
    selectedInvoicesModel: string[] | number[],
    selectedInvoices: SelectedInvoiceMap,
    areAllInvoicesSelected?: boolean,

};

type InvoiceStoreActions = {
    updateSearchParams: (params: Partial<SearchParams>) => void,
    haveSearchParamsChanged?: () => boolean,
    resetPagination: () => void,
    reset: () => void,
    setModal: (modal: InvoiceModals, show: boolean, transaction?: Transaction) => void,
    setSelectedInvoicesModel: (selectedInvoicesModel) => void,
    setSelectedInvoices: (selectedInvoices: SelectedInvoiceMap) => void,
    clearSelectedInvoices: () => void,
    getSelectedInvoices: () => Transaction[],
    getDoNotChargeInvoices: () => Transaction[],
    getCurrentDncInvoices: () => Transaction[],
    getReadyToBeChargedInvoices: () => Transaction[],
    setAreAllInvoicesSelected?: (areAllInvoicesSelected: boolean) => void,
};

export type InvoiceStore = InvoiceStoreState & InvoiceStoreActions;
const searchParamsFromURL = (): Partial<SearchParams> => {
    const searchParamsEntries = getUrlSearchParamsAsObject<Partial<SearchParams>>();
    // Convert supplier_ids to an array if it's a string
    if (!!searchParamsEntries?.supplier_ids && typeof searchParamsEntries.supplier_ids === 'string') {
        searchParamsEntries.supplier_ids = (searchParamsEntries.supplier_ids as string).split(',');
    }
    return {
        ...searchParamsEntries,
    };
};
const INVOICE_STORE_INITIAL_STATE: InvoiceStoreState = {
    searchParams: {
        supplier_id: '',
        limit: 20,
        page: 0,
        ...searchParamsFromURL(), // Merge the URL search params with the default search params
    },
    modals: {
        doNotChargeInvoice: false,
        transaction: null,
        refundCustomer: false,
        manualCharge: false,
        addPaymentMethod: false,
        bulkDoNotChargeInvoice: false,
        bulkManualCharge: false,
        payBill: false,
        uploadInvoices: false,
    },
    selectedInvoicesModel: [],
    selectedInvoices: {},
};

export const MAX_INVOICES_LIMIT = 100000;

export const filterReadyToBeChargedInvoices = (invoice:Transaction) => MANUAL_CHARGE_ENABLED_STATUSES.includes(invoice.status as TransactionStatus);
export const invoiceStoreDefinition = (set, getState): InvoiceStore => ({
    ...INVOICE_STORE_INITIAL_STATE,
    reset: () => set(INVOICE_STORE_INITIAL_STATE),
    updateSearchParams: (params: Partial<SearchParams>) => set((state) => {
        const newSearchParams = {
            ...state.searchParams,
            ...params,
        };
        updateUrlSearchParams<SearchParams>(newSearchParams);
        return {
            searchParams: newSearchParams,
        };
    }),
    resetPagination: () => set((state) => ({
        searchParams: {
            ...state.searchParams,
            offset: 0,
        },
    })),
    setModal: (modal: InvoiceModals, show: boolean, transaction: Transaction = null):void => {
        set((state) => ({
            modals: {
                ...state.modals,
                transaction,
                [modal]: show,
            },

        }));
    },
    haveSearchParamsChanged: (): boolean => {
        const { searchParams } = getState();
        const ignoredKeys: (keyof SearchParams)[] = ['page', 'offset', 'supplier_id'];
        // removing from searchParams the ignored keys and undefined values for an accurate comparison
        const currentSearchParams = omitBy(omit(searchParams, ignoredKeys), (value) => value === undefined);
        const initialSearchParams = omit(INVOICE_STORE_INITIAL_STATE.searchParams, ignoredKeys);
        return !isEqual(currentSearchParams, initialSearchParams);
    },
    setSelectedInvoicesModel: (selectedInvoicesModel): void => set({ selectedInvoicesModel }),
    setSelectedInvoices: (selectedInvoices): void => set({ selectedInvoices }),
    clearSelectedInvoices: (): void => set({ selectedInvoicesModel: [], selectedInvoices: {}, areAllInvoicesSelected: false }),
    getSelectedInvoices: (): Transaction[] => {
        const { selectedInvoicesModel, selectedInvoices } = getState();
        return selectedInvoicesModel.map((id) => selectedInvoices[id]);
    },
    getDoNotChargeInvoices: (): Transaction[] => {
        const selectedInvoices = getState().getSelectedInvoices() ?? [];
        return selectedInvoices.filter((invoice) => DO_NOT_CHARGE_ENABLED_STATUSES.includes(invoice.status as TransactionStatus));
    },
    getCurrentDncInvoices: (): Transaction[] => {
        const selectedInvoices = getState().getSelectedInvoices() ?? [];
        return selectedInvoices.filter((invoice) => invoice.status as TransactionStatus === TransactionStatus.DoNotCharge);
    },
    getReadyToBeChargedInvoices: (): Transaction[] => {
        const selectedInvoices = getState().getSelectedInvoices() ?? [];
        return selectedInvoices.filter(filterReadyToBeChargedInvoices);
    },
    setAreAllInvoicesSelected: (areAllInvoicesSelected: boolean): void => set({ areAllInvoicesSelected }),

});
export const useInvoiceStore = create<InvoiceStore>(invoiceStoreDefinition);

export const mapSelectedInvoiceToLoadedInvoice = (selected: string[] = [], loadedInvoices: Transaction[] = [], selectedInvoices : SelectedInvoiceMap = {}): SelectedInvoiceMap => selected.reduce((acc, id) => {
    const invoice = loadedInvoices?.find((inv:Transaction) => inv.id === id);
    if (invoice) {
        acc[id] = invoice;
    }
    return acc;
}, { ...selectedInvoices });
