import { Button, Loading, Modal, Separator, toast, Typography } from '@notch-ordering/ui-components';
import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { attachPaymentMethodToCustomer } from '@ar/network/AccountsReceivable.network';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY } from '@ar/hooks/queries/CustomerQueries.hook';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { FETCH_BULK_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY } from '@ar/components/Invoices/Modals/BulkManualChargeModal/BulkManualChargeModalConstants';
import { FETCH_BALANCES_QUERY_KEY } from '@ar/hooks/queries/BalanceQueries.hook';
import { getGpoId } from '@v2/utils/GPOUtils';
import { drawerInsideClassName } from '@/ar/components/CustomerDetails/CustomerGeneralPopup/CustomerGeneralPopup';

export interface AddNewPaymentMethodProps {
    isOpen?: boolean,
    handleClose: () => void,
    customerID: string,
    subTitle?: string,
    supplierID?: string,
}

export const CreateStripePaymentMethod = ({ isOpen, handleClose, customerID, subTitle, supplierID }: AddNewPaymentMethodProps): JSX.Element => {
    const { supplierLoginData } = useSupplierStore();
    const queryClient = useQueryClient();
    const gpoId = getGpoId(supplierLoginData);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>(null);
    const [hasFormSubmitted, setHasFormSubmitted] = useState(false);

    const stripe = useStripe();
    const elements = useElements();
    const isFormDisabled = !stripe || !elements;

    const supplierIDToUse = supplierID ?? supplierLoginData?.supplier_id;

    const useMutationAttachPaymentMethodToCustomer = useMutation(attachPaymentMethodToCustomer);
    const options:StripeCardElementOptions = {
        style: {
            base: {
                fontFamily: 'Inter',
                fontSize: '14px',
                fontSmoothing: 'antialiased',
                color: '#111111',
                lineHeight: '20px',
                '::placeholder': {
                    // gray-600
                    color: '#6E6E6E',
                },

            },
            invalid: {
                color: '#E34F2F',
                iconColor: '#E34F2F',

            },
        },
    };

    const cleanupState = ():void => {
        setIsLoading(false);
        setErrorMessage(null);
        setHasFormSubmitted(false);
    };

    const handleOnClose = ():void => {
        handleClose();
        cleanupState();
    };

    const handleSubmit = async (event):Promise<void> => {
        event.preventDefault();
        setHasFormSubmitted(true);
        if (isFormDisabled) {
            return;
        }

        if (errorMessage) {
            return;
        }
        setIsLoading(true);
        const payload = await stripe.createToken(elements.getElement(CardElement));
        if (!payload?.token?.id) {
            setIsLoading(false);
            setErrorMessage(payload?.error.message);
            return;
        }

        useMutationAttachPaymentMethodToCustomer.mutate({
            supplierID: supplierIDToUse,
            gpoId,
            customerID,
            body: {
                token_id: payload?.token?.id,
                default: false,
            },
        }, {
            onSuccess: () => {
                setIsLoading(false);
                toast.show({
                    message: 'Payment method added successfully',
                });
                queryClient.invalidateQueries([FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY]);
                queryClient.invalidateQueries([FETCH_BALANCES_QUERY_KEY]);
                queryClient.invalidateQueries([FETCH_BULK_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY]);
                handleOnClose();
            },
            onError: () => {
                setIsLoading(false);
                toast.show({
                    message: 'Failed to add payment method',
                });
            },
        });
    };

    return <Modal isOpen={isOpen}
        title={
            <>
                <Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="LG-2">Create new payment method</Typography>
                {Boolean(subTitle) && <Typography variant="LG-1" weight="font-regular" className="text-gray-600">{subTitle}</Typography>}
            </>
        }
        headerPadding="mt-0 mb-5"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL"
        desktopModalWidth="lg:w-[372px]"
        className={drawerInsideClassName}>
        <form onSubmit={handleSubmit}>
            <div className="p-5">
                <div className="flex-col flex gap-4">
                    <div className="flex flex-col w-full gap-2">
                        <Typography as="div" weight="font-medium" variant="LG-1" className="text-gray-700">
                            <CardElement
                                options={options}
                                onChange={(event):void => {
                                    setErrorMessage(event?.error?.message ?? null);
                                }}/>
                        </Typography>
                    </div>

                </div>
                { hasFormSubmitted && errorMessage
          && <Typography variant="BASE"
              className="text-red-300 mt-4 h-4 ">{errorMessage}</Typography>}
            </div>

            <Separator/>
            <div className="pt-5 px-5 flex justify-end gap-3">
                <Button variant="TERTIARY_FILLED"
                    as={'span'}
                    className="cursor-pointer"
                    size="SMALL"
                    onClick={handleOnClose}>
                    <Typography as="span" weight="font-medium">
            Cancel
                    </Typography>
                </Button>
                <Button variant="SECONDARY"
                    disabled={isFormDisabled}
                    loading={isLoading}
                    size="SMALL">
                    <Typography as="span" weight="font-medium">
                        {isLoading ? <Loading/> : 'Save '}
                    </Typography>
                </Button>
            </div>
        </form>
    </Modal>;
};
