import create from 'zustand';
import { persist } from 'zustand/middleware';

export const NOTIFICATIONS_NAME = 'notifications';
/**
 * State used by the Notifications Page
 */
type NotificationsStore = {
    hasClickedNotificationBannerDefault: boolean,
    setHasClickedNotificationBannerDefault: (hasClicked: boolean) => void,
    hasClickedNotificationBannerCustom: boolean,
    setHasClickedNotificationBannerCustom: (hasClicked: boolean) => void,
};

export const useNotificationsStore = create<NotificationsStore>()(
    persist(
        (set): NotificationsStore => ({
            hasClickedNotificationBannerDefault: false,
            setHasClickedNotificationBannerDefault: (hasClickedNotificationBannerDefault: boolean): void => set(() => ({ hasClickedNotificationBannerDefault })),
            hasClickedNotificationBannerCustom: false,
            setHasClickedNotificationBannerCustom: (hasClickedNotificationBannerCustom: boolean): void => set(() => ({ hasClickedNotificationBannerCustom })),
        }),
        { name: NOTIFICATIONS_NAME },
    ),
);
