import React from 'react';
import { Typography } from '@notch-ordering/ui-components';

export interface SectionTitleProps {
    children: React.ReactNode,
    className?: string,
}

export const SectionTitle : React.FC<SectionTitleProps> = ({ className, children }) => <Typography variant="LG-2" weight="font-semibold" className={className}>
    {children}
</Typography>;
