import React from 'react';
import { ConfirmModal, ConfirmModalProps } from '@ar/components/shared/ConfirmModal';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useBillStore } from '@ar/stores/BillsStore';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTransactions } from '@ar/network/AccountsReceivable.network';
import { toast } from '@notch-ordering/ui-components';
import CheckIcon from '@icons/check-icon.svg';
import { FETCH_BILLS_QUERY_KEY } from '@ar/hooks/queries/InvoicesQueries.hook';

export type BulkDoNotChargeModalProps = Partial<ConfirmModalProps>;

export const BillBulkDoNotChargeModal : React.FC<BulkDoNotChargeModalProps> = (props) => {
    const { modals, setModal, getDoNotChargeInvoices, clearSelectedInvoices } = useBillStore();
    const { supplierLoginData } = useSupplierStore();
    const queryClient = useQueryClient();
    const handleOnClose = ():void => {
        if (props?.onClose) props.onClose();
        setModal('bulkDoNotChargeInvoice', false);
    };
    const doNotChargeInvoices = getDoNotChargeInvoices();
    const { isLoading, mutate } = useMutation(updateTransactions);

    const handleOnConfirm = ():void => {
        if (props?.onConfirm) props.onConfirm();
        const doNotChargeInvoicesIDs = doNotChargeInvoices.map((invoice) => invoice.id);
        mutate({
            customerID: supplierLoginData?.customer_id,
            body: {
                transaction_ids: doNotChargeInvoicesIDs,
                do_no_charge: true,
            }
        }, {
            onSuccess: () => {
                handleOnClose();
                clearSelectedInvoices();
                queryClient.invalidateQueries([FETCH_BILLS_QUERY_KEY]);
                toast.show({
                    message: `${doNotChargeInvoices.length} bill${doNotChargeInvoices.length > 1 ? 's' : ''} marked as disputed.`,
                    icon: <CheckIcon/>,
                });
            },
            onError: (error) => {
                console.error(error);
                toast.show({
                    message: 'Failed to update bill(s) status',
                });
            },
        });
    };
    return <ConfirmModal
        onClose={handleOnClose}
        isLoading={isLoading}
        isOpen={modals.bulkDoNotChargeInvoice}
        title={`Confirm that you want ${doNotChargeInvoices.length} bill${doNotChargeInvoices.length > 1 ? 's' : ''} to be marked as disputed.`}
        prompt={
            <React.Fragment>
                <span className="mb-2 block">No automatic payments will be attempted for the following bills:</span>
                <ul className="mb-2">
                    {doNotChargeInvoices.map((invoice) => (
                        <li key={invoice.id}>#{invoice.invoice_number}</li>
                    ))}
                </ul>
            </React.Fragment>
        }
        onConfirm={handleOnConfirm}
        {...props}/>;
};
