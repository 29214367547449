import React from 'react';
import { Activity, ActivityType } from '@ar/network/AccountsReceivable.network';
import { mergeClassNames } from '@v2/utils/Helpers';
import { Loading, Separator, Typography } from '@notch-ordering/ui-components';
import { format, isValid } from 'date-fns';
import { notifyPDFGenerationResult, useGeneratePDFForTransactions } from '@/ar/hooks/mutations/InvoiceMutations';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { openUrlInNewTab } from '@/utils/UrlUtils';

export interface InvoiceEventItemProps {
    event: Activity,
    isLast: boolean,
    isFirst: boolean,
    isBill?: boolean,
}

const removeTimestampFromDescription = (description: string = ''): string => description.replace('T00:00:00.000Z', '');
const transformEventDescription = (description: string = '', isBill:boolean = false): string => {
    if (!isBill) return description;
    return description.replace(/Invoice/gi, 'Bill').replace(/invoice/gi, 'bill');
};

interface InvoiceEventDescriptionProps {
    activity: Activity,
    isBill?: boolean,
}

const InvoiceEventDescription:React.FC<InvoiceEventDescriptionProps> = ({ activity, isBill }) => {
    const eventDescription = removeTimestampFromDescription(activity.description ?? '');
    const generatePDFForTransactionsMutation = useGeneratePDFForTransactions();
    const { supplierLoginData } = useSupplierStore();
    const handleGeneratePDF = async (invoiceID: string) => {
        // Prevent multiple clicks
        if (generatePDFForTransactionsMutation.isLoading) return;
        // Generate PDF for the transaction
        generatePDFForTransactionsMutation.mutate({
            supplierID: supplierLoginData?.supplier_id,
            body: {
                transaction_ids: [invoiceID],
            }
        }, {
            onSuccess: async (data) => {
                // Open the PDF in a new tab
                const pdfUrl = data?.urls[0];
                openUrlInNewTab(pdfUrl);
                await notifyPDFGenerationResult(true);
            },
            onError: async () => {
                await notifyPDFGenerationResult(false);
            }

        });
    };
    switch (activity.type) {
        case ActivityType.InvoicePdfAttached: {
            const invoicePdfUrl = ('invoice_pdf' in activity.payload) ? activity.payload.invoice_pdf : '';
            return <>Attached invoice <a className="text-teal-500"
                href={invoicePdfUrl}
                target="_blank"
                rel="noreferrer noopener">pdf</a></>;
        }
        case ActivityType.RefundStatus: {
            const failureMessage = ('failure_message' in activity.payload && activity.payload?.failure_message) ? `(${activity.payload.failure_message})` : '';
            return <>{eventDescription} {failureMessage}</>;
        }
        // this is the case where the invoice is generated for on demand pdf supplier configuration only
        case ActivityType.InvoicePdfGenerationRequested: {
            return <div className="flex items-center" >Attached invoice <button
                className="text-teal-500 cursor-pointer flex items-center bg-transparent"
                onClick={() => handleGeneratePDF(activity.entity_id)}>pdf </button>
            {generatePDFForTransactionsMutation.isLoading && <Loading isDark/>}

            </div>;
        }
        default:
            return <>{transformEventDescription(eventDescription, isBill)}</>;
    }
};

export const InvoiceEventItem : React.FC<InvoiceEventItemProps> = ({ event, isFirst, isLast, isBill }) => {
    const eventDate = isValid(new Date(event.created_at)) ? new Date(event.created_at) : null;

    return <div key={event.id} className="flex flex-col gap-2 pr-5">
        <div className="flex gap-5 items-baseline relative">
            {!isLast && <div
                className="absolute h-[calc(100%-6px)] w-px border-gray-300 border-dashed border-l left-2 top-6"/>}
            <div className="h-full">
                <div
                    className={`w-4 h-4 rounded-full flex items-center justify-center ${isFirst ? 'bg-green-100' : 'bg-gray-200'}`}>
                    <div className={mergeClassNames('w-2 h-2 rounded-full', {
                        'bg-green-300': isFirst,
                        'bg-gray-600': !isFirst,
                    })}/>
                </div>
            </div>
            <div className="w-full">
                <div
                    className="flex lg:flex-row flex-col items-start justify-between gap-3 lg:gap-5 w-full mb-5 max-w-full">
                    <Typography as="div"
                        className="font-medium text-gray-600 lg:w-8/12 break-words flex-grow-0">
                        <InvoiceEventDescription activity={event} isBill={isBill}/>
                    </Typography>
                    {!!eventDate && <Typography as="div"
                        className="font-regular text-gray-600 lg:min-w-[160px] text-right lg:w-4/12">{format(eventDate, 'MMM d, yyyy, hh:mm a')}</Typography>}
                </div>
                {!isLast && <Separator/>}
            </div>
        </div>
    </div>;
};
