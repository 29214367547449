import React from 'react';
import { Button, Loading, toast, Typography } from '@notch-ordering/ui-components';
import { useParams } from 'react-router-dom';
import { emailInvoicePDF, EmailInvoicePDFParams } from '@ar/network/AccountsReceivable.network';
import { useMutation } from '@tanstack/react-query';
import CheckIcon from '@icons/check-icon.svg';
import WarningIcon from '@icons/warning-icon.svg';

export const EmailInvoicePDFPage : React.FC = () => {
    const { isSuccess, isLoading, mutate, isError } = useMutation(emailInvoicePDF, {
        onSuccess: () => {
            toast.show({
                icon: <CheckIcon />,
                message: 'Invoice sent successfully!',
            });
        },
        onError: () => {
            toast.show({
                message: 'An error occurred',
            });
        }
    });
    const showFormView = !isSuccess;
    // get the supplier_id and transaction_id from the url
    const { supplier_id: supplierID, transaction_id: transactionID } = useParams<EmailInvoicePDFParams['body']>();

    const handleSendEmail = async () => {
        if (!supplierID || !transactionID) {
            return;
        }
        mutate({ body: { supplier_id: supplierID, transaction_id: transactionID } });
    };

    return <div className="h-screen bg-white overflow-auto flex flex-col justify-center items-start lg:p-40 p-10">
        {isError && <Typography as="div" className="flex gap-2 items-center text-red-500 mb-4"><WarningIcon className="w-4 h-4" /> <span>Error occurred. Please try again.</span> </Typography>}
        {showFormView && <div>

            <Button variant="SECONDARY" disabled={isLoading} className="w-full flex items-center gap-2" onClick={handleSendEmail}><span>Get invoice attachment</span> {isLoading && <Loading />}</Button>

        </div>}
        {!showFormView
            && <p>Thanks! If you’re the intended recipient, please check your email for the attachments. If you don’t receive them, reach out to your supplier for assistance</p>

        }

    </div>;
};
