import React from 'react';
import { Outlet } from 'react-router-dom';

/**
 * The AR Payouts Wrapper page
 *
 * @returns JSX Element
 */
export const PayoutsWrapperPage = (): JSX.Element => (<Outlet/>
);
