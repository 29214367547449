import React from 'react';
import { Typography } from '@notch-ordering/ui-components';
import { mergeClassNames } from '@v2/utils/Helpers';

export interface PageTitleProps {
    children: React.ReactNode,
    className?: string,
}

export const PageTitle = ({ children, className }: PageTitleProps): JSX.Element => (
    <Typography
        weight="font-medium"
        as="h1"
        variant="4XL"
        className={mergeClassNames('m-0', className)} >
        {children}
    </Typography>
);
