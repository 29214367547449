import { Button,
    DragAndDropUpload,
    Typography, Badge } from '@notch-ordering/ui-components';
import React, { DragEvent, useRef } from 'react';
import { tCommon, tNamespace } from '@v2/i18n';
import UploadIcon from '@icons/upload-icon.svg';
import TrashIcon from '@icons/trash-icon.svg';
import { isPlatform } from '@ionic/react';
import { uploadImageActionSheet } from '@v2/utils/UploadImageMobile';
import { usePhotoGallery } from '@v2/hooks/usePhotoGallery';
import DangerIcon from '@icons/danger-icon.svg';
import isValidFileType from '@v2/utils/isValidFileType';
import AttachmentIcon from '@icons/file-attachment-icon.svg';
import { mergeClassNames } from '@v2/utils/Helpers';
import { useTranslation } from 'react-i18next';
import { EIonicPlatforms } from '@/constants/Mobile';

const ALLOWED_FILE_TYPES = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
const areFilesValid = (files: File[], allowedFileTypes: string[]): boolean => !files.some((file) => !isValidFileType(file, allowedFileTypes));

export const FileThumbnail = ({ file, allowedFileTypes, thumbnailLabel }: { file: File, allowedFileTypes: string[], thumbnailLabel?: string }): JSX.Element => {
    const isFileTypeAllowed = isValidFileType(file, allowedFileTypes);
    const isImage = file.type.includes('image');

    if (!isFileTypeAllowed) {
        return <DangerIcon className="w-8 h-8"/>;
    }

    if (isImage) {
        return <img className="w-8 h-8 rounded-md overflow-hidden" src={URL.createObjectURL(file)} alt={file.name} />;
    }

    return <div className="flex flex-col py-1 items-center pointer-events-none">
        <AttachmentIcon className="relative top-1.5 w-4 h-4 text-gray-700" />
        <Badge size="SMALL" className="z-10 mb-1 rounded" widthPadding="px-1" backgroundColor="bg-red-200">
            <Typography className="p-0 m-0" variant="SM" desktopSize="text-1">
                {thumbnailLabel}
            </Typography>
        </Badge>
    </div>;
};

export interface FileUploadInputState {
    files: File[],
    valid: boolean,
}
export interface FileUploadInputProps {
    files: File[],
    onChange?: (state: FileUploadInputState) => void,
    allowedFileTypes?: string[],
    multiple?: boolean,
    thumbnailLabel?: string,
}

export const FileUploadInput : React.FC<FileUploadInputProps> = ({
    files, onChange,
    allowedFileTypes = ALLOWED_FILE_TYPES,
    multiple = true,
    thumbnailLabel = 'PDF'
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices' });
    const [isSelectingImage, setIsSelectingImage] = React.useState<boolean>(false);

    const { takePhoto, pickImages } = usePhotoGallery();
    const hasFilesSelected = files.length > 0;
    const inputRef = useRef(null);
    const isNativeDevice = isPlatform(EIonicPlatforms.CAPACITOR);
    const fileInputTypeDesc = files.length === 0 ? tCommon('Labels.onlyPdfImagesAccepted') : '';

    const setFiles = (newFiles: File[]): void => {
        const valid = areFilesValid(newFiles, allowedFileTypes);
        onChange({ files: newFiles, valid });
    };

    const filterAndMergeSelectedFiles = (filesList: File[]): File[] => {
        if (!multiple) {
            return filesList;
        }
        // Filter out files that are already selected

        const newFiles = filesList.filter((file) => {
            const isFileAlreadySelected = files.some((prevFile) => prevFile.name === file.name);
            return !isFileAlreadySelected;
        });
        return [...files, ...newFiles];
    };
    const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>):void => {
        const { files: filesList } = event.target;
        if (filesList) {
            setFiles(filterAndMergeSelectedFiles(Array.from(filesList)));
        }
    };

    const onClickUploadFile = (): void => {
        inputRef.current.click();
    };

    const onDropFiles = (event: DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files) {
            setFiles(filterAndMergeSelectedFiles(Array.from(event.dataTransfer.files)));
        }
    };

    const onDragFiles = (event: DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleCamera = async ():Promise<void> => {
        const file = await takePhoto();

        if (file) {
            setFiles([...files, file]);
        }
    };

    const handlePhotoSelection = async ():Promise<void> => {
        const filesSelected = await pickImages();
        setFiles(filterAndMergeSelectedFiles(filesSelected));
    };

    const uploadImageSelect = async ():Promise<void> => {
        setIsSelectingImage(true);
        await uploadImageActionSheet({
            handleCamera,
            handlePhotoSelection,
        });
        setIsSelectingImage(false);
    };

    const handleClickDeleteFile = (idx: number) => (): void => {
        const newFiles = [...files];
        newFiles.splice(idx, 1);
        setFiles(newFiles);
    };

    return <>
        {hasFilesSelected && <div className="flex flex-col gap-2.5 mb-2.5">
            {
                files.map((file, idx) => {
                    const isFileTypeAllowed = isValidFileType(file, allowedFileTypes);

                    return (
                        <div
                            className={mergeClassNames(
                                'flex gap-2 items-center justify-between py-4 px-4 border border-gray-300 rounded-lg shadow-z2',
                                {
                                    'py-3 px-3': !isFileTypeAllowed,
                                }
                            )}
                            key={file.name + idx}
                            data-testid="uploaded-file">
                            <FileThumbnail file={file} allowedFileTypes={allowedFileTypes} thumbnailLabel={thumbnailLabel} />
                            <Typography className="m-0 p-0 truncate">
                                {isNativeDevice ? file.name.split('/').pop() : file.name}
                                {!isFileTypeAllowed && <Typography as="span" className="text-red-500 block">{t('invalidFileType')}</Typography>}
                            </Typography>
                            <Button
                                variant="ICON"
                                className="min-w-0 w-4 h-4 p-0 ml-auto"
                                onClick={handleClickDeleteFile(idx)}>
                                <TrashIcon className="w-4 h-4 text-gray-500" />
                            </Button>
                        </div>
                    );
                })
            }
        </div>}

        {!isNativeDevice && <DragAndDropUpload
            multiple={multiple}
            className="hover:bg-white hover:border-dashed !border-gray-400 !p-4"
            icon={<UploadIcon className="w-4 h-4 text-gray-600"/>}
            onInputChange={handleFilesChange}
            onClick={onClickUploadFile}
            onDrop={onDropFiles}
            onDragOver={onDragFiles}
            fileTypes={allowedFileTypes.join(', ')}
            uploadDesc={tCommon('Labels.clickToUploadImg')}
            typeDesc={fileInputTypeDesc}
            inputRef={inputRef}/>}
        {isNativeDevice && <div className="flex flex-col">
            <Button
                onClick={uploadImageSelect}
                className="w-full"
                variant="TERTIARY_FILLED"
                size="MEDIUM"
                loading={isSelectingImage}>
                <div className="flex justify-center items-center gap-2">
                    <UploadIcon className="w-5 h-5 text-gray-700"/>
                    <Typography as="span" weight="font-medium">{tCommon('Buttons.upload')}</Typography>
                </div>
            </Button>
            <Typography className="text-gray-600 mb-0 my-3 text-left" variant="BASE">
                {tCommon('Labels.onlyPdfImagesAccepted')}
            </Typography>
        </div>}
    </>;
};
