import React from 'react';
import { IntegrationsPage } from '@ar/pages/IntegrationsPage/IntegrationsPage';
import { IntegrationsProvider } from '@ar/pages/IntegrationsPage/IntegrationsContext';

/**
 * The AR Integrations Wrapper page
 *
 * @returns JSX Element
 */
export const IntegrationsWrapperPage = (): JSX.Element => (
    <IntegrationsProvider>
        <IntegrationsPage />
    </IntegrationsProvider>
);
