import React from 'react';
import CustomersEmptyStateIcon from '@ar/assets/images/customers-empty-state.svg';
import { Typography } from '@notch-ordering/ui-components';

interface CustomersEmptyStateProps {
    message?: string,
}

const CustomersEmptyState = ({ message } : CustomersEmptyStateProps): JSX.Element => (
    <div className="flex flex-col items-center justify-center flex-1 text-center">
        <CustomersEmptyStateIcon className="w-64 mb-10"/>
        <Typography variant="XL" className="mb-2">{`${message ?? 'You don\'t have any customers added yet.'}`}</Typography>
        <Typography variant="BASE" className="text-gray-600 max-w-xs">When an integration is linked to your organization, your customers will be imported and show up here for easy management.</Typography>
    </div>
);

export default CustomersEmptyState;
