import { ArTokenStrategyBase } from '@/auth/authStrategies/ArTokenStrategyBase';
import { TokenData } from '@/auth/authStrategies/TokenData';
import { EStrategyName } from './EStrategyName';
import { IAuthenticateStrategy } from './IAuthenticateStrategy';

const { accountsReceivableApi } = process.env.BASE_URLS || {};

/**
 * Handles authentication using a supplier token from be-auth
 */
export class ArTokenStrategy extends ArTokenStrategyBase implements IAuthenticateStrategy {
    /**
     *
     */
    public static get strategyName(): EStrategyName {
        return EStrategyName.ArToken;
    }

    /**
     *
     */
    public constructor(tokenData: TokenData) {
        super(tokenData, ArTokenStrategy.strategyName);
    }

    /**
     *
     */
    public static async login(email: string, password: string): Promise<ArTokenStrategy | null> {
        const token = await ArTokenStrategyBase.getToken(`${accountsReceivableApi}/sign_in`, {
            email,
            password,
        });

        if (!token) {
            return null;
        }
        return new ArTokenStrategy(token);
    }
}
