import React, { useEffect } from 'react';
import { Button,
    Modal,
    Select,
    Separator,
    toast,
    Toggle,
    Typography } from '@notch-ordering/ui-components';
import { IntegrationModals, useIntegrationsContext } from '@ar/pages/IntegrationsPage/IntegrationsContext';
import { deleteIntegrationConfiguration,
    EConfigurationType,
    RutterAccountType,
    updateIntegrationConfiguration } from '@ar/network/Bushwhack.network';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { FETCH_SUPPLIER_CONFIGURATION_QUERY_KEY, useGetRutterAccounts } from '@ar/hooks/queries/IntegrationsQuery.hook';
import CheckIcon from '@icons/check-icon.svg';
import { IntegrationDisconnectConfirmModal } from '@ar/components/Integrations/IntegrationModals/IntegrationDisconnectConfirmModal';
import { IntegrationsTabs } from '@ar/pages/IntegrationsPage/IntegrationsPageConstants';

export interface RutterModalProps {
    integrationType: IntegrationModals,
    integrationName: string,
}

export const RutterModal : React.FC<RutterModalProps> = ({ integrationType, integrationName }) => {
    const { modals, setModal, supplierIntegrationConfiguration, isRutterIntegrationSyncComplete, setIntegrationsTab, removeSupplierIntegration } = useIntegrationsContext();
    const { supplierLoginData } = useSupplierStore();
    const queryClient = useQueryClient();

    const integrationConfiguration = supplierIntegrationConfiguration?.find((integration) => integration.type === EConfigurationType.ArRutter && integration.ownerID === supplierLoginData.supplier_id);

    const [isEnabled, setIsEnabled] = React.useState<boolean>(integrationConfiguration?.isEnabled);
    const [rutterPaymentAccountIDValue, setRutterPaymentAccountIDValue] = React.useState<string>(integrationConfiguration?.data?.rutterConnectionID);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = React.useState<boolean>(false);

    let filterByAccountType: Set<RutterAccountType>;
    if (integrationConfiguration.type === EConfigurationType.ArRutter) {
        // The Rutter ID of the Account linked to the invoice payment. This should be a bank account.
        // Reference: https://docs.rutter.com/rest/2023-02-07/invoice-payments#create-an-invoice-payment
        filterByAccountType = new Set();
        filterByAccountType.add('bank');
        filterByAccountType.add('other_current_asset');
        filterByAccountType.add('fixed_asset');
    }
    const { data: rutterAccounts, isLoading: isLoadingRutterAccounts } = useGetRutterAccounts(supplierLoginData.supplier_id, isRutterIntegrationSyncComplete, filterByAccountType);
    const accountOptions = isLoadingRutterAccounts ? [] : rutterAccounts?.accounts?.map((account) => ({
        label: account.name,
        value: account.id,
    }));

    const cleanUp = ():void => {
        queryClient.invalidateQueries([FETCH_SUPPLIER_CONFIGURATION_QUERY_KEY]);
        setIsEnabled(integrationConfiguration?.isEnabled);
        setRutterPaymentAccountIDValue(integrationConfiguration?.data?.rutterConnectionID);
        setModal(integrationType, false);
    };

    const { mutate: mutateDelete } = useMutation(deleteIntegrationConfiguration, {
        onSuccess: () => {
            toast.show({
                message: 'Integration disconnected successfully',
                icon: <CheckIcon/>,
            });
            cleanUp();
            removeSupplierIntegration();
            setIntegrationsTab(IntegrationsTabs.Active);
        },
    });

    const { mutate: mutateUpdate, isLoading: isLoadingMutation } = useMutation(updateIntegrationConfiguration, {
        onSuccess: () => {
            toast.show({
                message: 'Integration updated successfully',
                icon: <CheckIcon/>,
            });
            cleanUp();
            setIntegrationsTab(IntegrationsTabs.Active);
        },
    });

    const handleOnClose = (): void => {
        setModal(integrationType, false);
        cleanUp();
    };

    const handleDisconnect = (): void => {
        if (integrationConfiguration) {
            mutateDelete(integrationConfiguration?.id);
        }
        setIsConfirmDeleteModalOpen(false);
    };

    const handleUpdate = (): void => {
        mutateUpdate({
            ...integrationConfiguration,
            data: { ...integrationConfiguration.data, rutterPaymentAccountID: rutterPaymentAccountIDValue },
            isEnabled,
        });
    };

    useEffect(() => {
        if (integrationConfiguration) {
            const { rutterPaymentAccountID } = integrationConfiguration.data;
            setIsEnabled(integrationConfiguration.isEnabled);
            setRutterPaymentAccountIDValue(rutterPaymentAccountID);
        }
    }, [integrationConfiguration]);

    return <><Modal isOpen={modals.netsuite || modals.qbo}
        title={
            <><Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="XL" >
                Manage integration
            </Typography>
            <Typography variant="LG-1"
                weight="font-regular"
                className="text-gray-600" >
                {integrationName}
            </Typography>
            </>
        }
        headerPadding="mt-0 mb-5 px-3"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL">
        <div className="p-8">
            <div className="flex justify-between gap-2 items-center mb-6">
                <Typography className="font-semibold">
                    Enable connection
                </Typography>
                <div>
                    <Toggle
                        isEnabled={isEnabled}
                        size="SMALL"
                        onChange={(): void => {
                            setIsEnabled(!isEnabled);
                        }}/>
                </div>
            </div>

            <Separator/>

            {isRutterIntegrationSyncComplete === false
                ? <div className="flex flex-col items-left  mt-5 w-full">
                    <Typography className="m-0 pb-5 text-red-300"
                        weight="font-medium">
                        {`Please wait until the initial sync of ${integrationName} is complete before updating the integration settings.`}</Typography>
                </div>
                : <div className="mt-6 space-y-4" >
                    <Typography className="font-semibold p-0 m-0">
                        Payment location
                    </Typography>
                    <Select
                        value={rutterPaymentAccountIDValue}
                        onChange={(e):void => setRutterPaymentAccountIDValue(e.target.value)}
                        options={accountOptions}
                        placeholder="Select"
                        disabled={isLoadingRutterAccounts}/>
                </div>
            }

        </div>

        <div className="pt-5 px-8 flex  gap-3">
            {integrationConfiguration && <Button variant="DESTRUCTIVE"
                size="SMALL"
                onClick={():void => setIsConfirmDeleteModalOpen(true)}>
                    Disconnect
            </Button>}
            <div className="flex gap-3 items-center ml-auto">
                <Button variant="TERTIARY_FILLED"
                    size="SMALL"
                    onClick={handleOnClose}>
                        Cancel
                </Button>
                <Button variant="SECONDARY"
                    disabled={isLoadingMutation || isLoadingRutterAccounts}
                    loading={isLoadingMutation}
                    size="SMALL"
                    onClick={handleUpdate}>
                        Save
                </Button>
            </div>
        </div>
    </Modal>
    <IntegrationDisconnectConfirmModal integrationName={integrationName} onConfirm={handleDisconnect} isOpen={isConfirmDeleteModalOpen} onClose={(): void => setIsConfirmDeleteModalOpen(false)}/>
    </>;
};
