import React, { useEffect, useState } from 'react';
import LogoIcon from '@icons/logo-icon.svg';
import { isPlatform } from '@ionic/react';
import { Button, Input, toast, Typography } from '@notch-ordering/ui-components';
import EyeIcon from '@icons/eye-icon.svg';
import EyeOffIcon from '@icons/eye-off-icon.svg';
import { BUCKET_ASSETS_STATIC_URL } from '@v2/constants/Assets';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { LETS_TALK } from '@v2/constants/ExternalUrls';
import { Link } from 'react-router-dom';
import { EPaths } from '@v2/constants/EPaths';
import { EIonicPlatforms } from '@/constants/Mobile';
import { ILoginFormState, LOGIN_FORM_INITIAL_STATE, LOGIN_FORM_LABELS, SESSION_EXPIRED_KEY } from './constants';
import { emailRegex } from '@/redux-form/helpers';
import loginFormStyles from './styles.module.scss';
import { UserAuthenticationProvider } from '@/auth/UserAuthenticationProvider';
import { AmplitudeEventActions, useAmplitude } from '@/containers/app/AmplitudeContext';
import { useSupplierStore } from '@/ar/stores/SupplierStore';

export const LoginForm: React.FC = () => {
    const showRequestADemoLink = !isPlatform(EIonicPlatforms.CAPACITOR);
    const { t } = useTranslation(tNamespace, { keyPrefix: 'SignIn' });
    const fromSubdomain = window.fromSubdomain || '';
    const logoLink = `${BUCKET_ASSETS_STATIC_URL}images/${fromSubdomain}.png`;
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [formState, setFormState] = useState<ILoginFormState>(
        LOGIN_FORM_INITIAL_STATE
    );
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const { setAmplitudeUserId, trackAmplitudeEvent, setAmplitudeUserProperties } = useAmplitude();
    const { supplierLoginData } = useSupplierStore();
    const isFormDisabled = formState.isLoading || !isFormValid;

    const updateFormState = (key, value): void => {
        setFormState((prevState) => ({
            ...prevState,
            [key]: value
        }));
    };

    const onInputChange = (event): void => {
        const { id, value } = event.target;
        updateFormState(id, value);
    };

    const handleSubmit = async (event): Promise<void> => {
        event.preventDefault();
        try {
            if (isFormValid) {
                setFormState((prevState) => ({
                    ...prevState,
                    isLoading: true
                }));
                await UserAuthenticationProvider.loginUser(formState.username, formState.password).then(() => {
                    window.location.reload();
                    setAmplitudeUserId(formState.username);
                    setAmplitudeUserProperties({
                        isCustomer: supplierLoginData.isCustomer,
                        isCustomerAndSupplier: supplierLoginData.isCustomerAndSupplier,
                        isSupplier: supplierLoginData.isSupplier,
                        isGpo: supplierLoginData.gpo,
                        currency: supplierLoginData.currency
                    });
                    trackAmplitudeEvent(AmplitudeEventActions.signIn, { userId: formState.username });
                });
                setFormState((prevState) => ({
                    ...prevState,
                    isLoading: false
                }));
            }
        } catch (ex) {
            console.log(ex);
            setFormState((prevState) => ({
                ...prevState,
                isLoading: false
            }));
            toast.show({
                message: 'Invalid credentials',
                showClose: true
            });
        }
    };

    useEffect(() => {
        const validateForm = formState.username
            && formState.password
            && emailRegex.test(formState.username);
        setIsFormValid(validateForm);
    }, [formState]);

    useEffect(() => {
        if (window.localStorage.getItem(SESSION_EXPIRED_KEY) === '1') {
            window.localStorage.setItem(SESSION_EXPIRED_KEY, '0');
            toast.show({
                message: 'Your session has expired',
                showClose: true,
            });
        }
    }, []);

    return (

        <div className="w-full h-full flex items-center px-5 z-10">
            <div
                className="bg-white rounded-xl sm:p-10 sm:pt-5 sm:pb-8 p-6 pt-0 m-auto flex flex-col w-full sm:max-w-[430px] items-center z-10">
                {!fromSubdomain
                    ? <LogoIcon className="h-[18px] w-[69px] relative sm:-top-[118px] -top-[60px] text-white"/>
                    : <img className="w-[150px] h-[150px] my-5" src={logoLink}/>}
                <div className="flex flex-col items-stretch w-full">
                    <div className="flex flex-col justify-between">
                        <Typography variant="3XL" desktopSize="m-0 text-7 pb-1">{t('signIn')}</Typography>
                        {showRequestADemoLink && <div className="flex flex-row">
                            <Typography className="text-gray-600 m-0">{t('notUsingNotch')}</Typography>
                            <a className="ml-1 text-teal-300" href={LETS_TALK}>
                                {t('getStarted')}
                            </a></div>}
                    </div>
                    <form className={loginFormStyles.loginForm} onSubmit={handleSubmit}>
                        <div>
                            <Input label="Email address"
                                id={LOGIN_FORM_LABELS.username.name}
                                type="EMAIL"
                                value={formState.username}
                                required={true}
                                className="pb-5 mt-10"
                                onChange={onInputChange}/>
                            <div className="relative">
                                <Input label="Password"
                                    id={LOGIN_FORM_LABELS.password.name}
                                    type={showPassword ? 'TEXT' : 'PASSWORD'}
                                    value={formState.password}
                                    required={true}
                                    className="pb-8"
                                    inputClassName="pr-10"
                                    onChange={onInputChange}/>
                                {showPassword ? <div
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                    onClick={(): void => setShowPassword(false)}
                                    role={'button'}>
                                    <EyeOffIcon className="text-gray-600 inline-flex w-4 h-4"/>
                                </div> : <div
                                    className=" absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                    onClick={(): void => setShowPassword(true)}
                                    role={'button'}>
                                    <EyeIcon className="text-gray-600 inline-flex w-4 h-4"/>
                                </div>}
                            </div>
                        </div>
                        <div>
                            <Button variant="SECONDARY"
                                className="w-full mb-4"
                                loading={formState.isLoading}
                                disabled={isFormDisabled}
                                isDarkLoader
                                type="submit">{t('signIn')}</Button>

                        </div>
                    </form>
                    <Link to={EPaths.FORGOT_PASSWORD} className="text-teal-300 text-center">Reset password</Link>
                </div>
            </div>
        </div>

    );
};

export default LoginForm;
