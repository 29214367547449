import React, { useState } from 'react';
import { Button, Popover } from '@notch-ordering/ui-components';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import { Payout } from '@ar/network/AccountsReceivable.network';
import { SinglePayoutTransactionExportModal } from '@ar/components/Payouts/SinglePayoutTransactionExportModal';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@ar/constants/TrackingConstants';

export interface PayoutsMoreColumnProps {
    payout: Payout,
}

export const PayoutsMoreColumn : React.FC<PayoutsMoreColumnProps> = ({ payout }) => {
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    return <>
        {isExportModalOpen && <SinglePayoutTransactionExportModal isOpen={isExportModalOpen}
            close={():void => setIsExportModalOpen(false)}
            payoutID={payout.id}/>}
        <Popover
            className="w-44"
            button={
                <Button
                    variant="TERTIARY_TABLE"
                    className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                    size="SMALL"
                    stopPropagation={false}>
                    <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                        <EllipsisIcon className="h-4 w-4"/>
                    </div>
                </Button>
            }
            items={
                [
                    {
                        label: 'Export',
                        onClick: ():void => {
                            setIsExportModalOpen(true);
                            sendTrackingEvent(TrackingEvents.exportListOfPayoutClicked);
                        },
                    },
                ]
            }/>
    </>;
};
