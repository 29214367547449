import React from 'react';
import { useGetSupplier } from '@ar/hooks/queries/SupplierQueries.hook';
import { Button, Loading, toast, Typography } from '@notch-ordering/ui-components';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import BankingConnectImg from '@ar/assets/images/banking-connect.svg';
import { verifyPaymentReceiver } from '@ar/network/AccountsReceivable.network';
import { useMutation } from '@tanstack/react-query';

export const BankingAdyen : React.FC = () => {
    const { supplierLoginData } = useSupplierStore();
    const { data: supplierData, isLoading } = useGetSupplier({
        supplierID: supplierLoginData?.supplier_id,
    });
    const verifyPaymentReceiverMutation = useMutation(verifyPaymentReceiver, {
        onSuccess: (data) => {
            window.open(data.url, '_blank');
        },
        onError: () => {
            toast.show({
                message: 'There was an error while verifying the payment receiver. Please try again.',
            });
        }
    });

    const handleClickSetup = (): void => {
        verifyPaymentReceiverMutation.mutate({
            supplierID: supplierLoginData?.supplier_id,
            paymentReceiverID: supplierData?.payment_receiver_id,
        });
    };

    return (
        <>
            {!isLoading
                && (<div className="lg:w-[478px] lg:mx-auto mt-20"> <div className="mb-9">
                    <BankingConnectImg className="w-[183px] h-[142px] mx-auto mb-6"/>
                    <Typography variant="LG-2" className="font-semibold mb-1">
                        Set up your payment account to begin receiving payouts
                    </Typography>
                    <Typography className="text-gray-600">
                        By securely creating your payment account, you can start receiving payouts for your customer&apos;s payments. This process should only take about 10 minutes to complete.
                    </Typography>
                </div>
                <Button variant="PRIMARY"
                    size="SMALL"
                    loading={verifyPaymentReceiverMutation.isLoading}
                    disabled={verifyPaymentReceiverMutation.isLoading}
                    className="bg-teal-500 w-full mb-8 hover:bg-teal-500"
                    onClick={handleClickSetup}>
                            Start setup
                </Button>
                <Typography variant="LG-1" className="font-medium mb-2">
                            Have questions?
                </Typography>
                <Typography className="text-gray-600 mb-4">
                    The payment account setup allows you to receive payments from your customers through a platform like Notch.
                </Typography><Typography className="text-gray-600">
                        By securely linking your bank account with the payment account, you can receive payouts and payments from your customers with the added benefit of Notch’s platform. Learn more.
                </Typography></div>)

            }

            {isLoading && (<Loading isDark/>)}

        </>
    );
};
