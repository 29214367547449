// tracking constant events

export const TrackingEventCategory = {
    bills: 'Bills',
    ordering: 'Ordering',
    catalogManagement: 'Catalog Management',
    integrations: 'Integrations',
    navigation: 'Navigation',
    onboarding: 'Onboarding',
    payments: 'Payments',
    staff: 'Staff',
    ocr: 'OCR',
} as const;

export type TrackingEvent = {
    category?: typeof TrackingEventCategory[keyof typeof TrackingEventCategory],
    action: string,
    label?: string,
    value?: number,
};

export const TrackingEventActions = {
    // Bills
    addBillClicked: 'add_bill_clicked',
    addButtonClicked: 'add_button_clicked',
    addPaymentClicked: 'add_payment_clicked',
    addProductsClicked: 'add_products_clicked',
    addSupplierClicked: 'add_supplier_clicked',
    addToCart: 'add_to_cart',
    addToSavedProducts: 'add_to_saved_products',
    allSavedProductsClicked: 'all_saved_products_clicked',
    billApprovedClicked: 'bill_approved_clicked',
    billRequestApprovalClicked: 'bill_request_approval_clicked',
    billSubmitUploadClicked: 'bill_submit_upload_clicked',
    catalogBulkUploadConfirm: 'catalog_bulk_upload_confirm',
    catalogFileUploadContinue: 'catalog_file_upload_continue',
    catalogMatchingFieldContinue: 'catalog_matching_field_continue',
    chatButtonClicked: 'chat_button_clicked',
    checkoutStarted: 'checkout_started',
    checkoutStep1: 'checkout_step_1',
    checkoutStep2: 'checkout_step_2',
    createProductClicked: 'create_product_clicked',
    createStaffClicked: 'create_staff_clicked',
    createSupplierClicked: 'create_supplier_clicked',
    customOrderGuideClicked: 'custom_order_guide_clicked',
    emptyHomeAddSupplierClicked: 'empty_home_add_supplier_clicked',
    enterProductSearchBar: 'enter_product_search_bar',
    exportCatalogClicked: 'export_catalog_clicked',
    integrationsClicked: 'integrations_clicked',
    navigationMenuClicked: 'navigation_menu_clicked',
    newItemClicked: 'new_item_clicked',
    newStaffClicked: 'new_staff_clicked',
    onboardingBillsClicked: 'onboarding_bills_clicked',
    onboardingCatalogClicked: 'onboarding_catalog_clicked',
    onboardingStepExpandClicked: 'onboarding_step_expand_clicked',
    onboardingIntegrationsClicked: 'onboarding_integrations_clicked',
    onboardingShopClicked: 'onboarding_shop_clicked',
    onboardingSkipStepClicked: 'onboarding_skip_step_clicked',
    onboardingStaffClicked: 'onboarding_staff_clicked',
    onboardingSuppliersClicked: 'onboarding_suppliers_clicked',
    openCreateNewSupplierClicked: 'open_create_new_supplier_clicked',
    orderConfirmation: 'order_confirmation',
    placeOrderClicked: 'place_order_clicked',
    productQuantityDecreased: 'product_quantity_decreased',
    productQuantityIncreased: 'product_quantity_increased',
    refreshSyncClicked: 'refresh_sync_clicked',
    removeFromSavedProducts: 'remove_from_saved_products',
    setUpCatalogClicked: 'set_up_catalog_clicked',
    setupSuppliersClicked: 'setup_suppliers_clicked',
    shopFilterClicked: 'shop_filter_clicked',
    shopSupplierClicked: 'shop_supplier_clicked',
    supplierCardClicked: 'supplier_card_clicked',
    uploadCatalogClicked: 'upload_catalog_clicked',
    venueSelected: 'venue_selected',
    venueSwitcherClicked: 'venue_switcher_clicked',
    viewCart: 'view_cart',
    viewFullCatalogClicked: 'view_full_catalog_clicked',
    // OCR tracking event actions
    accountingButtonClicked: 'accounting_button_clicked',
    approveButtonClicked: 'approve_button_clicked',
    imsButtonClicked: 'ims_button_clicked',
    saveAnywaysButtonClicked: 'save_anyways_button_clicked',
    // AR tracking event actions
    signInClicked: 'sign_in_clicked',
    goToNextPageClicked: 'go_to_next_page_clicked',
    goToPreviousPageClicked: 'go_to_previous_page_clicked',
    moreButtonClicked: 'more_button_clicked',
    filterSelected: 'filter_selected',
    enterSearchInvoice: 'enter_search_invoice',
    invoiceSelected: 'invoice_selected',
    chargeInvoiceClicked: 'charge_invoice_clicked',
    sendInvoiceClicked: 'send_invoice_clicked',
    invoicePaidEventSyncManuallyClicked: 'invoice_paid_event_sync_manually_clicked',
    doNotChargeClicked: 'do_not_charge_clicked',
    refundClicked: 'refund_clicked',
    exitActionPanel: 'exit_action_panel',
    enterSearchCustomer: 'enter_search_customer',
    customerRowClicked: 'customer_row_clicked',
    editCustomerClicked: 'edit_customer_clicked',
    payoutRowClicked: 'payout_row_clicked',
    exportListOfPayoutClicked: 'export_list_of_payout_clicked',
    exportPayoutDetailsClicked: 'export_payout_details_clicked',
} as const;

export const TrackingEvents: Partial<Record<keyof typeof TrackingEventActions, TrackingEvent>> = {
    // Bills
    addBillClicked: {
        category: TrackingEventCategory.bills,
        action: TrackingEventActions.addBillClicked,
    },
    refreshSyncClicked: {
        category: TrackingEventCategory.bills,
        action: TrackingEventActions.refreshSyncClicked,
    },
    addButtonClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.addButtonClicked,
    },
    addPaymentClicked: {
        category: TrackingEventCategory.payments,
        action: TrackingEventActions.addPaymentClicked
    },
    addProductsClicked: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.addProductsClicked,
    },
    addSupplierClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.addSupplierClicked,
    },
    addToCart: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.addToCart,
    },
    addToSavedProducts: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.addToSavedProducts,
    },
    allSavedProductsClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.allSavedProductsClicked,
    },
    billApprovedClicked: {
        category: TrackingEventCategory.bills,
        action: TrackingEventActions.billApprovedClicked,
    },
    billRequestApprovalClicked: {
        category: TrackingEventCategory.bills,
        action: TrackingEventActions.billRequestApprovalClicked,
    },
    billSubmitUploadClicked: {
        category: TrackingEventCategory.bills,
        action: TrackingEventActions.billSubmitUploadClicked,
    },
    catalogBulkUploadConfirm: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.catalogBulkUploadConfirm,
    },
    catalogFileUploadContinue: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.catalogFileUploadContinue,
    },
    catalogMatchingFieldContinue: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.catalogMatchingFieldContinue,
    },
    chatButtonClicked: {
        category: TrackingEventCategory.navigation,
        action: TrackingEventActions.chatButtonClicked,
    },
    checkoutStarted: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.checkoutStarted,
    },
    checkoutStep1: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.checkoutStep1,
    },
    checkoutStep2: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.checkoutStep2,
    },
    createProductClicked: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.createProductClicked
    },
    createStaffClicked: {
        category: TrackingEventCategory.staff,
        action: TrackingEventActions.createStaffClicked
    },
    createSupplierClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.createSupplierClicked
    },
    customOrderGuideClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.customOrderGuideClicked,
    },
    emptyHomeAddSupplierClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.emptyHomeAddSupplierClicked
    },
    enterProductSearchBar: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.enterProductSearchBar,
    },
    exportCatalogClicked: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.exportCatalogClicked,
    },
    integrationsClicked: {
        category: TrackingEventCategory.integrations,
        action: TrackingEventActions.integrationsClicked
    },

    newItemClicked: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.newItemClicked
    },
    newStaffClicked: {
        category: TrackingEventCategory.staff,
        action: TrackingEventActions.newStaffClicked
    },
    onboardingBillsClicked: {
        category: TrackingEventCategory.onboarding,
        action: TrackingEventActions.addBillClicked
    },
    onboardingCatalogClicked: {
        category: TrackingEventCategory.onboarding,
        action: TrackingEventActions.onboardingCatalogClicked
    },
    onboardingIntegrationsClicked: {
        category: TrackingEventCategory.onboarding,
        action: TrackingEventActions.onboardingIntegrationsClicked
    },
    onboardingShopClicked: {
        category: TrackingEventCategory.onboarding,
        action: TrackingEventActions.onboardingShopClicked
    },
    onboardingSkipStepClicked: {
        category: TrackingEventCategory.onboarding,
        action: TrackingEventActions.onboardingSkipStepClicked
    },
    onboardingStaffClicked: {
        category: TrackingEventCategory.onboarding,
        action: TrackingEventActions.onboardingStaffClicked
    },
    onboardingStepExpandClicked: {
        category: TrackingEventCategory.onboarding,
        action: TrackingEventActions.onboardingStepExpandClicked
    },
    onboardingSuppliersClicked: {
        category: TrackingEventCategory.onboarding,
        action: TrackingEventActions.onboardingSuppliersClicked
    },
    openCreateNewSupplierClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.openCreateNewSupplierClicked
    },
    orderConfirmation: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.orderConfirmation,
    },
    placeOrderClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.placeOrderClicked,
    },
    productQuantityDecreased: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.productQuantityDecreased,
    },
    productQuantityIncreased: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.productQuantityIncreased,
    },
    removeFromSavedProducts: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.removeFromSavedProducts,
    },
    setUpCatalogClicked: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.setUpCatalogClicked,
    },
    setupSuppliersClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.setupSuppliersClicked
    },
    shopFilterClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.shopFilterClicked,
    },
    shopSupplierClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.shopSupplierClicked,
    },
    supplierCardClicked: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.supplierCardClicked
    },
    uploadCatalogClicked: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.uploadCatalogClicked,
    },
    venueSelected: {
        category: TrackingEventCategory.navigation,
        action: TrackingEventActions.venueSelected,
    },
    venueSwitcherClicked: {
        category: TrackingEventCategory.navigation,
        action: TrackingEventActions.venueSwitcherClicked,
    },
    viewCart: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.viewCart,
    },
    viewFullCatalogClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.viewFullCatalogClicked,
    },
    // OCR tracking events
    accountingButtonClicked: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.accountingButtonClicked,
    },
    approveButtonClicked: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.approveButtonClicked,
    },
    imsButtonClicked: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.imsButtonClicked,
    },
    saveAnywaysButtonClicked: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.saveAnywaysButtonClicked,
    },
    // AR tracking events

    invoiceSelected: {
        action: TrackingEventActions.invoiceSelected,
    },
    moreButtonClicked: {
        action: TrackingEventActions.moreButtonClicked,
    },
    chargeInvoiceClicked: {
        action: TrackingEventActions.chargeInvoiceClicked,
    },
    sendInvoiceClicked: {
        action: TrackingEventActions.sendInvoiceClicked,
    },
    invoicePaidEventSyncManuallyClicked: {
        action: TrackingEventActions.invoicePaidEventSyncManuallyClicked,
    },
    doNotChargeClicked: {
        action: TrackingEventActions.doNotChargeClicked,
    },
    customerRowClicked: {
        action: TrackingEventActions.customerRowClicked,
    },
    editCustomerClicked: {
        action: TrackingEventActions.editCustomerClicked,
    },
    enterSearchCustomer: {
        action: TrackingEventActions.enterSearchCustomer,
    },
    enterSearchInvoice: {
        action: TrackingEventActions.enterSearchInvoice,
    },
    exitActionPanel: {
        action: TrackingEventActions.exitActionPanel,
    },
    exportListOfPayoutClicked: {
        action: TrackingEventActions.exportListOfPayoutClicked,
    },
    exportPayoutDetailsClicked: {
        action: TrackingEventActions.exportPayoutDetailsClicked,
    },
    filterSelected: {
        action: TrackingEventActions.filterSelected,
    },
    goToNextPageClicked: {
        action: TrackingEventActions.goToNextPageClicked,
    },
    payoutRowClicked: {
        action: TrackingEventActions.payoutRowClicked,
    },
    refundClicked: {
        action: TrackingEventActions.refundClicked,
    },
    signInClicked: {
        action: TrackingEventActions.signInClicked,
    },
    goToPreviousPageClicked: {
        action: TrackingEventActions.goToPreviousPageClicked,
    },
};
