import React, { useState } from 'react';
import { Transaction, TransactionStatus } from '@ar/network/AccountsReceivable.network';
import { InvoiceStatusBadge } from '@ar/components/Invoices/InvoiceStatusBadge';
import { Tooltip, Typography } from '@notch-ordering/ui-components';
import { getBillStatus } from '@ap/pages/BillsPage/BillsPageConstants';
import { centsToDollars, formatAsCurrency } from '@/utils/Formatters';

export interface InvoiceAmountColumnProps {
    invoice: Transaction,
    isBill?: boolean,
}

export type InvoiceStatusTooltipProps = {
    message: string,
    trigger: React.ReactNode,
    status: TransactionStatus,
};

export const ACTION_REQUIRED_TOOLTIP_MESSAGE = 'try another card';
export const InvoiceStatusTooltip: React.FC<InvoiceStatusTooltipProps> = ({ message, trigger, status }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const isActionRequired = status === TransactionStatus.RequiresAction || status === TransactionStatus.RequiresAction3DS;
    const tooltipMessage = message.concat(isActionRequired ? `, ${ACTION_REQUIRED_TOOLTIP_MESSAGE}` : '');
    return <Tooltip
        show={showTooltip}
        trigger={<div onMouseEnter={(): void => setShowTooltip(true)} onMouseLeave={(): void => setShowTooltip(false)}>
            {trigger}
        </div>}
        placement="top"
        tooltipClassName="py-1.5 px-2 rounded-md"
        className="text-left">
        <Typography>{tooltipMessage} </Typography>
    </Tooltip>;
};

const TOOLTIP_STATUSES:TransactionStatus[] = [TransactionStatus.RequiresAction, TransactionStatus.RequiresAction3DS, TransactionStatus.Failed];
export const InvoiceAmountColumn: React.FC<InvoiceAmountColumnProps> = ({ invoice, isBill }) => {
    const tooltipInvoiceStatus = invoice.status as TransactionStatus;
    const invoiceStatus = isBill ? getBillStatus(tooltipInvoiceStatus) : invoice.status;
    const shouldShowTooltip = TOOLTIP_STATUSES.includes(tooltipInvoiceStatus) && !!invoice.error_message;
    const invoiceStatusBadge = <InvoiceStatusBadge status={invoiceStatus as TransactionStatus}/>;
    return <div className="gap-4 flex items-center ">
        <div className="w-20 truncate">{formatAsCurrency(centsToDollars(invoice.total))}</div>
        {shouldShowTooltip ? <InvoiceStatusTooltip status={tooltipInvoiceStatus} message={invoice.error_message} trigger={invoiceStatusBadge}/> : invoiceStatusBadge}

    </div>;
};
