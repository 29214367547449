import '../../../styles/dataPage.scss';
import React, { useEffect, useState } from 'react';
import { PageTitle } from '@ar/components/PageTitle';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { Button, Loading, Separator, toast, Toggle, Typography } from '@notch-ordering/ui-components';
import { useMutation } from '@tanstack/react-query';
import AlertIcon from '@icons/alert-icon.svg';
import { useTranslation } from 'react-i18next';
import { tCommon, tNamespace } from '@v2/i18n';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { updateSupplier, UpdateSupplierData } from '../../network/AccountsReceivable.network';
import { useGetSupplier } from '@/ar/hooks/queries/SupplierQueries.hook';

type NotificationsSettings = {
    enable_payment_notifications: boolean,
};

const onErrorSavingSupplier = (error):void => {
    console.info(error, 'error');
    toast.show({
        message: 'Could not save settings.',
        icon: <AlertIcon className="text-red-300"/>,
    });
};

/**
 * The Supplier Notifications Page
 *
 * @returns JSX Element
 */
export const SupplierNotificationsPage = function (): JSX.Element {
    useDocumentTitle('Notifications - Notch');

    const { supplierLoginData } = useSupplierStore();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Notifications' });

    const { data: supplierData, isLoading, refetch: refetchSupplierData } = useGetSupplier({
        supplierID: supplierLoginData?.supplier_id,
    });
    const [notificationSettings, setNotificationSettings] = useState<NotificationsSettings>(null);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);

    const updateSupplierMutation = useMutation(updateSupplier);
    const handleSupplierUpdate = (body:UpdateSupplierData):void => {
        setSaveLoading(true);
        updateSupplierMutation.mutate(
            {
                supplierID: supplierLoginData?.supplier_id,
                body,
            },
            {
                onSuccess: () => {
                    toast.show({
                        message: t('changesSaved'),
                    });
                    setSaveLoading(false);
                    refetchSupplierData();
                },
                onError: (error) => {
                    onErrorSavingSupplier(error);
                    setSaveLoading(false);
                    refetchSupplierData();
                },
            }
        );
    };

    useEffect(() => {
        if (supplierData) {
            const data:NotificationsSettings = {
                enable_payment_notifications: supplierData.enable_payment_notifications,
            };
            setNotificationSettings(data);
        }
    }, [supplierData]);

    return (
        <>
            <div className="-mx-10">
                <PageTitle className="pl-10 mb-2 !tracking-normal">{t('notifications')}</PageTitle>
                <Typography className="text-gray-600 mb-9 pl-10">{t('configureNotifications')}</Typography>
                <Separator />
                {!isLoading && !!notificationSettings && <>
                    <div className="px-10 mt-10 font-body">
                        <Typography variant="LG-2" className="font-semibold mb-6">
                            {t('payments')}
                        </Typography>
                        <div className="space-y-6">
                            <div className="flex items-center justify-between gap-2">
                                <div><Typography className="font-medium mb-1">
                                    {t('sendEmail')}
                                </Typography>
                                <Typography className="text-gray-600">
                                    {t('sendEmailDescription')}
                                </Typography></div>
                                <div><Toggle isEnabled={notificationSettings.enable_payment_notifications}
                                    size="SMALL"
                                    onChange={(): void => {
                                        setNotificationSettings({ enable_payment_notifications: !notificationSettings.enable_payment_notifications });
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <Button
                            disabled={saveLoading}
                            loading={saveLoading}
                            className = "mt-10"
                            variant="SECONDARY"
                            size="SMALL"
                            onClick={(): void => {
                                handleSupplierUpdate({
                                    enable_payment_notifications: notificationSettings.enable_payment_notifications,
                                });
                            }}
                            minWidth="w-auto">
                            {tCommon('Buttons.save')}
                        </Button>
                    </div>
                </>}
                {isLoading && (<Loading isDark className="mt-10"/>)}
            </div>
        </>
    );
};
