import React from 'react';
import { ConfirmModal } from '@ar/components/shared/ConfirmModal';
import DangerIcon from '@icons/danger-icon.svg';

export interface IntegrationDisconnectConfirmModalProps {
    isOpen: boolean,
    onConfirm: () => void,
    integrationName: string,
    onClose: () => void,

}

export const IntegrationDisconnectConfirmModal : React.FC<IntegrationDisconnectConfirmModalProps> = ({
    isOpen,
    onConfirm,
    integrationName,
    onClose,
}) => <ConfirmModal isOpen={isOpen}
    title={<div className="flex flex-col gap-4">
        <DangerIcon className="w-8 h-8 text-red-500" />
        <div>Are you sure you want to disconnect {integrationName}?</div>
    </div>}
    confirmVariant={'DESTRUCTIVE'}
    confirmLabel={'Disconnect'}
    description={'Disconnecting this integration will no longer allow invoices to sync between your system and Notch.'}
    onConfirm={onConfirm}
    onClose={onClose}/>;
