import PropTypes from "prop-types";
import React from "react";
import LoginForm from "@v2/components/SignIn/LoginForm/LoginForm";
import withUseIsMobileWidth from "../../domains/shared/hooks/withUseIsMobileWidth";
import { EPaths } from "@v2/constants/EPaths";
import IntercomMobile from '@v2/components/ThirdParty/Intercom/IntercomMobile';

const SignInMain = ({ submitSignInForm, error }) => {
  return (
    <div>
      <div className="bg-plum-500 w-full h-full absolute" />
      <div className="bg-gray-700 opacity-75 w-full h-full absolute" />
      <LoginForm />
      <div className="fixed bottom-4 right-4">
        <IntercomMobile location={{ pathname: EPaths.SIGN_IN }} />
      </div>
    </div>
  );
};

SignInMain.propTypes = {
  error: PropTypes.bool.isRequired,
  submitSignInForm: PropTypes.func.isRequired,
};

export default withUseIsMobileWidth(SignInMain);
