import React, { useMemo, useState } from 'react';
import { InvoiceCustomerFilterButton } from '@ar/components/Invoices/InvoiceFilters/InvoiceCustomerFilterButton';
import { InvoiceStatusFilterButton } from '@ar/components/Invoices/InvoiceFilters/InvoiceStatusFilterButton';
import debounce from 'lodash/debounce';
import { useInvoiceStore } from '@ar/stores/InvoicesStore';
import { SearchInput } from '@ar/components/shared/SearchInput/SearchInput';
import { InvoiceRangeDateFilterButton } from '@ar/components/Invoices/InvoiceFilters/InvoiceRangeDateFilterButton';
import { InvoiceAutoPayFilterButton } from '@ar/components/Invoices/InvoiceFilters/InvoiceAutoPayFilterButton';
import { TrackingEvents } from '@ar/constants/TrackingConstants';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { InvoiceSupplierFilterButton } from '@/ar/components/Invoices/InvoiceFilters/InvoiceSupplierFilterButton';
import { useSupplierStore } from '@/ar/stores/SupplierStore';

const DEBOUNCE_SEARCH_QUERY_DELAY = 100;

export const InvoiceFilters : React.FC = () => {
    const { updateSearchParams, resetPagination, searchParams, clearSelectedInvoices } = useInvoiceStore();
    const { supplierLoginData } = useSupplierStore();
    const [searchQuery, setSearchQuery] = useState<string>(searchParams.invoice_number ?? '');
    const debounceSearchQuery = useMemo(() => debounce((query:string) => {
        if (query === '') {
            updateSearchParams({ invoice_number: undefined, page: 0 });
            return;
        }
        updateSearchParams({ invoice_number: query, page: 0 });
        resetPagination();
        clearSelectedInvoices();
    }, DEBOUNCE_SEARCH_QUERY_DELAY), []);
    const handleQueryChange = (query: string = ''):void => {
        setSearchQuery(query);
        debounceSearchQuery(query);
    };
    return <div className="flex flex-col gap-3 py-6">
        <SearchInput onChange={(value):void => handleQueryChange(value as string)}
            value={searchQuery}
            onFocus={() => sendTrackingEvent(TrackingEvents.enterSearchInvoice)}
            inputProps={{
                placeholder: 'Search for invoice number',
            }
            }/>
        <div className="flex gap-2 flex-wrap">
            <InvoiceStatusFilterButton/>
            <InvoiceCustomerFilterButton/>
            {supplierLoginData.gpo && <InvoiceSupplierFilterButton/>}
            <InvoiceRangeDateFilterButton label={'Due Date'}
                initialEndDate={searchParams?.due_date_end ? new Date(searchParams?.due_date_end) : undefined}
                initialStartDate={searchParams?.due_date_start ? new Date(searchParams?.due_date_start) : undefined}
                onDateRangeChange={({ endDateUTC, startDateUTC }):void => {
                    const params = {
                        due_date_start: startDateUTC ?? undefined,
                        due_date_end: endDateUTC ?? undefined,
                        page: 0,
                    };
                    updateSearchParams(params);
                    clearSelectedInvoices();
                }}/>
            <InvoiceRangeDateFilterButton
                initialStartDate={searchParams?.invoice_date_start ? new Date(searchParams?.invoice_date_start) : undefined}
                initialEndDate={searchParams?.invoice_date_end ? new Date(searchParams?.invoice_date_end) : undefined}

                label={'Issue Date'}
                onDateRangeChange={({ startDateUTC, endDateUTC }):void => {
                    const params = {
                        invoice_date_start: startDateUTC ?? undefined,
                        invoice_date_end: endDateUTC ?? undefined,
                        page: 0,
                    };
                    updateSearchParams(params);
                    clearSelectedInvoices();
                }}/>

            <InvoiceAutoPayFilterButton/>
        </div>
    </div>;
};
