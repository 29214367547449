import '../../../../../styles/dataPage.scss';
import React, { useEffect, useState, useContext, useReducer } from 'react';
import { CustomerDetailsContext } from '@ar/pages/CustomerDetailsWrapperPage/CustomerDetailsContext';
import { useGetSupplier, useGetSupplierNotifications } from '@ar/hooks/queries/SupplierQueries.hook';
import { Badge,
    Button,
    Combobox,
    ComboOption,
    Loading,
    Separator,
    toast,
    Toggle,
    Typography } from '@notch-ordering/ui-components';
import AddIcon from '@icons/add-icon.svg';
import AlertIcon from '@icons/alert-icon.svg';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { tCommon, tNamespace } from '@v2/i18n';
import { NotificationsData,
    NotificationsRules,
    UpdateCustomerData,
    updateCustomer,
    updateCustomerNotificationRules } from '../../../../network/AccountsReceivable.network';
import { DeletePaymentReminderTooltip } from '../../../../pages/NotificationsPageV2/DeletePaymentReminderTooltip';
import { ENotificationType, ERuleType,
    PaymentReminders,
    paymentReminderAfterOptions,
    paymentReminderBeforeOptions } from '../../../../pages/NotificationsPageV2/NotificationsDefault/NotificationsDefaultConstants';

type CustomNotificationsTabProps = {
    resetButtonEnabled?: boolean,
    refetchDataGrid?: () => void,
};

type NotificationsErrors = {
    afterDueDate?: TypeErrors[],
    beforeDueDate?: TypeErrors[],
};

type TypeErrors = {
    type?: boolean,
};

const MAXIMUM_NUMBER_OF_COMBOBOXES = 14;

type AddNewRuleButtonProps = {
    onClick: () => void,
    isDisabled: boolean,
    label: string,
};

const AddNewRuleButton: React.FC<AddNewRuleButtonProps> = ({ onClick, isDisabled, label }) => <Button
    className="cursor-pointer"
    variant={'LINK'}
    size={'NO_BACKGROUND'}
    as="div"
    onClick={onClick}
    disabled={isDisabled}>
    <div className="-ml-2 flex gap-x-3 mt-0 items-center">
        <AddIcon className="w-4 h-4" />
        <Typography className="mb-0" weight="font-medium">{label}</Typography>
    </div>
</Button>;

const onErrorSavingNotifications = (error): void => {
    console.info(error, 'error');
    toast.show({
        message: 'Could not save settings.',
        icon: <AlertIcon className="text-red-300"/>,
        showClose: false,
    });
};

const mapInitialReminderRules = (notifications: NotificationsData): PaymentReminders => ({
    onDueDate: notifications?.rules?.filter((item) => item.type === ERuleType.ON_DUE_DATE),
    afterDueDate: notifications?.rules?.filter((item) => item.type === ERuleType.AFTER_DUE_DATE),
    beforeDueDate: notifications?.rules?.filter((item) => item.type === ERuleType.BEFORE_DUE_DATE),
});

const MAP_DUE_DATE_TYPE_TO_RULE_TYPE = {
    onDueDate: ERuleType.ON_DUE_DATE,
    beforeDueDate: ERuleType.BEFORE_DUE_DATE,
    afterDueDate: ERuleType.AFTER_DUE_DATE,
};

const DEFAULT_REMINDER_RULE = {
    onDueDate: [{ type: ERuleType.ON_DUE_DATE, interval: 0 }],
    beforeDueDate: [{ type: ERuleType.BEFORE_DUE_DATE, interval: 1 }],
    afterDueDate: [{ type: ERuleType.AFTER_DUE_DATE, interval: 1 }],
};

type SelectedPaymentReminders = {
    onDueDate: NotificationsRules[],
    beforeDueDate: NotificationsRules[],
    afterDueDate: NotificationsRules[],
};

type NotificationsSettingsState = {
    invoiceNotifications: boolean | null,
    selectedPaymentReminders: SelectedPaymentReminders,
};

type Action =
    | { type: 'TOGGLE_INVOICE_NOTIFICATIONS', payload: { invoiceNotifications: boolean } }
    | { type: 'SET_SELECTED_PAYMENT_REMINDERS', payload: { paymentReminders: PaymentReminders } }
    | { type: 'ENABLE_PAYMENT_REMINDER_TYPE', payload: { dueDateType: ENotificationType } }
    | { type: 'DISABLE_PAYMENT_REMINDER_TYPE', payload: { dueDateType: ENotificationType } }
    | { type: 'ADD_NEW_PAYMENT_REMINDER', payload: { dueDateType: ENotificationType, newPaymentReminder: NotificationsRules } }
    | { type: 'DELETE_PAYMENT_REMINDER', payload: { dueDateType: ENotificationType, index: number } }
    | { type: 'UPDATE_PAYMENT_REMINDER', payload: { dueDateType: ENotificationType, index: number, interval: number } };

const notificationsSettingInitialState = {
    invoiceNotifications: null,
    selectedPaymentReminders: {
        onDueDate: [],
        afterDueDate: [],
        beforeDueDate: [],
    },
};

const reducer = (state: NotificationsSettingsState, action: Action) => {
    switch (action.type) {
        case 'TOGGLE_INVOICE_NOTIFICATIONS':
            return {
                ...state,
                invoiceNotifications: action.payload.invoiceNotifications,
            };
        case 'SET_SELECTED_PAYMENT_REMINDERS':
            return {
                ...state,
                selectedPaymentReminders: action.payload.paymentReminders,
            };
        case 'ENABLE_PAYMENT_REMINDER_TYPE':
            return {
                ...state,
                selectedPaymentReminders: {
                    ...state.selectedPaymentReminders,
                    [action.payload.dueDateType]: DEFAULT_REMINDER_RULE[action.payload.dueDateType],
                },
            };
        case 'DISABLE_PAYMENT_REMINDER_TYPE':
            return {
                ...state,
                selectedPaymentReminders: {
                    ...state.selectedPaymentReminders,
                    [action.payload.dueDateType]: [],
                },
            };
        case 'ADD_NEW_PAYMENT_REMINDER':
            return {
                ...state,
                selectedPaymentReminders: {
                    ...state.selectedPaymentReminders,
                    [action.payload.dueDateType]: [
                        ...state.selectedPaymentReminders[action.payload.dueDateType],
                        action.payload.newPaymentReminder,
                    ],
                },
            };
        case 'DELETE_PAYMENT_REMINDER':
            return {
                ...state,
                selectedPaymentReminders: {
                    ...state.selectedPaymentReminders,
                    [action.payload.dueDateType]: state.selectedPaymentReminders[action.payload.dueDateType].filter((_, index) => index !== action.payload.index),
                },
            };
        case 'UPDATE_PAYMENT_REMINDER':
            return {
                ...state,
                selectedPaymentReminders: {
                    ...state.selectedPaymentReminders,
                    [action.payload.dueDateType]: state.selectedPaymentReminders[action.payload.dueDateType].map((item, index) => {
                        if (index === action.payload.index) {
                            return {
                                ...item,
                                type: MAP_DUE_DATE_TYPE_TO_RULE_TYPE[action.payload.dueDateType],
                                interval: action.payload.interval,
                            };
                        }
                        return item;
                    }),
                },
            };
        default:
            return state;
    }
};

/**
 * The AR Customer Notifications Tab
 *
 * @returns JSX Element
 */

export const CustomerNotificationsTab: React.FC<CustomNotificationsTabProps> = ({ resetButtonEnabled, refetchDataGrid }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Notifications' });

    const {
        customer,
        customerNotifications,
        isCustomerLoading,
        customerID,
        supplierID,
        handleCloseGeneralPopup,
        refetchCustomer,
        refetchCustomerNotifications,
        isCustomerNotificationsLoading
    } = useContext(CustomerDetailsContext);

    const {
        data: supplierNotifications,
        isLoading: isSupplierNotificationsLoading
    } = useGetSupplierNotifications({ supplierID });

    const { data: supplierData, isLoading: isSupplierDataLoading } = useGetSupplier({
        supplierID,
    });

    const [notificationsSettingsState, dispatch] = useReducer<(notificationsSettingsState: NotificationsSettingsState, action: Action) => NotificationsSettingsState>(reducer, notificationsSettingInitialState);
    const [invalidPaymentReminder, setInvalidPaymentReminder] = useState(null);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [restoreLoading, setRestoreLoading] = useState<boolean>(false);
    const [isRefetching, setIsRefetching] = React.useState<boolean>(false);
    const isDataFetching = isSupplierNotificationsLoading || isCustomerLoading || isCustomerNotificationsLoading || isSupplierDataLoading;
    const hasCustomPaymentRules: boolean = customerNotifications?.rules?.length > 0 || customer?.disable_reminders;
    const hasCustomInvoiceRules: boolean = customer?.invoice_notifications !== null;
    const { selectedPaymentReminders, invoiceNotifications } = notificationsSettingsState;

    /**
     * set current notifications setting to a state after fetching the default (supplier's) notifications and customer's (customer supplier mapping) settings
     */
    useEffect(() => {
        if (isDataFetching || isRefetching) return;

        const hasCustomerRules = customerNotifications?.rules?.length > 0;
        const notifications = (hasCustomerRules || customer.disable_reminders) ? customerNotifications : supplierNotifications;

        dispatch({
            type: 'TOGGLE_INVOICE_NOTIFICATIONS',
            payload: {
                invoiceNotifications: customer.invoice_notifications,
            },
        });

        dispatch({
            type: 'SET_SELECTED_PAYMENT_REMINDERS',
            payload: {
                paymentReminders: mapInitialReminderRules(notifications),
            }
        });
    }, [isDataFetching, isRefetching, customer, supplierNotifications, customerNotifications]);

    const refetchData = async (): Promise<void> => {
        setIsRefetching(true);

        await Promise.all([refetchCustomer(), refetchCustomerNotifications()]);
        setIsRefetching(false);
    };

    /**
     * Handles updating customer and notifications
     */
    const handleNotificationsUpdate = (notificationsBody: NotificationsData, customerBody: UpdateCustomerData, restoreToDefault: boolean):void => {
        setInvalidPaymentReminder(null);

        if (restoreToDefault) {
            setRestoreLoading(true);
        } else {
            setSaveLoading(true);
        }

        Promise.all([updateCustomer({
            supplierID,
            customerID,
            invoice_notifications: customerBody.invoice_notifications,
            disable_reminders: customerBody.disable_reminders,
        }),
        updateCustomerNotificationRules({
            supplierID,
            customerID,
            body: { rules: notificationsBody.rules }
        })]).then(async () => {
            await refetchData();
            toast.show({
                message: restoreToDefault ? t('restoreToDefault') : t('changesSaved'),
                showClose: false,
            });
            if (refetchDataGrid) {
                await refetchDataGrid();
            }
        }).catch((error) => {
            const nullType = !!notificationsBody.rules.find((item) => item.type === null);
            setInvalidPaymentReminder(nullType);
            onErrorSavingNotifications(error);
        }).finally(() => {
            if (restoreToDefault) {
                setRestoreLoading(false);
            } else {
                setSaveLoading(false);
            }
        });
    };

    /**
     * Add a new payment reminder combobox
     */
    function addNewPaymentReminder(dueDateType: ENotificationType): void {
        dispatch({
            type: 'ADD_NEW_PAYMENT_REMINDER',
            payload: {
                dueDateType,
                newPaymentReminder: {
                    type: null,
                    interval: 0
                }
            }
        });
    }

    /**
     * delete a payment reminder combobox
     */
    function deletePaymentReminder(index, dueDateType: ENotificationType): void {
        dispatch({
            type: 'DELETE_PAYMENT_REMINDER',
            payload: {
                dueDateType,
                index
            }
        });
    }

    /**
     * Loops to find any unset comboboxes and sets errors for each
     */
    function handleNotificationsErrors(errors: NotificationsErrors, ruleType: ENotificationType): TypeErrors[] {
        for (let i = 0; i < selectedPaymentReminders[ruleType].length; i++) {
            const notification = selectedPaymentReminders[ruleType][i];
            const rulesErrors: TypeErrors = {};
            if (notification.type === null) {
                rulesErrors.type = true;
            }
            errors[ruleType][i] = rulesErrors;
        }

        return errors[ruleType];
    }

    /**
     * Send network call to update supplier when save settings is clicked
     */
    function saveSettings() {
        let isError = false;
        const { onDueDate, beforeDueDate, afterDueDate } = selectedPaymentReminders;

        const errors: NotificationsErrors = {
            afterDueDate: Array<TypeErrors>(afterDueDate.length),
            beforeDueDate: Array<TypeErrors>(beforeDueDate.length)
        };

        errors.afterDueDate = handleNotificationsErrors(errors, ENotificationType.AFTER_DUE_DATE);
        errors.beforeDueDate = handleNotificationsErrors(errors, ENotificationType.BEFORE_DUE_DATE);

        isError = [...errors.afterDueDate, ...errors.beforeDueDate].some((item) => item.type);

        if (isError) {
            setInvalidPaymentReminder(errors);
            toast.show({
                message: 'Could not save settings.',
                icon: <AlertIcon className="text-red-300"/>,
            });
            setSaveLoading(false);
            return;
        }

        const flattenPaymentReminder = [afterDueDate, beforeDueDate, onDueDate].flat();
        const removeDuplicatesPaymentReminder = flattenPaymentReminder.filter((value, index, self) => index === self.findIndex((f) => ((f.type === value.type) && (f.interval === value.interval))));
        const finalPaymentReminder = { rules: removeDuplicatesPaymentReminder };

        const supplierHasNotificationRules = !!supplierNotifications.rules.length;
        const noCustomRules = !onDueDate.length && !beforeDueDate.length && !afterDueDate.length;

        const customBody = {
            invoice_notifications: invoiceNotifications,
            disable_reminders: noCustomRules && supplierHasNotificationRules
        };
        handleNotificationsUpdate(finalPaymentReminder, customBody, false);
    }

    /**
     * enable or disable one of payment reminders
     */
    const togglePaymentReminderType = (dueDateType: ENotificationType, enableReminderType: boolean): void => {
        const actionType = enableReminderType ? 'ENABLE_PAYMENT_REMINDER_TYPE' : 'DISABLE_PAYMENT_REMINDER_TYPE';

        dispatch({
            type: actionType,
            payload: {
                dueDateType
            }
        });
    };

    /**
     * reset default (supplier's) notifications settings
     */
    const resetToDefaultNotificationsSettings = () => {
        const notificationsBody = {
            rules: []
        };

        const customerBody = {
            invoice_notifications: null,
            disable_reminders: false
        };

        handleNotificationsUpdate(notificationsBody, customerBody, true);
    };

    /**
     * handle when reminder rule option is changed in the dropdown
     */
    const handleNotificationRuleChange = (dueDateType: ENotificationType, index: number, selectedRuleOption: ComboOption) => {
        if (invalidPaymentReminder) {
            const invalidReminder = [...invalidPaymentReminder[dueDateType]];

            invalidReminder[index] = {
                ...invalidReminder[index],
                type: false
            };

            setInvalidPaymentReminder({
                ...invalidPaymentReminder,
                [dueDateType]: invalidReminder
            });
        }

        dispatch({
            type: 'UPDATE_PAYMENT_REMINDER',
            payload: {
                dueDateType,
                index,
                interval: Number(selectedRuleOption.value)
            }
        });
    };

    if (isDataFetching) {
        return <Loading className="mt-10" isDark />;
    }

    return (
        <>
            <div className="px-10">
                {/* Invoices section */}
                <div className="mt-10 font-body">
                    <div className="flex flex-row gap-x-2 mb-6">
                        <Typography variant="LG-2" className="font-semibold p-0 m-0 ">
                            {t('invoices')}
                        </Typography>
                        {hasCustomInvoiceRules && <Badge
                            variant="PURPLE"
                            size="SMALL">
                            {t('custom')}
                        </Badge>}
                    </div>
                    <div className="space-y-6">
                        <div className="flex items-center justify-between gap-2">
                            <div>
                                <div className="flex flex-row gap-x-2 mb-1">
                                    <Typography className="font-medium mb-0">
                                        {t('sendOnIssueDate')}
                                    </Typography>
                                </div>
                                <Typography className="text-gray-600">
                                    {t('sendOnIssueDateDescription')}
                                </Typography>
                            </div>
                            <div>
                                <Toggle isEnabled={invoiceNotifications ?? supplierData?.invoice_notifications}
                                    size="SMALL"
                                    onChange={(enableInvoiceNotifications: boolean): void => {
                                        dispatch({
                                            type: 'TOGGLE_INVOICE_NOTIFICATIONS',
                                            payload: {
                                                invoiceNotifications: enableInvoiceNotifications
                                            }
                                        });
                                    }}/>
                            </div>
                        </div>
                    </div>
                    {/* Payments section */}
                    <div className="flex flex-row gap-x-2 pb-6 mt-16">
                        <Typography variant="LG-2" className="font-semibold m-0 p-0 ">
                            {t('payments')}
                        </Typography>
                        {hasCustomPaymentRules && <Badge
                            variant="PURPLE"
                            size="SMALL">
                            {t('custom')}
                        </Badge>}
                    </div>
                    {/* On due date section */}
                    <div className="space-y-6">
                        <div className="flex items-center justify-between gap-2">
                            <div>
                                <Typography className="font-medium mb-1">
                                    {t('onDueDate')}
                                </Typography>
                                <Typography className="text-gray-600">
                                    {t('onDueDateDescription')}
                                </Typography>
                            </div>
                            <div>
                                <Toggle
                                    isEnabled={selectedPaymentReminders.onDueDate.length > 0}
                                    size="SMALL"
                                    onChange={(enableReminderType: boolean): void => {
                                        togglePaymentReminderType(ENotificationType.ON_DUE_DATE, enableReminderType);
                                    }}/>
                            </div>
                        </div>
                    </div>
                    <Separator className="my-6" />
                    {/* Before due date section */}
                    <div>
                        <div className="flex items-center justify-between gap-2">
                            <div>
                                <Typography className="font-medium mb-1">
                                    {t('beforeDueDate')}
                                </Typography>
                                <Typography className="text-gray-600 mb-4">
                                    {t('beforeDueDateDescription')}
                                </Typography></div>
                            <div>
                                <Toggle
                                    isEnabled={selectedPaymentReminders.beforeDueDate.length > 0}
                                    size="SMALL"
                                    onChange={(enableReminderType: boolean): void => {
                                        togglePaymentReminderType(ENotificationType.BEFORE_DUE_DATE, enableReminderType);
                                    }}/>
                            </div>
                        </div>
                        {selectedPaymentReminders.beforeDueDate.length > 0
                            && selectedPaymentReminders?.beforeDueDate?.map((currentPaymentReminder, i) => (<div key={v4()} className="flex flex-row mb-2">
                                <Combobox
                                    onChange={(selectedRule): void => handleNotificationRuleChange(ENotificationType.BEFORE_DUE_DATE, i, selectedRule)}
                                    options={paymentReminderBeforeOptions}
                                    isInvalid={invalidPaymentReminder?.beforeDueDate?.[i]?.type}
                                    invalidMessage={'Choose a valid reminder'}
                                    placeholder="Select reminder"
                                    className="w-[300px]"
                                    value={currentPaymentReminder.interval.toString()}
                                    variant="SMALL" />
                                {(selectedPaymentReminders.beforeDueDate && selectedPaymentReminders?.beforeDueDate?.length !== 1)
                                    && <DeletePaymentReminderTooltip onClick={() => {
                                        deletePaymentReminder(i, ENotificationType.BEFORE_DUE_DATE);
                                    } } />}
                            </div>))}
                        {!!selectedPaymentReminders.beforeDueDate.length && <AddNewRuleButton
                            onClick={() => addNewPaymentReminder(ENotificationType.BEFORE_DUE_DATE)}
                            label={t('add')}
                            isDisabled={isDataFetching || restoreLoading || saveLoading || selectedPaymentReminders?.beforeDueDate?.length === MAXIMUM_NUMBER_OF_COMBOBOXES}/>}
                    </div>
                    <Separator className="my-6" />
                    {/* After due date section */}
                    <div>
                        <div className="flex items-center justify-between gap-2">
                            <div>
                                <Typography className="font-medium mb-1">
                                    {t('afterDueDate')}
                                </Typography>
                                <Typography className="text-gray-600 mb-4">
                                    {t('afterDueDateDescription')}
                                </Typography></div>
                            <div>
                                <Toggle
                                    isEnabled={selectedPaymentReminders.afterDueDate.length > 0}
                                    size="SMALL"
                                    onChange={(enableReminderType: boolean): void => {
                                        togglePaymentReminderType(ENotificationType.AFTER_DUE_DATE, enableReminderType);
                                    }}/>
                            </div>
                        </div>
                        {selectedPaymentReminders?.afterDueDate?.map((currentPaymentReminder, i) => (<div key={v4()} className="flex flex-row mb-2">
                            <Combobox
                                onChange={(selectedRule): void => handleNotificationRuleChange(ENotificationType.AFTER_DUE_DATE, i, selectedRule)}
                                options={paymentReminderAfterOptions}
                                isInvalid={invalidPaymentReminder?.afterDueDate?.[i]?.type}
                                invalidMessage={'Choose a valid reminder'}
                                className="w-[300px]"
                                optionsClassName={(i + 1 === selectedPaymentReminders?.afterDueDate.length || i + 2 === selectedPaymentReminders?.afterDueDate.length) ? 'bottom-full' : ''}
                                placeholder="Select reminder"
                                value={currentPaymentReminder.interval.toString()}
                                variant="SMALL" />
                            {(!!selectedPaymentReminders.afterDueDate.length && selectedPaymentReminders?.afterDueDate?.length !== 1)
                                && <DeletePaymentReminderTooltip onClick={() => { deletePaymentReminder(i, ENotificationType.AFTER_DUE_DATE); } } />
                            }
                        </div>))}
                        {!!selectedPaymentReminders.afterDueDate.length && <AddNewRuleButton
                            onClick={() => addNewPaymentReminder(ENotificationType.AFTER_DUE_DATE)}
                            label={t('add')}
                            isDisabled={isDataFetching || restoreLoading || saveLoading || selectedPaymentReminders?.afterDueDate?.length === MAXIMUM_NUMBER_OF_COMBOBOXES}/>}
                    </div>
                </div>
            </div>
            <div className="sticky bottom-0 bg-white">
                <div className="w-full mt-6 h-px bg-gray-200" />
                <div className="px-10 py-6 flex justify-between">
                    <Button className="mr-2"
                        onClick={handleCloseGeneralPopup}
                        disabled={saveLoading || restoreLoading}
                        variant="TERTIARY_FILLED"
                        size="MEDIUM"
                        minWidth="w-auto">
                        {tCommon('Buttons.cancel')}
                    </Button>
                    <div className="flex flex-row justify-end gap-x-2">
                        {resetButtonEnabled && <Button
                            disabled={restoreLoading || saveLoading || (!hasCustomInvoiceRules && !hasCustomPaymentRules)}
                            loading={restoreLoading}
                            variant="SECONDARY"
                            size="MEDIUM"
                            onClick={resetToDefaultNotificationsSettings}
                            minWidth="w-auto">
                            {t('resetToDefault')}
                        </Button>}
                        <Button
                            disabled={saveLoading || restoreLoading}
                            loading={saveLoading}
                            variant="SECONDARY"
                            size="MEDIUM"
                            onClick={saveSettings}
                            minWidth="w-auto">
                            {tCommon('Buttons.save')}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};
