import React, { useMemo, useState } from 'react';
import { FilterLabel } from '@ar/components/shared/FilterLabel/FilterLabel';
import { PopoverItem } from '@notch-ordering/ui-components';
import { usePayoutsStore } from '@ar/stores/PayoutsStore';
import { GetPayoutsSortBy } from '@ar/network/AccountsReceivable.network';
import { SearchInput } from '@ar/components/shared/SearchInput/SearchInput';
import debounce from 'lodash/debounce';
import { InvoiceRangeDateFilterButton } from '@ar/components/Invoices/InvoiceFilters/InvoiceRangeDateFilterButton';
import { PayoutSupplierFilterButton } from '@ar/components/Payouts/PayoutsSupplierFilterButton';
import { FilterButton } from '@/components/shared/FilterButton';
import { dollarsToCents } from '@/utils/Formatters';

type FilterOption = { label: string, value: string | boolean | number };

const statusFilterOptions = [
    { label: 'Paid', value: 'paid' },
    { label: 'In transit', value: 'in_transit' },
    { label: 'Canceled', value: 'canceled' },
    { label: 'Pending', value: 'pending' },
    { label: 'Failed', value: 'failed' },
] as const;
const sortByOptions = [
    { label: 'Most recent', value: '-received_at' },
    { label: 'Oldest', value: 'received_at' },
] as const;

const getLabelByValue = (
    options: Readonly<FilterOption[]>,
    value: string | boolean | number,
): string => {
    const selectedOption = options.find((option) => option.value === value);

    return selectedOption?.label ?? '';
};

const DEBOUNCE_SEARCH_QUERY_DELAY = 100;

export const PayoutsFilters = (): JSX.Element => {
    const { updateSearchParams, searchParams, clearFilter } = usePayoutsStore();
    const [searchQuery, setSearchQuery] = useState<string>('');

    const isSorted = searchParams.sort_by !== undefined;
    const hasStatusChecked = searchParams.status !== undefined;

    const debounceSearchQuery = useMemo(() => debounce((query: string) => {
        const queryAmount = query === '' ? undefined : dollarsToCents(Number(query));
        updateSearchParams({ amount: queryAmount, page: 0 });
    }, DEBOUNCE_SEARCH_QUERY_DELAY), []);

    const handleQueryChange = (value: string):void => {
        if (Number.isNaN(Number(value)) && value !== '-') {
            return;
        }
        setSearchQuery(value);
        debounceSearchQuery(value);
    };

    const statusPopoverItems = useMemo<PopoverItem[]>(
        () => statusFilterOptions
            .map(({ label, value }) => {
                const currentValue = searchParams.status;

                return {
                    label: <FilterLabel label={label} isChecked={currentValue === value}/>,
                    onClick: (): void => {
                        if (currentValue === value) {
                            updateSearchParams({ status: undefined });
                        } else {
                            updateSearchParams({ status: value });
                        }
                    },
                };
            }),
        [searchParams.status],
    );

    const sortByPopoverItems = useMemo<PopoverItem[]>(
        () => sortByOptions
            .map(({ label, value }) => {
                const currentValue = searchParams.sort_by;
                const valueAsGetPayoutsSortBy = value as GetPayoutsSortBy;

                return ({
                    label: <FilterLabel label={label} isChecked={currentValue === valueAsGetPayoutsSortBy}/>,
                    onClick: (): void => {
                        if (currentValue === valueAsGetPayoutsSortBy) {
                            updateSearchParams({ sort_by: undefined });
                        } else {
                            updateSearchParams({ sort_by: valueAsGetPayoutsSortBy });
                        }
                    },
                });
            }),
        [searchParams.sort_by],
    );

    return (
        <div className="flex flex-col gap-3 pb-6">
            <SearchInput
                onChange={(value):void => handleQueryChange(value as string)}
                value={String(searchQuery)}
                inputProps={{
                    placeholder: 'Search for net amount',
                }
                }/>
            <div className="flex gap-2 flex-wrap">
                <PayoutSupplierFilterButton/>
                <FilterButton
                    isChecked={hasStatusChecked}
                    onClearFilter={(): void => clearFilter('status') }
                    items={statusPopoverItems}
                    label={hasStatusChecked ? `Status is ${getLabelByValue(statusFilterOptions, searchParams.status)}` : 'Status'}
                    closePanelOnClick={true}/>
                <InvoiceRangeDateFilterButton
                    initialStartDate={searchParams?.received_at_start_date ? new Date(searchParams?.received_at_start_date) : undefined}
                    initialEndDate={searchParams?.received_at_end_date ? new Date(searchParams?.received_at_end_date) : undefined}
                    label={'Paid Date'}
                    onDateRangeChange={({ startDateUTC, endDateUTC }):void => {
                        const params = {
                            received_at_start_date: startDateUTC ?? undefined,
                            received_at_end_date: endDateUTC ?? undefined,
                            page: 0,
                        };
                        updateSearchParams(params);
                    }}/>
                <FilterButton
                    isChecked={isSorted}
                    onClearFilter={(): void => clearFilter('sort_by')}
                    items={sortByPopoverItems}
                    label={isSorted ? `Sorted by ${getLabelByValue(sortByOptions, searchParams.sort_by)}` : 'Sort by'}
                    closePanelOnClick={true}/>
            </div>
        </div>
    );
};
