import * as Cypress from './Cypress';
import { isPlatform } from '@ionic/react';
import { EIonicPlatforms } from '../constants/Mobile';

/*
 ** Intercom
 */
export const init = () => {
  const disableInit =
    process.env.NODE_ENV === 'test' ||
    Cypress.isRunningTest();
  // Skip init when running unit tests or Cypress tests
  if (disableInit) {
    return;
  }

  /* eslint-disable */
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      function l() {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${process.env.INTERCOM_ID}`;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
  /* eslint-enable */
};

//disable default launcher on mobile phones
if ((isPlatform(EIonicPlatforms.MOBILE) || isPlatform(EIonicPlatforms.ANDROID)) && !isPlatform(EIonicPlatforms.TABLET)) {
  window.intercomSettings = {
    alignment: "left",
    hide_default_launcher: true,
    custom_launcher_selector: "#intercom-icon-mobile"
  };
}

export const initLoggedOutUser = () => {
  let retry = 0;
  const waitForIntercom = setInterval(() => {
    if (retry <= 5) {
      if (window.Intercom) {
        clearSession();

        window.Intercom('boot', {
          app_id: process.env.INTERCOM_ID,
          name: 'Non-logged in user',
          widget: { activator: '#IntercomDefaultWidget' },
        });

        clearInterval(waitForIntercom);
      } else {
        retry++;
      }
    } else {
      clearInterval(waitForIntercom);
    }
  }, 200);
};

export const initLoggedInUser = (buyer, account, user) => {
  let retry = 0;
  const waitForIntercom = setInterval(() => {
    if (retry <= 5) {
      if (window.Intercom) {
        clearSession();

        window.Intercom('boot', {
          app_id: process.env.INTERCOM_ID,
          user_id: String(account.memberID),
          name: `${user.firstName} ${user.lastName}`,
          user: `${user.firstName} ${user.lastName}`,
          email: user.email,
          created_at: account.created_at,
          widget: { activator: '#IntercomDefaultWidget' },
          company: {
            id: String(buyer.id),
            name: buyer.name,
            email: buyer.email,
            created_at: buyer.createdAt,
            orders: buyer.numberOrders,
          },
        });

        clearInterval(waitForIntercom);
      } else {
        retry++;
      }
    } else {
      clearInterval(waitForIntercom);
    }
  }, 200);
};

export const open = () => {
  if (window.Intercom) {
    window.Intercom('show');
  }
};

export const clearSession = () => {
  if (window.Intercom) {
    window.Intercom('shutdown');
  }
};

export const hide = () => {
  // The irony here is that wrapping this with setInterval gives enought time for Intercom to be available.
  let retry = 0;
  const waitForIntercom = setInterval(() => {
    if (retry <= 5) {
      if (window.Intercom) {
        window.Intercom('update', { hide_default_launcher: true });

        clearInterval(waitForIntercom);
      } else {
        retry++;
      }
    } else {
      clearInterval(waitForIntercom);
    }
  }, 200);
};

const setHorizontalPadding = (margin) => {
  if (window.Intercom) {
    window.Intercom('update', { horizontal_padding: 20 + margin });
  }
};

export const shiftLeft = () => setHorizontalPadding(385);
export const resetPosition = () => setHorizontalPadding(0);
