import React from 'react';
import { Banner, Button, EBannerType, Typography } from '@notch-ordering/ui-components';
import { useNavigate } from 'react-router-dom';
import { ARRoutePaths } from '@v2/constants/EPaths';
import InfoIcon from '@icons/info-icon.svg';

export const RutterSetupNotCompleteBanner : React.FC = () => {
    const navigate = useNavigate();
    return (<Banner alertType={EBannerType.WARNING}
        body={<>
            <Typography className="font-semibold text-gray-700">
            Payment Processing Alert
            </Typography>
            <div className="flex flex-row items-center">
                <Typography as="div" className="text-gray-600">
                    {"Your payment method will be charged, but it won't sync to your ERP until you complete the "}
                    <Button variant="LINK"
                        className="inline p-0 underline"
                        onClick={():void => navigate(`/${ARRoutePaths.AR_INTEGRATION}`)} >
                        {'integrations setup'}
                    </Button>
                    .
                </Typography>
            </div>
        </>}
        icon={<InfoIcon className="w-5 h-5 text-orange-500"/>}
        isDismissable={false}/>);
};
