import { useSupplierStore } from '@ar/stores/SupplierStore';
import { SIGN_UP_SUPPLIER_ID_KEY } from '@ar/pages/SignUpPage/CustomerSignUpPage';
import { EStrategyName } from './authStrategies/EStrategyName';
import { IAuthenticateStrategy } from './authStrategies/IAuthenticateStrategy';
import { SerializeStrategy, TokenData } from './authStrategies/TokenData';
import { ISession } from '@/auth/ISession';
import { ArTokenStrategy } from '@/auth/authStrategies/ArTokenStrategy';

const { DEPLOY_ENV } = process.env;

export const STORAGE_KEY = `${DEPLOY_ENV}_notchToken`;

type AuthStrategy = {
    new(tokenData: TokenData): IAuthenticateStrategy,
    strategyName: EStrategyName,
};
const AUTH_STRATEGIES: AuthStrategy[] = [
    ArTokenStrategy,
];

/**
 * Uses local storage to store an IAuthenticate instance
 * When get is called it checks if it exists and then loads the state from it
 * When no previous state exists then it tries the provided login function to retrieve an IAuthenticate instance
 */
export class UserAuthenticationProvider {
    private static _currentAuthenticateStrategy: IAuthenticateStrategy | null = null;

    private static _account: ISession['account'] | null = null;

    /**
     *
     */
    public static getAuthenticateStrategy(): IAuthenticateStrategy | null {
        if (this._currentAuthenticateStrategy !== null) {
            return this._currentAuthenticateStrategy;
        }

        const storageValue = window.localStorage.getItem(STORAGE_KEY);
        if (!storageValue) {
            return null;
        }

        let decodedValue: SerializeStrategy;
        try {
            decodedValue = JSON.parse(storageValue);
        } catch (e) {
            return null;
        }

        const useStrategy = AUTH_STRATEGIES.find(({ strategyName }) => strategyName === decodedValue.name);
        if (!useStrategy) {
            return null;
        }

        // eslint-disable-next-line new-cap
        this._currentAuthenticateStrategy = new useStrategy(decodedValue.token);
        return this._currentAuthenticateStrategy;
    }

    /**
     *
     */
    public static isLoggedIn(): boolean {
        if (this.getAuthenticateStrategy()) {
            return !this.getAuthenticateStrategy().isExpired();
        }

        return this.getAuthenticateStrategy() !== null;
    }

    /**
     *
     */
    public static isSupplier(): boolean {
        const strategy = this.getAuthenticateStrategy();
        return strategy?.getStrategyName() === EStrategyName.SupplierToken;
    }

    /**
     *
     */
    public static isAdmin(): boolean {
        const strategy = this.getAuthenticateStrategy();
        return strategy?.getStrategyName() === EStrategyName.AdminToken;
    }

    /**
     * @returns true if user authenticated through AR login
     */
    public static isARLogin(): boolean {
        const strategy = this.getAuthenticateStrategy();
        return strategy?.getStrategyName() === EStrategyName.ArToken;
    }

    /**
     *
     */
    public static async loginUser(email: string, password: string): Promise<void> {
        try {
            const result = await ArTokenStrategy.login(email, password);

            if (result) {
                this._currentAuthenticateStrategy = result;
            }

            window.localStorage.setItem(STORAGE_KEY, JSON.stringify(this._currentAuthenticateStrategy.serialize()));

            return;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     *
     */
    public static logOut(redirectPath = '/'): void {
        window.localStorage.removeItem(STORAGE_KEY);
        window.localStorage.removeItem(SIGN_UP_SUPPLIER_ID_KEY);
        window.location.reload();
        window.location.href = `/${redirectPath}`;
        useSupplierStore.getState().reset();
    }

    /**
     *  Get the session data and save session.account to the property _account
     */
    public static async getAccountData(): Promise<ISession['account']> {
        return this._account;
    }
}
