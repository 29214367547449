import React, { useEffect } from 'react';

/**
 * Sets the document title that is displayed in the browser tab and history.
 */
export function useDocumentTitle(title: string, deps: React.DependencyList = []): void {
    useEffect(() => {
        const prevTitle = document.title;
        document.title = title;
        return (): void => {
            document.title = prevTitle;
        };
    }, deps);
}
