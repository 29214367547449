import create from 'zustand';

type CustomersNotificationsStoreState = {
    searchParams: SearchParams,
};

type SearchParams = {
    limit?: number,
    offset?: number,
};

type CustomersNotificationsStoreActions = {
    updateSearchParams: (params: SearchParams) => void,
    reset: () => void,
};

type CustomersNotificationsStore = CustomersNotificationsStoreState & CustomersNotificationsStoreActions;

const CUSTOMERS_NOTIFICATIONS_STORE_INITIAL_STATE: CustomersNotificationsStoreState = {
    searchParams: {
        limit: 20,
        offset: 0,
    },
};

export const useCustomersNotificationsStore = create<CustomersNotificationsStore>(
    (set): CustomersNotificationsStore => ({
        ...CUSTOMERS_NOTIFICATIONS_STORE_INITIAL_STATE,

        updateSearchParams: (params: SearchParams) => set(
            (state) => ({
                searchParams: {
                    ...state.searchParams,
                    ...params,
                },
            }),
        ),

        reset: () => set(CUSTOMERS_NOTIFICATIONS_STORE_INITIAL_STATE),
    }),
);
