import { Badge, GridColDef, Typography } from '@notch-ordering/ui-components';
import React, { ReactNode } from 'react';
import { Payout } from '@ar/network/AccountsReceivable.network';
import { PayoutsMoreColumn } from '@ar/components/Payouts/PayoutsMoreColumn';
import { trackCurrentPageTitle, TrackingEvents } from '@ar/constants/TrackingConstants';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';
import { centsToDollars, formatAsCurrency } from '@/utils/Formatters';
import { PayoutsPagePendingTooltip } from './PayoutsPagePendingTooltip';

export const headerClassName = 'text-gray-600 font-body text-med';
export const cellClassName = 'text-gray-700 font-body text-med';

export const commonGridDef: GridColDef = {
    field: '',
    headerAlign: 'left',
    headerClassName,
    cellClassName,
    align: 'left',
};

const gridColDef: GridColDef = {
    ...commonGridDef,
    sortable: false,
    flex: 1,
};

export const PAYOUTS_COLUMNS = {
    receivedAt: 'received_at',
    status: 'status',
    gross: 'gross',
    net: 'net',
    fee: 'fee',
    numberOfRefunds: 'number_of_refunds',
    numberOfInvoices: 'number_of_invoices',
    supplierName: 'supplier_name',
    actions: 'actions',
} as const;

export const getPayoutStatusBadge = (status: string = ''): ReactNode => {
    switch (status) {
        case 'paid':
            return <Badge variant="GREEN" size="SMALL">Paid</Badge>;
        case 'in_transit':
            return <Badge variant="PURPLE" size="SMALL">In transit</Badge>;
        default:
            return <Badge variant="GRAY" size="SMALL">{status && status.length > 0 ? status.charAt(0).toUpperCase() + status.slice(1) : '' }</Badge>;
    }
};

export const getPayoutsColumns = (): GridColDef<Payout>[] => [{
    ...gridColDef,
    field: PAYOUTS_COLUMNS.receivedAt,
    headerName: 'Paid date',
    sortable: true,
    maxWidth: 110,
    renderCell: ({ row }) => <div className="flex gap-2 ">
        {row?.received_at ? formatIsoStringToUtcDate(new Date(row.received_at).toISOString(), 'MMM d, yyyy') : '--'}

    </div>,
},
{
    ...gridColDef,
    field: PAYOUTS_COLUMNS.status,
    headerName: '\n',
    minWidth: 120,
    renderCell: ({ value }) => <Typography className="truncate">{getPayoutStatusBadge(value)}</Typography>,

},
{
    ...gridColDef,
    field: PAYOUTS_COLUMNS.supplierName,
    headerName: 'Supplier',
    minWidth: 250,
},
{
    ...gridColDef,
    field: PAYOUTS_COLUMNS.gross,
    headerName: 'Gross amount',
    minWidth: 120,
    renderCell: ({ row, value }) => (row.number_of_invoices <= 0 ? <PayoutsPagePendingTooltip /> : <Typography className="truncate">{formatAsCurrency(centsToDollars(value))}</Typography>),
},
{
    ...gridColDef,
    field: PAYOUTS_COLUMNS.fee,
    headerName: 'Fees',
    renderCell: ({ row, value }) => (row.number_of_invoices <= 0 ? <PayoutsPagePendingTooltip /> : <Typography className="truncate">{formatAsCurrency(centsToDollars(value))}</Typography>),
},
{
    ...gridColDef,
    field: PAYOUTS_COLUMNS.net,
    headerName: 'Net amount',
    sortable: true,
    minWidth: 120,
    renderCell: ({ value }) => <Typography className="truncate">{formatAsCurrency(centsToDollars(value))}</Typography>,
},
{
    ...gridColDef,
    field: PAYOUTS_COLUMNS.numberOfInvoices,
    headerName: 'Invoices',
    renderCell: ({ row, value }) => (row.number_of_invoices <= 0 ? <PayoutsPagePendingTooltip /> : <Typography className="truncate">{value}</Typography>),
},
{
    ...gridColDef,
    field: PAYOUTS_COLUMNS.numberOfRefunds,
    headerName: 'Refunds',
    renderCell: ({ row, value }) => (row.number_of_invoices <= 0 ? <PayoutsPagePendingTooltip /> : <Typography className="truncate">{value}</Typography>),
},
{
    ...gridColDef,
    field: PAYOUTS_COLUMNS.actions,
    headerName: '',
    cellClassName: 'overflow-visible', // This must be set for the popover to be visible
    renderCell: ({ row }) => <div onClick={(e): void => {
        e.stopPropagation();
        trackCurrentPageTitle(TrackingEvents.moreButtonClicked);
    }}><PayoutsMoreColumn payout={row} /></div>,
},
];
