import React, { useEffect } from 'react';
import { Button, Input, Modal, Separator, toast, Toggle, Typography } from '@notch-ordering/ui-components';
import { useIntegrationsContext } from '@ar/pages/IntegrationsPage/IntegrationsContext';
import { createIntegrationConfiguration,
    deleteIntegrationConfiguration,
    EConfigurationType,
    FidelioConfigurationDataSchema,
    FidelioIntegrationConfigurationPayload,
    updateIntegrationConfiguration } from '@ar/network/Bushwhack.network';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { FETCH_SUPPLIER_CONFIGURATION_QUERY_KEY } from '@ar/hooks/queries/IntegrationsQuery.hook';
import CheckIcon from '@icons/check-icon.svg';
import { NumericFormat } from 'react-number-format';
import { minutesToSeconds, secondsToMinutes } from 'date-fns';
import { IntegrationDisconnectConfirmModal } from '@ar/components/Integrations/IntegrationModals/IntegrationDisconnectConfirmModal';
import { IntegrationsTabs } from '@ar/pages/IntegrationsPage/IntegrationsPageConstants';
import { EIntegrationType } from '@/ar/network/AccountsReceivable.network';

type FormData = FidelioIntegrationConfigurationPayload & {
    pollDelayMinutes?: number,
};
export const FidelioConnectModal : React.FC = () => {
    const { modals, setModal, supplierIntegrationConfiguration, setIntegrationsTab, updateSupplierIntegration, removeSupplierIntegration } = useIntegrationsContext();
    const { supplierLoginData } = useSupplierStore();
    const fidelioIntegrationConfiguration = supplierIntegrationConfiguration?.find((integration) => integration.type === EConfigurationType.Fidelio);
    const queryClient = useQueryClient();
    const FORM_DATA_INITIAL_STATE = {
        environmentName: '',
        pollDelaySeconds: 900,
        pollDelayMinutes: 15,
        url: '',
    };
    const [formData, setFormData] = React.useState<FormData>(FORM_DATA_INITIAL_STATE);
    const [isEnabled, setIsEnabled] = React.useState<boolean>(true);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = React.useState<boolean>(false);
    // const updateSupplierMutation = useMutation(updateSupplier);
    const cleanUp = ():void => {
        queryClient.invalidateQueries([FETCH_SUPPLIER_CONFIGURATION_QUERY_KEY]);
        setModal(EIntegrationType.Fidelio, false);
        setFormData({});
    };

    const deleteSupplierIntegrationMutation = useMutation(deleteIntegrationConfiguration, {
        onSuccess: () => {
            toast.show({
                message: 'Integration disconnected successfully',
                icon: <CheckIcon/>,
            });
            cleanUp();
            removeSupplierIntegration();
            setIntegrationsTab(IntegrationsTabs.Active);
        },
    });
    const updateSupplierIntegrationMutation = useMutation(updateIntegrationConfiguration, {
        onSuccess: () => {
            toast.show({
                message: 'Integration updated successfully',
                icon: <CheckIcon/>,
            });
            cleanUp();
            setIntegrationsTab(IntegrationsTabs.Active);
        },
    });
    const createSupplierIntegrationMutation = useMutation(createIntegrationConfiguration, {
        onSuccess: () => {
            toast.show({
                message: 'Integration connected successfully',
                icon: <CheckIcon/>,
            });
            updateSupplierIntegration({
                integration_name: EConfigurationType.Fidelio,
            });
            cleanUp();
            setIntegrationsTab(IntegrationsTabs.Active);
        },
    });

    const updateFormData = (key: keyof FormData, value: string | number): void => setFormData((prev) => ({
        ...prev,
        [key]: value,
    }));

    const handleOnClose = (): void => {
        setModal(EIntegrationType.Fidelio, false);
        cleanUp();
    };

    const handleDisconnect = (): void => {
        if (fidelioIntegrationConfiguration) {
            deleteSupplierIntegrationMutation.mutate(fidelioIntegrationConfiguration?.id);
        }
        setIsConfirmDeleteModalOpen(false);
    };

    const result = FidelioConfigurationDataSchema.safeParse(formData);
    const isFormValid = result.success;

    const handleConnect = (): void => {
        if (result.success) {
            const mutation = fidelioIntegrationConfiguration ? updateSupplierIntegrationMutation : createSupplierIntegrationMutation;
            mutation.mutate({
                ownerID: supplierLoginData?.supplier_id,
                type: EConfigurationType.Fidelio,
                data: formData,
                isEnabled,
                id: fidelioIntegrationConfiguration?.id,
            });
        }
    };

    const isMutating = updateSupplierIntegrationMutation.isLoading || createSupplierIntegrationMutation.isLoading;

    useEffect(() => {
        if (fidelioIntegrationConfiguration) {
            const { department, url, environmentName, pollDelaySeconds } = fidelioIntegrationConfiguration.data as FidelioIntegrationConfigurationPayload;
            setFormData({
                department,
                url,
                environmentName,
                pollDelaySeconds,
                pollDelayMinutes: secondsToMinutes(pollDelaySeconds),
            });
            setIsEnabled(fidelioIntegrationConfiguration.isEnabled);
        }
    }, [fidelioIntegrationConfiguration]);

    return <><Modal isOpen={modals.fidelio}
        title={
            <><Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="XL" >
                Set up connection
            </Typography>
            <Typography variant="LG-1"
                weight="font-regular"
                className="text-gray-600" >
                    Fidelio
            </Typography>
            </>
        }
        headerPadding="mt-0 mb-5 px-3"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL">
        <div className="p-8">
            <div className="flex justify-between gap-2 items-center mb-6">
                <Typography className="font-medium">
                Enable connection
                </Typography>
                <div>
                    <Toggle
                        isEnabled={isEnabled}
                        size="SMALL"
                        onChange={(): void => {
                            setIsEnabled(!isEnabled);
                        }}/>
                </div>
            </div>

            <Separator/>
            <div className="mt-6 space-y-6" >
                <Input
                    required
                    value={formData.url}
                    onChange={(e): void => updateFormData('url', e.target.value)}

                    label="Access URL"
                    helperText="This URL is provided by Fidelio to gain access to the connection bridge. It is the URL without the /GetView or /ImportDTO part after it."/>

                <Input
                    required
                    value={formData.environmentName}
                    onChange={(e): void => updateFormData('environmentName', e.target.value)}
                    label="Environment name"
                    helperText="This is the name of your Fidelio account"/>

                <NumericFormat customInput={Input}
                    suffix={formData.pollDelayMinutes > 1 ? ' minutes' : ' minute'}
                    required
                    value={formData.pollDelayMinutes}
                    onValueChange={(values): void => {
                        updateFormData('pollDelaySeconds', minutesToSeconds(values.floatValue));
                        updateFormData('pollDelayMinutes', values.floatValue);
                    }}
                    label="Syncing frequency"
                    helperText="This is how often you want to Notch to sync to your system"/>

            </div>
        </div>

        <div className="pt-5 px-8 flex  gap-3">
            {fidelioIntegrationConfiguration && <Button variant="DESTRUCTIVE"
                size="SMALL"
                loading={deleteSupplierIntegrationMutation.isLoading}
                disabled={deleteSupplierIntegrationMutation.isLoading}
                onClick={():void => setIsConfirmDeleteModalOpen(true)}>
                <Typography as="span" weight="font-medium">
                    Disconnect
                </Typography>
            </Button>}
            <div className="flex gap-3 items-center ml-auto">
                <Button variant="TERTIARY_FILLED"
                    size="SMALL"
                    onClick={handleOnClose}>
                    <Typography as="span" weight="font-medium">
                    Cancel
                    </Typography>
                </Button>
                <Button variant="SECONDARY"
                    disabled={!isFormValid || isMutating}
                    loading={isMutating}
                    size="SMALL"
                    onClick={handleConnect}>
                    <Typography as="span" weight="font-medium">
                        {fidelioIntegrationConfiguration ? 'Update' : 'Connect'}
                    </Typography>
                </Button></div>
        </div>
    </Modal>
    <IntegrationDisconnectConfirmModal integrationName={'Fidelio'} onConfirm={handleDisconnect} isOpen={isConfirmDeleteModalOpen} onClose={(): void => setIsConfirmDeleteModalOpen(false)}/>
    </>;
};
