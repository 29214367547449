import React, { useContext, useState } from 'react';
import { Button, Typography } from '@notch-ordering/ui-components';
import { CustomerDetailsContext } from '@ar/pages/CustomerDetailsWrapperPage/CustomerDetailsContext';
import { CreatePaymentMethod } from '@ar/components/shared/modals/CreatePaymentMethod/CreatePaymentMethod';
import AddIcon from '@icons/add-icon.svg';
import { useRefetchIncompletePaymentMethods } from '@ar/hooks/useRefetchPaymentMethods';
import { LoadingPaymentMethodBanner } from '@ar/components/Invoices/Modals/BulkManualChargeModal/LoadingPaymentMethodBanner';
import { PaymentMethod } from './PaymentMethod/PaymentMethod';

export const PaymentMethods = (): JSX.Element => {
    const { paymentMethods, customerID, customer } = useContext(CustomerDetailsContext);
    const [isAddPaymentOpen, setIsAddPaymentOpen] = useState(false);

    const hasOnlyOnePaymentMethod = paymentMethods?.payment_methods.length === 1;
    const hasNoPrimaryPaymentMethod = paymentMethods?.payment_methods.filter((paymentMethod) => paymentMethod.is_primary).length === 0;
    const autoCollection = customer?.auto_collection;
    const methods = paymentMethods?.payment_methods.map((paymentMethod) => <PaymentMethod key={paymentMethod.id} paymentMethod={paymentMethod} hasOnlyOnePaymentMethod={hasOnlyOnePaymentMethod}/>);
    const hasPaymentMethods = paymentMethods?.payment_methods.length > 0;
    const hasIncompletePaymentMethods = useRefetchIncompletePaymentMethods({ paymentMethods: paymentMethods?.payment_methods });

    return (<>
        <Typography as="div" variant="LG-2" weight="font-semibold" className="mb-6">
            Methods
            {!hasPaymentMethods && <Typography className="text-gray-600 mt-1">You have no payment methods added yet.</Typography>}
            {hasNoPrimaryPaymentMethod && autoCollection && <Typography className="text-red-600 mt-1">You have no primary payment method added yet and turned auto pay on.</Typography>}
        </Typography>
        {hasPaymentMethods && <div className="mb-6">
            {methods}
        </div>}
        {hasIncompletePaymentMethods && <LoadingPaymentMethodBanner className="p-0 mb-6" />}
        <Button
            onClick={(): void => { setIsAddPaymentOpen(true); }}
            className="-ml-4 py-0"
            size="MEDIUM"
            variant="TEXT">
            <div className="flex">
                <AddIcon width={16} className="mr-3.5 text-teal-500" />
                <span className="text-teal-500">Add payment method</span>
            </div>
        </Button>

        <CreatePaymentMethod isOpen={isAddPaymentOpen} handleClose={(): void => setIsAddPaymentOpen(false)} customerID={customerID} />
    </>);
};
