import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as SignInActions from '../../actions/signInActions';
import * as AnalyticsActions from '../../actions/analyticsActions';

import SignInMain from '../../components/signin/SignInMain.jsx';

import * as Intercom from '../../third-party/Intercom';
import * as Segment from '../../third-party/Segment';
import { IonPage, isPlatform } from '@ionic/react';

import { EIonicPlatforms } from '@/constants/Mobile';

const SignInContainer = (props) => {
  useEffect(() => {
    Intercom.initLoggedOutUser();
    Segment.init();

    props.analyticsActions.signinScreenLoaded();
  }, []);

  const submitSignInForm = () => {
    const { SignInForm, signInActions } = props;
    const { email, password } = SignInForm.values;

    if (SignInForm.values) {
      signInActions.signin(email, password);
    }
  };

  const analyticsViewPage = (screenName) => {
    props.analyticsActions.viewPage(screenName);
  };

  return (
    <IonPage>
      <SignInMain
        {...props}
        submitSignInForm={submitSignInForm}
        analyticsViewPage={analyticsViewPage}
      />
    </IonPage>
  );
};

SignInContainer.propTypes = {
  analyticsActions: PropTypes.objectOf(PropTypes.any).isRequired,
  signInActions: PropTypes.objectOf(PropTypes.any).isRequired,
};

function mapStatetoProps(state) {
  return {
    ...state.form,
    ...state.errorsReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signInActions: bindActionCreators(SignInActions, dispatch),
    analyticsActions: bindActionCreators(AnalyticsActions, dispatch),
  };
}

export default connect(mapStatetoProps, mapDispatchToProps)(SignInContainer);
