import React from 'react';
import { Input, InputProps } from '@notch-ordering/ui-components';
import { NumberFormatValues, PatternFormat } from 'react-number-format';

export type InputPhoneProps = Partial<Omit<InputProps, 'type' | 'onChange'>> & {
    format?: string,
    onChange?: (value: NumberFormatValues) => void,
    defaultValue?: number,
    mask?: string,

};

export const InputPhone: React.FC<InputPhoneProps> = ({
    value,
    defaultValue,
    format = '###-###-####',
    mask = '_',
    onChange = ():void => {},
    ...props
}) => <PatternFormat customInput={Input}
    valueIsNumericString
    {...props}
    format={format}
    mask={mask}
    onValueChange={onChange}
    defaultValue={defaultValue}
    value={value}/>;
