import React from 'react';
import { ConfirmModal, ConfirmModalProps } from '@ar/components/shared/ConfirmModal';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useInvoiceStore } from '@ar/stores/InvoicesStore';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTransaction } from '@ar/network/AccountsReceivable.network';
import { toast } from '@notch-ordering/ui-components';
import CheckIcon from '@icons/check-icon.svg';
import { FETCH_TRANSACTIONS_QUERY_KEY } from '@ar/hooks/queries/InvoicesQueries.hook';
import { getGpoId } from '@v2/utils/GPOUtils';

export type BulkDoNotChargeModalProps = Partial<ConfirmModalProps>;

export const BulkDoNotChargeModal : React.FC<BulkDoNotChargeModalProps> = (props) => {
    const { modals, setModal, getDoNotChargeInvoices, clearSelectedInvoices } = useInvoiceStore();
    const { supplierLoginData } = useSupplierStore();
    const queryClient = useQueryClient();
    const handleOnClose = ():void => {
        if (props?.onClose) props.onClose();
        setModal('bulkDoNotChargeInvoice', false);
    };
    const doNotChargeInvoices = getDoNotChargeInvoices();
    const { mutateAsync, isLoading } = useMutation(updateTransaction);

    const handleOnConfirm = ():void => {
        if (props?.onConfirm) props.onConfirm();

        const promises = doNotChargeInvoices.map((invoice) => mutateAsync(
            {
                supplierID: supplierLoginData?.supplier_id,
                gpoId: getGpoId(supplierLoginData),
                transactionID: invoice.id,
                body: {
                    do_no_charge: true,
                },
            },
        ));

        Promise.all(promises).then(() => {
            handleOnClose();
            clearSelectedInvoices();
            queryClient.invalidateQueries([FETCH_TRANSACTIONS_QUERY_KEY]);
            toast.show({
                message: `${doNotChargeInvoices.length} invoice${doNotChargeInvoices.length > 1 ? 's' : ''} marked as do not charge.`,
                icon: <CheckIcon/>,
            });
        }).catch((error) => {
            console.error(error);
            toast.show({
                message: 'Failed to update invoice(s) status',
            });
        });
    };
    return <ConfirmModal
        onClose={handleOnClose}
        isLoading={isLoading}
        isOpen={modals.bulkDoNotChargeInvoice}
        title={`Confirm that you want ${doNotChargeInvoices.length} invoice${doNotChargeInvoices.length > 1 ? 's' : ''} to be marked as do not charge.`}
        prompt={
            <React.Fragment>
                <span className="mb-2 block">No automatic payments will be tried for the following invoices:</span>
                <ul className="mb-2">
                    {doNotChargeInvoices.map((invoice) => (
                        <li key={invoice.id}>#{invoice.invoice_number}</li>
                    ))}
                </ul>
                You can only collect payment for these invoices manually.
            </React.Fragment>
        }
        onConfirm={handleOnConfirm}
        {...props}/>;
};
