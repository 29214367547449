import React, { useState } from 'react';
import { Separator, Typography } from '@notch-ordering/ui-components';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import { BatchIngestionResult, BatchIngestionResultStatus } from '@/ar/network/AccountsReceivable.network';
/**
 * Extracts error details from a BatchIngestionResult object.
 *
 * This function parses the error message to extract missing fields and row data.
 *
 * @param {BatchIngestionResult} error - The error object containing the message to be parsed.
 * @returns {object} An object containing:
 *   - `missingFields` {string[]} - An array of missing field names extracted from the error message.
 *   - `rowData` {Object | null} - The row data extracted from the
 */
const extractErrorDetails = (error: BatchIngestionResult) => {
    // Extract missing fields using a regular expression
    const missingFieldsMatch = error.message.match(/Missing (.*?)(?=\.)/g);
    const missingFields = missingFieldsMatch ? missingFieldsMatch.map((field) => field.replace('Missing ', '').trim()) : [];

    // Extract the row data from the message
    const rowDataMatch = error.message.match(/row:\s*(\{.*\})/);
    const rowData = rowDataMatch ? JSON.parse(rowDataMatch[1]) : null;

    return {
        missingFields,
        rowData,
    };
};

interface ErrorDetailsProps {
    messageDescription?: React.ReactNode,
    rowData: Record<string, string> | null,
    errorType: React.ReactNode,
    hideSeparator?: boolean,
}

/**
 * Renders an error details component.
 *
 * @param messageDescription - The description of the error message.
 * @param rowData - The data associated with the error.
 * @param errorType - The type of the error.
 * @param hideSeparator - Determines whether to hide the separator.
 */
const ErrorDetails: React.FC<ErrorDetailsProps> = ({ messageDescription, rowData, errorType, hideSeparator }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="space-y-2">
            <div>
                <div
                    className="cursor-pointer flex justify-between items-center "
                    onClick={toggleAccordion}>
                    <Typography variant="LG-1" as="div" className="font-medium">
                        {errorType}
                    </Typography>
                    <span className={`transform transition-transform ${isOpen ? 'rotate-180' : 'rotate-0'}`}>
                        <ChevronDownIcon className="w-4 h-4" />
                    </span>
                </div>
                {isOpen && (<>
                    <Typography variant="LG-1" as="div" className="flex gap-2 justify-between my-2">
                        {messageDescription}
                    </Typography>
                    {rowData && <div className="p-4">
                        <Typography variant="LG-1" as="div" className="flex gap-2 font-medium mb-2">
                            Meta data:
                        </Typography>
                        {Object.entries(rowData).map(([key, value]) => (
                            <div key={key} className="space-y-1 block">
                                <Typography className="font-medium" as="span">
                                    {key}:
                                </Typography>
                                <Typography className="ml-2" as="span">
                                    {value}
                                </Typography>
                            </div>
                        ))}
                    </div>}

                </>)}
            </div>
            {hideSeparator ? null : <Separator />}
        </div>
    );
};

/**
 * Retrieves the error details for a batch ingestion result and returns the corresponding JSX element.
 *
 * @param result - The batch ingestion result.
 * @param isLast - Indicates whether the element is the last one in the list.
 * @returns The JSX element representing the error details.
 */
const getInvoiceBatchIngestionErrors = (result: BatchIngestionResult, isLast: boolean) => {
    const { missingFields, rowData } = extractErrorDetails(result);
    switch (result.status) {
        case BatchIngestionResultStatus.Error:
            return <ErrorDetails errorType="Error Type: Error" messageDescription={<><span className="font-medium">Error:</span> <span>{result.message}</span></>} rowData={rowData} hideSeparator={isLast} />;

        case BatchIngestionResultStatus.MissingValues:
            return <ErrorDetails errorType="Error Type: Missing Values" messageDescription={<><span className="font-medium">Missing fields:</span> <span>{missingFields.join(', ')}</span></>} rowData={rowData} hideSeparator={isLast} />;
        default:
            return <ErrorDetails errorType="Error Type: Unknown" messageDescription={<><span className="font-medium">Error:</span> <span>{result.message}</span></>} rowData={rowData} hideSeparator={isLast} />;
    }
};

/**
 * SummaryBatchIngestionResultPanel component displays the summary of batch ingestion results.
 *
 * @param result - An array of BatchIngestionResult objects representing the batch ingestion results.
 * @returns A React functional component that renders the summary of batch ingestion results.
 */
export const SummaryBatchIngestionResultPanel: React.FC<{ result: BatchIngestionResult[] }> = ({ result }) => <div className="space-y-2">
    {result.map((res, idx) => <div key={idx} >
        { getInvoiceBatchIngestionErrors(res, idx === result.length - 1) }
    </div>)}
</div>;
