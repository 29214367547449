import { generatePDFForTransactions, invoicePaidEventSyncManually, sendInvoiceNotification, Transaction } from '@ar/network/AccountsReceivable.network';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@notch-ordering/ui-components';
import CheckIcon from '@icons/check-icon.svg';
import React from 'react';
import { FETCH_TRANSACTION_ACTIVITY_QUERY_KEY, FETCH_TRANSACTION_QUERY_KEY } from '../queries/InvoicesQueries.hook';
import { queryClient } from '@/containers/app/Root';

export const useSendInvoiceNotificationMutation = (invoice: Transaction) => useMutation(sendInvoiceNotification, {
    onSuccess: () => {
        toast.show({
            message: `Invoice sent to ${invoice?.email}`,
            icon: <CheckIcon/>,
        });
    },
    onError: () => {
        toast.show({
            message: 'Failed to send invoice',
        });
    },
});

export const useInvoicePaidEventSyncManuallyMutation = () => useMutation(invoicePaidEventSyncManually, {
    onSuccess: () => {
        toast.show({
            message: 'Payment sync\'d successfully',
            icon: <CheckIcon/>,
        });
    },
    onError: () => {
        toast.show({
            message: 'Failed to sync payment',
        });
    },
});

/**
 * Displays a toast notification based on the result of PDF generation and invalidates related queries.
 *
 *
 * @param {boolean} isSuccess - The result of the PDF generation.
 */
export const notifyPDFGenerationResult = async (isSuccess:boolean) => {
    toast.show({
        message: isSuccess ? 'PDF generated successfully' : 'Failed to generate PDF, please try again.',
        icon: isSuccess ? <CheckIcon/> : null,
    });
    await queryClient.invalidateQueries([FETCH_TRANSACTION_QUERY_KEY]);
    await queryClient.invalidateQueries([FETCH_TRANSACTION_ACTIVITY_QUERY_KEY]);
};

/**
 * Custom hook for generating PDF for transactions.
 *
 * @returns {MutationHook} The mutation hook for generating PDF for transactions.
 */
export const useGeneratePDFForTransactions = () => useMutation(generatePDFForTransactions);
