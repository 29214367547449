import React, { useState } from 'react';
import { Banner, Button, EBannerType, Input, Typography } from '@notch-ordering/ui-components';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { EPaths } from '@v2/constants/EPaths';
import { LOGIN_FORM_LABELS } from '@v2/components/SignIn/LoginForm/constants';
import EyeIcon from '@icons/eye-icon.svg';
import EyeOffIcon from '@icons/eye-off-icon.svg';
import { resetPassword } from '@ar/network/AccountsReceivable.network';
import { useMutation } from '@tanstack/react-query';
import InfoIcon from '@icons/info-icon.svg';

export const resetPasswordSchema = z.object({
    password: z.string().nonempty('Password is required')
});

export type ResetPassword = z.infer<typeof resetPasswordSchema>;
const ERROR_MESSAGE = 'The password reset link is invalid or has expired';

export const ResetPasswordPage : React.FC = () => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const { handleSubmit, control } = useForm<ResetPassword>({
        resolver: zodResolver(resetPasswordSchema),
        mode: 'onBlur',
        defaultValues: {
            password: ''
        }
    });
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token') ?? '';
    const { isError, isSuccess, mutate, isLoading } = useMutation(resetPassword);
    const showFormView = !isSuccess;

    const { errors } = useFormState({
        control,
    });
    const onSubmit = async (data:ResetPassword) => {
        mutate({
            body: {
                password: data.password,
                token,
            }
        });
    };
    if (!token) return <Navigate to={EPaths.SIGN_IN} />;
    return <div className="h-screen bg-plum-500 overflow-auto">
        <div className="bg-gray-700 opacity-75 w-full h-full absolute"/>

        <div className="w-full h-full flex items-center px-2">
            <div
                className="bg-white rounded-xl sm:p-10 sm:pt-5 sm:pb-8 p-6 m-auto flex flex-col w-full md:max-w-[430px] items-center z-10">

                {showFormView && <>
                    <Typography variant="3XL" desktopSize="m-0 text-5 pb-10 text-center">Reset Password</Typography>
                    <form className="flex flex-col gap-5 w-full" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => <div className="relative">
                                    <Input label="Password"
                                        id={LOGIN_FORM_LABELS.password.name}
                                        type={showPassword ? 'TEXT' : 'PASSWORD'}
                                        inputClassName="pr-10"
                                        variant="SMALL"
                                        isInvalid={!!errors.password}
                                        invalidMessage={errors.password?.message}
                                        required
                                        inputProps={{
                                            ...field,
                                        }}/>

                                    {showPassword ? <div
                                        className="absolute inset-y-0 right-4 top-10 flex items-center cursor-pointer w-4 h-4"
                                        onClick={(): void => setShowPassword(false)}
                                        role={'button'}>
                                        <EyeOffIcon className="text-gray-600 inline-flex w-4 h-4"/>
                                    </div> : <div
                                        className=" absolute inset-y-0 right-4 top-10 flex items-center cursor-pointer w-4 h-4"
                                        onClick={(): void => setShowPassword(true)}
                                        role={'button'}>
                                        <EyeIcon className="text-gray-600 inline-flex w-4 h-4"/>
                                    </div>}
                                </div>

                                }/>
                        </div>

                        <div className="flex flex-col gap-4">
                            <Button variant="SECONDARY"
                                className="w-full "
                                loading={isLoading}
                                type="submit">Update password</Button>
                            <Link to={EPaths.SIGN_IN} className="text-teal-300 text-center"> Back to Log in</Link>

                        </div>

                        {isError && <Banner alertType={EBannerType.ERROR}
                            body={<Typography as="div" className="text-gray-600">
                                {ERROR_MESSAGE}
                            </Typography>}
                            icon={<InfoIcon className="w-5 h-5 text-red-300"/>}
                            isDismissable={false}/>}

                    </form></>}
                {!showFormView && <div className="flex flex-col gap-8">
                    <Typography className="text-gray-600 m-0"> Password updated successfully</Typography>
                    <Link to={EPaths.SIGN_IN} className="text-teal-300 text-center"> Back to Log in</Link>

                </div>}
            </div>
        </div>
    </div>;
};
