import React, { useMemo } from 'react';
import { PopoverItem } from '@notch-ordering/ui-components';
import { useInvoiceStore } from '@ar/stores/InvoicesStore';
import { getPaymentStatusText } from '@ar/components/Invoices/InvoiceStatusBadge';
import { TransactionStatus } from '@ar/network/AccountsReceivable.network';
import { FilterLabel } from '@ar/components/shared/FilterLabel/FilterLabel';
import { FilterButton } from '@/components/shared/FilterButton';

export const invoicePaymentStatus:Array<TransactionStatus> = [
    TransactionStatus.Pending,
    TransactionStatus.PartialCharged,
    TransactionStatus.Charged,
    TransactionStatus.Refunded,
    TransactionStatus.Failed,
    TransactionStatus.DoNotCharge,
    TransactionStatus.Scheduled,
    TransactionStatus.ChargedOffline,
    TransactionStatus.PartialRefund,
    TransactionStatus.RequiresAction,
];
export const InvoiceStatusFilterButton : React.FC = () => {
    const { searchParams, updateSearchParams, clearSelectedInvoices } = useInvoiceStore();
    const isChecked = !!searchParams?.status;
    const customerItems = useMemo<PopoverItem[]>(() => invoicePaymentStatus.map((status) => ({
        label: <FilterLabel label={getPaymentStatusText(status)} isChecked={searchParams?.status === status}/>,
        onClick: (): void => {
            if (searchParams?.status === status) {
                updateSearchParams({ status: undefined, page: 0 });
            } else {
                updateSearchParams({ status, page: 0 });
            }
            clearSelectedInvoices();
        },
    })), [searchParams?.status]);
    return (
        <FilterButton isChecked={isChecked}
            onClearFilter={(): void => {
                updateSearchParams({ status: undefined, page: 0 });
                clearSelectedInvoices();
            }}
            items={customerItems}
            label={isChecked ? `Status is ${getPaymentStatusText(searchParams?.status)}` : 'Status'}
            closePanelOnClick={false}/>
    );
};
