import { Button, Modal, Separator, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import { mergeClassNames } from '@v2/utils/Helpers';
import { ModalProps } from '@notch-ordering/ui-components/src/components/Modal/ModalConstants';
import { ButtonProps } from '@notch-ordering/ui-components/src/components/Button/ButtonConstants';

export interface ConfirmModalProps extends Omit<ModalProps, 'close'> {
    isOpen: boolean,
    onConfirm: () => void,
    onClose: () => void,
    title?: React.ReactNode,
    prompt?: React.ReactNode,
    cancelLabel?: React.ReactNode,
    confirmLabel?: React.ReactNode,
    confirmButtonProps?: ButtonProps,
    className?: string,
    isLoading?: boolean,
    confirmVariant?: 'SECONDARY' | 'DESTRUCTIVE',
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
    isOpen,
    onConfirm,
    onClose,
    prompt,
    title,
    confirmLabel = 'Confirm',
    confirmButtonProps,
    cancelLabel = 'Cancel',
    className,
    isLoading,
    confirmVariant = 'SECONDARY',
    ...props
}) => <Modal isOpen={isOpen}
    title={
        <Typography className="m-0 pr-10" weight="font-semibold" variant="LG-2">{title}</Typography>
    }
    headerPadding="mt-0 mb-2"
    close={onClose}
    modalSize="SMALL"
    desktopModalWidth="lg:w-[372px]"
    className={mergeClassNames('', className)}
    {...props}>
    <div className="px-5 pb-5">
        <Typography as="div" className="text-gray-600 font-regular">
            {prompt}
        </Typography>
    </div>
    <Separator/>
    <div className="pt-5 px-5 flex justify-end gap-3">
        <Button variant="TERTIARY_FILLED"
            size="SMALL"
            onClick={onClose}>
            <Typography as="span" weight="font-medium">
                {cancelLabel}
            </Typography>
        </Button>
        <Button variant={confirmVariant}
            loading={isLoading}
            size="SMALL"
            onClick={onConfirm}
            {...confirmButtonProps}>
            <Typography as="span" weight="font-medium">
                {confirmLabel}
            </Typography>
        </Button>
    </div>
</Modal>;
