import { SupplierLoginData } from '@ar/stores/SupplierStore';
import { GPOMembership } from '@/ar/network/AccountsReceivable.network';

export const FOODBUY_DEMO_GPO_SUPPLIER_ID: string = 'fc2c7d8b-566c-4ec3-a328-b359b28e1339';
export const FOODBUY_DEMO_SUPPLIER_ID: string = '49f6baa9-12f5-409f-a62c-7babb34f9dda';
export const FOODBUY_DEMO_GPO_SUPPLIER_TOKEN: string = '0d89b01c-e641-436d-ae07-1b7e8db26fc2';

export function isFoodbuyDemoSupplier(arSupplierId: string): boolean {
    return (process.env.DEPLOY_ENV === 'staging' || process.env.DEPLOY_ENV === 'development') && (arSupplierId === FOODBUY_DEMO_GPO_SUPPLIER_ID || arSupplierId === FOODBUY_DEMO_SUPPLIER_ID);
}

export function getGPOSupplierAPIToken(arSupplierId: string): string {
    if (isFoodbuyDemoSupplier(arSupplierId)) {
        return FOODBUY_DEMO_GPO_SUPPLIER_TOKEN;
    }

    return process.env.NOTCH_SUPPLIER_API_TOKEN;
}

export function getGPOSupplierId(arSupplierId: string): string {
    if (isFoodbuyDemoSupplier(arSupplierId)) {
        return FOODBUY_DEMO_GPO_SUPPLIER_ID;
    }

    return process.env.NOTCH_SUPPLIER_ID;
}

export function getGpoData(gpoMemberships: GPOMembership[]): GPOMembership {
    const gpo = gpoMemberships[0];
    if (!gpo) {
        return undefined;
    }
    return { ...gpo, name: `${gpo.name} Admin` };
}

export function getGpoId(supplierLoginData: SupplierLoginData) {
    return supplierLoginData?.gpoMemberships.length > 0 ? supplierLoginData?.gpoMemberships[0].id : undefined;
}
