import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Banner, Button, EBannerType, Input, Loading, toast, Typography } from '@notch-ordering/ui-components';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { addCustomerAccount, signUpCustomer } from '@ar/network/AccountsReceivable.network';
import InfoIcon from '@icons/info-icon.svg';
import MarkAsDoneIcon from '@ar/assets/icons/check-circle-icon.svg';
import EyeOffIcon from '@icons/eye-off-icon.svg';
import EyeIcon from '@icons/eye-icon.svg';
import { EPaths } from '@v2/constants/EPaths';
import { AxiosError } from 'axios';
import PoweredByNotchIcon from '@ar/assets/icons/powered-by-notch-icon.svg';

import { useGetCustomerByEmail } from '@ar/hooks/queries/CustomerQueries.hook';
import NotchLogoImg from '@images/notch-logo.svg';
import { UserAuthenticationProvider } from '@/auth/UserAuthenticationProvider';
import { AmplitudeEventActions, useAmplitude } from '@/containers/app/AmplitudeContext';

const gpoSuppliersFieldErrorMessage = 'Please provide a valid group purchasing organization.';
export const gpoSuppliersField = z.array(z.object({
    gpo_id: z.string({
        invalid_type_error: gpoSuppliersFieldErrorMessage,
        required_error: gpoSuppliersFieldErrorMessage,
    }).uuid({ message: gpoSuppliersFieldErrorMessage }),
    gpo_name: z.string().optional(),
})).optional();
export const createCustomerAndAccountSchema = z.object({
    business_name: z.string().nonempty({ message: 'Business name is required' }),
    external_id: z.string().optional(),
    email: z.string().email({ message: 'Invalid email address' }),
    account_name: z.string().optional(),
    password: z.string({
        required_error: 'Password is required',
    }).nonempty({ message: 'Password is required' }),
    gpoSuppliers: gpoSuppliersField,
});
export type CreateCustomerAndAccount = z.infer<typeof createCustomerAndAccountSchema>;

enum ErrorTypes {
    ACCOUNT_EXIST = 'ACCOUNT_EXIST',
    CUSTOMER_CREATION = 'CUSTOMER_CREATION',
}

const bulletPoints = [
    { icon: <MarkAsDoneIcon className="w-5 h-5 text-white mr-[18px] flex-shrink-0" />, text: 'Invoice Copies', description: 'Get a comprehensive breakdown of your invoices.' },
    { icon: <MarkAsDoneIcon className="w-5 h-5 text-white mr-[18px] flex-shrink-0" />, text: 'Historical Bill Access', description: 'Get access to your past invoices for record-keeping and review.' },
    { icon: <MarkAsDoneIcon className="w-5 h-5 text-white mr-[18px] flex-shrink-0" />, text: 'Safe Payments', description: 'Your information is protected in our secure system.' },
];

export const SIGN_UP_SUPPLIER_ID_KEY = 'SignUpSupplierID';

export const CustomerSignUpPage: React.FC = () => {
    // get email address from query parameters
    const { search } = useLocation();

    // get the email parameter with regex from the search param without any decoding
    const regex = /email=([^&]+)/;
    const navigate = useNavigate();

    const params = new URLSearchParams(search);
    const supplierImgLogo = params.get('supplierLogo');
    const regExpSupplierID = /supplierID=([^&]+)/;
    const email = regex.exec(search)?.[1] ?? '';
    const supplierID = regExpSupplierID.exec(search)?.[1] ?? '';
    const { data: customerResponse, error, isLoading } = useGetCustomerByEmail({ email, supplierID });
    const isCustomerDataLoadError = !isLoading && !customerResponse && error;

    const signUpCustomerMutation = useMutation(signUpCustomer);
    const addCustomerAccountMutation = useMutation(addCustomerAccount);
    const [formError, setFormError] = React.useState<ErrorTypes>(null);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const { setAmplitudeUserId, trackAmplitudeEvent } = useAmplitude();

    const { handleSubmit, control, reset } = useForm<CreateCustomerAndAccount>({
        resolver: zodResolver(createCustomerAndAccountSchema),
        mode: 'onBlur',
        defaultValues: {
            email: email ?? '',
        }
    });
    const { errors } = useFormState({
        control,
    });
    const onSubmit = async (data: CreateCustomerAndAccount) => {
        try {
            addCustomerAccountMutation.mutate({
                body: {
                    email: data.email,
                    password: data.password,
                    name: data.business_name,
                },
                customerID: customerResponse?.id
            }, {
                onSuccess: () => {
                    toast.show({
                        message: 'Account successfully created'
                    });
                    setAmplitudeUserId(data.email);

                    trackAmplitudeEvent(AmplitudeEventActions.signUp, { userId: data.email, page: 'Customer sign up page' });
                    UserAuthenticationProvider.loginUser(data.email, data.password).then(() => {
                        window.localStorage.setItem(SIGN_UP_SUPPLIER_ID_KEY, supplierID);
                        window.location.reload();
                    });
                },
                onError: (err: AxiosError<{ error?: string }>) => {
                    const message = err.response?.data?.error || err.message;
                    console.log({ err });
                    if (message.includes('exists')) {
                        setFormError(ErrorTypes.ACCOUNT_EXIST);
                        toast.show({
                            message: 'Failed to create customer account'
                        });
                    } else {
                        setFormError(ErrorTypes.CUSTOMER_CREATION);
                        toast.show({
                            message: `Failed to create customer : ${message}`
                        });
                    }
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (customerResponse) {
            reset({
                email: email ?? '',
                business_name: customerResponse.name ?? '',
                account_name: customerResponse.name ?? '',
            });
        }
    }, [customerResponse]);

    useEffect(() => {
        if (!supplierID || !email) {
            navigate(EPaths.SIGN_IN);
        }
    }, [supplierID, email]);

    return <div className="w-full flex gap-2">
        <div className="w-full overflow-hidden relative bg-gray-700/50 hidden md:!block">
            <video autoPlay
                loop
                muted
                className="absolute -z-10 left-0 w-full min-h-full max-w-none object-cover">
                <source
                    src="https://storage.googleapis.com/chefhero-storage-release/static/app/videos/vid-portal-landingpage.mp4"
                    type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
            <div className="pl-10 pt-20 lg:pt-[114px] lg:pl-[86px]  w-full h-full flex pr-8">
                <div className="flex flex-col w-full lg:w-[500px]">
                    <div className="flex flex-col gap-0 w-full"><Typography className="text-white font-medium m-0">Spend
                        more
                        time doing what you love</Typography>
                    <Typography variant="5XL" className="text-white m-0">Easily Manage Your Bills!</Typography>
                    <Typography className="text-white p-0 m-0">Our comprehensive bill management portal is crafted
                            to
                            make your payment process hassle-free.</Typography></div>
                    <div className="lg:w-[325px] w-full mt-12">
                        <div className="flex flex-col gap-8">
                            {bulletPoints.map((point) => (<div key={point.text} className="flex gap-4">
                                <MarkAsDoneIcon className="w-5 h-5 text-white flex-shrink-0"/>
                                <div className="flex flex-col gap-1">
                                    <Typography className="text-white font-medium mb-0">{point.text}</Typography>
                                    <Typography variant="BASE"
                                        className="text-white mb-0">{point.description}</Typography>
                                </div>
                            </div>))}
                        </div>

                    </div>
                    <PoweredByNotchIcon className="w-[153px] mt-40"/>
                </div>
            </div>
        </div>
        <div className="bg-gray-25 w-full h-screen">
            <div className="w-full h-full flex items-center px-2">
                <div
                    className="bg-white rounded-xl border sm:p-8 sm:pt-8 sm:pb-8 p-8 pt-0 m-auto flex flex-col w-full sm:max-w-[500px] items-center z-10">

                    <div className="flex flex-col gap-4 mb-2">
                        <div className="text-center">
                            {supplierImgLogo ? <img
                                src={supplierImgLogo}
                                className="h-[61px] h- object-contain"/>
                                : <NotchLogoImg title="notch" className="h-5 object-contain"/>}
                        </div>
                        <Typography className="mb-0 font-medium pb-9" variant="LG-2">Get Started! Create your account</Typography>
                    </div>

                    {!isLoading && customerResponse && <form className="flex flex-col gap-1.5 w-full" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <Controller
                                name="business_name"
                                control={control}
                                render={({ field }) => <Input
                                    label="Business Name"
                                    fontSize="[&>label]:font-medium"
                                    disabled={true}
                                    variant="SMALL"
                                    isInvalid={!!errors.business_name}
                                    invalidMessage={errors.business_name?.message}
                                    required
                                    inputProps={{
                                        ...field,
                                    }}/>}/>
                        </div>
                        <div>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => <Input
                                    label="Email"
                                    disabled={true}
                                    variant="SMALL"
                                    fontSize="[&>label]:font-medium"
                                    isInvalid={!!errors.email}
                                    invalidMessage={errors.email?.message}
                                    required
                                    inputProps={{
                                        ...field,
                                    }}/>}/>
                        </div>
                        <div className="relative">
                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => <Input
                                    label="Password"
                                    className="font-medium"
                                    type={showPassword ? 'TEXT' : 'PASSWORD'}
                                    variant="SMALL"
                                    fontSize="[&>label]:font-medium"
                                    isInvalid={!!errors.password}
                                    invalidMessage={errors.password?.message}
                                    required
                                    inputProps={{
                                        ...field,
                                    }}/>}/>
                            {showPassword ? <div
                                className="absolute top-10 right-0 pr-3 flex items-center cursor-pointer"
                                onClick={(): void => setShowPassword(false)}
                                role={'button'}>
                                <EyeOffIcon className="text-gray-600 inline-flex w-4 h-4"/>
                            </div> : <div
                                className=" absolute top-10 right-0 pr-3 flex items-center cursor-pointer"
                                onClick={(): void => setShowPassword(true)}
                                role={'button'}>
                                <EyeIcon className="text-gray-600 inline-flex w-4 h-4"/>
                            </div>}
                        </div>

                        {!!formError
                        && <div className="w-full my-4">
                            <Banner alertType={EBannerType.ERROR}
                                body={<Typography as="div" className="text-gray-600">
                                    {formError === ErrorTypes.ACCOUNT_EXIST ? 'Customer account exists.' : 'Failed to create customer'}
                                    {formError === ErrorTypes.ACCOUNT_EXIST
                                            && <span>&nbsp;Please <Link to={EPaths.SIGN_IN}
                                                className="text-red-500">login</Link></span>}
                                </Typography>}
                                icon={<InfoIcon className="w-5 h-5 text-red-300"/>}
                                isDismissable={false}/>
                        </div>
                        }
                        <div>
                            <Button variant="SECONDARY"
                                className="w-full mt-4 mb-5"
                                loading={signUpCustomerMutation.isLoading}
                                type="submit">Continue</Button>
                        </div>
                        <div className="text-center"><Typography className="text-gray-600" variant="BASE">Already have
                            an account? <Link to={EPaths.SIGN_IN} className="text-plum-300 underline hover:underline hover:text-plum-500">Click here to log in</Link>

                        </Typography></div>
                    </form>}
                    {isLoading && <Loading isDark={true}/>}
                    {isCustomerDataLoadError && <div className="w-full my-4 flex flex-col gap-8">
                        <Typography as="div" className="text-gray-600">
                            Uh-oh! It seems something went wrong with your sign-up link. Please review the information you entered and try again. If you need help, please reach out to our <a
                                href="mailto:support@notch.financial?subject=Customer%20sign up%20issue"
                                target="_blank"
                                className="text-plum-300"
                                rel="noreferrer noopener">support team</a>. Thank you for your understanding!</Typography>

                        <div className="text-center"><Typography className="text-gray-600" variant="BASE">Already have
                            an account? <Link to={EPaths.SIGN_IN}
                            className="text-plum-300 underline hover:underline hover:text-plum-500">Click
                                here to log in</Link>

                        </Typography></div>
                    </div>}
                </div>
            </div>
        </div>
    </div>;
};
