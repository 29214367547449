import React from 'react';
import { Button, Modal, Separator, toast, Typography } from '@notch-ordering/ui-components';
import CheckIcon from '@icons/check-icon.svg';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useGetPayouts } from '@ar/hooks/queries/PayoutQueries.hook';
import { PayoutExportFormatsPopover } from '@ar/components/Payouts/PayoutExportFormatsPopover';
import { CSVLink } from 'react-csv';
import { mergeClassNames } from '@v2/utils/Helpers';
import { getGpoId, getGpoData } from '@v2/utils/GPOUtils';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';
import { centsToDollars } from '@/utils/Formatters';

export interface AllPayoutsExportModalProps {
    isOpen: boolean,
    close: () => void,

}
const CSV_COLUMNS = [
    {
        label: 'Gross',
        key: 'gross',
    },
    {
        label: 'Fees',
        key: 'fee',
    },
    {
        label: 'Net',
        key: 'net',
    },
    {
        label: 'Arrival date',
        key: 'received_at',
    },
    {
        label: 'Nº Invoices',
        key: 'number_of_invoices',
    },
    {
        label: 'Nº Refunds',
        key: 'number_of_refunds',
    },

    {
        label: 'Status',
        key: 'status',
    },
];

const DEFAULT_LIMIT = 10000;

export const STRIPE_PAYOUT_STATUSES = {
    paid: 'Paid',
    pending: 'Pending',
    in_transit: 'In transit',
    canceled: 'Canceled',
    failed: 'Failed',
};

export const AllPayoutsExportModal : React.FC<AllPayoutsExportModalProps> = ({
    isOpen,
    close,
}) => {
    const [selectedFormat, setSelectedFormat] = React.useState<string>('CSV');
    const { supplierLoginData } = useSupplierStore();
    const { data: payoutResponse, isLoading: isLoadingPayload } = useGetPayouts({
        supplier_id: supplierLoginData?.supplier_id,
        gpo_id: getGpoId(supplierLoginData),
        limit: DEFAULT_LIMIT,
    });
    const payouts = payoutResponse?.payouts ?? [];

    const csvTransactionsData = payouts.map((payout) => ({
        ...payout,
        fee: centsToDollars(payout.fee),
        gross: centsToDollars(payout.gross),
        net: centsToDollars(payout.net),
        received_at: payout.received_at ? formatIsoStringToUtcDate(new Date(payout.received_at).toISOString(), 'Y-MM-dd') : '--',
        status: STRIPE_PAYOUT_STATUSES[payout.status] ?? payout.status ?? '',
    })) ?? [];
    const handleOnClose = (): void => {
        close();
    };

    const gpoData = getGpoData(supplierLoginData.gpoMemberships);
    const supplierName = gpoData?.name ?? supplierLoginData.supplier_name;
    // using the same file name as the one used in retool
    const FILE_NAME = `${supplierName}_payouts_${formatIsoStringToUtcDate(new Date()?.toISOString(), 'Y-MM-dd')}.csv`;

    const handleDownload = (): void => {
        toast.show({
            message: 'File downloaded successfully',
            icon: <CheckIcon/>,
        });
        close();
    };
    return <Modal isOpen={isOpen}
        desktopModalWidth="lg:w-[348px]"
        title={
            <><Typography className="m-0" weight="font-semibold" variant="LG-2" >
                          Export data
            </Typography>

            </>
        }
        headerPadding="mt-0 mb-5 px-0"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL" >
        <div className="flex gap-2 justify-between items-center p-5">
            <Typography weight="font-medium">File format</Typography>
            <div>
                <PayoutExportFormatsPopover selectedFormat={selectedFormat} setSelectedFormat={setSelectedFormat}/>
            </div>
        </div>
        <Separator/>
        <div className="pt-5 flex justify-end pr-5">
            <Button className="mr-3 cursor-pointer"
                type="button"
                as="div"
                onClick={handleOnClose}
                variant="TERTIARY_FILLED"
                size="SMALL"
                minWidth="w-auto">
                Cancel
            </Button>
            <CSVLink
                data={csvTransactionsData}
                className={mergeClassNames({
                    'pointer-events-none': isLoadingPayload,
                })}
                headers={CSV_COLUMNS}
                filename={FILE_NAME}>
                <Button
                    disabled={isLoadingPayload}
                    loading={isLoadingPayload}
                    variant={'SECONDARY'}
                    size="SMALL"
                    minWidth="w-auto"
                    onClick={handleDownload}>
                    Download
                </Button>
            </CSVLink>
        </div>
    </Modal>;
};
