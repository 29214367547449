import React from 'react';
import { AccountingConfiguration, EApPlatform, EErpPlatform } from '@notch-ordering/shared-logic';
import QBOIcon from '@images/integrations/qbo-icon.svg';
import QBODotIcon from '@images/integrations/qbo-dot-icon.svg';
import NetSuiteIcon from '@images/integrations/netsuite-icon.svg';
import NetSuiteDotIcon from '@images/integrations/netsuite-dot-icon.svg';
import XeroIcon from '@images/integrations/xero-icon.svg';
import XeroDotIcon from '@images/integrations/xero-dot-icon.svg';
import BigChipIcon from '@images/integrations/big-chip-icon.svg';
import BigChipDotIcon from '@images/integrations/big-chip-dot-icon.svg';
import MD365Icon from '@images/integrations/dynamics365-icon.svg';
import MD365DotIcon from '@images/integrations/dynamics365-dot-icon.svg';
import MarketmanIcon from '@images/integrations/marketman-icon.svg';
import MarketmanDotIcon from '@images/integrations/marketman-dot-icon.svg';
import OCIcon from '@images/integrations/oc-icon.svg';
import OCDotIcon from '@images/integrations/oc-dot-icon.svg';
import WarningIcon from '@icons/alert-icon.svg';
import { RutterConfig, updateAccountingConfiguration, updateQBOBuyerConfiguration } from '@v2/network/BushwhackAPI';

export const ALL_ACCOUNTING_INTEGRATIONS = {
    [EApPlatform.QBO]: {
        nameKey: 'qboName',
        descriptionKey: 'qboDescription',
        exploreIcon: QBOIcon,
        accountingPlatform: EApPlatform.QBO
    },
    [EApPlatform.QBD]: {
        nameKey: 'qbdName',
        descriptionKey: 'qbdDescription',
        exploreIcon: QBOIcon,
        accountingPlatform: EApPlatform.QBD
    },
    [EApPlatform.NETSUITE]: {
        nameKey: 'netSuiteName',
        descriptionKey: 'netSuiteDescription',
        exploreIcon: NetSuiteIcon,
        accountingPlatform: EApPlatform.NETSUITE
    },
    [EApPlatform.XERO]: {
        nameKey: 'xeroName',
        descriptionKey: 'xeroDescription',
        exploreIcon: XeroIcon,
        accountingPlatform: EApPlatform.XERO
    },
    [EApPlatform.MD365]: {
        nameKey: 'md365Name',
        descriptionKey: 'md365Description',
        exploreIcon: MD365Icon,
        accountingPlatform: EApPlatform.MD365
    }
} as const;

/**
 * Utility for getting the translation key for an integration name
 */
export const integrationNameKeys = (integration: EApPlatform):string => {
    switch (integration) {
        case EApPlatform.QBO:
            return 'qboName';
        case EApPlatform.NETSUITE:
            return 'netSuiteName';
        case EApPlatform.XERO:
            return 'xeroName';
        case EApPlatform.BIGCHIP:
            return 'bigChipName';
        case EApPlatform.MD365:
            return 'md365Name';
        case EApPlatform.QBD:
            return 'qbdName';
        default:
            return null;
    }
};

/**
 * Utility for getting integration icons
 */
export const integrationIcons = (integration: EApPlatform | EErpPlatform, height: string, width: string):React.ReactNode => {
    switch (integration) {
        case EApPlatform.QBO || EApPlatform.QBD:
            return <QBOIcon className={`${height} ${width}`}/>;
        case EApPlatform.NETSUITE:
            return <NetSuiteIcon className={`${height} ${width}`}/>;
        case EApPlatform.XERO:
            return <XeroIcon className={`${height} ${width}`}/>;
        case EApPlatform.BIGCHIP:
            return <BigChipIcon className={`${height} ${width}`}/>;
        case EApPlatform.MD365:
            return <MD365Icon className={`${height} ${width}`}/>;
        case EErpPlatform.OPTIMUMCONTROL:
            return <OCIcon className={`${height} ${width}`}/>;
        case EErpPlatform.MARKETMAN:
            return <MarketmanIcon className={`${height} ${width}`}/>;
        case null:
        case undefined:
            // this case is necessary to prevent the warning icon from rendering simply due to an absence of integrations rather than invalid ones
            return null;
        default:
            console.warn(`Could not find icon for "${integration}" integration`);
            return <WarningIcon className={`${height} ${width} text-red-400`} />;
    }
};

/**
 * Utility for getting integration icons with dot (for indicating sync status)
 */
export const integrationDotIcons = (integration: EApPlatform | EErpPlatform, height: string, width: string):React.ReactNode => {
    switch (integration) {
        case EApPlatform.QBO || EApPlatform.QBD:
            return <QBODotIcon className={`${height} ${width}`}/>;
        case EApPlatform.NETSUITE:
            return <NetSuiteDotIcon className={`${height} ${width}`}/>;
        case EApPlatform.XERO:
            return <XeroDotIcon className={`${height} ${width}`}/>;
        case EApPlatform.BIGCHIP:
            return <BigChipDotIcon className={`${height} ${width}`}/>;
        case EApPlatform.MD365:
            return <MD365DotIcon className={`${height} ${width}`}/>;
        case EErpPlatform.OPTIMUMCONTROL:
            return <OCDotIcon className={`${height} ${width}`}/>;
        case EErpPlatform.MARKETMAN:
            return <MarketmanDotIcon className={`${height} ${width}`}/>;
        case null:
        case undefined:
            // this case is necessary to prevent the warning icon from rendering simply due to an absence of integrations rather than invalid ones
            return null;
        default:
            console.warn(`Could not find icon for "${integration}" integration`);
            return <WarningIcon className={`${height} ${width} text-red-400`} />;
    }
};

/**
 * Utility for getting the translation key for an integration description
 */
export const integrationDescriptionKeys = (integration: EApPlatform):string => {
    switch (integration) {
        case EApPlatform.QBO:
            return 'qboDescription';
        case EApPlatform.NETSUITE:
            return 'netSuiteDescription';
        case EApPlatform.XERO:
            return 'xeroDescription';
        case EApPlatform.BIGCHIP:
            return 'bigChipDescription';
        case EApPlatform.MD365:
            return 'md365Description';
        case EApPlatform.QBD:
            return 'qbdDescription';
        default:
            return null;
    }
};

/**
 * Utility for updating QBO and Rutter configurations
 */
export const updateIntegrationConfiguration = async (
    accountingPlatform: EApPlatform,
    configID: string,
    accountingConfig: AccountingConfiguration,
    configurationData: RutterConfig,
    isUpdateCurrency: boolean,
    onConfigUpdate: (config: AccountingConfiguration) => void
): Promise<void> => {
    configurationData.platform = accountingPlatform;

    switch (accountingPlatform) {
        case EApPlatform.QBO:
            await updateQBOBuyerConfiguration(configID, configurationData);
            break;
        case EApPlatform.NETSUITE:
        case EApPlatform.XERO:
        case EApPlatform.MD365:
        case EApPlatform.QBD:
            await updateAccountingConfiguration(configID, { ...accountingConfig,
                data: {
                    ...accountingConfig.data,
                    ...configurationData,
                    ...(configurationData.taxCode && {
                        taxCode: {
                            id: configurationData.taxCode.id,
                            name: configurationData.taxCode.name,
                            taxable: true,
                            description: configurationData.taxCode.name,
                        },
                    }),
                    ...(configurationData.taxCodeNonTaxable && {
                        taxCode: {
                            id: configurationData.taxCode.id,
                            name: configurationData.taxCode.name,
                            taxable: true,
                            description: configurationData.taxCode.name,
                        },
                    }),
                } }, accountingConfig.data.platform, accountingConfig.data.accessToken, isUpdateCurrency, onConfigUpdate);
            break;
        default:
            break;
    }
};

export function isRutterPlatform(platform: EApPlatform): boolean {
    switch (platform) {
        case EApPlatform.NETSUITE:
        case EApPlatform.XERO:
        case EApPlatform.MD365:
        case EApPlatform.QBD:
            return true;
        default:
            return false;
    }
}
