import { getBalances, GetBalancesParams, GetBalancesResponse } from '@ar/network/AccountsReceivable.network';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const FETCH_BALANCES_QUERY_KEY = 'FETCH_BALANCES_QUERY_KEY';

export const useGetBalances = (params: GetBalancesParams): UseQueryResult<GetBalancesResponse> => useQuery(
    [FETCH_BALANCES_QUERY_KEY, params],
    async () => getBalances(params),
    { enabled: Boolean(params.supplier_id) || Boolean(params.gpo_id) },
);
