import '../../../styles/dataPage.scss';
import React, { useState } from 'react';
import { Button, Loading, NotchDataGrid, Tooltip, Typography } from '@notch-ordering/ui-components';
import { PageTitle } from '@ar/components/PageTitle';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useGetPayouts, useGetPayoutSchedule } from '@ar/hooks/queries/PayoutQueries.hook';
import { getPayoutsColumns, PAYOUTS_COLUMNS } from '@ar/pages/PayoutsPage/PayoutsPageConstants';
import { useGetSupplier } from '@ar/hooks/queries/SupplierQueries.hook';
import { GridSortModel } from '@mui/x-data-grid';
import { GetPayoutsSortBy } from '@ar/network/AccountsReceivable.network';
import { usePayoutsStore } from '@ar/stores/PayoutsStore';
import { PayoutsFilters } from '@ar/components/Payouts/PayoutsFilters';
import InfoOutlineIcon from '@icons/info-outline-icon.svg';
import { useNavigate } from 'react-router-dom';
import { ARRoutePaths } from '@v2/constants/EPaths';
import ExportIcon from '@icons/export-icon.svg';
import { AllPayoutsExportModal } from '@ar/components/Payouts/AllPayoutsExportModal';
import { TrackingEvents } from '@ar/constants/TrackingConstants';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { SeparatorInternalPageFullWidth } from '@ar/components/shared/SeparatorInternalPageFullWidth';
import { getGpoId } from '@v2/utils/GPOUtils';
import { PayoutsEmptyState } from '@/ar/components/Payouts/PayoutsEmptyState';
import { centsToDollars, formatAsCurrency } from '@/utils/Formatters';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';

/**
 * The AR Payouts page
 *
 * @returns JSX Element
 */
export const PayoutsPage = function PayoutsPage(): JSX.Element {
    useDocumentTitle('Payouts - Notch');
    const { supplierLoginData, supplier } = useSupplierStore();
    const { searchParams, updateSearchParams, haveSearchParamsChanged } = usePayoutsStore();
    const [showPendingBalanceTooltip, setShowPendingBalanceTooltip] = useState(false);
    const [showExportPayoutsModal, setShowExportPayoutsModal] = useState(false);
    const navigate = useNavigate();
    const gpoId = getGpoId(supplierLoginData);
    useGetSupplier({
        supplierID: supplierLoginData?.supplier_id,
        gpoId
    });

    const { data: payoutsResponse, isLoading, status } = useGetPayouts({
        supplier_id: supplierLoginData?.supplier_id,
        ...searchParams,
        offset: searchParams.page * searchParams.limit,
        gpo_id: gpoId,
    });

    const { data: payoutScheduleResponse } = useGetPayoutSchedule({
        stripeAccountID: supplierLoginData.stripe_account_id,
        supplierID: supplierLoginData.supplier_id,
        gpoId,
    });
    const schedule = payoutScheduleResponse?.schedule;

    const payouts = payoutsResponse?.payouts ?? [];
    const hasResults = payoutsResponse?.total > 0;
    const successStatus = status === 'success';
    const showResults = successStatus && hasResults;
    const noResults = successStatus && payoutsResponse?.total === 0 && !haveSearchParamsChanged();
    const emptyFilteredResults = successStatus && !hasResults && haveSearchParamsChanged();
    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        const { field, sort } = sortModel[0] ?? {
            field: undefined,
            sort: undefined,
        };
        let sortField = field;
        if (!field || !sort) {
            return;
        }
        if (field === PAYOUTS_COLUMNS.net) {
            sortField = 'amount';
        }
        const direction = sort === 'asc' ? '' : '-';
        const fieldToSortBy:GetPayoutsSortBy = `${direction}${sortField}` as GetPayoutsSortBy;
        updateSearchParams({ sort_by: fieldToSortBy });
    }, []);
    // TODO: determine how to know when a supplier has not set up their account to stripe
    // if (supplier && supplier?.onboarding_link) {
    //     return <PayoutsAccountNotLinked/>;
    // }
    const getColumnVisibilityModel = (): Record<string, boolean> => ({
        // display the column only if gpo user
        [PAYOUTS_COLUMNS.supplierName]: supplierLoginData.gpo,
    });
    if (noResults) {
        return (
            <PayoutsEmptyState/>
        );
    }

    return (
        <>
            <div className="flex flex-col gap-8 justify-between mb-6">
                <div className="flex justify-between gap-4 items-start">
                    <div><
                        PageTitle>Payouts</PageTitle>
                    <div className="h-5">
                        {!!schedule?.delay_days
                                && <Typography className="text-gray-600">Your payouts are scheduled to be processed
                                    every {schedule?.delay_days} {schedule?.delay_days === 1 ? 'day' : 'days'}</Typography>}</div>
                    </div>
                    <Button size="SMALL"
                        className="flex items-center gap-2"
                        onClick={(): void => setShowExportPayoutsModal(true)}>
                        <ExportIcon className="w-4 h-4"/> <span>Export</span>
                    </Button>
                </div>

                {supplier?.pending_balance > 0 && <div>
                    <Typography as="div" weight="font-medium" variant="LG-1" className="text-gray-600 flex gap-1 items-center"><span>Pending amount</span>
                        <Tooltip show={showPendingBalanceTooltip}
                            showArrow={false}

                            tooltipClassName="py-1.5 px-2 rounded-md"
                            placement="bottom"
                            trigger={
                                <div onMouseEnter={(): void => setShowPendingBalanceTooltip(true)} onMouseLeave={(): void => setShowPendingBalanceTooltip(false)}>
                                    <InfoOutlineIcon className="w-3 h-3 inline-block ml-1 text-gray-600"/>
                                </div>}>
                            <div>
                                <Typography weight="font-medium">Amount collected but not deposited.</Typography>
                                <p>Amount will be deposited on the next payout.</p>
                            </div>
                        </Tooltip>
                    </Typography>
                    <Typography weight="font-medium" variant="XL">{formatAsCurrency(centsToDollars(supplier?.pending_balance))}</Typography>
                </div>}
            </div>
            <SeparatorInternalPageFullWidth className="pb-px mb-6"/>
            <PayoutsFilters/>
            {isLoading && <Loading isDark />}
            {showResults && <NotchDataGrid
                autoHeight
                loading={isLoading}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                columns={getPayoutsColumns()}
                page={searchParams.page}
                onPageChange={(newPage): void => {
                    updateSearchParams({ page: newPage });
                    const pageChangeEvent = newPage > searchParams.page ? TrackingEvents.goToNextPageClicked : TrackingEvents.goToPreviousPageClicked;
                    sendTrackingEvent(pageChangeEvent);
                }}
                onRowClick={(row): void => {
                    navigate(`/${ARRoutePaths.AR_PAYOUTS}/${row.id}`);
                    sendTrackingEvent(TrackingEvents.payoutRowClicked);
                }}
                columnVisibilityModel={getColumnVisibilityModel()}
                rowCount={payoutsResponse?.total ?? 0}
                rowsPerPageOptions={[searchParams.limit]}
                pageSize={searchParams.limit}
                paginationMode="server"
                rowHeight={61}
                rows={payouts}
                sx={{
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer',
                    },
                    '& .MuiDataGrid-cell': {
                        overflow: 'visible !important',
                    },
                }}/>}

            {emptyFilteredResults && <div className="flex flex-col gap-2 items-center justify-center mt-32">
                <Typography variant="LG-2" weight="font-semibold">No results found</Typography>
                <Typography className="text-gray-600">Try searching for something else or adjusting your filters</Typography>
            </div>
            }
            {showExportPayoutsModal && <AllPayoutsExportModal isOpen={showExportPayoutsModal} close={() => setShowExportPayoutsModal(false)} />}

        </>
    );
};
