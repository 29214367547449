import { getPayout,
    GetPayoutParams, GetPayoutResponse, getPayouts,
    getPayoutSchedule,
    GetPayoutsParams,
    GetPayoutsResponse, GetPayoutsScheduleParams, GetPayoutsScheduleResponse } from '@ar/network/AccountsReceivable.network';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const FETCH_PAYOUTS_QUERY_KEY = 'FETCH_PAYOUTS_QUERY_KEY';

export const useGetPayouts = (params: GetPayoutsParams): UseQueryResult<GetPayoutsResponse> => useQuery(
    [FETCH_PAYOUTS_QUERY_KEY, params],
    async () => getPayouts(params),
    { enabled: !!params?.supplier_id || !!params.gpo_id },
);

export const FETCH_PAYOUT_SCHEDULE_QUERY_KEY = 'FETCH_STRIPE_ACCOUNT_QUERY_KEY';

export const useGetPayoutSchedule = (params: GetPayoutsScheduleParams): UseQueryResult<GetPayoutsScheduleResponse> => useQuery(
    [FETCH_PAYOUT_SCHEDULE_QUERY_KEY, params],
    async () => getPayoutSchedule(params),
    { enabled: !!params?.stripeAccountID },
);

export const FETCH_PAYOUT_BY_ID_QUERY_KEY = 'FETCH_PAYOUT_BY_ID_QUERY_KEY';

export const useGetPayoutById = (params: GetPayoutParams): UseQueryResult<GetPayoutResponse> => useQuery(
    [FETCH_PAYOUT_BY_ID_QUERY_KEY, params],
    async () => getPayout(params),
    { enabled: !!params?.payoutID || !!params?.supplierID || !!params?.gpoId },
);
