import { getGpos, getSupplier, getSupplierCustomer,
    getSupplierNotificationRules,
    GetSupplierParams,
    getSupplierPaymentRates,
    GetSupplierPaymentRatesOutput,
    GetSupplierResponse,
    getSuppliers,
    GetSuppliersOutput,
    GetSuppliersParams,
    GetSuppliersResponse,
    getSupplierStripeExternalAccount,
    GetSupplierStripeExternalAccountResponse,
    NotificationsData } from '@ar/network/AccountsReceivable.network';
import { useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { getGpoId } from '@v2/utils/GPOUtils';

export const FETCH_SUPPLIER_QUERY_KEY = 'FETCH_SUPPLIER_QUERY_KEY';

export const useGetSupplier = (params: GetSupplierParams): UseQueryResult<GetSupplierResponse> => {
    const { setSupplier } = useSupplierStore();
    const { supplierLoginData } = useSupplierStore();
    // this variable verifies the presence of a `gpoId` in the `supplierLoginData` on each call before adding it to the request, if not present it will be `undefined`
    const gpoId = getGpoId(supplierLoginData);
    return useQuery(
        [FETCH_SUPPLIER_QUERY_KEY, params],
        async () => getSupplier({
            ...params,
            gpoId,
        }),
        {
            enabled: !!params?.supplierID,
            onSuccess: (data) => setSupplier(data),
        },
    );
};

export const FETCH_SUPPLIER_INFO_QUERY_KEY = 'FETCH_SUPPLIER_INFO_QUERY_KEY';

export const useGetSupplierInfo = (params: GetSupplierParams): UseQueryResult<GetSupplierResponse> => useQuery(
    [FETCH_SUPPLIER_INFO_QUERY_KEY, params],
    async () => getSupplierCustomer(params),
    {
        enabled: !!params?.supplierID,
    },
);

export const FETCH_SUPPLIER_STRIPE_ACCOUNT_QUERY_KEY = 'FETCH_SUPPLIER_STRIPE_ACCOUNT_QUERY_KEY';

export const useGetSupplierStripeExternalAccount = (params: GetSupplierParams): UseQueryResult<GetSupplierStripeExternalAccountResponse> => useQuery(
    [FETCH_SUPPLIER_STRIPE_ACCOUNT_QUERY_KEY, params],
    async () => getSupplierStripeExternalAccount(params),
    { enabled: !!params?.supplierID },
);

export const FETCH_SUPPLIERS_QUERY_KEY = 'FETCH_SUPPLIERS_QUERY_KEY';
export const useGetSuppliers = (params: GetSuppliersParams): UseQueryResult<GetSuppliersOutput> => useQuery([FETCH_SUPPLIERS_QUERY_KEY, params], async () => getSuppliers(params));

export const FETCH_SUPPLIER_PAYMENT_RATES_QUERY_KEY = 'FETCH_SUPPLIER_PAYMENT_RATES_QUERY_KEY';

export const useGetSupplierPaymentRates = (params: GetSupplierParams): UseQueryResult<GetSupplierPaymentRatesOutput> => useQuery(
    [FETCH_SUPPLIER_PAYMENT_RATES_QUERY_KEY, params],
    async () => getSupplierPaymentRates(params.supplierID),
    { enabled: !!params?.supplierID },
);

export const FETCH_SUPPLIER_NOTIFICATIONS_QUERY_KEY = 'FETCH_SUPPLIER_NOTIFICATIONS_QUERY_KEY';

export const useGetSupplierNotifications = (params: GetSupplierParams): UseQueryResult<NotificationsData> => useQuery(
    [FETCH_SUPPLIER_NOTIFICATIONS_QUERY_KEY, params],
    async () => getSupplierNotificationRules(params),
    { enabled: !!params?.supplierID },
);

export const FETCH_GPOS_QUERY_KEY = 'FETCH_GPOS_QUERY_KEY';

export const useGetGpos = () => useQuery(
    [FETCH_GPOS_QUERY_KEY],
    async () => getGpos()
);

export type TUseGetSuppliersQuery = {
    supplier_id?: string,
    gpo_id?: string,
    limit?: number,
    offset?: number,
};

export const FETCH_SUPPLIERS_INFINITE_QUERY_KEY = 'FETCH_SUPPLIERS_INFINITE_QUERY_KEY';
export const useGetSuppliersInfinite = (
    params: TUseGetSuppliersQuery,
): UseInfiniteQueryResult<GetSuppliersResponse> => useInfiniteQuery(
    [FETCH_SUPPLIERS_INFINITE_QUERY_KEY, params],
    async ({ pageParam = null }) => getSuppliers({
        ...params,
        offset: pageParam,
    }),
    {
        getNextPageParam: (lastPage) => {
            const nextPageOffset = lastPage.offset + lastPage.limit;
            return nextPageOffset < lastPage.total ? nextPageOffset : null;
        },
        enabled: !!params.supplier_id || !!params.gpo_id,
    },
);
