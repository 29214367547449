import { getAutoPayPaymentMethod } from '@v2/utils/getAutoPayPaymentMethod';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { GetPaymentMethodResponse } from '@/ar/network/AccountsReceivable.network';

type AutoPayMethodResult = [ GetPaymentMethodResponse, Dispatch<SetStateAction<GetPaymentMethodResponse>>];

export function useAutoPayMethod(supplierID, isCustomer, paymentMethods): AutoPayMethodResult {
    const [autoPayMethod, setAutoPayMethod] = useState<GetPaymentMethodResponse>(null);

    useEffect(() => {
        const method = getAutoPayPaymentMethod(supplierID, isCustomer, paymentMethods);
        setAutoPayMethod(method);
    }, [supplierID, isCustomer, paymentMethods]);

    return [autoPayMethod, setAutoPayMethod];
}
