import { SelectOption } from '@notch-ordering/ui-components';
import Provinces from 'provinces';

export const { US_STATES, CANADA_STATES } = Provinces.reduce((acc, province) => {
    if (province.country === 'US') {
        return { ...acc, US_STATES: [...acc.US_STATES, province] };
    }

    if (province.country === 'CA') {
        return { ...acc, CANADA_STATES: [...acc.CANADA_STATES, province] };
    }
    return acc;
}, { US_STATES: [] as Province[], CANADA_STATES: [] as Province[] });

const mapProvinceToSelectOption = (province: Province): SelectOption => ({
    label: province.name,
    value: province.short,
});

export const PROVINCE_STATES_OPTIONS = {
    us: US_STATES.map(mapProvinceToSelectOption),
    ca: CANADA_STATES.map(mapProvinceToSelectOption),
};

/**
 * Returns an array of state/province options based on the provided country code.
 *
 * @param {string} country - The country code. It should be 'us' for United States and 'ca' for Canada.
 * @returns {SelectOption[]} - An array of state/province options. Each option is an object with 'label' and 'value' properties. 'label' is the full name of the state/province and 'value' is the two-letter code. If the country code is not 'us' or 'ca', it defaults to Canadian provinces.
 */

export const getStateSelectOptionsByCountryCode = (country: string): SelectOption[] => PROVINCE_STATES_OPTIONS[country] ?? PROVINCE_STATES_OPTIONS.ca;

/**
 * Converts a full state name into its corresponding two-letter code, depending on the country.
 *
 * @param {string} country - The country code. Default is an empty string.
 * @param {string} state - The full state name. Default is an empty string.
 * @returns {string} - The two-letter state code. If the state name is already a two-letter code or is an empty string, it returns the state name as is. If the state name does not match any known state names, it returns an empty string.
 */
export const convertStateNameToCode = (country: string = 'ca', state: string = ''): string => {
    // if the state is already a two-letter code or an empty string, return it as is
    if (state.length === 2 || state.length === 0) {
        return state;
    }
    const provinceOptions = getStateSelectOptionsByCountryCode(country);
    const stateName = state.toLowerCase();
    const selectedStateOption = provinceOptions.find((option) => (option.label as string).toLowerCase() === stateName);
    return selectedStateOption?.value as string ?? '';
};
