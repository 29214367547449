/**
 * Updates the URL search parameters with the provided object.
 * Modifies the URL search params and pushes the updated URL to the browser history.
 *
 * @param params - The object containing the search parameters to be updated.
 */
export const updateUrlSearchParams = <T>(searchParams: T): void => {
    // Update the URL with the new search params
    const url = new URL(window.location.href);

    // Add or remove search parameters dynamically from the URL search params
    Object.entries(searchParams).forEach(([key, value]) => {
        if (value !== undefined) {
            url.searchParams.set(key, String(value));
        } else {
            url.searchParams.delete(key);
        }
    });

    window.history.pushState({}, '', url.toString());
};

/**
 * Returns the URLSearchParams object containing the query parameters from the current window location.
 *
 * @returns {URLSearchParams} The URLSearchParams object.
 */
export const getUrlSearchParams = (): URLSearchParams => new URLSearchParams(window.location.search);

/**
 * Returns the URL search parameters as an object from the current window location.
 *
 * @returns {Record<string, string>} The object containing the search parameters.
 */
export const getUrlSearchParamsAsObject = <T = Record<string, string>>(): T => Object.fromEntries(getUrlSearchParams().entries()) as T;

/**
 * Opens a URL in a new browser tab.
 *
 * This function creates a temporary link element, sets its href to the provided PDF URL,
 * and triggers a click event to open the PDF in a new tab.
 *
 * @param {string} url - The URL of the PDF to be opened.
 */
export const openUrlInNewTab = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
};
