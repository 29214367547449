import { LDClient, LDContext } from 'launchdarkly-react-client-sdk';

let isLaunchDarklySetup: boolean = false;

export const enum LaunchDarklyContextKind {
    customer = 'customer',
    arSupplier = 'arSupplier',
    multi = 'multi'
}

export const getCustomerContext = (customerID: string): LDContext => ({
    kind: LaunchDarklyContextKind.customer,
    key: customerID
});

export const getSupplierContext = (supplierID: string): LDContext => ({
    kind: LaunchDarklyContextKind.arSupplier,
    key: supplierID
});

export async function setupLaunchDarkly(context: LDContext, client:LDClient): Promise<void> {
    if (isLaunchDarklySetup) {
        // Nothing to do
        return;
    }

    if (client) {
        await client.identify(context);
    }

    isLaunchDarklySetup = true;
}

/**
 * This function sets an anonymous context for the LaunchDarkly client.
 *
 * @param {LDClient} client - The LaunchDarkly client instance.
 * @returns {Promise<void>} - A Promise that resolves when the client has been identified with the new context.
 * @example
 */
export async function setAnonymousContext(client:LDClient): Promise<void> {
    const newContext:LDContext = {
        kind: 'user',
        anonymous: true
    };

    // If a client is provided, identify the client with the new context
    if (client) {
        await client.identify(newContext);
    }
}
