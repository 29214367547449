import React from 'react';
import { useParams } from 'react-router-dom';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useGetCustomer, useGetCustomerNotifications, useGetCustomerPaymentMethods } from '@ar/hooks/queries/CustomerQueries.hook';
import { GetBalancesResponse, GetCustomerResponse, GetPaymentMethodsResponse, NotificationsData } from '@ar/network/AccountsReceivable.network';
import { useGetBalances } from '@ar/hooks/queries/BalanceQueries.hook';
import { CustomerDetailsTabs } from '@ar/components/CustomerDetails/CustomerGeneralPopup/CustomerGeneralPopup.constants';
import { getGpoId } from '@v2/utils/GPOUtils';

interface ICustomerDetailsProviderProps {
    customerID: string,
    setCustomerId: React.Dispatch<React.SetStateAction<string>>,
    supplierID: string,
    customer: GetCustomerResponse,
    customerNotifications: NotificationsData,
    isCustomerLoading: boolean,
    isCustomerNotificationsLoading: boolean,
    paymentMethods: GetPaymentMethodsResponse,
    isLoadingPaymentMethods: boolean,
    customerBalanceData: GetBalancesResponse,
    isLoadingCustomerBalanceData: boolean,
    isCustomerPopupOpen: boolean,
    setIsCustomerPopupOpen: React.Dispatch<React.SetStateAction<boolean>>,
    customerPopupTab: string,
    setCustomerPopupTab: React.Dispatch<React.SetStateAction<string>>,
    handleCloseGeneralPopup: () => void,
    refetchCustomer: () => void,
    refetchCustomerNotifications: () => void,
    refetchPayments: () => void,
    gpoId?: string,
    isCustomer?: boolean,
    isSupplier?: boolean,
}
/**
 *
 * CustomerDetailsContext - Context for the customer details
 */
export const CustomerDetailsContext = React.createContext<ICustomerDetailsProviderProps>({
    customerID: '',
    setCustomerId: () => null,
    supplierID: '',
    customer: {} as GetCustomerResponse,
    customerNotifications: {} as NotificationsData,
    isCustomerLoading: false,
    isCustomerNotificationsLoading: false,
    paymentMethods: {} as GetPaymentMethodsResponse,
    isLoadingPaymentMethods: false,
    customerBalanceData: {} as GetBalancesResponse,
    isLoadingCustomerBalanceData: false,
    isCustomerPopupOpen: false,
    setIsCustomerPopupOpen: () => null,
    customerPopupTab: CustomerDetailsTabs.Payments,
    setCustomerPopupTab: () => null,
    handleCloseGeneralPopup: () => null,
    refetchCustomer: () => null,
    refetchCustomerNotifications: () => null,
    refetchPayments: () => null,
    gpoId: '',
    isCustomer: false,
    isSupplier: false,
});

/**
 *
 * Customer Details provider which holds the customer for all child components
 *
 * @param children
 * @function Object() { [native code] }
 */
export const CustomerDetailsProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const { customerID: urlCustomerID } = useParams();
    const [isCustomerPopupOpen, setIsCustomerPopupOpen] = React.useState<boolean>(false);
    const [customerPopupTab, setCustomerPopupTab] = React.useState<string>(CustomerDetailsTabs.Payments);
    const [customerID, setCustomerId] = React.useState<string>(urlCustomerID || '');

    const { supplierLoginData } = useSupplierStore();
    const gpoId = getGpoId(supplierLoginData);
    const supplierID = supplierLoginData?.supplier_id;
    const { data: customerResults, isLoading, refetch: refetchCustomer } = useGetCustomer({ customerID, supplierID, gpoId });
    const { data: paymentMethods, isLoading: isLoadingPaymentMethods, refetch: refetchPayments } = useGetCustomerPaymentMethods({ customerID, supplierID, gpoId });
    const { data: customerBalanceData, isLoading: isLoadingCustomerBalanceData } = useGetBalances({
        supplier_id: supplierID,
        customer_id: customerID,
        gpo_id: gpoId,
    });
    const {
        data: customerNotifications,
        isLoading: isCustomerNotificationsLoading,
        refetch: refetchCustomerNotifications
    } = useGetCustomerNotifications({ supplierID, customerID });

    const handleCloseGeneralPopup = (): void => {
        setIsCustomerPopupOpen(false);
        setCustomerPopupTab(CustomerDetailsTabs.Payments);
    };

    const value = {
        customerID,
        setCustomerId,
        supplierID,
        customer: customerResults,
        customerNotifications,
        isCustomerLoading: isLoading,
        paymentMethods,
        isLoadingPaymentMethods,
        customerBalanceData,
        isLoadingCustomerBalanceData,
        isCustomerNotificationsLoading,
        isCustomerPopupOpen,
        setIsCustomerPopupOpen,
        customerPopupTab,
        setCustomerPopupTab,
        handleCloseGeneralPopup,
        gpoId,
        isCustomer: supplierLoginData.isCustomer,
        isSupplier: supplierLoginData.isSupplier,
        refetchCustomer,
        refetchCustomerNotifications,
        refetchPayments,
    };

    React.useEffect(() => {
        setCustomerId(urlCustomerID);
    }, [urlCustomerID]);

    return <CustomerDetailsContext.Provider value={value}>{children}</CustomerDetailsContext.Provider>;
};
