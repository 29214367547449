/**
 * This component will render a navigation link
 */
import React from 'react';
import { Typography } from '@notch-ordering/ui-components';
import { NavLink } from 'react-router-dom';
import { mergeClassNames } from '@v2/utils/Helpers';

export interface SideNavigationLinkProps {
    to: string,
    className?: string,
    children: React.ReactNode,
    target?: string,
    rel?: string,
    onClick?: () => void,
    icon?: React.ReactNode,
    activeIcon?: React.ReactNode,
    isSubmenuActive?: boolean,
}

export const SideNavigationLink: React.FC<SideNavigationLinkProps> = ({
    to,
    target,
    rel,
    className,
    children,
    onClick,
    icon,
    activeIcon,
    isSubmenuActive,
}) => {
    const [isNavActive, setIsNavActive] = React.useState(false);
    return <NavLink
        to={to}
        target={target}
        rel={rel}
        onClick={onClick}
        className={({ isActive }): string => {
            setIsNavActive(isActive);
            return mergeClassNames(
                'flex lg:rounded-xl pt-3.5 pb-2.5 lg:py-1.5 lg:pl-2.5 items-center hover:text-gray-650 lg:active:bg-gray-200 lg:hover:bg-gray-100 text-gray-650',
                {
                    'text-teal-500 lg:bg-gray-100 hover:text-teal-500 lg:hover:bg-gray-100': isActive,
                    'text-teal-500 hover:text-teal-500': isSubmenuActive,
                },
                className,
            );
        }
        }>
        <Typography
            as="div"
            weight={isNavActive || isSubmenuActive ? 'font-medium' : 'font-regular'}
            className="w-full">

            <div className="flex gap-3 items-center">
                {icon ? <div className="flex w-4 h-4">
                    {isNavActive ? activeIcon : icon}
                </div> : <div className="flex w-4 h-4"/>}
                {children}
            </div>
        </Typography>
    </NavLink>;
};
