import React from 'react';
import { Outlet } from 'react-router-dom';

/**
 * The AR Invoices Wrapper page
 *
 * @returns JSX Element
 */
export const InvoicesWrapperPage = (): JSX.Element => (<Outlet/>
);
