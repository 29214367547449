import '../../../styles/dataPage.scss';
import React, { useEffect } from 'react';
import { PageTitle } from '@ar/components/PageTitle';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useGetSupplier } from '@ar/hooks/queries/SupplierQueries.hook';
import { Loading } from '@notch-ordering/ui-components';
import { BankingAdyen } from '@ar/pages/BankingPage/BankingAdyen';
import { useNavigate } from 'react-router-dom';
import { ARRoutePaths } from '@v2/constants/EPaths';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';

/**
 * The AR Settings Banking page
 *
 * @returns JSX Element
 */
export const BankingPage = function BankingPage(): JSX.Element {
    useDocumentTitle('Banking - Notch');
    const { supplierLoginData } = useSupplierStore();
    const { data: supplierData, isLoading } = useGetSupplier({
        supplierID: supplierLoginData?.supplier_id,
    });
    const navigate = useNavigate();
    const isAdyenEnabled = !!supplierData?.payment_receiver_id;
    // Redirect to AR settings page if Adyen is not enabled
    useEffect(() => {
        if (supplierData && !isLoading && !isAdyenEnabled) {
            return navigate(`/${ARRoutePaths.AR_SETTINGS}`);
        }
        return undefined;
    }, [supplierData, isLoading, isAdyenEnabled]);

    return (
        <div>
            <PageTitle className="mb-2">Banking</PageTitle>
            {isLoading && <Loading />}
            <BankingAdyen/>
        </div>
    );
};
