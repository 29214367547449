import React from 'react';
import { Button, Input, Loading, Select, toast } from '@notch-ordering/ui-components';
import { useMutation } from '@tanstack/react-query';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import CheckIcon from '@icons/check-icon.svg';
import { isEmpty } from 'lodash';

import { GetCustomerResponse, UpdateCustomerData, updateCustomerByCustomerAuth } from '@/ar/network/AccountsReceivable.network';

import { validationSchema } from '@/ap/pages/SettingsPage/CustomerSettingsGeneral/CustomerSettingsGeneral.validationSchema';
import { queryClient } from '@/containers/app/Root';
import { FETCH_CUSTOMER_QUERY_KEY, useGetCustomerByCustomerAuth } from '@/ar/hooks/queries/CustomerQueries.hook';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { InputAddressAutocomplete } from '@/components/shared/InputAddressAutocomplete';
import { extractAddressFromPlace } from '@/utils/AddressUtils';
import { getStateSelectOptionsByCountryCode } from '@/ap/AddressConstants';

export const CustomerSettingsGeneral = (): JSX.Element => {
    const { supplierLoginData } = useSupplierStore();
    const customerID = supplierLoginData?.customer_id;

    const { data: customer, isLoading: isCustomerLoading, refetch: refetchCustomer } = useGetCustomerByCustomerAuth({ customerID });

    const updateCustomerMutation = useMutation(
        updateCustomerByCustomerAuth,
    );
    const isLoading = isCustomerLoading || updateCustomerMutation.isLoading;

    const { handleSubmit, control, reset, watch, setValue } = useForm<GetCustomerResponse>({
        resolver: zodResolver(validationSchema),
        defaultValues: { ...customer },
        mode: 'onBlur',
    });

    const { errors } = useFormState({
        control,
    });

    const resetCustomer = (): void => {
        reset({
            ...customer,
        });
    };

    const countryField = watch('address.country');

    const onSubmit = (newFormDataCustomer:GetCustomerResponse): void => {
        const newCustomer: UpdateCustomerData = {
            first_name: newFormDataCustomer.first_name,
            last_name: newFormDataCustomer.last_name,
            line1: newFormDataCustomer.address.line1,
            line2: newFormDataCustomer.address.line2,
            city: newFormDataCustomer.address.city,
            postal_code: newFormDataCustomer.address.postal_code,
            state: newFormDataCustomer.address.state,
            country: newFormDataCustomer.address.country,
        };

        updateCustomerMutation.mutate({ customerID, ...newCustomer }, {
            onSuccess: async () => {
                await queryClient.invalidateQueries([FETCH_CUSTOMER_QUERY_KEY, customerID]);
                toast.show({
                    message: 'Your settings have been updated!',
                    icon: <CheckIcon />,
                    showClose: false,
                });
                refetchCustomer();
            },
            onError: () => {
                toast.show({
                    message: 'Error while updating information',
                    showClose: false,
                });
            },
        });
    };
    const countryMapByName: Record<string, 'ca' | 'us'> = {
        'United States': 'us',
        Canada: 'ca',
    };

    const handlePlaceSelected = (place: google.maps.places.PlaceResult): void => {
        const { line1, line2, city, postal_code: postalCode, country, state_code: stateCode } = extractAddressFromPlace(place);
        const mappedCountry = countryMapByName[country];
        setValue('address.line1', line1);
        setValue('address.line2', line2);
        setValue('address.city', city);
        setValue('address.state', stateCode);
        setValue('address.postal_code', postalCode);
        setValue('address.country', mappedCountry);
    };

    React.useEffect(() => {
        if (!isEmpty(customer)) {
            resetCustomer();
        }
    }, [customer]);
    if (isCustomerLoading) {
        return <Loading isDark/>;
    }
    return (

        <form className="pt-4 grid customer-details" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-3">
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => <Input
                        label="Business name"
                        variant="SMALL"
                        disabled
                        required
                        inputProps={{
                            ...field,
                        }}/>}/>

                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => <Input
                        label="Email Address"
                        variant="SMALL"
                        disabled
                        required
                        inputProps={{
                            ...field,
                        }}/>}/>
                <div className=" flex">
                    <div className="w-1/2 mr-3">
                        <Controller
                            name="first_name"
                            control={control}
                            render={({ field }) => <Input
                                label="First name"
                                isInvalid={!!errors.first_name}
                                invalidMessage={errors.first_name?.message}
                                variant="SMALL"
                                inputProps={{
                                    ...field,
                                }}/>}/>
                    </div>
                    <div className="w-1/2">
                        <Controller
                            name="last_name"
                            control={control}
                            render={({ field }) => <Input
                                label="Last name"
                                isInvalid={!!errors.last_name}
                                invalidMessage={errors.last_name?.message}
                                variant="SMALL"
                                inputProps={{
                                    ...field,
                                }}/>}/>
                    </div>
                </div>

                <div className="flex flex-col gap-3">
                    <div className="flex">
                        <div className="w-3/4 mr-3 mt-0.5">
                            <Controller
                                name="address.line1"
                                control={control}
                                render={({ field }) => (
                                    <InputAddressAutocomplete

                                        variant="SMALL"
                                        className="w-full"
                                        isInvalid={!!errors.address?.line1}
                                        label="Street address"
                                        value={field.value}
                                        onChange={(e): void => {
                                            field.onChange(e.target.value);
                                        }}

                                        googleAutocompleteInputProps={{
                                            onKeyDown: (e): void => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                }
                                            },
                                        }}
                                        onPlaceSelected={handlePlaceSelected}
                                        searchTypes={['address']}
                                        invalidMessage={errors.address?.line1?.message}
                                        required/>
                                )}/>

                        </div>
                        <div className="w-1/4" >
                            <Controller
                                name="address.line2"
                                control={control}
                                render={({ field }) => <Input
                                    label="Unit number"
                                    variant="SMALL"
                                    isInvalid={!!errors.address?.line2}
                                    invalidMessage={errors.address?.line2?.message}
                                    inputProps={{
                                        ...field,
                                    }}/>}/>
                        </div>
                    </div>
                    <div className=" flex">
                        <div className="w-1/2 mr-3">
                            <Controller
                                name="address.country"
                                control={control}
                                render={({ field }) => <Select required
                                    label="Country"
                                    variant="SMALL"
                                    isInvalid={!!errors.address?.country}
                                    invalidMessage={errors.address?.country?.message}
                                    value={field.value}
                                    onChange={(value) => {
                                        field.onChange(value);
                                    }}

                                    options={[{
                                        value: 'ca',
                                        label: 'Canada',
                                    }, {
                                        value: 'us',
                                        label: 'United States',
                                    }]} />}/>

                        </div>
                        <div className="w-1/2">
                            <Controller
                                name="address.postal_code"
                                control={control}
                                render={({ field }) => <Input
                                    label="Postal Code"
                                    variant="SMALL"
                                    isInvalid={!!errors.address?.postal_code}
                                    invalidMessage={errors.address?.postal_code?.message}
                                    required
                                    inputProps={{
                                        ...field,
                                    }}/>}/>
                        </div>
                    </div>
                    <div className=" flex">
                        <div className="w-1/2 mr-3">
                            <Controller
                                name="address.city"
                                control={control}
                                render={({ field }) => <Input
                                    label="City"
                                    isInvalid={!!errors.address?.city}
                                    invalidMessage={errors.address?.city?.message}
                                    variant="SMALL"
                                    required
                                    inputProps={{
                                        ...field,
                                    }}/>}/>
                        </div>
                        <div className="w-1/2">
                            <Controller
                                name="address.state"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label={countryField === 'ca' ? 'Province' : 'State'}
                                        variant="SMALL"
                                        required
                                        isInvalid={!!errors.address?.state}
                                        invalidMessage={errors.address?.state?.message}
                                        onChange={(e): void => {
                                            field.onChange(e.target.value);
                                        }}
                                        value={field.value}
                                        options={getStateSelectOptionsByCountryCode(countryField)}/>
                                )}/>

                        </div>
                    </div>
                    <Controller
                        name="currency"
                        control={control}
                        render={({ field }) => <Input
                            label="Currency"
                            variant="SMALL"
                            disabled
                            required
                            inputProps={{
                                ...field,
                                value: field.value?.toUpperCase()
                            }}/>}/>
                </div>
            </div>
            <div className="py-6 flex justify-between">
                <Button
                    disabled={isLoading}
                    loading={isLoading}
                    variant={'SECONDARY'}
                    size="MEDIUM"
                    minWidth="w-auto"
                    type="submit">
                      Save
                </Button>
            </div>
        </form>
    );
};
