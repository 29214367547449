import { GridColDef, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import { GetPayoutTransaction } from '@ar/network/AccountsReceivable.network';
import PaymentMethodBadge from '@ar/components/shared/PaymentMethodBadge/PaymentMethodBadge';
import USFlagIcon from '@ar/assets/icons/us-flag-icon.svg';
import CanadaFlagIcon from '@ar/assets/icons/canada-flag-icon.svg';
import { centsToDollars, formatAsCurrency } from '@/utils/Formatters';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';

export const headerClassName = 'text-gray-600 font-body text-med';
export const cellClassName = 'text-gray-700 font-body text-med';

export const commonGridDef: GridColDef = {
    field: '',
    headerAlign: 'left',
    headerClassName,
    cellClassName,
    align: 'left',
};

const gridColDef: GridColDef = {
    ...commonGridDef,
    sortable: true,
    flex: 1,
};

export const PAYOUT_COLUMNS = {
    reporting_category: 'reporting_category',
    invoice_number: 'invoice_number',
    customer_name: 'customer_name',
    customer_id: 'customer_id',
    external_id: 'external_id',
    type_of_payment: 'type_of_payment',
    gross: 'gross',
    fee: 'fee',
    net: 'net',
    created_at: 'created_at',
    description: 'description',
} as const;

export const getPayoutColumns = (): GridColDef<GetPayoutTransaction>[] => [{
    ...gridColDef,
    field: PAYOUT_COLUMNS.gross,
    headerName: 'Gross',
    renderCell: ({ value }) => <Typography className="truncate">{formatAsCurrency(centsToDollars(value))}</Typography>,
    minWidth: 90,
},
{
    ...gridColDef,
    field: PAYOUT_COLUMNS.net,
    headerName: 'Net',
    renderCell: ({ value }) => <Typography className="truncate">{formatAsCurrency(centsToDollars(value))}</Typography>,
    minWidth: 90,

},
{
    ...gridColDef,
    field: PAYOUT_COLUMNS.invoice_number,
    headerName: 'Invoice #',
    minWidth: 120,
    renderCell: ({ row }):JSX.Element => {
        const { invoice_number: invoiceNumber } = row;
        const invoices = invoiceNumber.length > 0 ? invoiceNumber : [];
        return <div className="flex flex-col">
            {invoices.map((invoice, index) => <Typography key={index} className="truncate">{invoice}</Typography>)}
            {invoices.length === 0 && '--'}
        </div>;
    },

},
{
    ...gridColDef,
    field: PAYOUT_COLUMNS.created_at,
    headerName: 'Issue date',
    renderCell: ({ value }) => <Typography className="truncate">{value ? formatIsoStringToUtcDate(new Date(value).toISOString(), 'MMM d, yyyy') : '--'}</Typography>,
    minWidth: 120,

},

{
    ...gridColDef,
    field: PAYOUT_COLUMNS.customer_name,
    headerName: 'Customer name',
    minWidth: 130,

    renderCell: ({ value }) => <Typography className="truncate">{value}</Typography>,
},
{
    ...gridColDef,
    field: PAYOUT_COLUMNS.customer_id,
    headerName: 'Customer ID',
    minWidth: 160,
    renderCell: ({ value }) => <Typography className="truncate">{value}</Typography>,
},
{
    ...gridColDef,
    field: PAYOUT_COLUMNS.type_of_payment,
    minWidth: 150,
    headerName: 'Payment method',
    renderCell: ({ row }):JSX.Element => <>
        <PaymentMethodBadge brand={row.type_of_payment} last4={row.last4 ?? '--'}/>
    </>,

}, {
    ...gridColDef,
    field: PAYOUT_COLUMNS.fee,
    minWidth: 80,
    headerName: 'Fees',
    renderCell: ({ value }) => <Typography className="truncate">{formatAsCurrency(centsToDollars(value))}</Typography>,

},
];

export const getFlagIconByCurrency = (currency: string = ''): React.ReactNode => {
    const currencyLower = currency.toLowerCase().trim();
    switch (currencyLower) {
        case 'usd':
            return <USFlagIcon className="h-4 w-4" />;
        case 'cad':
            return <CanadaFlagIcon className="h-4 w-4" />;
        default:
            return null;
    }
};
