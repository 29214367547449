import create from 'zustand';
import { persist } from 'zustand/middleware';
import { GetCustomerSupplierMappingsOutput, SupplierData } from '@ar/network/AccountsReceivable.network';
import { ArTokenResponse } from '@/auth/authStrategies/ArTokenStrategyBase';

export type SupplierLoginData = Omit<ArTokenResponse, 'api_token' | 'refresh_token' > | null;
type SupplierStoreState = {
    supplierLoginData: SupplierLoginData,
    supplier: SupplierData,
    customerSupplierMappings: GetCustomerSupplierMappingsOutput | null,
};

type SupplierStoreActions = {
    setSupplierLoginData: (supplierLoginData: SupplierLoginData) => void,
    setSupplier: (supplierData: SupplierData) => void,
    setCustomerSupplierMappings: (customerSupplierMappings: GetCustomerSupplierMappingsOutput) => void,
    reset: () => void,
};

export type SupplierStore = SupplierStoreState & SupplierStoreActions;

const SUPPLIER_STORE_NAME = 'supplier';
const SUPPLIER_STORE_INITIAL_STATE: SupplierStoreState = {
    supplierLoginData: null,
    supplier: null,
    customerSupplierMappings: null,
};

export const useSupplierStore = create<SupplierStore>()(
    persist(
        (set): SupplierStore => ({
            ...SUPPLIER_STORE_INITIAL_STATE,
            setSupplierLoginData: (supplierLoginData: SupplierLoginData) => set({ supplierLoginData }),
            reset: () => set(SUPPLIER_STORE_INITIAL_STATE),
            setSupplier: (supplierData: SupplierData) => set({ supplier: supplierData }),
            setCustomerSupplierMappings: (customerSupplierMappings: GetCustomerSupplierMappingsOutput) => set({ customerSupplierMappings }),
        }),
        { name: SUPPLIER_STORE_NAME },
    ),
);
