import React, { useMemo, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { PopoverItem } from '@notch-ordering/ui-components';
import { validate as uuidValidate } from 'uuid';
import { FilterLabel } from '@/ar/components/shared/FilterLabel/FilterLabel';
import { FilterButton } from '@/components/shared/FilterButton';
import { SearchInput } from '@/ar/components/shared/SearchInput/SearchInput';
import { useCustomerSuppliersStore, SearchParams as SupplierSearchParams } from '@/ap/stores/CustomerSuppliersStore';

const DEBOUNCE_DELAY = 100;

type FilterOption = { label: string, value: string | boolean | number };

const paymentMethodFilterOptions = [{ label: 'Available', value: true }, { label: 'Not available', value: false }] as const;
const autoPayFilterOptions = [{ label: 'Enabled', value: true }, { label: 'Disabled', value: false }] as const;
const sortByOptions = [
    { label: 'Supplier name A-Z', value: 'name' },
    { label: 'Supplier name Z-A', value: '-name' },
] as const;

const getLabelByValue = (
    options: Readonly<FilterOption[]>,
    value: string | boolean | number,
): string => {
    const selectedOption = options.find((option) => option.value === value);

    return selectedOption.label ?? '';
};

export const SuppliersFilters = (): JSX.Element => {
    const [searchInputValue, setSearchInputValue] = React.useState<string>('');
    const { updateSearchParams, searchParams, clearFilter } = useCustomerSuppliersStore();

    const hasPaymentMethodChecked = searchParams.has_payment_method !== undefined;
    const hasAutoCollectionChecked = searchParams.has_auto_collection !== undefined;
    const isSorted = searchParams.sort_by !== undefined;

    const paymentMethodPopoverItems = useMemo<PopoverItem[]>(
        () => paymentMethodFilterOptions
            .map(({ label, value }) => {
                const currentValue = searchParams.has_payment_method;

                return {
                    label: <FilterLabel label={label} isChecked={currentValue === value}/>,
                    onClick: (): void => {
                        if (currentValue === value) {
                            updateSearchParams({ has_payment_method: undefined });
                        } else {
                            updateSearchParams({ has_payment_method: value });
                        }
                    },
                };
            }),
        [searchParams.has_payment_method, updateSearchParams],
    );

    const autoPayPopoverItems = useMemo<PopoverItem[]>(
        () => autoPayFilterOptions
            .map(({ label, value }) => {
                const currentValue = searchParams.has_auto_collection;

                return ({
                    label: <FilterLabel label={label} isChecked={currentValue === value}/>,
                    onClick: (): void => {
                        if (currentValue === value) {
                            updateSearchParams({ has_auto_collection: undefined });
                        } else {
                            updateSearchParams({ has_auto_collection: value });
                        }
                    },
                });
            }),
        [searchParams.has_auto_collection, updateSearchParams],
    );

    const sortByPopoverItems = useMemo<PopoverItem[]>(
        () => sortByOptions
            .map(({ label, value }) => {
                const currentValue = searchParams.sort_by;

                return ({
                    label: <FilterLabel label={label} isChecked={currentValue === value}/>,
                    onClick: (): void => {
                        if (currentValue === value) {
                            updateSearchParams({ sort_by: undefined });
                        } else {
                            updateSearchParams({ sort_by: value });
                        }
                    },
                });
            }),
        [searchParams.sort_by, updateSearchParams],
    );

    const debounceUpdateSearchParams = useCallback(
        debounce((params: Partial<SupplierSearchParams>) => {
            updateSearchParams(params);
        }, DEBOUNCE_DELAY),
        [updateSearchParams],
    );

    const handleChangeSearchInput = (value: string | string[]): void => {
        const stringValue = value as string;

        setSearchInputValue(stringValue);

        // If the value is a supplier id, we want to search by supplier id
        // Otherwise, we want to search by name
        if (uuidValidate(stringValue)) {
            debounceUpdateSearchParams({ supplier_id: stringValue, name: undefined });
        } else {
            debounceUpdateSearchParams({ name: stringValue, supplier_id: undefined });
        }
    };

    useEffect(() => {
        handleChangeSearchInput(searchInputValue);
    }, []);

    return (

        <div className="flex flex-col gap-3 py-6">
            <SearchInput
                onChange={handleChangeSearchInput}
                value={searchInputValue}
                inputProps={{ placeholder: 'Search' }}/>

            <div className="flex gap-2 flex-wrap">
                <FilterButton
                    isChecked={hasPaymentMethodChecked}
                    onClearFilter={(): void => clearFilter('has_payment_method') }
                    items={paymentMethodPopoverItems}
                    label={hasPaymentMethodChecked ? `Payment method is ${getLabelByValue(paymentMethodFilterOptions, searchParams.has_payment_method)}` : 'Payment method'}
                    closePanelOnClick={false}/>
                <FilterButton
                    isChecked={hasAutoCollectionChecked}
                    onClearFilter={(): void => clearFilter('has_auto_collection') }
                    items={autoPayPopoverItems}
                    label={hasAutoCollectionChecked ? `Auto Pay is ${getLabelByValue(autoPayFilterOptions, searchParams.has_auto_collection)}` : 'Auto Pay'}
                    closePanelOnClick={false}/>
                <FilterButton
                    isChecked={isSorted}
                    onClearFilter={(): void => clearFilter('sort_by')}
                    items={sortByPopoverItems}
                    label={isSorted ? `Sorted by ${getLabelByValue(sortByOptions, searchParams.sort_by)}` : 'Sort by'}
                    closePanelOnClick={false}/>
            </div>
        </div>
    );
};
