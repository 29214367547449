import { Button, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import { DateRangePicker } from '@ar/components/Invoices/InvoiceFilters/DateRangePicker';
import { mergeClassNames } from '@v2/utils/Helpers';
import CloseIcon from '@icons/close-icon.svg';
import { trackFilterSelected } from '@ar/constants/TrackingConstants';
import { endOfDay, format, startOfDay } from 'date-fns';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';

export type OnDateRangeChange = {
    startDate: Date, endDate: Date, startDateUTC: string, endDateUTC: string,
};
export interface InvoiceRangeDateFilterButtonProps {
    label: string,
    initialStartDate?: Date,
    initialEndDate?: Date,
    onDateRangeChange?: (onDateRangeChangeParams:OnDateRangeChange) => void,

}

export const InvoiceRangeDateFilterButton: React.FC<InvoiceRangeDateFilterButtonProps> = ({ label = '', initialStartDate, initialEndDate, onDateRangeChange }) => {
    const [showDateRange, setShowDateRange] = React.useState(false);
    const handleDateRangeClick = React.useCallback(() => {
        setShowDateRange((prev) => !prev);
        trackFilterSelected(label);
    }, []);
    const handleDateRangeChange = (startDate, endDate):void => {
        const startUTCDate = startDate ? startOfDay(startDate) : undefined;
        const endUTCDate = endDate ? endOfDay(endDate) : undefined;

        // Format the UTC dates as ISO strings with 'Z' for UTC time here because the date picker has no UTC option for dates
        const formattedStartUTCDate = startUTCDate ? format(startUTCDate, "yyyy-MM-dd'T'00:00:00'Z'") : undefined;
        const formattedEndUTCDate = endUTCDate ? format(endUTCDate, "yyyy-MM-dd'T'23:59:59'Z'") : undefined;
        onDateRangeChange({
            endDate,
            startDate,
            startDateUTC: formattedStartUTCDate,
            endDateUTC: formattedEndUTCDate,
        });
        setShowDateRange(false);
    };
    const isChecked = !!initialStartDate && !!initialEndDate;
    const handleClearFilter = (e):void => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        onDateRangeChange({
            endDate: undefined,
            startDate: undefined,
            startDateUTC: undefined,
            endDateUTC: undefined,

        });
        setShowDateRange(false);
    };

    // format filter date in UTC
    const formatFilterDate = (date: Date):string => formatIsoStringToUtcDate(date.toISOString(), 'MMM d, yyyy');

    return <div className="relative">

        <Button variant="TERTIARY_FILLED"
            size="MEDIUM"
            onClick={handleDateRangeClick}
            className={mergeClassNames('px-2.5 py-1 rounded-lg', {
                'bg-teal-50 text-teal-400 hover:bg-teal-100 active:bg-teal-100': isChecked,
            })}
            stopPropagation={false}>
            <div className="flex gap-3 items-center">
                <Typography className="mb-0" weight="font-medium">
                    {isChecked ? `${label} is ${formatFilterDate(initialStartDate)} - ${formatFilterDate(initialEndDate)}` : label}
                </Typography>
                {isChecked ? <CloseIcon onClick={handleClearFilter} className="w-4 h-4 text-teal-400"/> : <ChevronDownIcon className="w-4 h-4 text-gray-600"/>}
            </div>
        </Button>
        {showDateRange && <DateRangePicker
            onClose={():void => setShowDateRange(false)}
            className="absolute top-8 rounded-[10px] w-[248px] bg-white left-0 right-0 z-50 px-0 py-4 shadow-z3"
            isOpen={showDateRange}
            onDateRangeChange={handleDateRangeChange}
            initialStartDate={initialStartDate}
            initialEndDate={initialEndDate}/>
        }
    </div>;
};
