import React, { ChangeEventHandler, RefObject, useRef } from 'react';
import cx from 'classnames';
import Autocomplete, { ReactGoogleAutocompleteInputProps } from 'react-google-autocomplete';
import { Typography } from '@notch-ordering/ui-components';

type Props = {
    className?: string,
    label?: string,
    id?: string,
    required?: boolean,
    onChange?: ChangeEventHandler<HTMLInputElement>,
    onPlaceSelected: (places: google.maps.places.PlaceResult, ref: RefObject<HTMLInputElement>, autocompleteRef: RefObject<google.maps.places.Autocomplete>) => void,
    isInvalid?: boolean,
    invalidMessage?: string,
    disabled?: boolean,
    countryRestrictions?: string[],
    searchTypes?: string[],
    value?: string,
    googleAutocompleteInputProps?:ReactGoogleAutocompleteInputProps,
    variant?: 'SMALL' | 'MEDIUM',
};

export const InputAddressAutocomplete: React.FC<Props> = ({
    variant = 'MEDIUM',
    className,
    label,
    id,
    required,
    onChange,
    onPlaceSelected,
    isInvalid,
    invalidMessage,
    disabled,
    countryRestrictions,
    searchTypes,
    value,
    googleAutocompleteInputProps,
}) => {
    const inputRef = useRef(null);
    const country: string[] = countryRestrictions ?? ['us', 'ca'];
    const types: string[] = searchTypes ?? ['geocode'];

    return (
        <div className={cx(className)}>
            <div className="flex flex-col w-full gap-2">
                <Typography as="div" weight="font-medium" variant="LG-1" className="text-gray-700">
                    <label htmlFor={id} className="mb-0">
                        {label}
                        {required ?? <span className="text-gray-600 font-regular"> {'(optional)'} </span>}
                    </label>
                </Typography>

                <div className="relative">
                    <Autocomplete

                        className={cx(
                            'w-full border-gray-400 border appearance-none focus:border-teal-250 focus:shadow-z6 focus:outline-none disabled:bg-gray-100 disabled:text-gray-500 disabled:placeholder-gray-500 px-3 py-2.5 bg-white h-10 rounded-lg font-regular text-gray-700 placeholder:text-gray-600 text-2',
                            {
                                'border-red-300': isInvalid,
                                'bg-gray-100': disabled,
                                'border-gray-400': isInvalid,
                                'h-8 py-1.5 px-2.5': variant === 'SMALL',
                            },
                        )}
                        ref={inputRef}
                        apiKey={process.env.GOOGLE_MAP_PARAMS.key}
                        onPlaceSelected={onPlaceSelected}
                        onChange={onChange}
                        options={{
                            types,
                            componentRestrictions: { country },
                        }}
                        value={value}
                        {...googleAutocompleteInputProps}/>
                </div>
                {isInvalid && <Typography as="span" variant="BASE" className="text-red-300">{invalidMessage}</Typography>}
            </div>
        </div>
    );
};
