import React, { useMemo } from 'react';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { IntegrationsTabs } from '@ar/pages/IntegrationsPage/IntegrationsPageConstants';
import { EIntegrationType, SupplierData, updateSupplier, UpdateSupplierData } from '@ar/network/AccountsReceivable.network';
import { useGetSupplierConfiguration } from '@ar/hooks/queries/IntegrationsQuery.hook';
import { EConfigurationType, ERutterPlatform, getRutterConfiguration, SupplierConfiguration } from '@ar/network/Bushwhack.network';
import { useGetSupplier } from '@ar/hooks/queries/SupplierQueries.hook';
import { useMutation, useQuery } from '@tanstack/react-query';
import { v4 } from 'uuid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BUSHWHACK_API, BUSHWHACK_API_KEY } from '@/utils/constants';

interface IIntegration {
    name: SupplierData['integration_name'],
    last_synced_at: SupplierData['last_synced_at'],
    id?: string,
    isEnabled?: boolean,
    created_at: string,
    type: SupplierConfiguration['type'],
    isConnected?: boolean,
    integrationType: EIntegrationType,
    rutterSyncStatus?: boolean,
    rutterPaymentAccountID?: string,
}

export enum EQBOIntegrationType {
    WORKATO = 'workato',
    REQUIRESLOGIN = 'requiresLogin'
}

export type IntegrationModals = EIntegrationType.Fidelio | EIntegrationType.NetSuite | EIntegrationType.Spire | EIntegrationType.Sage300 | EIntegrationType.QBO;
export interface IIntegrationsProviderProps {
    integration: IIntegration | null,
    isLoading: boolean,
    integrationsTab: IntegrationsTabs,
    setIntegrationsTab: React.Dispatch<React.SetStateAction<IntegrationsTabs>>,
    modals: Record<IntegrationModals, boolean>,
    setModal: (modal: IntegrationModals, show: boolean) => void,
    supplierIntegrationConfiguration: SupplierConfiguration[],
    updateSupplierIntegration: (params: UpdateSupplierData) => void,
    removeSupplierIntegration?: () => void,
    isRutterIntegrationSyncComplete?: boolean,
}
/**
 *
 * IntegrationsContext - Context for the integrations page
 */
export const IntegrationsContext = React.createContext<IIntegrationsProviderProps>({
    integration: null,
    isLoading: false,
    integrationsTab: IntegrationsTabs.Active,
    setIntegrationsTab: () => null,
    modals: {
        fidelio: false,
        netsuite: false,
        spire: false,
        sage_300: false,
        qbo: false
    },
    setModal: () => null,
    supplierIntegrationConfiguration: [],
    updateSupplierIntegration: () => null,
    removeSupplierIntegration: () => null,
    isRutterIntegrationSyncComplete: false,
});

export type IntegrationWebhookUrls = Partial<Record<EConfigurationType | EIntegrationType.NetSuite | EIntegrationType.QBO, string>>;

const arRutterWebhookUrl: string = `${BUSHWHACK_API}/rutter/ar/ar-webhook?key=${BUSHWHACK_API_KEY}`;
export const integrationWebhookUrls: IntegrationWebhookUrls = {
    [EConfigurationType.Fidelio]: `${BUSHWHACK_API}/fidelio/ar-webhook?key=${BUSHWHACK_API_KEY}`,
    [EConfigurationType.Spire]: `${BUSHWHACK_API}/spire/ar-webhook?key=${BUSHWHACK_API_KEY}`,
    [EConfigurationType.Sage300]: `${BUSHWHACK_API}/sage-300/ar-webhook?key=${BUSHWHACK_API_KEY}`,
    [EConfigurationType.ArRutter]: arRutterWebhookUrl,
    [EIntegrationType.NetSuite]: arRutterWebhookUrl,
    [EIntegrationType.QBO]: arRutterWebhookUrl,
};

export const getIntegrationWebhookUrl = (integration: string = ''): string | null => {
    const integrationName = integration?.toLowerCase()?.replace(/[_]/g, '-');
    return integrationName in integrationWebhookUrls ? integrationWebhookUrls[integrationName] : null;
};

export const getConfigTypeFromSupplierIntegrationName = (supplierIntegrationName: EIntegrationType): EConfigurationType | null => {
    let integrationType: EConfigurationType | null;
    switch (supplierIntegrationName) {
        case EIntegrationType.QBOWORKATO:
            integrationType = EConfigurationType.Workato;
            break;
        case EIntegrationType.QBO:
        case EIntegrationType.NetSuite:
            integrationType = EConfigurationType.ArRutter;
            break;
        case EIntegrationType.Fidelio:
            integrationType = EConfigurationType.Fidelio;
            break;
        case EIntegrationType.Spire:
            integrationType = EConfigurationType.Spire;
            break;
        case EIntegrationType.Sage300:
            integrationType = EConfigurationType.Sage300;
            break;
        case EIntegrationType.CsvImport: // fall-through
        case EIntegrationType.PdfImport:
        case EIntegrationType.QBD:
        default:
            integrationType = null;
            break;
    }

    return integrationType;
};

export const isNotchedHandledIntegration = (supplierIntegrationName: EIntegrationType): boolean => {
    switch (supplierIntegrationName) {
        case EIntegrationType.QBO:
        case EIntegrationType.NetSuite:
        case EIntegrationType.Fidelio:
        case EIntegrationType.Spire:
        case EIntegrationType.Sage300:
            return true;
        case EIntegrationType.CsvImport: // fall-through
        case EIntegrationType.PdfImport:
        case EIntegrationType.QBD:
        case EIntegrationType.QBOWORKATO:
        default:
            return false;
    }
};

export function isRutterPlatform(platform: ERutterPlatform): boolean {
    switch (platform) {
        case ERutterPlatform.NETSUITE:
        case ERutterPlatform.QUICKBOOKS:
            return true;
        default:
            return false;
    }
}

export function isIntegrationReady(platform: ERutterPlatform, isPlatformReady: boolean): boolean {
    // return true if the integration platform is not on rutter
    if (!isRutterPlatform(platform)) {
        return true;
    }

    return isPlatformReady;
}

/**
 * Integrations provider
 *
 * @param children
 * @function Object() { [native code] }
 */
export const IntegrationsProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const updateSupplierMutation = useMutation(updateSupplier);
    const flags = useFlags();
    const [integrationsTab, setIntegrationsTab] = React.useState<IntegrationsTabs>(IntegrationsTabs.Active);
    const [modals, setModals] = React.useState<IIntegrationsProviderProps['modals']>({
        fidelio: false,
        netsuite: false,
        spire: false,
        sage_300: false,
        qbo: false,
    });

    const { supplier, supplierLoginData } = useSupplierStore();

    const { refetch: refetchSupplier } = useGetSupplier({
        supplierID: supplierLoginData?.supplier_id,
    });

    const integrationNameRutter = (connectedIntegrationPlatform: string):string => {
        switch (connectedIntegrationPlatform) {
            case 'Netsuite':
                return EIntegrationType.NetSuite;
            case 'Quickbooks':
                return EIntegrationType.QBO;
            default:
                return null;
        }
    };

    const { data: supplierConfiguration, isLoading, refetch: refetchSupplierConfiguration } = useGetSupplierConfiguration(supplierLoginData.supplier_id);
    const connectedIntegration = supplierConfiguration?.results?.find((i) => i.ownerID === supplierLoginData.supplier_id);

    const integrationType = (connectedIntegrationType: EConfigurationType):string => {
        switch (connectedIntegrationType) {
            case EConfigurationType.ArRutter:
                return integrationNameRutter(connectedIntegration?.data.platform);
            default:
                return connectedIntegration?.type;
        }
    };

    const accountingPlatform = connectedIntegration?.data?.platform;
    const accessToken = connectedIntegration?.data?.rutterAccessToken;
    const isPlatformEnabled = !!supplierLoginData.supplier_id && !!accessToken && !!accountingPlatform;
    const { data: rutterConfiguration } = useQuery(['FETCH_ACCOUNTING_INTEGRATION_INITIAL_SYNC', supplierLoginData.supplier_id], () => getRutterConfiguration(
        supplierLoginData.supplier_id,
        connectedIntegration?.data?.platform,
        connectedIntegration?.data?.rutterAccessToken
    ), { enabled: isPlatformEnabled,
        cacheTime: 0,
        retry: 1 });

    const rutterSyncStatus = isIntegrationReady(connectedIntegration?.data?.platform, rutterConfiguration?.isReady);

    const supplierIntegrationName = integrationType(connectedIntegration?.type) ?? supplier?.integration_name ?? '';
    const isQBOWorkato = supplierIntegrationName === EIntegrationType.QBO && flags.qboIntegrationType === EQBOIntegrationType.WORKATO;
    const integrationNameForConfigurationType = isQBOWorkato ? EIntegrationType.QBOWORKATO : supplierIntegrationName;
    const isNotchedHandled = isNotchedHandledIntegration(integrationNameForConfigurationType as EIntegrationType);

    let integration:IIntegration = null;

    if (!isLoading) {
        if (connectedIntegration) {
            const { createdAt, id, isEnabled, data, type } = connectedIntegration;
            const integrationName = integrationNameRutter(data?.platform) ?? type ?? '';
            integration = {
                id,
                name: integrationName.toLowerCase().replace(/-/g, '_'),
                last_synced_at: rutterConfiguration?.lastSyncCompletedAt,
                created_at: createdAt,
                isEnabled,
                type,
                isConnected: true,
                integrationType: supplierIntegrationName as EIntegrationType,
                rutterSyncStatus,
                rutterPaymentAccountID: data.rutterPaymentAccountID
            };
        } else if (supplierIntegrationName && !isNotchedHandled) {
            integration = {
                id: v4(),
                created_at: '',
                // by default if no record in bushwhack, we assume it is enabled
                isEnabled: true,
                type: getConfigTypeFromSupplierIntegrationName(integrationNameForConfigurationType as EIntegrationType),
                name: supplierIntegrationName,
                last_synced_at: '',
                isConnected: !isNotchedHandledIntegration(supplierIntegrationName as EIntegrationType),
                integrationType: supplierIntegrationName as EIntegrationType
            };
        }
    }

    React.useEffect(() => {
        if (integrationsTab === IntegrationsTabs.Active && !integration) {
            // retrieve supplier configuration data
            refetchSupplierConfiguration();
            // retrieve supplier data
            refetchSupplier();
        }
    }, [integrationsTab]);

    const updateSupplierIntegration = (params:UpdateSupplierData):void => {
        const cashApplicationWebhookUrl = params?.integration_name ? getIntegrationWebhookUrl(params.integration_name) : null;
        updateSupplierMutation.mutate({
            supplierID: supplierLoginData?.supplier_id,
            body: {
                cash_application_webhook_url: cashApplicationWebhookUrl,
                ...params,
            },
        }, {
            onSuccess: () => {
                refetchSupplier();
            },
        });
    };

    const removeSupplierIntegration = ():void => {
        updateSupplierIntegration({
            integration_name: null,
        });
    };

    const value:IIntegrationsProviderProps = useMemo(() => ({
        integration,
        isLoading,
        integrationsTab,
        setIntegrationsTab,
        supplierIntegrationConfiguration: supplierConfiguration?.results ?? [],
        modals,
        updateSupplierIntegration,
        removeSupplierIntegration,
        setModal: (modal: IntegrationModals, show: boolean): void => {
            setModals({
                ...modals,
                [modal]: show,
            });
        },
        isRutterIntegrationSyncComplete: rutterSyncStatus,
    }), [integration, isLoading, integrationsTab, setIntegrationsTab, modals, setModals, supplierConfiguration, rutterSyncStatus, updateSupplierIntegration, removeSupplierIntegration]);

    return <IntegrationsContext.Provider value={value}>{children}</IntegrationsContext.Provider>;
};

export const useIntegrationsContext = (): IIntegrationsProviderProps => React.useContext(IntegrationsContext);
