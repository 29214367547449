import '../../../styles/dataPage.scss';
import React, { useState } from 'react';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useGetPayoutById } from '@ar/hooks/queries/PayoutQueries.hook';
import { useNavigate, useParams } from 'react-router-dom';
import BackIcon from '@icons/back-icon.svg';
import { Button, Loading, NotchDataGrid, Separator, Typography } from '@notch-ordering/ui-components';
import { PageTitle } from '@ar/components/PageTitle';
import { getPayoutStatusBadge } from '@ar/pages/PayoutsPage/PayoutsPageConstants';
import { getFlagIconByCurrency, getPayoutColumns } from '@ar/pages/PayoutDetailsPage/PayoutDetailsPageConstants';
import { useGetSupplier } from '@ar/hooks/queries/SupplierQueries.hook';
import { ARRoutePaths } from '@v2/constants/EPaths';
import PadIcon from '@icons/pad-icon.svg';
import { v4 as uuidv4 } from 'uuid';
import { SinglePayoutTransactionExportModal } from '@ar/components/Payouts/SinglePayoutTransactionExportModal';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@ar/constants/TrackingConstants';
import { SeparatorInternalPageFullWidth } from '@ar/components/shared/SeparatorInternalPageFullWidth';
import { SectionTitle } from '@ar/components/shared/SectionTitle';
import { getGpoId } from '@v2/utils/GPOUtils';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';
import { centsToDollars, formatAsCurrency } from '@/utils/Formatters';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';

const PAGINATION = {
    limit: 50,
};

/**
 * The AR Payouts detail page
 *
 * @returns JSX Element
 */
export const PayoutDetailsPage = function PayoutsPage(): JSX.Element {
    useDocumentTitle('Payout Details - Notch');
    const { supplierLoginData, supplier } = useSupplierStore();
    const { payoutID } = useParams();
    const [pageSize, setPageSize] = useState<number>(PAGINATION.limit);
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const gpoId = getGpoId(supplierLoginData);
    const navigate = useNavigate();
    useGetSupplier({
        supplierID: supplierLoginData?.supplier_id,
        gpoId
    });
    const { data: payout, isLoading: isLoadingPayload, status } = useGetPayoutById({
        payoutID,
        supplierID: supplierLoginData?.supplier_id,
        gpoId,
    });
    const hasLoaded = status === 'success' && !isLoadingPayload;

    return (
        <>
            {isLoadingPayload && <Loading isDark />}

            {hasLoaded && <div>

                <div className="flex gap-2 justify-between items-center  mb-6 ">
                    <Button className="flex items-center -ml-3.5 text-gray-600"
                        size="SMALL"
                        variant="SUBDUED_ICON"
                        onClick={(): void => navigate(`/${ARRoutePaths.AR_PAYOUTS}`)}>
                        <BackIcon className="w-4 h-4 mr-3"/>
                    Back
                    </Button>
                    <Button size="SMALL"
                        onClick={(): void => {
                            setIsExportModalOpen(true);
                            sendTrackingEvent(TrackingEvents.exportPayoutDetailsClicked);
                        }}>
                        Export
                    </Button>
                </div>

                <div className="flex items-end gap-4 mb-10">
                    <PageTitle>{formatAsCurrency(centsToDollars(payout?.net))}</PageTitle>
                    <div className="pb-2.5">
                        {getPayoutStatusBadge(payout?.status)}
                    </div>
                </div>
                <SeparatorInternalPageFullWidth/>
                <div className="flex lg:flex-row flex-col-reverse">
                    <div className="overflow-x-auto lg:border-r border-r-gray-200 w-full h-full">
                        <div
                            className="py-10">
                            <SectionTitle className="mb-6">
                                Balances
                            </SectionTitle>
                            <div className="flex lg:gap-[70px] gap-10">
                                <div>
                                    <Typography variant="LG-1" className="font-medium text-gray-600 mb-1">
                                    Gross
                                    </Typography>
                                    <Typography variant="LG-2" className="font-medium">
                                        {formatAsCurrency(centsToDollars(payout?.gross))}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="LG-1" className="font-medium text-gray-600 mb-1">
                                    Net
                                    </Typography>
                                    <Typography variant="LG-2" className="font-medium">
                                        {formatAsCurrency(centsToDollars(payout?.net))}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="LG-1" className="font-medium text-gray-600 mb-1">
                                    Fees
                                    </Typography>
                                    <Typography variant="LG-2" className="font-medium">
                                        {formatAsCurrency(centsToDollars(payout?.fee))}
                                    </Typography>
                                </div>
                            </div>

                        </div>
                        <Separator className="-ml-10 w-[calc(100%+40px)] "/>

                        <div className="pt-10 pb-6">
                            <SectionTitle>
                                Invoices
                            </SectionTitle>
                        </div>
                        <NotchDataGrid
                            autoHeight={true}
                            loading={isLoadingPayload}
                            getRowHeight={() => 'auto'}
                            sortingMode="client"
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnSelector
                            disableColumnMenu
                            columns={getPayoutColumns()}
                            rowsPerPageOptions={[50, 100]}
                            onPageSizeChange={(newPageSize):void => setPageSize(newPageSize)}
                            hideFooter={payout.transactions.length < PAGINATION.limit}
                            rowCount={payout?.transactions?.length}
                            pageSize={pageSize}
                            paginationMode="client"
                            sx={{
                                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                                    py: '20px',
                                    alignItems: 'flex-start',
                                },
                                '& .MuiDataGrid-virtualScroller': {
                                    minHeight: 'auto',
                                },
                            }}
                            getRowId={(): string => uuidv4()}
                            localeText={{ noRowsLabel: 'The report is currently processing and may take a few days to complete.' }}
                            rows={payout.transactions}/></div>
                    <div className="py-10 pl-8 max-w-[272px] w-full">
                        <Typography variant="LG-2" className="mb-6" weight="font-semibold">
                            Basic details
                        </Typography>
                        {supplierLoginData.gpo && <div className="mb-10">
                            <Typography variant="LG-1" className="font-medium text-gray-600 mb-1">
                                Supplier
                            </Typography>
                            <Typography variant="LG-1" className="font-regular flex gap-2 items-center">
                                <span>{payout.supplier_name}</span>
                            </Typography>
                        </div>}
                        <div className="mb-10">
                            <Typography variant="LG-1" className="font-medium text-gray-600 mb-1">
                                Currency
                            </Typography>
                            <Typography variant="LG-1" className="font-regular flex gap-2 items-center">
                                <span>
                                    {getFlagIconByCurrency(supplier?.currency)}
                                </span><span>{supplier?.currency?.toUpperCase()}</span>
                            </Typography>
                        </div>
                        <div className="mb-10">
                            <Typography variant="LG-1" className="font-medium text-gray-600 mb-1">
                                Paid date
                            </Typography>
                            <Typography variant="LG-1" className="font-regular">
                                {payout?.received_at ? formatIsoStringToUtcDate(new Date(payout?.received_at).toISOString(), 'MMM do, yyyy') : '--'}
                            </Typography>
                        </div>
                        {!!payout.last4 && <div className="mb-10">
                            <Typography variant="LG-1" className="font-medium text-gray-600 mb-1">
                                Paid to
                            </Typography>
                            {!!payout?.last4
                                && <Typography variant="LG-1" className="font-regular flex gap-2 items-center">
                                    <PadIcon className={'h-4'}/> <span>•••• {payout?.last4}</span>
                                </Typography>}
                        </div>}
                    </div>
                </div>

            </div>}
            {isExportModalOpen && <SinglePayoutTransactionExportModal payoutID={payout?.id}
                isOpen={isExportModalOpen}
                close={(): void => setIsExportModalOpen(false)}/>}
        </>
    );
};
