import React from 'react';
import { Button, Popover, PopoverProps, Typography } from '@notch-ordering/ui-components';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import CloseIcon from '@icons/close-icon.svg';
import { PopoverItem } from '@notch-ordering/ui-components/src/components/Popover/Popover';
import cx from 'classnames';

import { trackFilterSelected } from '@ar/constants/TrackingConstants';
import { mergeClassNames } from '@v2/utils/Helpers';

export interface FilterButtonProps {
    items: PopoverItem[],
    label: string,
    className?: string,
    closePanelOnClick?: boolean,
    isChecked?: boolean,
    onClearFilter?: (
        e) => void,
    placement?: PopoverProps['placement'],
    header?: React.ReactNode,
    headerEnabled?: boolean,
}

export type FilterPopoverButtonProps = {
    isChecked: boolean,
    label: string,
    handleClearFilter: (e) => void,
};
export const FilterPopoverButton:React.FC<FilterPopoverButtonProps> = ({ isChecked, handleClearFilter, label }) => <Button variant="TERTIARY_FILLED"
    size="MEDIUM"
    className={mergeClassNames('px-2.5 py-1 rounded-lg', {
        'bg-teal-50 text-teal-400 hover:bg-teal-100 active:bg-teal-100': isChecked,
    })}
    stopPropagation={false}
    onClick={() => trackFilterSelected(label)}>
    <div className="flex gap-3 items-center">
        <Typography className="mb-0" weight="font-medium">{label}</Typography>
        {isChecked ? <CloseIcon onClick={handleClearFilter} className="w-3.5 h-3.5 text-teal-400"/>
            : <ChevronDownIcon className="w-4 h-4 text-gray-600"/>}
    </div>
</Button>;

export const FilterButton : React.FC<FilterButtonProps> = ({
    items, label = '', className, closePanelOnClick = true, isChecked, onClearFilter, placement,
    header, headerEnabled,
}) => {
    const handleClearFilter = (e):void => {
        if (e) {
            e.stopPropagation();
        }
        if (onClearFilter) {
            onClearFilter(e);
        }
    };

    return <Popover
        className={cx('w-48', className)}
        buttonClassName="ml-auto"
        header={header}
        headerEnabled={headerEnabled}
        closePanelOnClick={closePanelOnClick}
        button={ <FilterPopoverButton label={label} isChecked={isChecked} handleClearFilter={handleClearFilter}/>}
        items={items}
        placement={placement}/>;
};
