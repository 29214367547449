import React, { useMemo, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { PopoverItem } from '@notch-ordering/ui-components';
import { TrackingEvents } from '@ar/constants/TrackingConstants';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { FilterLabel } from '@/ar/components/shared/FilterLabel/FilterLabel';
import { FilterButton } from '@/components/shared/FilterButton';
import { useCustomersStore, SearchParams as CustomersSearchParams } from '@/ar/stores/CustomersStore';
import { SearchInput } from '@/ar/components/shared/SearchInput/SearchInput';

const DEBOUNCE_DELAY = 100;

type FilterOption = { label: string, value: string | boolean | number };

const paymentMethodFilterOptions = [{ label: 'Available', value: true }, { label: 'Not available', value: false }] as const;
const autoPayFilterOptions = [{ label: 'Enabled', value: true }, { label: 'Disabled', value: false }] as const;
const sortByOptions = [
    { label: 'Customer name A-Z', value: 'name' },
    { label: 'Customer name Z-A', value: '-name' },
    { label: 'Open balance (low - high)', value: 'open' },
    { label: 'Open balance (high - low)', value: '-open' },
    { label: 'Overdue balance (low - high)', value: 'overdue' },
    { label: 'Overdue balance (high - low)', value: '-overdue' },
] as const;

const getLabelByValue = (
    options: Readonly<FilterOption[]>,
    value: string | boolean | number,
): string => {
    const selectedOption = options.find((option) => option.value === value);

    return selectedOption.label ?? '';
};

export const CustomersFilters = (): JSX.Element => {
    const [searchInputValue, setSearchInputValue] = React.useState<string>('');
    const { updateSearchParams, searchParams, clearFilter } = useCustomersStore();

    const hasPaymentMethodChecked = searchParams.has_payment_method !== undefined;
    const hasAutoCollectionChecked = searchParams.has_auto_collection !== undefined;
    const isSorted = searchParams.sort_by !== undefined;

    const paymentMethodPopoverItems = useMemo<PopoverItem[]>(
        () => paymentMethodFilterOptions
            .map(({ label, value }) => {
                const currentValue = searchParams.has_payment_method;

                return {
                    label: <FilterLabel label={label} isChecked={currentValue === value}/>,
                    onClick: (): void => {
                        if (currentValue === value) {
                            updateSearchParams({ has_payment_method: undefined });
                        } else {
                            updateSearchParams({ has_payment_method: value });
                        }
                    },
                };
            }),
        [searchParams.has_payment_method, updateSearchParams],
    );

    const autoPayPopoverItems = useMemo<PopoverItem[]>(
        () => autoPayFilterOptions
            .map(({ label, value }) => {
                const currentValue = searchParams.has_auto_collection;

                return ({
                    label: <FilterLabel label={label} isChecked={currentValue === value}/>,
                    onClick: (): void => {
                        if (currentValue === value) {
                            updateSearchParams({ has_auto_collection: undefined });
                        } else {
                            updateSearchParams({ has_auto_collection: value });
                        }
                    },
                });
            }),
        [searchParams.has_auto_collection, updateSearchParams],
    );

    const sortByPopoverItems = useMemo<PopoverItem[]>(
        () => sortByOptions
            .map(({ label, value }) => {
                const currentValue = searchParams.sort_by;

                return ({
                    label: <FilterLabel label={label} isChecked={currentValue === value}/>,
                    onClick: (): void => {
                        if (currentValue === value) {
                            updateSearchParams({ sort_by: undefined });
                        } else {
                            updateSearchParams({ sort_by: value });
                        }
                    },
                });
            }),
        [searchParams.sort_by, updateSearchParams],
    );

    const debounceUpdateSearchParams = useCallback(
        debounce((params: Partial<CustomersSearchParams>) => {
            updateSearchParams(params);
        }, DEBOUNCE_DELAY),
        [updateSearchParams],
    );

    const handleChangeSearchInput = (value: string | string[]): void => {
        const stringValue = value as string;
        const customerIdRegExp = /^cus_.*$/g;

        setSearchInputValue(stringValue);

        // If the value is a customer id, we want to search by customer id
        // Otherwise, we want to search by name
        if (customerIdRegExp.test(stringValue)) {
            debounceUpdateSearchParams({ customer_id: stringValue, name: undefined });
        } else {
            debounceUpdateSearchParams({ name: stringValue, customer_id: undefined });
        }
    };

    useEffect(() => {
        handleChangeSearchInput(searchInputValue);
    }, []);

    return (

        <div className="flex flex-col gap-3 py-6">
            <SearchInput
                onFocus={() => sendTrackingEvent(TrackingEvents.enterSearchCustomer)}
                onChange={handleChangeSearchInput}
                value={searchInputValue}
                inputProps={{ placeholder: 'Search' }}/>

            <div className="flex gap-2 flex-wrap">
                <FilterButton
                    isChecked={hasPaymentMethodChecked}
                    onClearFilter={(): void => clearFilter('has_payment_method') }
                    items={paymentMethodPopoverItems}
                    label={hasPaymentMethodChecked ? `Payment method is ${getLabelByValue(paymentMethodFilterOptions, searchParams.has_payment_method)}` : 'Payment method'}
                    closePanelOnClick={false}/>
                <FilterButton
                    isChecked={hasAutoCollectionChecked}
                    onClearFilter={(): void => clearFilter('has_auto_collection') }
                    items={autoPayPopoverItems}
                    label={hasAutoCollectionChecked ? `Auto Pay is ${getLabelByValue(autoPayFilterOptions, searchParams.has_auto_collection)}` : 'Auto Pay'}
                    closePanelOnClick={false}/>
                <FilterButton
                    isChecked={isSorted}
                    onClearFilter={(): void => clearFilter('sort_by')}
                    items={sortByPopoverItems}
                    label={isSorted ? `Sorted by ${getLabelByValue(sortByOptions, searchParams.sort_by)}` : 'Sort by'}
                    closePanelOnClick={false}/>
            </div>
        </div>
    );
};
