import React, { useContext, useState } from 'react';
import { toast, Toggle, Typography, Input, Popover, Button, Tooltip, PopoverItem } from '@notch-ordering/ui-components';
import { useForm } from 'react-hook-form';
import { CustomerDetailsContext } from '@ar/pages/CustomerDetailsWrapperPage/CustomerDetailsContext';
import { capitalize, isEmpty, lowerCase } from 'lodash';
import { useMutation } from '@tanstack/react-query';
import { UpdateCustomerData,
    updateCustomer,
    GetCustomerResponse,
    setDefaultPaymentMethod,
    setPreferredPaymentMethodForSupplier,
    createLinkPaymentMethodForSupplier } from '@ar/network/AccountsReceivable.network';
import { FETCH_CUSTOMER_QUERY_KEY } from '@ar/hooks/queries/CustomerQueries.hook';
import CheckIcon from '@icons/check-icon.svg';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import PaymentMethodBadge from '@ar/components/shared/PaymentMethodBadge/PaymentMethodBadge';
import { FilterLabel } from '@ar/components/shared/FilterLabel/FilterLabel';
import { ChargeOnLabels, ChargeOnValues, WeekDays } from '@ar/components/CustomerDetails/AutoPay/AutoPay.constants';
import { normalizeCustomer } from '@ar/components/CustomerDetails/AutoPay/helpers/normalizeCustomer';
import { mergeClassNames } from '@v2/utils/Helpers';
import { queryClient } from '@/containers/app/Root';
import { useAutoPayMethod } from '@/ar/hooks/queries/AutoPayMethod.hook';

/**
 * The AR Payouts page
 *
 * @returns JSX Element
 */
export const AutoPay = (): JSX.Element => {
    const { customer, isCustomer, isSupplier, isCustomerLoading, customerID, supplierID, paymentMethods, handleCloseGeneralPopup, refetchCustomer, refetchPayments, } = useContext(CustomerDetailsContext);
    const [autoPayMethod, setAutoPayMethod] = useAutoPayMethod(supplierID, isCustomer, paymentMethods);
    const [showDisabledTooltip, setShowDisabledTooltip] = useState(false);

    const updateCustomerMutation = useMutation(
        updateCustomer,
    );

    const setDefaultPaymentMethodMutation = useMutation(
        setDefaultPaymentMethod
    );
    const createLinkPaymentMethodForSupplierMutation = useMutation(
        createLinkPaymentMethodForSupplier
    );
    const setPreferredPaymentMethodForSupplierMutation = useMutation(
        setPreferredPaymentMethodForSupplier
    );
    const isLoading = isCustomerLoading || updateCustomerMutation.isLoading;
    const defaultCustomerValues = normalizeCustomer(customer);
    const { handleSubmit, reset, setValue, watch } = useForm<GetCustomerResponse>({
        defaultValues: defaultCustomerValues,
    });

    const updateDataAfterMutation = async (message: string, refetchPaymentsAfter: boolean, refetchCustomerAfter: boolean = false) => {
        await queryClient.invalidateQueries([FETCH_CUSTOMER_QUERY_KEY, customerID, supplierID]);
        toast.show({
            message,
            icon: <CheckIcon />,
            showClose: false,
        });
        if (refetchPaymentsAfter) {
            refetchPayments();
        }
        if (refetchCustomerAfter) {
            refetchCustomer();
        }
    };

    const onSubmit = (data): void => {
        const { auto_collection, charge_invoice_when, charge_invoice_on_weekday, charge_invoice_on_monthday } = data;

        const updatedCustomer: UpdateCustomerData = {
            business_name: customer.name,
            email: customer.email,
            auto_collection,
            weekly_statement_email: customer.weekly_statement_email,
            terms: customer.terms,
            charge_invoice_when: ChargeOnValues[charge_invoice_when],
            charge_invoice_on_weekday,
            charge_invoice_on_monthday,
            invoice_notifications: customer.invoice_notifications,
            line1: customer.address.line1,
            line2: customer.address.line2,
            city: customer.address.city,
            postal_code: customer.address.postal_code,
            state: customer.address.state,
            country: customer.address.country,
        };

        if (auto_collection && autoPayMethod?.id) {
            if (isCustomer) {
                setDefaultPaymentMethodMutation.mutate({ paymentMethodID: autoPayMethod?.id, supplierID, body: { customer_id: customerID, is_primary: true } }, {
                    onSuccess: async () => {
                        await updateDataAfterMutation('Auto payment method successfully updated', true);
                    },
                    onError: () => {
                        toast.show({
                            message: 'Error while updating auto payment method',
                            showClose: false,
                        });
                    },
                });
            }
            if (isSupplier) {
                const linkPaymentMethodToSupplier = autoPayMethod.connectedSuppliers.find((currentSupplier) => currentSupplier.supplierId === supplierID);
                if (linkPaymentMethodToSupplier) {
                    setPreferredPaymentMethodForSupplierMutation.mutate({ paymentMethodID: autoPayMethod?.id, supplierID, body: { supplier_id: supplierID, is_preferred: true } }, {
                        onSuccess: async () => {
                            await updateDataAfterMutation('Auto payment method successfully updated', true);
                        },
                        onError: () => {
                            toast.show({
                                message: 'Error while updating auto payment method',
                                showClose: false,
                            });
                        },
                    });
                } else {
                    createLinkPaymentMethodForSupplierMutation.mutate({ paymentMethodID: autoPayMethod?.id, supplierID, body: { supplier_id: supplierID, is_preferred: true } }, {
                        onSuccess: async () => {
                            await updateDataAfterMutation('Auto payment method successfully updated', true);
                        },
                        onError: () => {
                            toast.show({
                                message: 'Error while updating auto payment method',
                                showClose: false,
                            });
                        },
                    });
                }
            }
        }

        updateCustomerMutation.mutate({ customerID, supplierID, ...updatedCustomer }, {
            onSuccess: async () => {
                await updateDataAfterMutation('Successfully updated', false, true);
            },
            onError: () => {
                toast.show({
                    message: 'Error while updating information',
                    showClose: false,
                });
            },
        });
    };

    const paymentMethodOptions = (isShowCheck: boolean = true): PopoverItem[] => paymentMethods?.payment_methods.map((paymentMethod) => ({
        label: <FilterLabel
            label={<PaymentMethodBadge key={paymentMethod.id} brand={paymentMethod.brand} last4={paymentMethod.last4} />}
            isChecked={isShowCheck && paymentMethod.id === autoPayMethod?.id}/>,
        onClick: (): void => {
            setAutoPayMethod(paymentMethod);
        },
    }));

    const chargeOnOptions = Object.keys(ChargeOnLabels).map((key) => ({
        label: <FilterLabel
            label={<span className="whitespace-nowrap">{ChargeOnLabels[key]}</span>}
            isChecked={key === watch('charge_invoice_when')} />,
        onClick: (): void => {
            setValue('charge_invoice_when', key);
            const onWeekdayValue = ChargeOnValues.weekly === ChargeOnValues[key] || ChargeOnValues.biweekly === ChargeOnValues[key]
                ? lowerCase(WeekDays.MONDAY) : null;

            const onMonthdayValue = ChargeOnValues.monthly === ChargeOnValues[key] ? 1 : null;

            setValue('charge_invoice_on_weekday', onWeekdayValue);
            setValue('charge_invoice_on_monthday', onMonthdayValue);
        },
    }));

    const weeklyOptions = Object.values(WeekDays).map((day) => ({
        label: <FilterLabel
            label={<span className="whitespace-nowrap">on {day}</span>}
            isChecked={lowerCase(day) === watch('charge_invoice_on_weekday')} />,
        onClick: (): void => setValue('charge_invoice_on_weekday', lowerCase(day)),
    }));

    const handleClickCancel = (): void => {
        reset();
        handleCloseGeneralPopup();
    };

    const handleChangeToggle = (isEnabled: boolean): void => {
        setValue('auto_collection', isEnabled);
        if (!isEnabled) {
            setValue('charge_invoice_when', null);
            setValue('charge_invoice_on_weekday', null);
            setValue('charge_invoice_on_monthday', null);
        } else {
            setValue('charge_invoice_when', defaultCustomerValues?.charge_invoice_when || 'dueDate');
        }
    };

    const chargeInvoiceWhen = watch('charge_invoice_when');

    React.useEffect(() => {
        if (!isEmpty(customer)) {
            reset(normalizeCustomer(customer));
        }
    }, [customer]);

    return (
        <form className="grid customer-details" onSubmit={handleSubmit(onSubmit)}>
            <div className="mx-10 my-8 mb-0">
                <div className=" mb-8">
                    <div className="flex justify-between">
                        <Typography variant="LG-2" weight="font-semibold">
                            AutoPay
                        </Typography>
                        <Tooltip
                            show={showDisabledTooltip && !autoPayMethod}
                            trigger={<div onMouseEnter={(): void => setShowDisabledTooltip(true)} onMouseLeave={(): void => setShowDisabledTooltip(false)}>
                                <Toggle className={mergeClassNames({
                                    'bg-gray-200 pointer-events-none': !autoPayMethod,
                                })}
                                onChange={handleChangeToggle}
                                isEnabled={watch('auto_collection')} />
                            </div>}
                            placement="top-end"
                            showArrow={false}
                            tooltipClassName="py-1.5 px-2 rounded-md"
                            className="text-left">
                            <Typography className="font-medium">Add a default payment method to enable AutoPay</Typography>
                        </Tooltip>

                    </div>

                </div>

                <div className="my-8">
                    {Boolean(watch('auto_collection')) && !!autoPayMethod && <div className="my-4">
                        <Typography weight="font-medium mb-3">
                            Charge on
                        </Typography>
                        <div className="flex">
                            <Popover
                                className="text-base"
                                items={chargeOnOptions}
                                button={<Button
                                    as="div"
                                    type="button"
                                    variant="TERTIARY_OUTLINED"
                                    size="SMALL"
                                    className="cursor-pointer mr-1.5 min-w-[140px]"
                                    widthPadding="px-2.5"
                                    stopPropagation={false}>
                                    <div className="flex gap-3 items-center justify-between">
                                        <Typography>{ChargeOnLabels[chargeInvoiceWhen]}</Typography>
                                        <ChevronDownIcon className="w-4 h-4 text-gray-600" />
                                    </div>
                                </Button>} />

                            {(ChargeOnValues.weekly === ChargeOnValues[watch('charge_invoice_when')] || ChargeOnValues.biweekly === ChargeOnValues[watch('charge_invoice_when')])
                                && <Popover
                                    className="text-base"
                                    items={weeklyOptions}
                                    button={<Button
                                        as="div"
                                        type="button"
                                        variant="TERTIARY_OUTLINED"
                                        size="SMALL"
                                        className="cursor-pointer min-w-[140px]"
                                        widthPadding="px-2.5"
                                        stopPropagation={false}>
                                        <div className="flex items-center justify-between">
                                            {!watch('charge_invoice_on_weekday') && <Typography className="text-gray-600">Weekday</Typography>}
                                            {Boolean(watch('charge_invoice_on_weekday')) && <Typography>on {capitalize(watch('charge_invoice_on_weekday'))}</Typography>}
                                            <ChevronDownIcon className="w-4 h-4 text-gray-600" />
                                        </div>
                                    </Button>} />}

                            {(ChargeOnValues.monthly === ChargeOnValues[watch('charge_invoice_when')])
                                && <Input
                                    width="w-[140px]"
                                    height="h-[34px]"
                                    inputProps={{
                                        placeholder: 'day',
                                        max: 31,
                                        min: 1,
                                        required: true,
                                    }}
                                    value={watch('charge_invoice_on_monthday')}
                                    onChange={(e): void => setValue('charge_invoice_on_monthday', e.target.valueAsNumber)}
                                    variant="SMALL"
                                    type="NUMBER" />}
                        </div>

                    </div>}
                    <div className="w-full h-px bg-gray-200" />
                    {paymentMethods?.payment_methods.length ? <div className="mt-4">
                        <Typography weight="font-medium mb-3">
                            Payment method
                        </Typography>
                        <Popover
                            className="text-base"
                            parentDivClassName="w-[140px]"
                            placement="bottom-start"
                            items={paymentMethodOptions()}
                            button={<Button
                                as="div"
                                type="button"
                                variant="TERTIARY_OUTLINED"
                                size="SMALL"
                                className="cursor-pointer px-2.5 w-[140px]"
                                stopPropagation={false}>
                                <div className="flex items-center gap-1">
                                    <PaymentMethodBadge
                                        key={autoPayMethod?.id ?? ''}
                                        brand={autoPayMethod?.brand ?? ''}
                                        className="gap-1"
                                        last4={autoPayMethod?.last4 ?? ''} />
                                    <ChevronDownIcon className="w-4 h-4 text-gray-600"/>
                                </div>
                            </Button>}/>
                    </div> : <Typography className="text-gray-600  mt-4">
                            Please add a payment method to configure AutoPay.
                    </Typography>}
                </div>
            </div>
            <div className="w-full h-px bg-gray-200" />
            <div className="my-6 mx-8 flex justify-between">
                <Button className="mr-3 cursor-pointer"
                    type="button"
                    as="div"
                    onClick={handleClickCancel}
                    disabled={isLoading}
                    variant="TERTIARY_FILLED"
                    size="MEDIUM"
                    minWidth="w-auto">
                    Cancel
                </Button>
                <Button
                    disabled={isLoading}
                    variant={'SECONDARY'}
                    size="MEDIUM"
                    minWidth="w-auto"
                    type="submit">
                    Save
                </Button>
            </div>
        </form>
    );
};
