import React, { useMemo } from 'react';
import { AutocompletePopover, PopoverAutoCompleteItem } from '@notch-ordering/ui-components';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { FilterLabel } from '@ar/components/shared/FilterLabel/FilterLabel';
import { useGetCustomerSupplierMappings } from '@ar/hooks/queries/CustomerQueries.hook';
import { useBillStore } from '@ar/stores/BillsStore';
import { InvoiceFilterLoadingButton } from '@ar/components/Invoices/InvoiceFilters/InvoiceFilterLoadingButton';
import { FilterPopoverButton } from '@/components/shared/FilterButton';

export const BillSupplierFilterButton : React.FC = () => {
    // Store variables
    const { supplierLoginData } = useSupplierStore();
    const { searchParams, updateSearchParams, clearSelectedInvoices } = useBillStore();

    const { data: supplierResponse, isLoading } = useGetCustomerSupplierMappings({
        customerID: supplierLoginData.customer_id
    });
    const suppliers = supplierResponse?.suppliers ?? [];

    // Other variables
    const hasSupplierSelected = searchParams?.supplier_ids?.length > 0;
    // Returns the name of the supplier with the specified ID, or an empty string if no supplier is found.
    const getSupplierNames = ():string => suppliers.filter((currentSupplier) => searchParams?.supplier_ids?.includes(currentSupplier.id)).map((currentSupplier) => currentSupplier.name).join(', ') ?? '';

    // List of supplier items that are filtered based on the search query and mapped to a list of PopoverItem objects with click handlers and keyboard navigation support.
    const supplierItems = useMemo<PopoverAutoCompleteItem[]>(() => suppliers
        .map((currentSupplier) => ({
            key: currentSupplier.id,
            searchableField: currentSupplier.name,
            label: <FilterLabel label={currentSupplier.name} isChecked={searchParams?.supplier_ids?.includes(currentSupplier.id)}/>,
            onClick: (): void => {
                const newSearchParams = { supplier_ids: searchParams?.supplier_ids?.includes(currentSupplier.id) ? searchParams.supplier_ids.filter((id) => id !== currentSupplier.id) : [...(searchParams.supplier_ids ?? []), currentSupplier.id] };
                updateSearchParams(newSearchParams);
                clearSelectedInvoices();
            },
        })), [searchParams?.supplier_ids, suppliers]);
    if (isLoading) {
        return <InvoiceFilterLoadingButton/>;
    }

    return (
        <AutocompletePopover items={supplierItems}
            placement={'bottom-start'}
            className="max-h-80 w-[200px] overflow-auto"
            button={<FilterPopoverButton
                isChecked={hasSupplierSelected}
                label={hasSupplierSelected ? ` ${searchParams?.supplier_ids?.length > 1 ? 'Suppliers are ' : 'Supplier is'} ${getSupplierNames()}` : 'Supplier'}
                handleClearFilter={() => {
                    updateSearchParams({ supplier_ids: undefined });
                    clearSelectedInvoices();
                }}/>}/>

    );
};
