import { format } from 'date-fns-tz';
import { isValid as isValidDate, parse } from 'date-fns';
import { DEFAULT_INPUT_DATE_FORMAT, ISO_FORMAT } from './constants';
import { isStringValidDate } from './FieldUtils';

export const formatDate = (date?: Date, dateFormat = DEFAULT_INPUT_DATE_FORMAT): string => {
    try {
        if (!date || !isValidDate(date)) {
            return '';
        }
        return format(date, dateFormat, { timeZone: 'UTC' });
    } catch {
        return '';
    }
};

export const formatIsoStringToUtcDate = (dateString: string, dateFormat = DEFAULT_INPUT_DATE_FORMAT): string => {
    const parsedIso = parse(dateString, ISO_FORMAT, new Date());
    const isValidIsoString = isValidDate(parsedIso);

    if (!isValidIsoString) return '';

    const dateObj = new Date(parsedIso);
    const utcDate = dateObj.getUTCDate();
    const utcMonth = dateObj.getUTCMonth();
    const utcYear = dateObj.getUTCFullYear();
    const utcDateObjInLocalTimezone = new Date(utcYear, utcMonth, utcDate);

    return format(utcDateObjInLocalTimezone, dateFormat, { timeZone: 'UTC' });
};

export const getUtcIsoCurrentDateString = (): string => {
    const localDate = new Date();
    const date = localDate.getDate();
    const month = localDate.getMonth();
    const year = localDate.getFullYear();
    const utcFormattedDate = format(new Date(year, month, date), ISO_FORMAT, { timeZone: 'UTC' });

    return new Date(utcFormattedDate).toISOString();
};

/**
 * Parses string and tries to return date string in ISO format. If provided date string can't be parsed as valid date then returns current UTC date in ISO format
 *
 * @param dateString the string which can be either already formatted ISO date string or other string which might represent date but not yet formatted in ISO
 * @returns {string} ISO date sting
 */
export function parseInitialDate(dateString: string): string {
    if (!dateString) return getUtcIsoCurrentDateString();

    const dateIso = parse(dateString, ISO_FORMAT, new Date());
    if (isValidDate(dateIso)) {
        // handle cases when date comes in ISO format like these:
        // - '2023-09-26T00:00:00.000+0000'
        // - '2023-09-26T00:00:00.000z'
        return dateString;
    }

    let dateStr = dateString;
    if (dateString.includes('GMT')) {
        // handle cases when date comes in format like these:
        // - 'Mon Sep 19 2022 00:00:00 GMT-0400 (Eastern Daylight Time)'
        // - 'Sat Jul 01 2023 21:00:00 GMT-0700 (Pacific Daylight Time)'

        const [dateStringWithoutTimeZone] = dateString.split('GMT');
        dateStr = dateStringWithoutTimeZone;
    }

    if (isStringValidDate(dateStr)) {
        // handle parsed but non-formatted cases like these:
        // - 'Mon Sep 19 2022 00:00:00'
        // - 'Sat Jul 01 2023 21:00:00'
        // - '2023-05-17'
        // - '09-19-2022'
        // - '1/6/2023'
        // - '23-JUN-2022'
        // - '2023'
        // - 'August 4, 2022'
        // - 'SEPTEMBER 2020'
        // - 'MARCH 30, 2022'
        // - 'Jul.18.2023'

        const newDate = format(new Date(dateStr), DEFAULT_INPUT_DATE_FORMAT, { timeZone: 'UTC' });
        const [year, month, dayDate] = newDate.split('-').map((str: string): number => +str);
        const dateUTC = Date.UTC(year, month - 1, dayDate);

        return new Date(dateUTC).toISOString();
    }

    return getUtcIsoCurrentDateString();
}

export const formatDisplayedDate = (date: string | Date): string => {
    if (!date) return '';

    if (typeof date === 'string') {
        return formatIsoStringToUtcDate(date);
    }

    if (typeof date === 'object') {
        return formatDate(date);
    }

    return '';
};

// convert a 2-digit year to 4-digit year
export const normalizeYear = (expYear: number): number => {
    if (!expYear) {
        return expYear;
    }
    const expYearStr = expYear.toString();
    if (expYearStr.length === 2) {
        return parseInt(`20${expYearStr}`, 10);
    }
    return expYear;
};
