import React from 'react';
import { useIntegrationsContext } from '@ar/pages/IntegrationsPage/IntegrationsContext';
import IntegrationsActiveEmptyState
    from '@ar/components/Integrations/IntegrationsActive/IntegrationActiveEmptyState/IntegrationActiveEmptyState';
import { Loading, NotchDataGrid } from '@notch-ordering/ui-components';
import { getColumns } from '@ar/components/Integrations/IntegrationsActive/IntegrationsActiveConstants';

/**
 * The AR Integrations Active Tab
 *
 * @returns JSX Element
 */
export const IntegrationsActive = (): JSX.Element => {
    const { integration, isLoading } = useIntegrationsContext();

    // Show the empty state if integration is empty.
    if (!integration && !isLoading) {
        return <IntegrationsActiveEmptyState />;
    }

    return (
        <div className="px-10 py-6">
            {isLoading && <Loading isDark className="mt-10" />}

            {!isLoading && <NotchDataGrid
                autoHeight
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                hideFooter
                loading={isLoading}
                getRowId={(row):string => row?.name}
                rows={[integration]}
                columns={getColumns(integration?.type)}/>}
        </div>
    );
};
