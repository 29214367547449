import Mixpanel from 'mixpanel-browser';

import {
  identifyUserForLogging,
  logException,
} from '../domains/shared/logger.js';
import * as Cypress from '../third-party/Cypress';
import * as GoogleTagManager from '@v2/ext/GoogleTagManager';
import * as Intercom from '../third-party/Intercom';
import * as Segment from '../third-party/Segment';
import { getCampaignParams } from '../third-party/Mixpanel';
import * as FacebookPixel from '../third-party/FacebookPixel';
import * as Optimize from '../third-party/Optimize';
import * as Hubspot from '../third-party/Hubspot';

/*
 * This method will initialize the third-party libraries used by the WebApp after logging in:
 *   - Intercom
 *   - Mixpanel
 *   - Exception logger
 *   - FullStory
 *   - GoogleTagManager
 *   - Segment
 */
export function initLibrariesAfterLogin(buyer, account, impersonator, vendors) {
  // Skip third-party init when running unit tests or Cypress tests
  if (process.env.NODE_ENV === 'test' || Cypress.isRunningTest()) {
    return;
  }
  try {
    // Check is the user for the session is an impersontor, set the user as
    // the impersonator account otherwise set it as the actual accountholder
    const isImpersonatorEmpty = Object.keys(impersonator).every(
      (key) => !impersonator[key]
    );
    const user = isImpersonatorEmpty ? account : impersonator;

    // Initialize the Intercom chat when the impersonator is not set
    if (isImpersonatorEmpty) {
      Intercom.initLoggedInUser(buyer, account, user);
    }

    // Initialize Third Party Libraries
    if (process.env.NODE_ENV !== 'development') {
      const { params, firstParams } = getCampaignParams();

      const userAnalyticsBaseData = {
        ...params,
        email: account.email,
        name: `${account.firstName} ${account.lastName}`,
        user: `${account.firstName} ${account.lastName}`,
        user_id: account.memberID,
        created_at: account.created_at,
        company: buyer.name,
        'Testing Buyer': buyer.testingBuyer,
      };

      if (!isImpersonatorEmpty) {
        userAnalyticsBaseData['Impersonator email'] = impersonator.email;
        userAnalyticsBaseData[
          'Impersonator name'
        ] = `${impersonator.firstName} ${impersonator.lastName}`;
      }

      Mixpanel.init(process.env.MIXPANEL_ID);
      Mixpanel.identify(account.memberID);
      Mixpanel.people.set(userAnalyticsBaseData);
      Mixpanel.people.set_once(firstParams);
      Mixpanel.register(userAnalyticsBaseData);

      identifyUserForLogging({ user, buyer });

      if (window.FS) {
        FS.identify(buyer.urlsafe, {
          displayName: buyer.name,
          email: user.email,
          user: `${user.firstName} ${user.lastName}`,
        });
      }

      const dataLayer = {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        user: `${user.firstName} ${user.lastName}`,
        user_id: account.memberID,
        created_at: account.created_at,
        company: buyer.name,
        isTestBuyer: buyer.testingBuyer,
      };
      GoogleTagManager.init(dataLayer);

      const referralVendor =
        vendors.find((v) => v.urlsafe === buyer.referralVendorID) || {};
      const userTraits = {
        ...params,
        buyerName: buyer.name,
        buyerID: buyer.id,
        email: account.email,
        buyerCreatedAt: buyer.createdAt,
        impersonatorEmail: impersonator.email || '',
        onboardingType: buyer.isSelfOnboarded
          ? 'selfOnboarded'
          : 'salesOnboarded',
        acquisitionChannel: referralVendor.name || 'none',
        numOfOrders: buyer.numberOrders,
      };
      Segment.init();
      Segment.identify(account.memberID, userTraits);
    } else {
      GoogleTagManager.init(); // Init a GTM container with empty dataLayer for dev testing
    }
  } catch (error) {
    console.error('An Error occured with initLibrariesAfterLogin');
    console.error(error);
    logException(error);
  }
}

/*
 * This method will initialize the third-party libraries used by the WebApp before or without logging in:
 *   - Google Tag manager (without user information)
 *   - Facebook tracking pixel
 *   - Hubspot
 *   - Optimize
 */
export function initLibrariesNotRequiringLogin() {
  // Skip third-party init when running unit tests or Cypress tests
  if (process.env.NODE_ENV === 'test' || Cypress.isRunningTest()) {
    return;
  }

  try {
    if (process.env.NODE_ENV === 'development') {
      return;
    }
    GoogleTagManager.init();
    FacebookPixel.init();
    Hubspot.init();
    Optimize.init();
  } catch (error) {
    console.error('An Error occured with initLibrariesNotRequiringLogin');
    console.error(error);
    logException(error);
  }
}
