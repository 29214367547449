import '../../../styles/dataPage.scss';
import React, { useContext, useState } from 'react';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import RightArrowIcon from '@icons/arrow-right-icon.svg';
import RefreshIcon from '@icons/refresh-icon.svg';
import { Button, Drawer, Popover, Typography } from '@notch-ordering/ui-components';
import { useNavigate } from 'react-router-dom';
import { ARRoutePaths } from '@v2/constants/EPaths';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { CustomerDetailsContext } from '../CustomerDetailsWrapperPage/CustomerDetailsContext';
import { CustomerNotificationsTab } from '../../components/CustomerDetails/CustomerGeneralPopup/CustomerNotificationTab/CustomerNotificationsTab';
import { ResetToDefaultNotificationsModal } from './ResetToDefaultNotificationsModal';

type CustomDrawerProps = {
    isOpen: boolean,
    close: () => void,
    refetch: () => void,
};

export const drawerInsideClassName = 'drawer-body';
/**
 * Drawer with customer specific AR notifications
 *
 * @returns JSX Element
 */
export const NotificationsCustomDrawer: React.FC<CustomDrawerProps> = ({ isOpen, close, refetch }) => {
    const { customer } = useContext(CustomerDetailsContext);
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Notifications' });
    const navigate = useNavigate();
    const [isResetModalOpen, setIsResetModalOpen] = useState<boolean>(false);
    const drawerInsideSelectors = [`.${drawerInsideClassName}`];

    return (
        <div className="min-h-screen w-full fixed right-0 top-0 z-50 pointer-events-none">
            <Drawer isVisible={isOpen}
                close={() => { close(); }}
                insideSelectors={drawerInsideSelectors}
                title={customer ? `${customer?.name}` : ''}
                headerContent={<div className="flex flex-row justify-end pr-5 pb-2">
                    <Popover
                        className="w-44"
                        popOverClassName="absolute z-50"
                        button={
                            <Button
                                variant="TERTIARY_TABLE"
                                className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                                size="SMALL"
                                stopPropagation={false}>
                                <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                                    <EllipsisIcon className="h-4 w-4" />
                                </div>
                            </Button>
                        }
                        items={
                            [
                                {
                                    label: <div className="flex items-center gap-3">
                                        <RightArrowIcon className="h-4 w-4"/>
                                        <Typography variant="BASE" className="m-0 block truncate" desktopSize="lg:text-2">{t('goToCustomer')}</Typography>
                                    </div>,
                                    onClick: (): void => { navigate(`/${ARRoutePaths.AR_CUSTOMERS}/${customer?.id}`); },
                                },
                                {
                                    label: <div className="flex items-center gap-3">
                                        <RefreshIcon className="h-4 w-4 text-red-500"/>
                                        <Typography variant="BASE" className="m-0 block truncate text-red-500" desktopSize="lg:text-2">{t('resetToDefault')}</Typography>
                                    </div>,
                                    onClick: (): void => { setIsResetModalOpen(true); },
                                },
                            ]
                        }/>
                </div>}
                className="bg-white pointer-events-auto">
                <CustomerNotificationsTab refetchDataGrid={refetch} resetButtonEnabled={false} />
            </Drawer>
            {isResetModalOpen && <ResetToDefaultNotificationsModal isOpen={isResetModalOpen}
                close={(): void => setIsResetModalOpen(false)}
                onSave={close}
                refetch={refetch}/>}
        </div>
    );
};
