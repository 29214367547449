import React from 'react';
import { Button, Popover, Typography } from '@notch-ordering/ui-components';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import { Transaction, TransactionStatus } from '@ar/network/AccountsReceivable.network';
import { trackCurrentPageTitle, TrackingEvents } from '@ar/constants/TrackingConstants';
import CardIcon from '@icons/card-icon.svg';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { DO_NOT_CHARGE_ENABLED_STATUSES,
    DontPopoverLabel, MANUAL_CHARGE_ENABLED_STATUSES } from '@ar/components/Invoices/InvoiceActionButtonColumn/InvoiceActionButtonColumn';
import { useBillStore } from '@ar/stores/BillsStore';

export interface InvoiceActionButtonColumnProps {
    invoice: Transaction,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const BillsActionButtonColumn: React.FC<InvoiceActionButtonColumnProps> = ({ invoice }) => {
    const { setModal } = useBillStore();
    const items = [
        {
            label: <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
                <div className="w-4"><CardIcon className="w-4 h-4 mt-0.5 "/></div>
                <div className="flex flex-col truncate">
                    <div>Pay</div>
                </div>
            </Typography>,
            className: 'z-50',
            hidden: !MANUAL_CHARGE_ENABLED_STATUSES.includes(invoice.status as TransactionStatus),
            onClick: (): void => {
                setModal('manualCharge', true, invoice);
                sendTrackingEvent(TrackingEvents.chargeInvoiceClicked);
            },
        },
        {
            label: <DontPopoverLabel label={'Dispute'}/>,
            className: 'z-50',
            hidden: !DO_NOT_CHARGE_ENABLED_STATUSES.includes(invoice.status as TransactionStatus),
            onClick: (): void => {
                setModal('doNotChargeInvoice', true, invoice);
                sendTrackingEvent(TrackingEvents.doNotChargeClicked);
            },
        },
    ];

    // check if all items are hidden
    const allItemsHidden = items.every((item) => item.hidden);
    if (allItemsHidden) return null;

    return <div className="flex gap-1"
        onClick={(e): void => {
            e.stopPropagation();
            trackCurrentPageTitle(TrackingEvents.moreButtonClicked);
        }}>
        <div>
            <Popover
                className="w-auto"
                button=
                    {<Button
                        variant="TERTIARY_TABLE"
                        className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                        size="SMALL"
                        stopPropagation={false}>
                        <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                            <EllipsisIcon className="h-4 w-4"/>
                        </div>
                    </Button>}
                items={items}/>

        </div>
    </div>;
};
