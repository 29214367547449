import React, { useContext } from 'react';
import { Button, GridColDef, GridRenderCellParams, Popover } from '@notch-ordering/ui-components';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import { CustomerDetailsContext } from '@ar/pages/CustomerDetailsWrapperPage/CustomerDetailsContext';

interface Props {
    param: GridRenderCellParams<GridColDef>,
    setCustomNotificationsDrawerOpen: () => void,
}

const CustomersRowMoreButton = ({ param, setCustomNotificationsDrawerOpen }: Props): JSX.Element => {
    const { setCustomerId, } = useContext(CustomerDetailsContext);

    /**
     * Handle clicking the button which opens the modal prompt
     */
    function handleClick(): void {
        setCustomerId(param.row.id);
        setCustomNotificationsDrawerOpen();
    }

    return (
        <div>
            <Popover
                className="w-44"
                button={
                    <Button
                        variant="TERTIARY_TABLE"
                        className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                        size="SMALL"
                        stopPropagation={false}>
                        <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                            <EllipsisIcon className="h-4 w-4" />
                        </div>
                    </Button>
                }
                items={
                    [
                        {
                            label: 'Edit details',
                            onClick: handleClick,
                        },
                    ]
                }/>
        </div>
    );
};

export default CustomersRowMoreButton;
