import React from 'react';
import NotificationsEmptyState from '@ar/assets/images/notifications-empty-state.svg';
import { Typography } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';

export const CustomNotificationsEmptyState = (): JSX.Element => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Notifications' });

    return (
        <div className="flex flex-col items-center justify-center flex-1 text-center">
            <NotificationsEmptyState className="w-[112px] h-[64px] mb-10"/>
            <Typography className="mb-2 font-medium">
                {t('noCustomers')}
            </Typography>
            <Typography className="text-gray-600">
                {t('noCustomersDescription')}
            </Typography>
        </div>
    );
};
