import React from 'react';
import { GridColDef, GridColumns, NotchDataGrid } from '@notch-ordering/ui-components';
import { PageTitle } from '@ar/components/PageTitle';
import PaymentMethodBadge from '@ar/components/shared/PaymentMethodBadge/PaymentMethodBadge';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { TrackingEvents } from '@ar/constants/TrackingConstants';
import { useGetCustomerSupplierMappings } from '@ar/hooks/queries/CustomerQueries.hook';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { GetCustomerSupplierMappingsOutput } from '@/ar/network/AccountsReceivable.network';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
// Required for [CORE-513]
// import SuppliersRowMoreButton from './SuppliersRowMoreButton/SuppliersRowMoreButton';
import { SuppliersFilters } from './SuppliersFilters/SuppliersFilters';
import { useCustomerSuppliersStore } from '@/ap/stores/CustomerSuppliersStore';

const headerClassName = 'text-gray-600 font-body text-med';
const cellClassName = 'text-gray-700 font-body text-med';

export const commonGridDef: GridColDef = {
    field: '',
    headerAlign: 'left',
    headerClassName,
    cellClassName,
    align: 'left',
    sortable: false,
};

const columns: GridColumns = [
    {
        ...commonGridDef,
        field: 'name',
        headerName: 'Name',
        flex: 1,
    },
    {
        ...commonGridDef,
        field: 'default_payment_method',
        headerName: 'Default Payment method',
        flex: 1,
        renderCell: ({ row }): React.ReactNode => {
            const preferredPaymentMethod = row.payment_methods.find((pm) => pm.is_preferred);
            if (!preferredPaymentMethod || !preferredPaymentMethod.brand) {
                return null;
            }

            return (
                <PaymentMethodBadge
                    brand={preferredPaymentMethod.brand}
                    last4={preferredPaymentMethod.last4}/>
            );
        },
    },
    // Required for [CORE-513]
    // {
    //     ...commonGridDef,
    //     field: 'action',
    //     headerName: '',
    //     cellClassName: 'overflow-visible', // This must be set for the popover to be visible
    //     width: 40,
    //     renderCell: () => {
    //         const handleClick = (e): void => {
    //             e.stopPropagation();
    //         };
    //         return (
    //             <div onClick={handleClick}>
    //                 <SuppliersRowMoreButton/>
    //             </div>
    //         );
    //     },
    // },
];

/**
 * The AP Suppliers page
 *
 * @returns JSX Element
 */
export const SuppliersPage = function (): JSX.Element {
    useDocumentTitle('Suppliers');

    const { supplierLoginData } = useSupplierStore();
    const { searchParams, updateSearchParams } = useCustomerSuppliersStore();

    const customerSupplierMappingsResponse = useGetCustomerSupplierMappings({
        customerID: supplierLoginData.customer_id,
        ...searchParams
    });

    const renderDataGrid = (): JSX.Element => {
        if (customerSupplierMappingsResponse.isError || customerSupplierMappingsResponse.isRefetchError) {
            return <div>{customerSupplierMappingsResponse.error?.toString()}</div>;
        }

        const isLoading: boolean = customerSupplierMappingsResponse.isLoading || customerSupplierMappingsResponse.isFetching;
        let tableRows: GetCustomerSupplierMappingsOutput['suppliers'] = [];
        let rowCount: number = 0;

        // in the case where we're loading we don't want to set it to bad orders or row data so we have 0 and empty defaults
        if (customerSupplierMappingsResponse?.data?.suppliers) {
            tableRows = customerSupplierMappingsResponse.data.suppliers;
            rowCount = customerSupplierMappingsResponse.data.suppliers.length;
        }

        return (
            <NotchDataGrid
                autoHeight
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                keepNonExistentRowsSelected
                paginationMode="server"
                sortingMode="server"
                rows={tableRows}
                columns={columns}
                rowCount={rowCount}
                loading={isLoading}
                pageSize={searchParams.limit}
                onPageChange={(page): void => {
                    updateSearchParams({ offset: searchParams.limit * page });
                    const previousPage = Math.floor(searchParams.offset / searchParams.limit);
                    const pageChangeEvent = page > previousPage ? TrackingEvents.goToNextPageClicked : TrackingEvents.goToPreviousPageClicked;
                    sendTrackingEvent(pageChangeEvent);
                }}
                sx={{
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer',
                    },
                }}/>
        );
    };

    return (
        <>
            <PageTitle>Suppliers</PageTitle>

            <SuppliersFilters />

            {renderDataGrid()}
        </>
    );
};
