import React, { useEffect, useMemo, useState } from 'react';
import { ActionBar, toast } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import CheckIcon from '@icons/check-icon.svg';
import { getGpoId } from '@v2/utils/GPOUtils';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { getBalances, sendCustomersWelcomeEmail } from '@/ar/network/AccountsReceivable.network';

export type ActionBarProps = {
    selectedCustomers: string[],
    clearSelected: () => void,
};

export const CustomersActionBar = ({ selectedCustomers, clearSelected }: ActionBarProps): JSX.Element => {
    const { supplierLoginData } = useSupplierStore();
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [customersWithoutAccount, setCustomersWithoutAccount] = useState(new Set());
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Notifications' });
    const gpoId = getGpoId(supplierLoginData);

    useEffect(() => {
        async function getAllCustomersBalances(): Promise<void> {
            const getAllBalances = await getBalances({
                supplier_id: supplierLoginData.supplier_id,
                limit: 1000000,
                gpo_id: gpoId,
            });
            const customersWithoutAccounts = getAllBalances?.customers?.filter((customer) => !customer.has_account && !customer.account_with_email_exists && customer.email);
            const customerIDsWithoutAccounts = new Set(customersWithoutAccounts?.map((customer) => customer.id));
            setCustomersWithoutAccount(customerIDsWithoutAccounts);
        }

        getAllCustomersBalances();
    }, [supplierLoginData]);

    const selectedCustomersWithoutAccounts = useMemo(() => {
        if (!selectedCustomers || !customersWithoutAccount) return [];
        return selectedCustomers.filter((customer) => customersWithoutAccount.has(customer));
    }, [selectedCustomers, customersWithoutAccount]);

    const sendCustomersAWelcomeEmail = ():void => {
        setSaveLoading(true);
        const body = {
            supplier_id: supplierLoginData?.supplier_id,
            customer_ids: selectedCustomers
        };
        sendCustomersWelcomeEmail({
            supplierID: supplierLoginData?.supplier_id,
            body
        }).then(() => {
            clearSelected();
            toast.show({
                icon: <CheckIcon/>,
                message: 'Payment portal invitation successfully sent!'
            });
            setSaveLoading(false);
        }).catch((error) => {
            setSaveLoading(false);
            toast.show({
                message: `${error}`,
            });
        });
    };

    return (<>
        {(selectedCustomers?.length > 0) && <div className="flex justify-center items-center">
            <ActionBar
                className="my-6"
                text={`${selectedCustomers.length} ${selectedCustomers.length === 1 ? t('customerSelected') : t('customersSelected')}`}
                isOpen={selectedCustomers.length > 0}
                close={():void => {
                    clearSelected();
                }}
                selection={selectedCustomers}
                onClose={clearSelected}
                buttons={[
                    {
                        variant: 'TERTIARY_DARK',
                        onClick: (): void => sendCustomersAWelcomeEmail(),
                        text: (`${t('sendInvitation')} (${selectedCustomersWithoutAccounts.length}) `),
                        loading: saveLoading
                    },
                ]}/>
        </div>}
    </>
    );
};
