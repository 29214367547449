import React from 'react';
import { Typography } from '@notch-ordering/ui-components';
import VaultImage from '@images/vault.svg';

export const PaymentMethodsEmpty = (): JSX.Element => {
    const noPaymentMethod = 'No Payment Method Set Up Yet';
    const toProceedWithBillPayments = 'To proceed with bill payments, you need to add a payment method to your account.';

    return (<div className="w-[500px] bg-gray-25 border border-dashed rounded-xl flex flex-col items-center px-24 py-10">
        <VaultImage className="w-16 mb-5" />
        <Typography as="div" weight="font-medium" className="pb-1">{noPaymentMethod}</Typography>
        <Typography as="div" variant="BASE" className="text-gray-600 text-center">{toProceedWithBillPayments}</Typography>
    </div>);
};
