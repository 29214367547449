import React from 'react';
import IntegrationEmptyStateIcon from '@ar/assets/images/integrations-active-empty-state.svg';
import { Button, Typography } from '@notch-ordering/ui-components';
import { useIntegrationsContext } from '@ar/pages/IntegrationsPage/IntegrationsContext';
import { IntegrationsTabs } from '@ar/pages/IntegrationsPage/IntegrationsPageConstants';

const IntegrationsActiveEmptyState = (): JSX.Element => {
    const { setIntegrationsTab } = useIntegrationsContext();

    return (
        <div className="flex flex-col items-center justify-center flex-1 text-center">
            <IntegrationEmptyStateIcon className="w-72 mb-10"/>
            <Typography variant="2XL" className="mb-2 font-semibold">
              Seamlessly integrate with your existing systems.
            </Typography>
            <Typography className="text-gray-600 lg:w-[356px]">
              Browse our available integrations and allow easily and fast data flow between your systems.
            </Typography>
            <Button className="mt-16" onClick={() => setIntegrationsTab(IntegrationsTabs.Explore)}>
              Browse integrations
            </Button>
        </div>
    );
};

export default IntegrationsActiveEmptyState;
