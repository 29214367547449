import axios, { AxiosResponse } from 'axios';
import { getBearer, throwAxiosError } from '@v2/utils/AxiosUtils';
import { isRutterPlatform } from '@v2/Pages/Integrations/IntegrationPlatformUtils';
import { EApPlatform, AccountingConfiguration } from '@notch-ordering/shared-logic';

export const { bushwhackUrl } = process.env.BASE_URLS || {};
const QBO_ROUTE = `${bushwhackUrl}/quickbooks`;
const ACCOUNTING_ROUTE = `${bushwhackUrl}/accounting/ap`;
const BUYER_CONFIGURATION_ROUTE = `${bushwhackUrl}/buyerConfigurations`;

export const FETCH_EXTERNAL_INVOICES = 'FETCH_EXTERNAL_INVOICES';

export type GetVendorsResponse = {
    vendors: Array<VendorDetails>,
};

export type SupplierMapping = {
    id?: string,
    apIntegrationID: string,
    supplierUrlsafeKey: string,
    apVendorID: string,
};

export type SupplierMappings = { [id: string]: string };

export type GetSupplierMappings = {
    mappings: SupplierMappings,
};

export type SyncStatus = {
    resourceIdentifier: string | null,
    event: string | null,
    error: string | null,
    lastSyncTime: string | null,
};

export type GetResourceSyncDetails = {
    statuses: SyncStatus[],
};

export type VendorDetails = {
    id: string,
    displayName: string,
    companyName: string,
    balance: number,
    active: boolean,
};

export type VendorCreateSchema = {
    PrimaryEmailAddr: {
        Address: string,
    },
    WebAddr?: {
        URI: string,
    },
    PrimaryPhone?: {
        FreeFormNumber: string,
    },
    DisplayName: string,
    Suffix?: string,
    Title?: string,
    Mobile?: {
        FreeFormNumber: string,
    },
    FamilyName?: string,
    TaxIdentifier?: string,
    AcctNum?: string,
    CompanyName: string,
    BillAddr: {
        City: string,
        Country: string,
        Line3?: string,
        Line2?: string,
        Line1: string,
        PostalCode: string,
        CountrySubDivisionCode?: string,
    },
    GivenName?: string,
    PrintOnCheckName: string,
};

export type VendorDetailsSchema = VendorCreateSchema & {
    Vendor1099: boolean,
    domain: string,
    SyncToken: number,
    AcctNum: string,
    sparse: boolean,
    Active: boolean,
    Balance: number,
    Id: string,
    MetaData: {
        CreateTime: string,
        LastUpdatedTime: string,
    },
};

export type CompanySchema = {
    companyName: string,
    country: string,
    id: string,
    phone: string,
    email: string,
    currencyCode?: string, // Used for Rutter only
};

/**
 * See bushwhackUrl-api buyerQBO.requests.ts for schema source of truth.
 */
export type GetOAuthConnectURLResponse = {
    qb_url: string,
};

/**
 * See bushwhackUrl-api buyerQBO.requests.ts for schema source of truth.
 */
export type GetClassesResponse = {
    classes: Array<ApClassSchema>,
};

/**
 * See bushwhackUrl-api gLCode.dto.ts for schema.
 */
export type GLCodeSchema = {
    id: string,
    name: string,
    code: string,
    platformID?: string,
};

export type GetGLCodesResponse = Array<GLCodeSchema>;

export type ApClassSchema = {
    id: string,
    name: string,
    fullyQualifiedName?: string,
    subClass?: boolean,
};

export type GetTaxCodesResponse = {
    taxCodes: Array<TaxCodeSchema>,
};

/**
 * See bushwhackUrl-api taxCode.dto.ts for schema.
 */
export type TaxCodeSchema = {
    id: string,
    name: string,
    code?: string,
    totalTaxRate?: number,
    effectiveTaxRate?: number,
    taxable?: boolean,
    description?: string,
};

export enum TaxCalculationSettings {
    ACCOUNTING = 'ACCOUNTING',
    INCLUSIVE_OF_TAX = 'INCLUSIVE_OF_TAX',
    CUSTOM = 'CUSTOM'
}

/**
 * See bushwhackUrl-api buyerQBO.requests.ts for schema.
 */
export type RevokeAccountingAccessResponse = {
    message: string,
};

/**
 * See bushwhackUrl-api qboBuyerConfiguration.dto.ts for schema.
 */
export type BuyerConfigurationBaseSchema = {
    id?: string,
    buyerId?: string,
    configurationData?: {
        secret?: string,
        securityToken?: string,
        redirectURI?: string,
        realmID?: string,
        notchVendorID?: string,
        classData?: ApClassSchema,
        taxCalculationSettings?: TaxCalculationSettings,
        taxCode?: TaxCodeSchema,
        taxCodeNonTaxable?: TaxCodeSchema,
        authorizationCode?: string,
        accessToken?: string,
        refreshToken?: string,
    },
    isEnabled?: boolean,
    integrationConfiguration?: {
        id: string,
        ownerEmail: string,
        type: string,
        configurationData: Record<string, unknown>,
        isEnabled: boolean,
    },
};

/**
 * See bushwhackUrl-api buyerConfigurations.requests.ts for schema.
 */
export type SaveProductCategoryGLMappingsSchema = {
    glMappings: Record<string, string>,
};
export type GetProductCategoryGLMappingsSchema = {
    glMappings: Record<string, string>,
};

export enum EConfigurationType {
    ApRutter = 'apRutter',
    QBO = 'QBO',
    BigChip = 'big-chip'
}

export type ClassSchema = {
    id: string,
    name: string,
};

export type LocationSchema = {
    id: string,
    name: string,
};

export type DepartmentSchema = {
    id: string,
    name: string,
};

export type RutterConfig = {
    platform?: EApPlatform,
    accessToken?: string,
    connectionID?:string,
    enableLocations?: boolean,
    defaultLocation?: LocationSchema,
    enableClasses?: boolean,
    defaultClass?: ClassSchema,
    enableDepartment?: boolean,
    defaultDepartment?: DepartmentSchema,
    classData?: ApClassSchema,
    taxCalculationSettings?: TaxCalculationSettings,
    taxCode?: TaxCodeSchema,
    taxCodeNonTaxable?: TaxCodeSchema,
    createdAt?: string,
    updatedAt?: string,
};

export type UpdateConfigurationSchema = Partial<Omit<AccountingConfiguration, 'createdAt' | 'updatedAt'>>;

export type ConfigurationSearchResults = {
    results: AccountingConfiguration[],
    total: number,
    hasNextPage: boolean,
    cursor: string | null,
};

export type RutterConnectionStatus = {
    connectionID: string | null,
    isReady: boolean,
    isDisabled: boolean,
    disabledReason: string | null,
    platform: string | null,
};

export async function getAccountingCompany(platform: EApPlatform, buyerUrlsafeKey: string, accessToken: string): Promise<CompanySchema> {
    const url = `${ACCOUNTING_ROUTE}/${platform}/company/buyer/${buyerUrlsafeKey}`;
    const response: AxiosResponse<CompanySchema> = await axios.get(url, { params: { accessToken } })
        .catch((e) => throwAxiosError(e, 'Could not get company', false));

    return response.data;
}

export async function saveProductCategoryGLMappings(platform: EApPlatform, buyerUrlsafeKey: string, glMappings: Record<string, string>): Promise<SaveProductCategoryGLMappingsSchema> {
    const url = `${ACCOUNTING_ROUTE}/${platform}/product/mapping/buyer/${buyerUrlsafeKey}`;
    const body: SaveProductCategoryGLMappingsSchema = {
        glMappings
    };
    const response: AxiosResponse<SaveProductCategoryGLMappingsSchema> = await axios.post(url, body)
        .catch((e) => throwAxiosError(e, 'Could not save product category gl mappings'));

    return response.data;
}

export async function getProductCategoryGLMappings(platform: EApPlatform, buyerUrlsafeKey: string): Promise<GetProductCategoryGLMappingsSchema> {
    const url = `${ACCOUNTING_ROUTE}/${platform}/product/mapping/buyer/${buyerUrlsafeKey}`;
    const response: AxiosResponse<GetProductCategoryGLMappingsSchema> = await axios.get(url)
        .catch((e) => throwAxiosError(e, 'Could not get product category gl mappings'));

    return response.data;
}

export async function connectToQBO(buyerUrlsafeKey: string, memberEmail: string, redirectURI?: string): Promise<GetOAuthConnectURLResponse> {
    const url: string = `${QBO_ROUTE}/oauth/?buyerID=${buyerUrlsafeKey}&memberEmail=${encodeURIComponent(memberEmail)}${redirectURI ? `&redirectURI=${encodeURIComponent(redirectURI)}` : ''}`;

    const response: AxiosResponse<GetOAuthConnectURLResponse> = await axios.get(url)
        .catch((e) => throwAxiosError(e));

    return response.data;
}

export async function revokeAccountingAccess(platform: EApPlatform, buyerUrlsafeKey: string, accessToken: string): Promise<RevokeAccountingAccessResponse> {
    const url: string = `${ACCOUNTING_ROUTE}/${platform}/auth/revoke/buyer/${buyerUrlsafeKey}`;

    const response: AxiosResponse<RevokeAccountingAccessResponse> = await axios.post(url, undefined, { params: { accessToken } })
        .catch((e) => throwAxiosError(e));

    return response.data;
}

export async function getPaymentGLCodes(platform: EApPlatform, buyerUrlsafeKey: string, accessToken: string): Promise<GetGLCodesResponse> {
    const url: string = `${ACCOUNTING_ROUTE}/${platform}/glPaymentAccounts/buyer/${buyerUrlsafeKey}`;

    const response: AxiosResponse<GetGLCodesResponse> = await axios.get(url, { params: { accessToken } })
        .catch((e) => throwAxiosError(e));

    return response.data;
}

export async function updateQBOBuyerConfiguration(buyerConfigurationID: string, configurationData: RutterConfig): Promise<BuyerConfigurationBaseSchema> {
    const url = `${BUYER_CONFIGURATION_ROUTE}/${buyerConfigurationID}`;
    const body: BuyerConfigurationBaseSchema = {
        configurationData
    };
    const response: AxiosResponse<BuyerConfigurationBaseSchema> = await axios.patch(url, body)
        .catch((e) => throwAxiosError(e, 'Could not update buyer configuration'));

    return response.data;
}

export async function getAccountingVendors(platform: EApPlatform, buyerUrlsafeKey: string, accessToken: string): Promise<VendorDetails[]> {
    const url: string = `${ACCOUNTING_ROUTE}/${platform}/vendors/buyer/${buyerUrlsafeKey}`;

    const response: AxiosResponse<GetVendorsResponse> = await axios.get(url, { params: { accessToken } })
        .catch((e) => throwAxiosError(e));

    return response.data?.vendors || [];
}

export async function getSupplierMappings(platform: EApPlatform, buyerUrlsafeKey: string, accessToken: string): Promise<SupplierMappings> {
    const url: string = `${ACCOUNTING_ROUTE}/${platform}/vendors/mapping/buyer/${buyerUrlsafeKey}`;

    const response: AxiosResponse<GetSupplierMappings> = await axios.get(url, { params: { accessToken } })
        .catch((e) => throwAxiosError(e));

    return response.data?.mappings || {};
}

export async function updateSupplierMappings(platform: EApPlatform, buyerUrlsafeKey: string, mappings: SupplierMapping[], accessToken: string): Promise<SupplierMappings> {
    const url: string = `${ACCOUNTING_ROUTE}/${platform}/vendors/mapping/buyer/${buyerUrlsafeKey}`;

    const response: AxiosResponse<GetSupplierMappings> = await axios.post(url, { mappings }, { params: { accessToken } })
        .catch((e) => throwAxiosError(e));

    return response.data?.mappings || {};
}

export async function getResourceSyncDetails(buyerUrlsafeKey: string, syncIds: string[]): Promise<SyncStatus[]> {
    const joinedIds = syncIds.join();
    const url = `${bushwhackUrl}/resources/status?buyerUrlsafeKey=${buyerUrlsafeKey}&ids=${joinedIds}`;

    const response: AxiosResponse<GetResourceSyncDetails> = await axios.get(url)
        .catch((e) => throwAxiosError(e));

    return response.data?.statuses;
}

export async function getAccountingClasses(platform: EApPlatform, buyerUrlsafeKey: string, accessToken: string): Promise<GetClassesResponse> {
    const url: string = `${ACCOUNTING_ROUTE}/${platform}/classes/buyer/${buyerUrlsafeKey}`;

    const response: AxiosResponse<GetClassesResponse> = await axios.get(url, { params: { accessToken } })
        .catch((e) => throwAxiosError(e));

    return response.data;
}

export async function getAccountingTaxCodes(platform: EApPlatform, buyerUrlsafeKey: string, accessToken: string): Promise<GetTaxCodesResponse> {
    const url: string = `${ACCOUNTING_ROUTE}/${platform}/taxcodes/buyer/${buyerUrlsafeKey}`;

    const response: AxiosResponse<GetTaxCodesResponse> = await axios.get(url, { params: { accessToken } })
        .catch((e) => throwAxiosError(e));

    return response.data;
}

export async function getAccountingConfiguration(buyerUrlsafeKey: string): Promise<AccountingConfiguration | null> {
    const url: string = `${ACCOUNTING_ROUTE}/configurations/buyer/${buyerUrlsafeKey}`;
    const bearer: string = await getBearer();
    const response: AxiosResponse<AccountingConfiguration> = await axios.get(url, { headers: { Authorization: bearer } });

    return response.data;
}

export async function getInitialSyncComplete(buyerUrlsafeKey: string, platform: EApPlatform, accessToken: string): Promise<boolean> {
    if (!isRutterPlatform(platform)) {
        return true;
    }

    const url: string = `${bushwhackUrl}/rutter/ap/connectionStatus/${buyerUrlsafeKey}`;

    try {
        const response: AxiosResponse<RutterConnectionStatus> = await axios.get(url, { params: { accessToken } });

        return response.data?.isReady ?? true;
    } catch (e) {
        // We don't want to block a user if this endpoint doesn't work
        return true;
    }
}

export async function getRutterAccessToken(buyerUrlsafeKey: string, publicToken: string, platform: EApPlatform): Promise<AccountingConfiguration> {
    const url: string = `${bushwhackUrl}/rutter/ap/accessToken/${buyerUrlsafeKey}`;
    const body: { publicToken: string, platform: EApPlatform } = { publicToken, platform };
    const response: AxiosResponse<AccountingConfiguration> = await axios.post(url, body)
        .catch((e) => throwAxiosError(e));

    return response.data;
}

export async function updateAccountingConfiguration(configID: string, configurationData: UpdateConfigurationSchema, platform: EApPlatform, accessToken: string, isUpdateCurrency: boolean, onUpdate: (config: AccountingConfiguration) => void): Promise<AccountingConfiguration> {
    if (isUpdateCurrency && configurationData.type === EConfigurationType.ApRutter) {
        // The currency code in the config should match the currency in the company info for the accounting integration
        // Rutter takes time to intitally sync so we can't grab it on the backend on the connect call
        const company: CompanySchema = await getAccountingCompany(platform, configurationData.ownerID, accessToken);
        configurationData.data.currencyCode = company.currencyCode;
    }

    const url = `${bushwhackUrl}/configurations/${configID}`;
    const response: AxiosResponse<AccountingConfiguration> = await axios.patch(url, configurationData)
        .catch((e) => throwAxiosError(e, 'Could not update accounting configuration'));

    const updatedConfig: AccountingConfiguration = response.data;

    if (onUpdate) {
        onUpdate(updatedConfig);
    }

    return updatedConfig;
}

export async function connectBuyerToBigChip(buyerUrlsafeKey: string): Promise<void> {
    const url = `${bushwhackUrl}/big-chip/connect`;
    await axios.post(url, { buyerUrlsafeKey })
        .catch((e) => throwAxiosError(e, 'Failed to connect buyer to Big Chip'));
}
