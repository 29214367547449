import '../../../../styles/dataPage.scss';
import React, { useEffect, useState } from 'react';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useGetSupplier, useGetSupplierNotifications } from '@ar/hooks/queries/SupplierQueries.hook';
import { Banner, Button,
    Combobox,
    EBannerType,
    Loading,
    Separator,
    toast,
    Toggle,
    Tooltip,
    Typography } from '@notch-ordering/ui-components';
import AddIcon from '@icons/add-icon.svg';
import AlertIcon from '@icons/alert-icon.svg';
import BellIcon from '@icons/bell-icon.svg';
import InfoIcon from '@icons/info-outline-icon.svg';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { tCommon, tNamespace } from '@v2/i18n';
import { NotificationsData,
    RuleType,
    updateSupplierNotificationRules,
    updateSupplier,
    UpdateSupplierData,
    NotificationsRules } from '../../../network/AccountsReceivable.network';
import { DeletePaymentReminderTooltip } from '../DeletePaymentReminderTooltip';
import { ENotificationType, ERuleType,
    LanguageType,
    NotificationsSettings,
    PaymentReminders,
    invoiceLanguageOptions,
    paymentReminderAfterOptions,
    paymentReminderBeforeOptions } from './NotificationsDefaultConstants';
import { useNotificationsStore } from '../NotificationsStore';

const onErrorSavingNotifications = (error):void => {
    console.info(error, 'error');
    toast.show({
        message: 'Could not save settings.',
        icon: <AlertIcon className="text-red-300"/>,
    });
};

const MAXIMUM_NUMBER_OF_COMBOBOXES = 14;

let onDueDateEnabled: boolean = false;
let afterDueDateEnabled: boolean = false;
let beforeDueDateEnabled: boolean = false;

type NotificationsErrors = {
    afterDueDate?: TypeErrors[],
    beforeDueDate?: TypeErrors[],
};

type InfoToolTip = {
    language: boolean,
    invoices: boolean,
    payments: boolean,
    beforeDueDate: boolean,
    afterDueDate: boolean,
};

type TypeErrors = {
    type?: boolean,
};

export type ToolTipType = 'language' | 'invoices' | 'payments' | 'beforeDueDate' | 'afterDueDate';

/**
 * Default Global Customer Notifications Page
 *
 * @returns JSX Element
 */
export const NotificationsDefault = function (): JSX.Element {
    const { supplierLoginData } = useSupplierStore();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Notifications' });
    const { data: supplierData, isLoading: isSupplierLoading, refetch: refetchSupplier } = useGetSupplier({
        supplierID: supplierLoginData?.supplier_id,
    });
    const { data: notificationsData, isLoading: isNotificationsLoading, refetch: refetchNotifications } = useGetSupplierNotifications({
        supplierID: supplierLoginData.supplier_id
    });
    const { hasClickedNotificationBannerDefault, setHasClickedNotificationBannerDefault } = useNotificationsStore();
    const [notificationSettings, setNotificationSettings] = useState<NotificationsSettings>({ invoice_notifications: false, notifications_language: null });
    const [selectedPaymentReminders, setSelectedPaymentReminders] = useState<PaymentReminders>({ onDueDate: [], afterDueDate: [], beforeDueDate: [] });
    const [invalidPaymentReminder, setInvalidPaymentReminder] = useState(null);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [infoToolTip, setInfoToolTip] = useState<InfoToolTip>({ language: false, invoices: false, payments: false, beforeDueDate: false, afterDueDate: false });

    /**
     * Handles updating supplier and notifications
     */
    const handleNotificationsUpdate = (notificationsBody:NotificationsData, supplierBody:UpdateSupplierData):void => {
        setInvalidPaymentReminder(null);
        setSaveLoading(true);

        const updateNotificationsPromise = updateSupplierNotificationRules({
            supplierID: supplierLoginData?.supplier_id,
            body: notificationsBody,
        });
        const updateSupplierPromise = updateSupplier({
            supplierID: supplierLoginData?.supplier_id,
            body: supplierBody,
        });

        Promise.all([
            updateNotificationsPromise,
            updateSupplierPromise
        ]).then(() => {
            toast.show({
                message: t('changesSaved'),
            });
            refetchSupplier();
            refetchNotifications();
            setSaveLoading(false);
        }).catch((error) => {
            const nullType = !!notificationsBody.rules.find((item) => item.type === null);
            setInvalidPaymentReminder(nullType);
            onErrorSavingNotifications(error);
            setSaveLoading(false);
        });
    };

    /**
     * Add a new payment reminder combobox
     */
    function addNewPaymentReminder(dueDateType: ENotificationType): void {
        const newPaymentReminder = [...selectedPaymentReminders[dueDateType]];
        newPaymentReminder.push({
            type: null,
            interval: 0,
        });

        setSelectedPaymentReminders({ ...selectedPaymentReminders, [dueDateType]: newPaymentReminder });
    }

    /**
     * delete a payment reminder combobox
     */
    function deletePaymentReminder(index, dueDateType: ENotificationType): void {
        const paymentReminderDeletion = [...selectedPaymentReminders[dueDateType]];
        paymentReminderDeletion.splice(index, 1);

        setSelectedPaymentReminders({ ...selectedPaymentReminders, [dueDateType]: paymentReminderDeletion });

        if (invalidPaymentReminder) {
            const errorDeletion = [...invalidPaymentReminder[dueDateType]];
            errorDeletion.splice(index, 1);
            setInvalidPaymentReminder({ ...invalidPaymentReminder, [dueDateType]: errorDeletion });
        }
    }

    /**
     * Loops to find any unset comboboxes and sets errors for each
     */
    function handleNotificationsErrors(errors: NotificationsErrors, ruleType: ENotificationType): TypeErrors[] {
        for (let i = 0; i < selectedPaymentReminders[ruleType].length; i++) {
            const notification = selectedPaymentReminders[ruleType][i];
            const rulesErrors:TypeErrors = {};
            if (notification.type === null) {
                rulesErrors.type = true;
            }
            errors[ruleType][i] = rulesErrors;
        }

        return errors[ruleType];
    }

    /**
     * Send network call to update supplier when save settings is clicked
     */
    function saveSettings() {
        let isError = false;

        const errors: NotificationsErrors = {
            afterDueDate: Array<TypeErrors>(selectedPaymentReminders.afterDueDate.length),
            beforeDueDate: Array<TypeErrors>(selectedPaymentReminders.beforeDueDate.length)
        };

        errors.afterDueDate = handleNotificationsErrors(errors, ENotificationType.AFTER_DUE_DATE);
        errors.beforeDueDate = handleNotificationsErrors(errors, ENotificationType.BEFORE_DUE_DATE);

        isError = [...errors.afterDueDate, ...errors.beforeDueDate].some((item) => item.type);

        if (isError) {
            setInvalidPaymentReminder(errors);
            toast.show({
                message: 'Could not save settings.',
                icon: <AlertIcon className="text-red-300"/>,
            });
            setSaveLoading(false);
            return;
        }

        const flattenPaymentReminder = [selectedPaymentReminders.afterDueDate, selectedPaymentReminders.beforeDueDate, selectedPaymentReminders.onDueDate].flat();
        const removeDuplicatesPaymentReminder = flattenPaymentReminder.filter((value, index, self) => index === self.findIndex((f) => ((f.type === value.type) && (f.interval === value.interval))));
        const finalPaymentReminder = { rules: removeDuplicatesPaymentReminder };
        handleNotificationsUpdate(finalPaymentReminder, notificationSettings);
    }

    /**
     * filters the initial notifications into afterDueDate, beforeDueDate and onDueDate
     */
    function filterInitialNotifications(notifications: NotificationsData, ruleType: ERuleType): NotificationsRules[] {
        const initialDate = notifications?.rules?.filter((item) => (item.type === ruleType));

        return initialDate;
    }

    useEffect(() => {
        const initialPaymentReminderBefore = selectedPaymentReminders.beforeDueDate ? [...selectedPaymentReminders.beforeDueDate] : [];
        const initialPaymentReminderAfter = selectedPaymentReminders.afterDueDate ? [...selectedPaymentReminders.afterDueDate] : [];
        // If notifications are on and there are no before due date payment reminders set it to one day before due date
        if (beforeDueDateEnabled && selectedPaymentReminders?.beforeDueDate?.length === 0) {
            initialPaymentReminderBefore.push({ type: ERuleType.BEFORE_DUE_DATE, interval: 1 });
        }
        // If notifications are on and there are no after due date payment reminders set it to one day after due date
        if (afterDueDateEnabled && selectedPaymentReminders?.afterDueDate?.length === 0) {
            initialPaymentReminderAfter.push({ type: ERuleType.AFTER_DUE_DATE, interval: 1 });
        }
        setSelectedPaymentReminders({ ...selectedPaymentReminders, afterDueDate: initialPaymentReminderAfter, beforeDueDate: initialPaymentReminderBefore });
    }, [notificationSettings, onDueDateEnabled, beforeDueDateEnabled, afterDueDateEnabled]);

    useEffect(() => {
        if (supplierData) {
            // Set supplier notification setting
            const data:NotificationsSettings = {
                invoice_notifications: supplierData?.invoice_notifications,
                notifications_language: supplierData?.notifications_language
            };
            setNotificationSettings(data);
        }
    }, [supplierData]);

    useEffect(() => {
        if (notificationsData) {
            // Set initial payment reminder
            const initialOnDueDate = filterInitialNotifications(notificationsData, ERuleType.ON_DUE_DATE);
            const initialAfterDueDate = filterInitialNotifications(notificationsData, ERuleType.AFTER_DUE_DATE);
            const initialBeforeDueDate = filterInitialNotifications(notificationsData, ERuleType.BEFORE_DUE_DATE);

            setSelectedPaymentReminders({ onDueDate: initialOnDueDate, afterDueDate: initialAfterDueDate, beforeDueDate: initialBeforeDueDate });

            onDueDateEnabled = initialOnDueDate?.length > 0;
            afterDueDateEnabled = initialAfterDueDate?.length > 0;
            beforeDueDateEnabled = initialBeforeDueDate?.length > 0;
        }
    }, [notificationsData]);

    const renderInfoToolTip = (toolTipType: ToolTipType, toolTipDescription: string): JSX.Element => (
        <Tooltip
            show={infoToolTip[toolTipType]}
            className="flex"
            tooltipClassName={'w-60'}
            showArrow={false}
            onShow ={(): void => { setInfoToolTip({ ...infoToolTip, [toolTipType]: true }); }}
            onHide ={(): void => { setInfoToolTip({ ...infoToolTip, [toolTipType]: false }); }}
            placement="top"
            trigger={<InfoIcon className="w-4 h-4"/>}>
            {t(toolTipDescription)}
        </Tooltip>
    );

    return (
        <div className="px-10">
            {!isSupplierLoading && !isNotificationsLoading && <>
                {!hasClickedNotificationBannerDefault && <div className="w-full my-6">
                    <Banner alertType={EBannerType.SUBDUED}
                        body={<div>
                            <BellIcon className="w-5 h-5 text-gray-700"/>
                            <Typography as="div" className=" text-gray-700 mt-4 font-medium">
                                {t('defaultNotifications')}
                            </Typography>
                            <Typography as="div" className="text-gray-600 mt-2">
                                {t('defaultNotificationsDescription')}
                            </Typography></div>}
                        onClick={() => { setHasClickedNotificationBannerDefault(true); }}
                        isDismissable={true}/>
                </div>}
                <div className="pt-10">
                    <div className="flex flex-row gap-x-2 mb-6 items-center">
                        <Typography variant="LG-2" className="font-semibold p-0 m-0">
                            {t('invoiceLanguageSettings')}
                        </Typography>
                        {renderInfoToolTip('language', 'notCustomizable')}
                    </div>
                    <div className="space-y-6">
                        <div className="flex items-center justify-between gap-x-10">
                            <Typography className="text-gray-600 p-0 m-0">
                                {t('invoiceLanguageDescription')}
                            </Typography>
                            {notificationSettings.notifications_language && <Combobox
                                onChange={(value): void => {
                                    setNotificationSettings({
                                        ...notificationSettings,
                                        notifications_language: value.value as LanguageType,
                                    });
                                }}
                                options={invoiceLanguageOptions}
                                className="w-[250px] flex-shrink-0"
                                value={notificationSettings?.notifications_language}
                                variant="SMALL" />}

                        </div>
                    </div>
                </div>
                <Separator className="my-6" />
                {/* Invoices section */}
                <div className="mt-10 font-body">
                    <div className="flex flex-row gap-x-2 mb-6 items-center">
                        <Typography variant="LG-2" className="font-semibold p-0 m-0">
                            {t('invoices')}
                        </Typography>
                        {renderInfoToolTip('invoices', 'customizable')}
                    </div>
                    <div className="space-y-6">
                        <div className="flex items-center justify-between gap-2">
                            <div><Typography className="font-medium mb-1">
                                {t('sendOnIssueDate')}
                            </Typography>
                            <Typography className="text-gray-600 p-0 m-0">
                                {t('sendOnIssueDateDescription')}
                            </Typography></div>
                            <div><Toggle isEnabled={notificationSettings.invoice_notifications}
                                size="SMALL"
                                onChange={(): void => {
                                    setNotificationSettings({
                                        ...notificationSettings,
                                        invoice_notifications: !notificationSettings.invoice_notifications,
                                    });
                                }}/></div>

                        </div>
                    </div>
                    <Separator className="my-6" />
                    {/* Payments section */}
                    <div className="flex flex-row gap-x-2 mb-6 items-center mt-10">
                        <Typography variant="LG-2" className="font-semibold m-0 p-0">
                            {t('payments')}
                        </Typography>
                        {renderInfoToolTip('payments', 'customizable')}
                    </div>
                    {/* On due date section */}
                    <div className="space-y-6">
                        <div className="flex items-center justify-between gap-2">
                            <div>
                                <Typography className="font-medium mb-1">
                                    {t('onDueDate')}
                                </Typography>
                                <Typography className="text-gray-600 p-0 m-0">
                                    {t('onDueDateDescription')}
                                </Typography>
                            </div>
                            <div>
                                <Toggle isEnabled={onDueDateEnabled}
                                    size="SMALL"
                                    onChange={(): void => {
                                        onDueDateEnabled = !onDueDateEnabled;
                                        if (!onDueDateEnabled) {
                                            setSelectedPaymentReminders({ ...selectedPaymentReminders, onDueDate: [] });
                                        } else {
                                            setSelectedPaymentReminders({ ...selectedPaymentReminders, onDueDate: [{ type: ERuleType.ON_DUE_DATE, interval: 0 }] });
                                        }
                                    } } /></div>
                        </div>
                    </div>
                    <Separator className="my-6" />
                    {/* Before due date section */}
                    <div>
                        <div className="flex items-center justify-between gap-2">
                            <div>
                                <div className="flex flex-row gap-x-2 mb-1 items-center">
                                    <Typography className="font-medium m-0 p-0">
                                        {t('beforeDueDate')}
                                    </Typography>
                                    {renderInfoToolTip('beforeDueDate', 'customizable')}
                                </div>
                                <Typography className="text-gray-600 mb-4">
                                    {t('beforeDueDateDescription')}
                                </Typography></div>
                            <div>
                                <Toggle isEnabled={beforeDueDateEnabled}
                                    size="SMALL"
                                    onChange={(): void => {
                                        beforeDueDateEnabled = !beforeDueDateEnabled;
                                        if (!beforeDueDateEnabled) {
                                            setSelectedPaymentReminders({ ...selectedPaymentReminders, beforeDueDate: [] });
                                        } else {
                                            setSelectedPaymentReminders({ ...selectedPaymentReminders, beforeDueDate: [{ type: ERuleType.BEFORE_DUE_DATE, interval: 1 }] });
                                        }
                                    }}/></div>
                        </div>
                        {selectedPaymentReminders.beforeDueDate
                            && selectedPaymentReminders?.beforeDueDate?.map((currentPaymentReminder, i) => (<div key={v4()} className="flex flex-row mb-2">
                                <Combobox
                                    onChange={(value): void => {
                                        if (invalidPaymentReminder) {
                                            const invalidReminder = [...invalidPaymentReminder.beforeDueDate];
                                            invalidReminder[i] = {
                                                ...invalidReminder[i],
                                                type: false
                                            };
                                            setInvalidPaymentReminder({
                                                ...invalidPaymentReminder,
                                                beforeDueDate: invalidReminder
                                            });
                                        }
                                        const paymentReminder = [...selectedPaymentReminders.beforeDueDate];
                                        paymentReminder[i] = {
                                            ...paymentReminder[i],
                                            type: ERuleType.BEFORE_DUE_DATE as RuleType,
                                            interval: Number(value.value)
                                        };
                                        setSelectedPaymentReminders({
                                            ...selectedPaymentReminders,
                                            beforeDueDate: paymentReminder,
                                        });
                                    }}
                                    options={paymentReminderBeforeOptions}
                                    isInvalid={invalidPaymentReminder?.beforeDueDate?.[i]?.type}
                                    invalidMessage={'Choose a valid reminder'}
                                    placeholder="Select reminder"
                                    className="w-[300px]"
                                    value={currentPaymentReminder.interval.toString()}
                                    variant="SMALL" />
                                {(selectedPaymentReminders.beforeDueDate && selectedPaymentReminders?.beforeDueDate?.length !== 1)
                                    && <DeletePaymentReminderTooltip onClick={() => {
                                        deletePaymentReminder(i, ENotificationType.BEFORE_DUE_DATE);
                                    } } />}
                            </div>))}
                        {beforeDueDateEnabled && <Button className="cursor-pointer"
                            variant={'LINK'}
                            size={'NO_BACKGROUND'}
                            as="div"
                            onClick={() => { addNewPaymentReminder(ENotificationType.BEFORE_DUE_DATE); } }
                            disabled={isNotificationsLoading || isSupplierLoading || saveLoading || selectedPaymentReminders?.beforeDueDate?.length === MAXIMUM_NUMBER_OF_COMBOBOXES}>
                            <div className="-ml-2 flex gap-x-3 mt-0 items-center">
                                <AddIcon className="w-4 h-4"/>
                                <Typography className="mb-0" weight="font-medium">{t('add')}</Typography>
                            </div></Button>}
                    </div>
                    <Separator className="my-6" />
                    {/* After due date section */}
                    <div className="mb-24">
                        <div className="flex items-center justify-between gap-2">
                            <div>
                                <div className="flex flex-row gap-x-2 mb-1 items-center">
                                    <Typography className="font-medium m-0 p-0">
                                        {t('afterDueDate')}
                                    </Typography>
                                    {renderInfoToolTip('afterDueDate', 'customizable')}
                                </div>
                                <Typography className="text-gray-600 mb-4">
                                    {t('afterDueDateDescription')}
                                </Typography></div>
                            <div>
                                <Toggle isEnabled={afterDueDateEnabled}
                                    size="SMALL"
                                    onChange={(): void => {
                                        afterDueDateEnabled = !afterDueDateEnabled;
                                        if (!afterDueDateEnabled) {
                                            setSelectedPaymentReminders({ ...selectedPaymentReminders, afterDueDate: [] });
                                        } else {
                                            setSelectedPaymentReminders({ ...selectedPaymentReminders, afterDueDate: [{ type: ERuleType.AFTER_DUE_DATE, interval: 1 }] });
                                        }
                                    }}/></div>
                        </div>
                        {selectedPaymentReminders?.afterDueDate?.map((currentPaymentReminder, i) => (<div key={v4()} className="flex flex-row mb-2">
                            <Combobox
                                onChange={(value): void => {
                                    if (invalidPaymentReminder) {
                                        const invalidReminder = [...invalidPaymentReminder.afterDueDate];
                                        invalidReminder[i] = {
                                            ...invalidReminder[i],
                                            type: false
                                        };
                                        setInvalidPaymentReminder({
                                            ...invalidPaymentReminder,
                                            afterDueDate: invalidReminder
                                        });
                                    }
                                    const paymentReminder = [...selectedPaymentReminders.afterDueDate];
                                    paymentReminder[i] = {
                                        ...paymentReminder[i],
                                        type: ERuleType.AFTER_DUE_DATE as RuleType,
                                        interval: Number(value.value)
                                    };
                                    setSelectedPaymentReminders({
                                        ...selectedPaymentReminders,
                                        afterDueDate: paymentReminder,
                                    });
                                }}
                                options={paymentReminderAfterOptions}
                                isInvalid={invalidPaymentReminder?.afterDueDate?.[i]?.type}
                                invalidMessage={'Choose a valid reminder'}
                                className="w-[300px]"
                                placeholder="Select reminder"
                                optionsClassName={(i + 1 === selectedPaymentReminders?.afterDueDate.length || i + 2 === selectedPaymentReminders?.afterDueDate.length) ? 'bottom-full' : ''}
                                value={currentPaymentReminder.interval.toString()}
                                variant="SMALL" />
                            {(selectedPaymentReminders.afterDueDate && selectedPaymentReminders?.afterDueDate?.length !== 1)
                                    && <DeletePaymentReminderTooltip onClick={() => { deletePaymentReminder(i, ENotificationType.AFTER_DUE_DATE); } } />}
                        </div>))}
                        {afterDueDateEnabled && <Button className="cursor-pointer"
                            variant={'LINK'}
                            size={'NO_BACKGROUND'}
                            as="div"
                            onClick={() => { addNewPaymentReminder(ENotificationType.AFTER_DUE_DATE); } }
                            disabled={isNotificationsLoading || isSupplierLoading || saveLoading || selectedPaymentReminders?.afterDueDate?.length === MAXIMUM_NUMBER_OF_COMBOBOXES}>
                            <div className="-ml-2 flex gap-x-3 items-center mt-0">
                                <AddIcon className="w-4 h-4"/>
                                <Typography className="mb-0" weight="font-medium">{t('add')}</Typography>
                            </div></Button>}
                    </div>
                    <div className="flex justify-center items-center">
                        <div className="fixed bottom-10 z-10 px-5 py-3 bg-gray-700 w-fit h-fit max-w-full mx-1 rounded-xl text-white shadow-z4 flex justify-between items-center gap-x-2 pointer-events-auto">
                            <Typography as="div" weight="font-medium" className="w-fit h-fit text-center flex items-center lg:mr-28 mr-4">
                                {'Save default notifications'}
                            </Typography>
                            <Button
                                loading={saveLoading}
                                variant="TERTIARY_DARK"
                                size="SMALL"
                                onClick={saveSettings}>
                                {tCommon('Buttons.save')}
                            </Button>
                        </div>
                    </div>
                </div>
            </>}
            {(isNotificationsLoading && isSupplierLoading) && (<Loading className="mt-10" isDark />)}
        </div>
    );
};
