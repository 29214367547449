import React, { useEffect, useState } from 'react';
import { Badge, Button, Tabs, Typography, toast } from '@notch-ordering/ui-components';
import { PageTitle } from '@ar/components/PageTitle';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { useGetSupplier } from '@ar/hooks/queries/SupplierQueries.hook';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { NotificationsDefault } from './NotificationsDefault/NotificationsDefault';
import { NotificationsCustom } from './NotificationsCustom';
import { CustomerDetailsProvider } from '../CustomerDetailsWrapperPage/CustomerDetailsContext';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { updateSupplier } from '@/ar/network/AccountsReceivable.network';

export enum NotificationsTabs {
    Default = 'Default',
    Exceptions = 'Exceptions',
}

/**
 * The AR Customer Notifications Page
 *
 * @returns JSX Element
 */
export const CustomerNotificationsPage = (): JSX.Element => {
    useDocumentTitle('Customer Notifications - Notch');

    const { t } = useTranslation(tNamespace, { keyPrefix: 'Notifications' });
    const ActiveTabKey = 'activeTab';
    const [searchParams, setSearchParams] = useSearchParams();
    const { supplierLoginData } = useSupplierStore();
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [supplierLoaded, setSupplierLoaded] = useState<boolean>(false);
    const [pauseSupplierNotifications, setPauseSupplierNotifications] = useState<boolean>(false);
    const [notificationsTab, setNotificationsTab] = useState<NotificationsTabs>(searchParams.get(ActiveTabKey) as NotificationsTabs || NotificationsTabs.Default);

    const { data: supplierData, isLoading: isSupplierLoading, refetch: refetchSupplier } = useGetSupplier({
        supplierID: supplierLoginData?.supplier_id,
    });

    const handleTabChange = (value: string): void => {
        setNotificationsTab(NotificationsTabs[value]);
        setSearchParams({ [ActiveTabKey]: value });
    };

    useEffect(() => {
        if (supplierData) {
            // Set supplier pause notification setting
            setPauseSupplierNotifications(supplierData.pause_notifications);
            setSupplierLoaded(true);
        }
    }, [supplierData]);

    /**
     * Send network call to update supplier when pause notifications button is clicked
     */
    function pauseNotifications() {
        setSaveLoading(true);

        updateSupplier({
            supplierID: supplierLoginData?.supplier_id,
            body: {
                pause_notifications: !pauseSupplierNotifications
            }
        }).then(() => {
            if (!pauseSupplierNotifications) {
                toast.show({
                    message: 'Notifications paused',
                });
            } else {
                toast.show({
                    message: 'Notifications active',
                });
            }
            setPauseSupplierNotifications(!pauseSupplierNotifications);
            refetchSupplier();
            setSaveLoading(false);
        }).catch((error) => {
            console.error(error);
            toast.show({
                message: `${error}`,
            });
            setSaveLoading(false);
        });
    }

    return (
        <>
            <div className="-mx-10 -my-16 w-[calc(100%+80px)]">
                {supplierLoaded && <div className="flex flex-row items-center justify-between pr-10">
                    <div className="flex flex-col">
                        <div className="flex flex-row gap-x-2 items-end">
                            <PageTitle className="mt-16 mb-2 ml-10">{t('notifications')}</PageTitle>
                            {!pauseSupplierNotifications ? <Badge
                                className="h-6 mb-4"
                                variant="GREEN"
                                size="SMALL">
                                {'Active'}
                            </Badge>
                                : <Badge
                                    className="h-6 mb-4"
                                    variant="ORANGE"
                                    size="SMALL">
                                    {'Paused'}
                                </Badge>}
                        </div>
                        <Typography className="text-gray-600 mx-10 p-0 mb-8">{t('notificationsDescription')}</Typography>
                    </div>
                    <Button
                        loading={saveLoading || isSupplierLoading}
                        disabled={saveLoading || isSupplierLoading}
                        className="mt-10"
                        variant="SECONDARY"
                        size="SMALL"
                        onClick={pauseNotifications}>
                        {pauseSupplierNotifications ? 'Turn On' : 'Pause & Edit'}
                    </Button>
                </div>}
                <div className="px-10 mb-[-2px]">
                    <Tabs
                        onChange={handleTabChange}
                        tabs={Object.values(NotificationsTabs)}
                        value={notificationsTab} />
                </div>
                <div className="w-full h-px bg-gray-200 -mt-[1px]" />
                {notificationsTab === NotificationsTabs.Default && <NotificationsDefault/>}
                <CustomerDetailsProvider>
                    {notificationsTab === NotificationsTabs.Exceptions && <NotificationsCustom/>}
                </CustomerDetailsProvider>
            </div>
        </>
    );
};
