import { NotificationsRules } from '@/ar/network/AccountsReceivable.network';

export enum ERuleType {
    BEFORE_DUE_DATE = 'before_due_date',
    AFTER_DUE_DATE = 'after_due_date',
    ON_DUE_DATE = 'on_due_date',
}

export enum ENotificationType {
    BEFORE_DUE_DATE = 'beforeDueDate',
    AFTER_DUE_DATE = 'afterDueDate',
    ON_DUE_DATE = 'onDueDate',
}

export enum LanguageType {
    ENGLISH = 'en',
    ENGLISH_FRENCH = 'en_fr',
    FRENCH_ENGLISH = 'fr_en',
}

export type NotificationsSettings = {
    invoice_notifications: boolean,
    notifications_language?: LanguageType,
    disable_reminders?: boolean,
    customer_payment_link_reminder_rules?: boolean,
};

export type PaymentReminders = {
    onDueDate: NotificationsRules[],
    afterDueDate: NotificationsRules[],
    beforeDueDate: NotificationsRules[],
};

export type PaymentReminderOption = {
    label: string,
    value: string,
};

export const invoiceLanguageOptions:PaymentReminderOption[] = [{ label: 'English', value: LanguageType.ENGLISH },
    { label: 'Bilingual (English -> French)', value: LanguageType.ENGLISH_FRENCH },
    { label: 'Bilingual (French -> English)', value: LanguageType.FRENCH_ENGLISH },

];

export const paymentReminderOnOption:PaymentReminderOption[] = [{ label: 'On Due Date', value: '0' }];

const paymentReminderDaysOptionGenerator = (start: number, end: number, suffix: string):PaymentReminderOption[] => {
    const options:PaymentReminderOption[] = [];
    for (let i = start; i <= end; i++) {
        options.push({ label: `${i} ${i === 1 ? 'day' : 'days'} ${suffix}`, value: `${i}` });
    }
    return options;
};

export const paymentReminderAfterOptions:PaymentReminderOption[] = paymentReminderDaysOptionGenerator(1, 14, 'after due date');

export const paymentReminderBeforeOptions:PaymentReminderOption[] = paymentReminderDaysOptionGenerator(1, 14, 'before due date');
