import React, { useEffect, useState } from 'react';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { Badge, Button, Checkbox, Loading, Modal, Separator, toast, Typography } from '@notch-ordering/ui-components';
import { useInvoiceStore } from '@ar/stores/InvoicesStore';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { CurrencyInput } from '@ar/components/shared/InputCurrency';
import InfoIcon from '@icons/info-icon.svg';

import { GetPaymentMethodResponse, getPaymentMethods,
    payInvoices,
    Transaction } from '@ar/network/AccountsReceivable.network';
import { FETCH_TRANSACTIONS_QUERY_KEY } from '@ar/hooks/queries/InvoicesQueries.hook';
import { PaymentMethodsPopover } from '@ar/components/shared/PaymentMethodsPopover';

import { mergeClassNames } from '@v2/utils/Helpers';
import { areAllInvoicesFromSameCustomer,
    FETCH_BULK_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY,

    groupInvoicesByCustomerID,
    PaymentMethodByCustomer } from '@ar/components/Invoices/Modals/BulkManualChargeModal/BulkManualChargeModalConstants';
import { getGpoId } from '@v2/utils/GPOUtils';
import { LoadingPaymentMethodBanner } from '@ar/components/Invoices/Modals/BulkManualChargeModal/LoadingPaymentMethodBanner';
import { centsToDollars, formatAsCurrency } from '@/utils/Formatters';
import { AmplitudeCharge, AmplitudeEventActions, useAmplitude } from '@/containers/app/AmplitudeContext';
import { useRutterSetupStatus } from '@/ar/hooks/queries/IntegrationsQuery.hook';
import { RutterSetupNotCompleteBanner } from '@/ar/components/shared/RutterSetupNotCompleteBanner';

export const BulkManualChargeModal : React.FC = () => {
    const { modals, setModal, getReadyToBeChargedInvoices, clearSelectedInvoices } = useInvoiceStore();
    const { supplierLoginData } = useSupplierStore();
    const gpoId = getGpoId(supplierLoginData);
    const queryClient = useQueryClient();
    const { isRutterSetupNotComplete } = useRutterSetupStatus(supplierLoginData.supplier_id);
    const invoices = getReadyToBeChargedInvoices();// check if all invoices to be charged have the same customer id
    const isSameCustomerID = areAllInvoicesFromSameCustomer(invoices);
    const invoicesByCustomerID = groupInvoicesByCustomerID(invoices);
    const customerInvoices = Object.values(invoicesByCustomerID) ?? [];
    const customerIDs = Object.keys(invoicesByCustomerID);

    // get the payment methods for each customer

    const paymentMethodQueries = useQueries({
        queries: customerIDs.map((customerID) => ({
            queryKey: [FETCH_BULK_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY, customerID],
            enabled: modals.bulkManualCharge && !!customerID && (!!supplierLoginData?.supplier_id || !!gpoId),
            queryFn: async ():Promise<PaymentMethodByCustomer> => {
                const paymentMethods = await getPaymentMethods({
                    supplierID: supplierLoginData?.supplier_id,
                    gpoId,
                    customerID,
                });
                return {
                    [customerID]: paymentMethods,
                };
            },

        })),

    });
    // check if any of the queries are loading
    const arePaymentMethodsLoading = paymentMethodQueries.some((query) => query.isLoading);
    // set the payment methods by customer
    const paymentMethodsByCustomer:PaymentMethodByCustomer = paymentMethodQueries.reduce((acc, query) => ({
        ...acc,
        ...(query.data || {}),
    }), {});

    // get the invoices that are ready to be charged based on conditions defined in the store
    const getInvoicesWithPaymentMethod = ():Transaction[] => invoices?.filter((inv) => paymentMethodsByCustomer[inv.customer_id]?.payment_methods.length > 0) ?? [];

    // get the total amount to be charged
    const totalOutstandingAmount = invoices.reduce((acc, invoice) => acc + invoice.outstanding_amount, 0);

    // state variables
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<GetPaymentMethodResponse>(null);
    const [amount, setAmount] = useState(totalOutstandingAmount ?? 0);
    const [isLump, setIsLump] = useState(true);
    const { trackAmplitudeEvent } = useAmplitude();

    const hasIncompletePaymentMethod = selectedPaymentMethod && !selectedPaymentMethod?.last4;

    // close the modal method cleaning up the state and invalidating the query
    const handleOnClose = ():void => {
        setSelectedPaymentMethod(null);
        setAmount(0);
        setIsLump(true);
        setModal('bulkManualCharge', false, null);
        queryClient.invalidateQueries([FETCH_TRANSACTIONS_QUERY_KEY]);
    };

    // mutation to charge the invoices
    const payInvoicesMutation = useMutation(payInvoices, {
        onSuccess: (_data, variables) => {
            const chargeSuccessful = _data.filter((data) => data.charged === true);
            const chargeSuccessfulCount = chargeSuccessful.length;
            const numberOfInvoicesCharged = variables.body.transaction_ids.length;
            let toastMessage;
            if (chargeSuccessfulCount === numberOfInvoicesCharged) {
            // All Invoices charged successfully
                const messageText = `${formatAsCurrency(amount)} charged for ${numberOfInvoicesCharged > 1 ? `${numberOfInvoicesCharged} invoices` : `${numberOfInvoicesCharged} invoice`}`;
                toastMessage = isSameCustomerID ? messageText : `${numberOfInvoicesCharged > 1 ? `${numberOfInvoicesCharged} invoices` : `${numberOfInvoicesCharged} invoice`} charged`;
            } else if (chargeSuccessfulCount === 0) {
            // No Invoices charged successfully
                toastMessage = `${numberOfInvoicesCharged > 1 ? 'All charges have failed' : 'Charge has failed'}`;
            } else if ((chargeSuccessfulCount > 0) && (chargeSuccessfulCount !== numberOfInvoicesCharged)) {
            // Only some Invocies charged successfully
                const textNumberFailed = `${numberOfInvoicesCharged - chargeSuccessfulCount > 1 ? `${numberOfInvoicesCharged - chargeSuccessfulCount} charges have failed` : `${numberOfInvoicesCharged - chargeSuccessfulCount} charge has failed`}`;
                const textNumberSuccesful = `${chargeSuccessfulCount > 1 ? `${chargeSuccessfulCount} charges have succeeded` : `${chargeSuccessfulCount} charges has succeeded`}`;
                toastMessage = `${textNumberFailed}, ${textNumberSuccesful}`;
            }
            toast.show({
                message: toastMessage
            });
            trackAmplitudeEvent(AmplitudeEventActions.charged, { type: AmplitudeCharge.bulkCharge, countInvoices: invoices.length });
            handleOnClose();
            // after the invoices have been charged, clear the selected invoices
            clearSelectedInvoices();
        },
        onError: (error) => {
            toast.show({
                message: 'Something went wrong while charging the invoices',
            });
            console.error(error);
        },
    });

    const handleCharge = ():void => {
        // get the ids of the invoices to be charged
        const invoicesIDs = getInvoicesWithPaymentMethod().map((invoice) => invoice.id);
        // if there is more than one invoice, use the user selection to determine if it should be lumped else don't lump
        const isLumped = invoices.length > 1 ? isLump : false;
        trackAmplitudeEvent(AmplitudeEventActions.pushCharge, { type: AmplitudeCharge.bulkCharge, countInvoices: invoices.length });
        payInvoicesMutation.mutate({
            supplierID: supplierLoginData?.supplier_id,
            gpoId,
            body: {
                is_lumped: isLumped,
                // if the customer id is the same for all invoices the payment popover selection won't be shown and undefined will be passed
                payment_method_id: selectedPaymentMethod?.id ?? undefined,
                transaction_ids: invoicesIDs,
            },
        });
    };
    useEffect(() => {
        if (modals.bulkManualCharge) {
            trackAmplitudeEvent(AmplitudeEventActions.pageChargeViewed, { type: AmplitudeCharge.bulkCharge, countInvoices: invoices.length });
        }
    }, [modals.bulkManualCharge]);
    // whenever the invoices selected change, update the amount to be charged
    useEffect(() => {
        setAmount(centsToDollars(totalOutstandingAmount));
    }, [invoices]);

    if (!modals.bulkManualCharge) return null;

    return <Modal isOpen={modals.bulkManualCharge}
        title={
            <>{isSameCustomerID ? <><Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="LG-2">Confirm amount to be charged for {invoices.length > 1 ? `${invoices.length} invoices` : ` ${invoices.length} invoice`} </Typography>
                <Typography variant="LG-1" weight="font-regular" className="text-gray-600">{invoices[0]?.business_name}</Typography></>
                : <><Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="LG-2">Confirm charge for {getInvoicesWithPaymentMethod().length > 1 ? `${getInvoicesWithPaymentMethod().length} invoices` : 'invoice'} </Typography>
                    <Typography variant="LG-1" weight="font-regular" className="text-gray-600">The default payment method will be used for
                        each customer.</Typography></>

            }
            </>
        }
        headerPadding="mt-0 mb-5"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL"
        desktopModalWidth="lg:w-[372px]">
        <div className="p-5">
            {isRutterSetupNotComplete && <div className="pb-5">
                <RutterSetupNotCompleteBanner/>
            </div>}
            <div className="flex-col flex gap-4">
                {isSameCustomerID && <div className="flex flex-col w-full gap-2">
                    <Typography as="div" weight="font-medium" variant="LG-1" className="text-gray-700">
                        Payment method
                    </Typography>
                    <PaymentMethodsPopover
                        selectedPaymentMethod={selectedPaymentMethod}
                        setSelectedPaymentMethod={setSelectedPaymentMethod}
                        transaction={invoices[0]} />

                </div>}

                {isSameCustomerID && <CurrencyInput
                    required
                    label={'Amount'}
                    variant="SMALL"
                    value={amount}
                    disabled={true}
                    helperText={`Total amount Remaining: ${formatAsCurrency(centsToDollars(totalOutstandingAmount))}`}
                    onChange={(e):void => {
                        setAmount(e.floatValue);
                    }}
                    isInvalid={amount > totalOutstandingAmount || !amount}
                    invalidMessage={'Charge amount cannot be greater than remaining balance on invoice.'}/>}
                {!isSameCustomerID && !arePaymentMethodsLoading && <ul className="flex flex-col gap-5">
                    {customerInvoices.map((gt, index) => {
                        const ct = gt?.reduce((acc, invoice) => acc + invoice.outstanding_amount, 0) ?? 0;
                        const customerHasPaymentMethods = paymentMethodsByCustomer[gt[0]?.customer_id]?.payment_methods?.length;
                        const invoiceNumbers = gt?.map((invoice) => invoice.invoice_number) ?? [];
                        return <li key={`${index}_${gt[0]?.id ?? ''}`}>
                            <div className="flex flex-col gap-5">
                                <div className="flex justify-between gap-2">
                                    <div className="space-y-1">
                                        <Typography as="div" weight="font-medium" variant="LG-1" className="text-gray-700">
                                            {gt[0]?.business_name}
                                        </Typography>
                                        <Typography as="div" weight="font-regular" variant="LG-1" className="text-gray-600">
                                            {invoiceNumbers?.join(', ')}
                                        </Typography>
                                    </div>
                                    <Typography as="div"
                                        weight="font-regular"
                                        variant="LG-1"
                                        className={mergeClassNames('text-gray-700', {
                                            'text-gray-500': !customerHasPaymentMethods,
                                        })}>
                                        {formatAsCurrency(centsToDollars(ct))}
                                    </Typography>
                                </div>
                                {!customerHasPaymentMethods && !arePaymentMethodsLoading
                                    && <Badge variant="ORANGE" className="py-2 px-3">
                                        <div className="flex items-center gap-3">
                                            <InfoIcon className="h-4 w-4 shrink-0"/>
                                            <Typography className="text-gray-700">This customer has no default payment method on
                                            file and will not be charged</Typography>
                                        </div>
                                    </Badge>}
                                <Separator/>
                            </div>
                        </li>;
                    })}
                </ul>}
                {!isSameCustomerID && arePaymentMethodsLoading && <Loading isDark/>}

                {invoices.length > 1 && <div>
                    <Checkbox
                        className="items-start [&>*:first-child]:shrink-0"
                        label={isSameCustomerID ? 'Charge as one lump payment' : 'Charge multiple invoices as one payment for each customer'}
                        value={'isLump'}
                        checked={isLump}
                        onChange={():void => {
                            setIsLump(!isLump);
                        }}/>
                </div>}
            </div>
        </div>
        {hasIncompletePaymentMethod && <LoadingPaymentMethodBanner/>}
        <Separator/>
        <div className="pt-5 px-5 flex justify-end gap-3">
            <Button variant="TERTIARY_FILLED"
                size="SMALL"
                onClick={handleOnClose}>
                <Typography as="span" weight="font-medium">
                    Cancel
                </Typography>
            </Button>
            <Button variant="SECONDARY"
                disabled={payInvoicesMutation.isLoading || (!selectedPaymentMethod && isSameCustomerID) || (!isSameCustomerID && getInvoicesWithPaymentMethod().length === 0) || arePaymentMethodsLoading || hasIncompletePaymentMethod}
                loading={payInvoicesMutation.isLoading}
                size="SMALL"
                onClick={handleCharge}>
                <Typography as="span" weight="font-medium">
                    {payInvoicesMutation.isLoading ? <Loading/> : 'Charge '}
                </Typography>
            </Button>
        </div>
    </Modal>;
};
