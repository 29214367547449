import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Banner, Button, EBannerType, GridColDef, GridColumns, Loading, NotchDataGrid, toast } from '@notch-ordering/ui-components';
import { PageTitle } from '@ar/components/PageTitle';
import PaymentMethodBadge from '@ar/components/shared/PaymentMethodBadge/PaymentMethodBadge';
import { FETCH_BALANCES_QUERY_KEY, useGetBalances } from '@ar/hooks/queries/BalanceQueries.hook';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { ARRoutePaths } from '@v2/constants/EPaths';
import { trackCurrentPageTitle, TrackingEvents } from '@ar/constants/TrackingConstants';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { getGpoId } from '@v2/utils/GPOUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCustomersStore } from '@/ar/stores/CustomersStore';
import { EBatchIngestionType, GetBalancesResponse, InvoicePDFGenerationType, getBalances } from '@/ar/network/AccountsReceivable.network';
import { formatAsCurrency } from '@/utils/Formatters';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import CustomersEmptyState from './CustomersEmptyState/CustomersEmptyState';
import CustomersRowMoreButton from './CustomersRowMoreButton/CustomersRowMoreButton';
import { CustomersFilters } from './CustomersFilters/CustomersFilters';
import { CustomersActionBar } from './CustomersActionBar/CustomersActionBar';
import { useGetSupplier } from '@/ar/hooks/queries/SupplierQueries.hook';

import { queryClient } from '@/containers/app/Root';
import { UploadInvoicesModal } from '@/ar/components/Invoices/Modals/UploadInvoicesModal/UploadInvoicesModal';

const headerClassName = 'text-gray-600 font-body text-med';
const cellClassName = 'text-gray-700 font-body text-med';

export const commonGridDef: GridColDef = {
    field: '',
    headerAlign: 'left',
    headerClassName,
    cellClassName,
    align: 'left',
    sortable: false,
};

const getColumns = (showInternalCustomerName: boolean): GridColumns => {
    const columns: GridColumns = [
        {
            ...commonGridDef,
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: ({ row }): React.ReactNode => {
                const customerDisplayName = (showInternalCustomerName && row.platform_internal_name)
                    ? `${row.name} - ${row.platform_internal_name}`
                    : row.name;

                return <>{customerDisplayName}</>;
            },
        },
        {
            ...commonGridDef,
            field: 'open',
            headerName: 'Open balance',
            width: 150,
            renderCell: ({ value }): React.ReactNode => formatAsCurrency(value),
        },
        {
            ...commonGridDef,
            field: 'overdue',
            headerName: 'Overdue balance',
            width: 200,
            renderCell: ({ value }): React.ReactNode => formatAsCurrency(value),
        },
        {
            ...commonGridDef,
            field: 'payment_method',
            headerName: 'Payment method',
            flex: 1,
            renderCell: ({ row }): React.ReactNode => {
                if (!row.payment_method_brand) {
                    return <></>;
                }

                return (
                    <PaymentMethodBadge
                        brand={row.payment_method_brand}
                        last4={row.payment_method_last4}
                        autoCollection={row.auto_collection}/>
                );
            },
        },
        {
            ...commonGridDef,
            field: 'id',
            headerName: 'Customer ID',
            width: 170,
        },
        {
            ...commonGridDef,
            field: 'action',
            headerName: '',
            cellClassName: 'overflow-visible', // This must be set for the popover to be visible
            width: 40,
            renderCell: (param) => {
                const handleClick = (e): void => {
                    e.stopPropagation();
                    trackCurrentPageTitle(TrackingEvents.moreButtonClicked);
                };
                return (
                    <div onClick={handleClick}>
                        <CustomersRowMoreButton param={param}/>
                    </div>
                );
            },
        },
    ];

    return columns;
};

/**
 * The AR Customers page
 *
 * @returns JSX Element
 */

export const CustomersPage = (): JSX.Element => {
    useDocumentTitle('Customers - Notch');

    const { supplierLoginData, supplier } = useSupplierStore();
    const { searchParams, updateSearchParams } = useCustomersStore();
    const gpoId = getGpoId(supplierLoginData);
    const isGPO = supplierLoginData.gpo;
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
    const [uploadCustomersModalOpen, setUploadCustomersModalOpen] = useState(false);
    const flags = useFlags();
    useGetSupplier({
        supplierID: supplierLoginData?.supplier_id,
        gpoId,
    });
    const supplierHasInvoiceIngestionEnabled = supplier?.invoice_pdf_generation === InvoicePDFGenerationType.Always;
    const showInternalCustomerName = supplier.enable_accounting_internal_customer_name;

    const getBalancesQuery = useGetBalances({
        supplier_id: supplierLoginData.supplier_id,
        gpo_id: gpoId,
        has_auto_collection: searchParams.has_auto_collection,
        has_payment_method: searchParams.has_payment_method,
        limit: searchParams.limit,
        offset: searchParams.offset,
        sort_by: searchParams.sort_by,
        customer_id: searchParams.customer_id,
        name: searchParams.name,
    });

    const hasSelectedAllCustomersInPage = getBalancesQuery?.data?.total > searchParams?.limit && selectionModel?.length === getBalancesQuery?.data?.customers?.length;
    const handleClearAllSelection = ():void => {
        setSelectAllChecked(false);
        setSelectionModel([]);
    };

    const handleSelectAllClick = async (): Promise<void> => {
        try {
            setIsLoadingCustomers(true);
            const getAllBalances = await getBalances({
                supplier_id: supplierLoginData.supplier_id,
                limit: 1000000,
                customer_id: searchParams.customer_id,
                gpo_id: gpoId,
            });
            setSelectAllChecked(true);
            const allCustomers = getAllBalances?.customers;
            const allCustomerIDs = allCustomers?.map((invoice) => invoice.id) || [];
            setSelectionModel(allCustomerIDs);
            setIsLoadingCustomers(false);
        } catch (error) {
            setIsLoadingCustomers(false);
            handleClearAllSelection();
            toast.show({
                message: `${error}`,
            });
        }
    };

    /**
     * Render the data grid section of the UI. Split out so we can handle cases where the data might not be valid or loaded
     *
     * @returns A JSX element that is a spinner if the query is loading, an error text if there was an error, or a NotchDataGrid if the query succeeded
     */
    const renderDataGrid = (): JSX.Element => {
        const navigate = useNavigate();

        if (getBalancesQuery.isError || getBalancesQuery.isRefetchError) {
            return <div>{getBalancesQuery.error.toString()}</div>;
        }

        const isLoading: boolean = getBalancesQuery.isLoading || getBalancesQuery.isFetching;
        let tableRows: GetBalancesResponse['customers'] = [];
        let rowCount: number = 0;

        // in the case where we're loading we don't want to set it to bad orders or row data so we have 0 and empty defaults
        if (getBalancesQuery?.data?.customers) {
            tableRows = getBalancesQuery.data.customers;
            rowCount = getBalancesQuery.data.total;
        }

        const handleOnRowClick = (params): void => {
            navigate(`/${ARRoutePaths.AR_CUSTOMERS}/${params.row.id}`);
            sendTrackingEvent(TrackingEvents.customerRowClicked);
        };

        // if we're not loading and we have no data and we have no search params then we want to show the empty state
        if (!isLoading && getBalancesQuery?.data?.total === 0 && !searchParams.customer_id) {
            return <CustomersEmptyState
                message={ getBalancesQuery?.data?.total_without_filter ? 'No results. Try searching for a different customer.' : 'You don\'t have any customers added yet.'}/>;
        }
        const getColumnsVisibilityModel = (): Record<string, boolean> => {
            // if keys are not present in the object by default, all the columns are visible.
            if (supplierLoginData.gpo) {
                return {
                    action: false,
                };
            }

            return {};
        };

        return (
            <NotchDataGrid
                autoHeight
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                checkboxSelection={flags.customerPortalInvites && !isGPO}
                keepNonExistentRowsSelected
                paginationMode="server"
                sortingMode="server"
                columnVisibilityModel={getColumnsVisibilityModel()}
                rows={tableRows}
                columns={getColumns(showInternalCustomerName)}
                rowCount={rowCount}
                loading={isLoading}
                pageSize={searchParams.limit}
                onPageChange={(page): void => {
                    updateSearchParams({ offset: searchParams.limit * page });
                    const previousPage = Math.floor(searchParams.offset / searchParams.limit);
                    const pageChangeEvent = page > previousPage ? TrackingEvents.goToNextPageClicked : TrackingEvents.goToPreviousPageClicked;
                    sendTrackingEvent(pageChangeEvent);
                }}
                onSelectionModelChange={(selected): void => {
                    setSelectionModel(selected);
                }}
                selectionModel={selectionModel}
                onRowClick={handleOnRowClick}
                sx={{
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer',
                    },
                }}/>
        );
    };

    return (
        <>
            <div className="flex items-start">
                <PageTitle>Customers</PageTitle>
                {supplierHasInvoiceIngestionEnabled && <Button variant="SECONDARY"
                    size="SMALL"
                    className="ml-auto"
                    onClick={(): void => {
                        setUploadCustomersModalOpen(true);
                    }
                    }>Upload customers</Button>}
            </div>
            <CustomersFilters />

            {((hasSelectedAllCustomersInPage || selectAllChecked) && flags.customerPortalInvites) && <Banner className="my-2 text-center"
                alertType={EBannerType.INFO}
                body={ <div>
                    {!selectAllChecked && !isLoadingCustomers && <>All {searchParams.limit} Customers on this page are selected.
                        <span role="button" onClick={handleSelectAllClick} className="font-medium text-teal-500 cursor-pointer ml-1">
                        Select all customers
                        </span>
                    </>}
                    {isLoadingCustomers && <Loading isDark />}
                    {selectAllChecked && !isLoadingCustomers && <>All customers are selected.
                        <span role="button" onClick={handleClearAllSelection} className="font-medium text-teal-500 cursor-pointer ml-1">
                        Clear selection
                        </span></>}
                </div>}/>}

            {renderDataGrid()}
            { supplierHasInvoiceIngestionEnabled && <UploadInvoicesModal ingestionType={EBatchIngestionType.CUSTOMERS}
                errorDescription="Please review the errors that occurred during the bulk upload of customer data using a .csv file. Ensure that your file meets our format specifications to avoid these issues in the future."
                errorTitle="Customer Upload Summary"
                isModalOpen={uploadCustomersModalOpen}
                onClose={async (): Promise<void> => {
                    setUploadCustomersModalOpen(false);
                    await queryClient.invalidateQueries([FETCH_BALANCES_QUERY_KEY]);
                }}
                subHeaderText="Upload Your Customers via CSV"
                description="Ensure it meets our format specifications to avoid errors."
                title="Upload customers"/>}

            {flags.customerPortalInvites && <CustomersActionBar
                selectedCustomers={selectionModel}
                clearSelected={handleClearAllSelection} />}
        </>
    );
};
