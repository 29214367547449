import { GetPaymentMethodsResponse } from '@/ar/network/AccountsReceivable.network';

export function getAutoPayPaymentMethod(supplierId?: string, isCustomer?: boolean, paymentMethods?:GetPaymentMethodsResponse) {
    const findDefaultMethod = paymentMethods?.payment_methods?.find((method) => method.is_primary);
    if (isCustomer || !supplierId) {
        return findDefaultMethod;
    }
    const findPreferred = paymentMethods?.payment_methods.find((currentPaymentMethod) => {
        const findConnected = currentPaymentMethod.connectedSuppliers?.find((currentSupplier) => (currentSupplier.supplierId === supplierId) && currentSupplier.isPreferred);
        if (findConnected) {
            return true;
        }
        return false;
    });

    return findPreferred ?? findDefaultMethod;
}
