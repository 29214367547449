import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CreateAdyenPaymentMethod } from '@ar/components/shared/modals/CreateAdyenPaymentMethod/CreateAdyenPaymentMethod';
import { CreateStripePaymentMethod } from '@ar/components/shared/modals/CreateStripePaymentMethod/CreateStripePaymentMethod';
import { ActivePaymentMethods } from '@/utils/constants';
import { FeatureFlags } from '@/constants/FeatureFlags';

export interface AddNewPaymentMethodProps {
    isOpen?: boolean,
    handleClose: () => void,
    customerID: string,
    subTitle?: string,
    supplierID?: string,
}

export const CreatePaymentMethod = ({
    isOpen,
    handleClose,
    customerID,
    subTitle,
    supplierID
}: AddNewPaymentMethodProps): JSX.Element => {
    const flags = useFlags<FeatureFlags>();
    const enableAddPaymentMethodsWithAdyen = flags?.arPaymentProvider?.card === ActivePaymentMethods.adyen;
    const showStripePaymentMethodModal = !enableAddPaymentMethodsWithAdyen && isOpen;
    const showAdyenPaymentMethodModal = enableAddPaymentMethodsWithAdyen && isOpen;
    return <>
        {showStripePaymentMethodModal
            && <CreateStripePaymentMethod isOpen={isOpen}
                handleClose={handleClose}
                supplierID={supplierID}
                subTitle={subTitle}
                customerID={customerID}/>
        }
        {showAdyenPaymentMethodModal
            && <CreateAdyenPaymentMethod isOpen={isOpen}
                handleClose={handleClose}
                subTitle={subTitle}
                supplierID={supplierID}
                customerID={customerID}/>}
    </>;
};
