import React, { useState } from 'react';
import { Badge, Tooltip } from '@notch-ordering/ui-components';

export const PayoutsPagePendingTooltip = function PayoutsPagePendingTooltip(): JSX.Element {
    const [shouldShowTooltip, setShouldShowTooltip] = useState<boolean>(false);

    return (
        <Tooltip show={shouldShowTooltip}
            placement="top"
            trigger={<div onMouseEnter={(): void => setShouldShowTooltip(true)} onMouseLeave={(): void => setShouldShowTooltip(false)}>
                <Badge>Pending</Badge>
            </div>}>
            The report is currently processing.
        </Tooltip>
    );
};
