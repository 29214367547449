import React, { useContext } from 'react';
import { Banner, EBannerType, GridColDef, GridColumns, Loading, NotchDataGrid, Typography } from '@notch-ordering/ui-components';
import BellIcon from '@icons/bell-icon.svg';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { ARRoutePaths } from '@v2/constants/EPaths';
import { useNavigate } from 'react-router-dom';
import { CustomNotificationsEmptyState } from './CustomNotificationsEmptyState';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { useGetCustomersWithCustomNotifications } from '@/ar/hooks/queries/CustomerQueries.hook';
import { Customers } from '@/ar/network/AccountsReceivable.network';
import { NotificationsCustomDrawer } from './CustomNotificationsDrawer';
import CustomRowMoreButton from './CustomRowMoreButton';
import { useCustomersNotificationsStore } from './CustomNotificationsStore';
import { CustomerDetailsContext } from '../CustomerDetailsWrapperPage/CustomerDetailsContext';

const headerClassName = 'text-gray-600 font-body text-med';
const cellClassName = 'text-gray-700 font-body text-med';

export const drawerInsideClassName = 'drawer-body';

export const commonGridDef: GridColDef = {
    field: '',
    headerAlign: 'left',
    headerClassName,
    cellClassName,
    align: 'left',
    sortable: false,
};

/**
 * The Customer Notifications Custom Tab
 *
 * @returns JSX Element
 */
export const NotificationsCustom = function (): JSX.Element {
    const navigate = useNavigate();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Notifications' });
    const supplierLoginData = useSupplierStore((state) => state.supplierLoginData);
    const {
        isCustomerPopupOpen,
        setIsCustomerPopupOpen,
        handleCloseGeneralPopup,
    } = useContext(CustomerDetailsContext);
    const { searchParams, updateSearchParams } = useCustomersNotificationsStore();

    const { data: customerData, isLoading: customersLoading, refetch: refetchCustomers } = useGetCustomersWithCustomNotifications({
        supplierID: supplierLoginData?.supplier_id,
    });

    const isLoading: boolean = customersLoading;
    let tableRows: Customers[] = [];
    let rowCount: number = 0;

    if (customerData?.customers) {
        tableRows = customerData.customers;
        rowCount = tableRows.length;
    }

    const handleOnRowClick = (params): void => {
        navigate(`/${ARRoutePaths.AR_CUSTOMERS}/${params.row.id}`);
    };

    const columns: GridColumns = [
        {
            ...commonGridDef,
            field: 'businessName',
            headerName: 'Name',
            flex: 1,
        },
        {
            ...commonGridDef,
            field: 'editedSettings',
            headerName: 'Notification edits',
            flex: 1,
            renderCell: (param) => {
                const newEditsArray: string[] = param?.row?.editedSettings?.fields?.map((edit) => {
                    switch (edit) {
                        case 'notificationRules':
                            return 'Payments';
                        case 'disableReminders':
                            return 'Payments';
                        case 'invoiceNotifications':
                            return 'Invoices';
                        default:
                            return '';
                    }
                });

                const joinedEditsArray: string = newEditsArray.join(', ');

                return (
                    <div>
                        <Typography>{joinedEditsArray ? `${joinedEditsArray}` : ''}</Typography>
                    </div>
                );
            },
        },
        {
            ...commonGridDef,
            field: 'action',
            headerName: '',
            cellClassName: 'overflow-visible', // This must be set for the popover to be visible
            width: 40,
            renderCell: (param) => {
                const handleClick = (e): void => {
                    e.stopPropagation();
                };
                return (
                    <div onClick={handleClick}>
                        <CustomRowMoreButton param={param}
                            setCustomNotificationsDrawerOpen={(): void => {
                                setIsCustomerPopupOpen(true);
                            }}/>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            {!isLoading && <div className="px-10">
                <div className="w-full my-6">
                    <Banner alertType={EBannerType.SUBDUED}
                        body={<div>
                            <BellIcon className="w-5 h-5 text-gray-700"/>
                            <Typography as="div" className=" text-gray-700 mt-4 font-medium">
                                {t('customNotifications')}
                            </Typography><Typography as="div" className="text-gray-600 mt-2">
                                {t('customNotificationsDescription')}
                            </Typography></div>}
                        isDismissable={false}/>
                </div>
                {customerData?.customers?.length === 0 ? <div className="mt-[120px]">
                    <CustomNotificationsEmptyState/>
                </div>
                    : <div className="mt-5">
                        <NotchDataGrid
                            autoHeight
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnSelector
                            disableColumnMenu
                            paginationMode="client"
                            sortingMode="client"
                            rows={tableRows}
                            columns={columns}
                            rowCount={rowCount}
                            loading={isLoading}
                            pageSize={searchParams.limit}
                            onPageChange={(page): void => {
                                updateSearchParams({ offset: searchParams.limit * page });
                            }}
                            onRowClick={handleOnRowClick}
                            sx={{
                                '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer',
                                },
                            }}/>
                    </div>}
            </div>}
            {isLoading && <Loading className="mt-10" isDark />}
            {isCustomerPopupOpen && <NotificationsCustomDrawer isOpen={isCustomerPopupOpen}
                close={handleCloseGeneralPopup}
                refetch={refetchCustomers}/>}
        </>
    );
};
