import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Loading, Modal, Separator, toast, Typography } from '@notch-ordering/ui-components';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { CurrencyInput } from '@ar/components/shared/InputCurrency';

import { GetPaymentMethodResponse, payBills } from '@ar/network/AccountsReceivable.network';
import { FETCH_BILLS_QUERY_KEY } from '@ar/hooks/queries/InvoicesQueries.hook';
import { useBillStore } from '@ar/stores/BillsStore';
import { centsToDollars, formatAsCurrency } from '@/utils/Formatters';
import { PaymentMethodsPopover } from '@/ar/components/shared/PaymentMethodsPopover';
import { LoadingPaymentMethodBanner } from '@/ar/components/Invoices/Modals/BulkManualChargeModal/LoadingPaymentMethodBanner';
import { AmplitudeCharge, AmplitudeEventActions, useAmplitude } from '@/containers/app/AmplitudeContext';

export const BillManualChargeModal : React.FC = () => {
    const { modals, setModal } = useBillStore();
    const { supplierLoginData } = useSupplierStore();
    const queryClient = useQueryClient();
    const currentTransaction = modals.transaction;
    const [installmentDate, setInstallmentDate] = useState<string>('');
    const [amount, setAmount] = useState<number>(currentTransaction?.outstanding_amount ?? 0);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<GetPaymentMethodResponse>(null);

    const { trackAmplitudeEvent } = useAmplitude();
    const isModalOpen = modals.manualCharge;
    const hasIncompletePaymentMethodSelected = selectedPaymentMethod && !selectedPaymentMethod?.last4;
    const formIsInvalid = ():boolean => !selectedPaymentMethod || !amount || amount > currentTransaction?.outstanding_amount;
    const handleOnClose = ():void => {
        setSelectedPaymentMethod(null);
        setAmount(0);
        queryClient.invalidateQueries([FETCH_BILLS_QUERY_KEY]);
        setModal('manualCharge', false, null);
        setInstallmentDate('');
    };
    useEffect(() => {
        if (isModalOpen) {
            trackAmplitudeEvent(AmplitudeEventActions.pageChargeViewed, { type: AmplitudeCharge.individualCharge, transaction: currentTransaction?.id, countInvoices: 1 });
        }
    }, [isModalOpen]);
    const payBillsMutation = useMutation(payBills, {
        onSuccess: (_data) => {
            const paidSuccessful = _data[0].charged;
            let toastMessage;
            if (paidSuccessful) {
                toastMessage = `${formatAsCurrency(amount)} paid for Bill #${currentTransaction?.invoice_number}`;
            } else {
                toastMessage = 'Payment has failed';
            }
            toast.show({
                message: toastMessage,
            });
            trackAmplitudeEvent(AmplitudeEventActions.charged, { type: AmplitudeCharge.individualCharge, transaction: currentTransaction?.id, countInvoices: 1 });
            handleOnClose();
        },
        onError: (error) => {
            toast.show({
                message: 'Something went wrong. Please try again.',
            });
            console.info(error);
        },
    });
    const handleCharge = ():void => {
        if (formIsInvalid()) {
            return;
        }
        trackAmplitudeEvent(AmplitudeEventActions.pushCharge, { type: AmplitudeCharge.individualCharge, transaction: currentTransaction?.id, countInvoices: 1 });
        payBillsMutation.mutate({
            customerID: supplierLoginData?.customer_id,
            body: {
                is_lumped: false,
                payment_method_id: selectedPaymentMethod?.id,
                transaction_ids: [currentTransaction?.id],
                installments_schedule: installmentDate ? [installmentDate] : null
            },
        });
    };

    useEffect(() => {
        if (currentTransaction) {
            setAmount(centsToDollars(currentTransaction?.outstanding_amount));
        }
    }, [currentTransaction]);

    if (!currentTransaction || !isModalOpen) return null;
    return <Modal isOpen={isModalOpen}
        title={
            <><Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="LG-2">Confirm amount to be paid for
                bill {currentTransaction?.invoice_number ? `#${currentTransaction?.invoice_number}` : ''}</Typography>
            <Typography variant="LG-1" weight="font-regular" className="text-gray-600">{currentTransaction?.business_name}</Typography>
            </>
        }
        headerPadding="mt-0 mb-5"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL"
        desktopModalWidth="lg:w-[372px]">
        <div className="p-5 flex flex-col gap-4">
            <div className="flex-col flex gap-4">
                <div className="flex flex-col w-full gap-2">
                    <Typography as="div" weight="font-medium" variant="LG-1" className="text-gray-700">
                        Payment method
                    </Typography>
                    <PaymentMethodsPopover selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} transaction={currentTransaction} isCustomerPortal />
                </div>
                <CurrencyInput
                    required
                    label={'Amount'}
                    variant="SMALL"
                    value={amount}
                    disabled={true}
                    helperText={`Remaining amount: ${formatAsCurrency(centsToDollars(currentTransaction?.outstanding_amount))}`}
                    onChange={(e): void => {
                        setAmount(e.floatValue);
                    }}
                    isInvalid={amount > currentTransaction?.outstanding_amount}
                    invalidMessage={'Charge amount cannot be greater than remaining balance on invoice.'}/>
            </div>
            {hasIncompletePaymentMethodSelected && <LoadingPaymentMethodBanner/>}
            { /* TODO: CORE-495 - <SchedulePaymentInput installmentDate={installmentDate} setInstallmentDate={setInstallmentDate}/> */ }
        </div>
        <Separator/>
        <div className="pt-5 px-5 flex justify-end gap-3">
            <Button variant="TERTIARY_FILLED"
                size="SMALL"
                onClick={handleOnClose}>
                <Typography as="span" weight="font-medium">
                    Cancel
                </Typography>
            </Button>
            <Button variant="SECONDARY"
                disabled={payBillsMutation.isLoading || hasIncompletePaymentMethodSelected}
                loading={payBillsMutation.isLoading}
                size="SMALL"
                onClick={handleCharge}>
                <Typography as="span" weight="font-medium">
                    {payBillsMutation.isLoading ? <Loading/> : 'Charge '}
                </Typography>
            </Button>
        </div>
    </Modal>;
};
