import React from 'react';
import { Typography } from '@notch-ordering/ui-components';
import CheckIcon from '@icons/check-icon.svg';

export interface FilterLabelProps {
    isChecked: boolean,
    label: React.ReactNode,
}

export const FilterLabel = ({ isChecked, label }: FilterLabelProps): JSX.Element => (
    <Typography className="mb-0 flex gap-2 relative items-center">
        <span className="pr-5">{label}</span>
        {isChecked && (<div className="w-4 ml-auto absolute right-0 flex justify-center items-center">
            <CheckIcon className="w-4 h-4 ml-auto"/>
        </div>)}
    </Typography>
);
