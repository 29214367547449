/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from 'axios';
import { toast } from '@notch-ordering/ui-components';
import { ACCOUNTS_RECEIVABLE_API } from '@ar/network/AccountsReceivable.network';
import { getAccessToken, getAPIConfig } from '@/api/APIClient';
import { UserAuthenticationProvider } from '@/auth/UserAuthenticationProvider';
import { ArTokenStrategyBase } from '@/auth/authStrategies/ArTokenStrategyBase';

let isAuthSetup: boolean = false;
const MAX_ERROR_LENGTH: number = 200;
type ErrorData = { error?: string, message?: string };

/**
 * https://axios-http.com/docs/handling_errors
 */
export function parseAxiosError(error: AxiosError, message: string): string {
    let errorMessage: string | undefined;

    if (error.response?.data && (error.response.data as ErrorData).error) {
        // Response from server contains an error response
        errorMessage = (error.response.data as ErrorData).error;
    } else if (error.response?.data && (error.response.data as ErrorData).message) {
        // Response from server contains an error message
        errorMessage = (error.response.data as ErrorData).message;
    } else if (error.message) {
        // Something happened in setting up the request that triggered an Error
        errorMessage = error.message;
    } else if (error.request) {
        // The request was made but no response was received
        errorMessage = 'No response';
    }

    if (!errorMessage) {
        errorMessage = 'Unknown Error';
    }

    if (errorMessage.length > MAX_ERROR_LENGTH) {
        errorMessage = errorMessage.substring(0, MAX_ERROR_LENGTH);
    }

    errorMessage = `${message}: ${errorMessage}`;

    // eslint-disable-next-line no-console
    console.log(`${errorMessage}`);

    return errorMessage;
}

export function throwAxiosError(error: AxiosError, message: string = 'Error', showToast: boolean = true): any {
    const errorMessage: string = parseAxiosError(error, message);

    if (showToast) {
        toast.show({
            message: `Network Error: ${errorMessage}`,
            showClose: true
        });
    }

    throw new Error(errorMessage);
}

export async function getBearer(): Promise<string> {
    const apiConfig: unknown = await getAPIConfig();
    const accessToken: string = await getAccessToken(apiConfig);
    const bearer: string = `Bearer ${accessToken}`;

    return bearer;
}

export const X_AR_AUTOMATION_TOKEN_HEADER = 'x-ar-automation-token';

export function setupAxiosAuth(): void {
    if (isAuthSetup) {
        // Nothing to do
        return;
    }

    // Add a request interceptor
    axios.interceptors.request.use(async (config) => {
        if (config.url.indexOf(ACCOUNTS_RECEIVABLE_API) > -1) {
            // if no authentication strategy is set, this will return null and the request will be made without an access token for public endpoints
            const accessToken: string = await UserAuthenticationProvider.getAuthenticateStrategy()
                ?.getAccessToken();
            if (accessToken) {
                config.headers[X_AR_AUTOMATION_TOKEN_HEADER] = accessToken;
            }
        }

        return config;
    });

    axios.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.response?.status === 401) {
                const strategy = UserAuthenticationProvider.getAuthenticateStrategy();
                if (strategy && strategy instanceof ArTokenStrategyBase) {
                    // if there's an error try to refresh and if not successful, log out
                    if (!await strategy.refreshToken()) {
                        UserAuthenticationProvider.logOut();
                    }
                }
            }

            return Promise.reject(error);
        },
    );

    isAuthSetup = true;
}
