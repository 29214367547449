import { Modal, Separator, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import { mergeClassNames } from '@v2/utils/Helpers';
import { ModalProps } from '@notch-ordering/ui-components/src/components/Modal/ModalConstants';

export interface InfoModalProps extends Omit<ModalProps, 'close'> {
    isOpen: boolean,
    onClose: () => void,
    title?: React.ReactNode,
    className?: string,
    isLoading?: boolean,
}

export const InfoModal: React.FC<InfoModalProps> = ({
    isOpen,
    onClose,
    title,
    className,
    isLoading,
    ...props
}) => <Modal isOpen={isOpen}
    title={
        <Typography className="m-0 pr-10" weight="font-semibold" variant="LG-2">{title}</Typography>
    }
    headerPadding="mt-0 mb-2"
    close={onClose}
    modalSize="SMALL"
    desktopModalWidth="lg:w-[372px]"
    className={mergeClassNames('', className)}
    {...props}>
    <Separator/>
</Modal>;
