import React, { useEffect, useMemo } from 'react';
import { AutocompletePopover, PopoverAutoCompleteItem } from '@notch-ordering/ui-components';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { FilterLabel } from '@ar/components/shared/FilterLabel/FilterLabel';
import { usePayoutsStore } from '@ar/stores/PayoutsStore';
import { getGpoId } from '@v2/utils/GPOUtils';
import { FilterPopoverButton } from '@/components/shared/FilterButton';
import { useGetSuppliersInfinite } from '@/ar/hooks/queries/SupplierQueries.hook';
import { InvoiceFilterLoadingButton } from '@/ar/components/Invoices/InvoiceFilters/InvoiceFilterLoadingButton';

const DEFAULT_SUPPLIERS_LIMIT = 1000;
export const PayoutSupplierFilterButton : React.FC = () => {
    // Store variables
    const { supplierLoginData } = useSupplierStore();
    const { searchParams, updateSearchParams } = usePayoutsStore();

    const supplierID = supplierLoginData?.supplier_id;
    const gpoId = getGpoId(supplierLoginData);
    const { data: suppliersResults, hasNextPage, fetchNextPage, isFetching, isLoading } = useGetSuppliersInfinite({ supplier_id: supplierID, gpo_id: gpoId, limit: DEFAULT_SUPPLIERS_LIMIT });

    const suppliersNotGpo = supplierLoginData.supplier_memberships ?? [];
    const suppliers = supplierLoginData.gpo ? suppliersResults?.pages?.flatMap((page) => page.suppliers) ?? [] : suppliersNotGpo.map((supplier) => ({ id: supplier.supplier_id, name: supplier.supplier_name }));

    // Other variables
    const hasSupplierSelected = searchParams?.supplier_ids?.length > 0;
    // Returns the name of the supplier with the specified ID, or an empty string if no supplier is found.
    const getSupplierNames = ():string => suppliers.filter((currentSupplier) => searchParams?.supplier_ids?.includes(currentSupplier.id)).map((currentSupplier) => currentSupplier.name).join(', ') ?? '';

    // List of supplier items that are filtered based on the search query and mapped to a list of PopoverItem objects with click handlers and keyboard navigation support.
    const supplierItems = useMemo<PopoverAutoCompleteItem[]>(() => suppliers
        .map((currentSupplier) => ({
            key: currentSupplier.id,
            searchableField: currentSupplier.name,
            label: <FilterLabel label={currentSupplier.name} isChecked={searchParams?.supplier_ids?.includes(currentSupplier.id)}/>,
            onClick: (): void => {
                const newSearchParams = { supplier_ids: searchParams?.supplier_ids?.includes(currentSupplier.id) ? searchParams.supplier_ids.filter((id) => id !== currentSupplier.id) : [...(searchParams.supplier_ids ?? []), currentSupplier.id] };
                updateSearchParams(newSearchParams);
            },
        })), [searchParams?.supplier_ids, suppliers]);

    // this filter is not applicable if the user is not a GPO
    if (!supplierLoginData.gpo) {
        return null;
    }
    useEffect(() => {
        if (gpoId && hasNextPage && !isFetching) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetching]);
    if (gpoId && isLoading) {
        return <InvoiceFilterLoadingButton/>;
    }
    return (
        <AutocompletePopover items={supplierItems}
            placement={'bottom-start'}
            className="max-h-80 w-[200px] overflow-auto"
            button={<FilterPopoverButton
                isChecked={hasSupplierSelected}
                label={hasSupplierSelected ? `Supplier is ${getSupplierNames()}` : 'Supplier'}
                handleClearFilter={() => {
                    updateSearchParams({ supplier_ids: undefined });
                }}/>}/>

    );
};
