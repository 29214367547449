import React, { useEffect } from 'react';
import { Badge, Button, Loading, Popover, Typography } from '@notch-ordering/ui-components';

import { PopoverItem } from '@notch-ordering/ui-components/src/components/Popover/Popover';
import { useGetCustomerPaymentMethods } from '@ar/hooks/queries/CustomerQueries.hook';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useInvoiceStore } from '@ar/stores/InvoicesStore';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import { getCCIcon } from '@ar/components/shared/PaymentMethodBadge/PaymentMethodBadge';
import CheckIcon from '@icons/check-icon.svg';
import { getGpoId } from '@v2/utils/GPOUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isPaymentMethodIncomplete, useRefetchIncompletePaymentMethods } from '@ar/hooks/useRefetchPaymentMethods';
import { GetPaymentMethodResponse, Transaction } from '@/ar/network/AccountsReceivable.network';
import { FeatureFlags } from '@/constants/FeatureFlags';
import { ActivePaymentMethods } from '@/utils/constants';
import { useBillStore } from '@/ar/stores/BillsStore';
import { useAutoPayMethod } from '@/ar/hooks/queries/AutoPayMethod.hook';

export interface PaymentMethodsPopoverProps {
    selectedPaymentMethod: GetPaymentMethodResponse,
    setSelectedPaymentMethod: (paymentMethod: GetPaymentMethodResponse) => void,
    transaction: Transaction,
    isCustomerPortal?: boolean,
}

export const getPaymentMethodOptionElement = (paymentMethod:GetPaymentMethodResponse, isSelected?: boolean, isAdyenEnabled = false, isAutoPay?: boolean): JSX.Element => {
    if (!paymentMethod) return null;
    const isOptionLoading = isPaymentMethodIncomplete(isAdyenEnabled, paymentMethod);
    return <div className="flex items-center gap-2 pointer-events-none font-regular">
        <span>{getCCIcon(paymentMethod?.brand)}</span>
        <span className="flex items-center gap-1"><span>••••</span> {(isOptionLoading) ? <Loading isDark/> : paymentMethod.last4}</span>
        {(isAutoPay ?? paymentMethod?.is_primary) && <Badge size="SMALL">Default</Badge>}
        {isSelected && <span className="ml-auto">
            <CheckIcon className="h-4 w-4 text-gray-700"/>
        </span>}
    </div>;
};

export const PaymentMethodsPopover : React.FC<PaymentMethodsPopoverProps> = ({
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    transaction,
    isCustomerPortal
}) => {
    const { setModal } = isCustomerPortal ? useBillStore() : useInvoiceStore();
    const { supplierLoginData } = useSupplierStore();
    const flags = useFlags<FeatureFlags>();
    const enableAddPaymentMethodsWithAdyen = flags?.arPaymentProvider?.card === ActivePaymentMethods.adyen;
    const supplierID = supplierLoginData?.supplier_id ?? transaction?.supplier_id;
    const { data: paymentMethodsResponse, isLoading: isLoadingPaymentMethods, status: paymentMethodStatus } = useGetCustomerPaymentMethods({
        supplierID,
        customerID: transaction?.customer_id,
        gpoId: getGpoId(supplierLoginData),
    });

    const [autoPayMethod] = useAutoPayMethod(supplierID, supplierLoginData.isCustomer, paymentMethodsResponse);

    useRefetchIncompletePaymentMethods({
        paymentMethods: paymentMethodsResponse?.payment_methods ?? [],
        onComplete: () => {
            setSelectedPaymentMethod(paymentMethodsResponse?.payment_methods?.find((paymentMethod) => paymentMethod?.id === selectedPaymentMethod?.id) ?? null);
        }
    });

    const noPaymentMethodsOption:PopoverItem = {
        label: <div className="py-4 flex justify-center flex-col items-center gap-3 pointer-events-none">
            <Typography variant="LG-1" weight="font-regular" className="text-gray-600 text-center">No payment methods saved for this customer.</Typography>
            <Button variant="TEXT" className="text-teal-300 font-medium p-0 m-0" >Create new</Button>
        </div>,
        className: 'hover:bg-white',
        /**
         *
         */
        onClick(): void {
            setModal('addPaymentMethod', true, transaction);
        },
    };
    const paymentMethods = paymentMethodsResponse?.payment_methods ?? [];
    const paymentMethodOptions:PopoverItem[] = paymentMethods?.map((paymentMethod) => ({
        active: selectedPaymentMethod?.id === paymentMethod?.id,
        label: getPaymentMethodOptionElement(paymentMethod, selectedPaymentMethod?.id === paymentMethod?.id, enableAddPaymentMethodsWithAdyen, paymentMethod?.id === autoPayMethod?.id),
        /**
         *
         */
        onClick(): void {
            setSelectedPaymentMethod(paymentMethod ?? null);
        },
    })).concat([{
        active: false,

        label: <Button variant="TEXT" className="text-teal-300 font-medium p-0 m-0 pointer-events-none" >Create new payment method</Button>,
        /**
         *
         */
        onClick():void {
            setModal('addPaymentMethod', true, transaction);
        },

    }]) ?? [];
    const paymentMethodSelected = selectedPaymentMethod ? paymentMethods?.find((paymentMethod) => paymentMethod?.id === selectedPaymentMethod?.id) : null;
    const hasPaymentMethods = paymentMethodStatus === 'success' && paymentMethodsResponse?.payment_methods?.length > 0;
    const popOverItems = hasPaymentMethods ? paymentMethodOptions : [noPaymentMethodsOption];

    // if there are payment methods and there is no selected payment method, select the default payment method
    useEffect(() => {
        if (paymentMethods?.length > 0 && !selectedPaymentMethod) {
            setSelectedPaymentMethod(autoPayMethod ?? paymentMethodsResponse?.payment_methods[0]);
        }
    }, [paymentMethodsResponse, selectedPaymentMethod]);

    useEffect(() => {
        if (autoPayMethod) {
            setSelectedPaymentMethod(autoPayMethod);
        }
    }, [autoPayMethod?.id]);
    return <Popover items={popOverItems}
        placement="bottom-start"
        popOverClassName="w-full [&>*:first-child]:w-full h-auto"
        buttonClassName={'w-full'}
        parentDivClassName="w-full"
        className="w-full overflow-auto"
        button={
            <Button variant="TERTIARY_OUTLINED"
                as="div"
                size="SMALL"
                className="w-full cursor-pointer"
                heightPadding="py-[5px]"
                widthPadding="px-2.5"
                stopPropagation={false}>
                {isLoadingPaymentMethods ? <Loading isDark/>
                    : <div className="flex gap-3 items-center w-full ">
                        {getPaymentMethodOptionElement(paymentMethodSelected, false, enableAddPaymentMethodsWithAdyen, paymentMethodSelected?.id === autoPayMethod?.id)}
                        {!hasPaymentMethods && <span className="text-gray-500">Select</span>}
                        <ChevronDownIcon className="h-4 w-4 text-gray-700 ml-auto"/>

                    </div>}
            </Button>
        }/>;
};
