import React from 'react';
import { Banner, EBannerType, Typography } from '@notch-ordering/ui-components';
import InfoIcon from '@icons/info-icon.svg';
import { mergeClassNames } from '@v2/utils/Helpers';

export interface LoadingPaymentMethodBannerProps {
    className?: string,
}

export const LoadingPaymentMethodBanner : React.FC<LoadingPaymentMethodBannerProps> = ({ className }) => <div className={mergeClassNames('px-5 pb-5', className)}><Banner alertType={EBannerType.INFO}
    body={<Typography as="div" className="text-gray-600">We are
                                                  securely saving your card details. This process may take
                                                  up to 30 seconds. Thank you for your
                                                  patience.</Typography>}
    icon={<InfoIcon className="w-5 h-5"/>}
    isDismissable={false}/></div>;
