/**
 * Credit card expiry years can be stored in the database in multiple formats (i.e. 24 vs. 2024).
 * This function will convert the 2-digit version to the 4-digit one.
 *
 * @param cardYear Number representing the expiry year for the credit card, typically 2 or 4 digits
 * @returns Number representing the credit card expiry year, with at least 4 digits
 */
export function convertCardYear(cardYear: number) {
    if (cardYear > 1000) {
        // year is already at least 4 digits
        return cardYear;
    }
    // year is fewer than 4 digits
    return cardYear + 2000; // for now, we won't worry about expiry years of 2100 or greater
}

/**
 * Check if a credit card is expired given the expiry year and month
 *
 * @param cardYear Credit card expiry year
 * @param cardMonth Credit card expiry month
 * @returns true if card is expired, otherwise false
 */
export function isCardExpired(cardYear: number, cardMonth: number) {
    const fullCardYear = convertCardYear(cardYear);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // adjust for index starting at 0

    if (fullCardYear < currentYear) {
        return true;
    }
    if (fullCardYear > currentYear) {
        return false;
    }
    if (cardMonth < currentMonth) {
        return true;
    }
    return false;
}
