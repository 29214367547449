import { GetPaymentMethodsResponse, Transaction } from '@ar/network/AccountsReceivable.network';

export type PaymentMethodByCustomer = Record<string, GetPaymentMethodsResponse>;
export type InvoicesByCustomer = Record<string, Transaction[]>;
export const FETCH_BULK_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY = 'FETCH_BULK_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY';

/**
 * Groups invoices by customer ID.
 *
 * @param invoices - An array of transactions representing invoices.
 * @returns An object where the keys are customer IDs and the values are arrays of transactions.
 */
export const groupInvoicesByCustomerID = (invoices: Transaction[] = []): InvoicesByCustomer => invoices?.reduce((acc, inv) => {
    const customerID = inv.customer_id;
    if (!acc[customerID]) {
        acc[customerID] = [];
    }
    acc[customerID].push(inv);
    return acc;
}, {}) ?? {};

/**
 * Checks if all invoices are from the same customer.
 *
 * @param invoices - An array of transactions representing invoices.
 * @returns A boolean indicating whether all invoices are from the same customer.
 */
export const areAllInvoicesFromSameCustomer = (invoices: Transaction[] = []): boolean => {
    if (!invoices?.length) {
        return false;
    }
    const { customer_id: firstCustomerID } = invoices[0];
    return invoices.every((invoice) => invoice.customer_id === firstCustomerID);
};
