import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { mergeClassNames } from '@v2/utils/Helpers';
import './RangePicker.scss';
import { Button, Typography } from '@notch-ordering/ui-components';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';
import { useOnClickOutside } from '@/shared/hooks/useClickOutside';

export type DateRangePickerProps = {
    initialStartDate?: Date,
    initialEndDate?: Date,
    onDateRangeChange?: (startDate: Date, endDate: Date) => void,
    className?: string,
    onClose?: () => void,
    isOpen?: boolean,
};

export const DateRangePicker:React.FC<DateRangePickerProps> = ({
    initialStartDate,
    initialEndDate,
    onDateRangeChange,
    className,
    isOpen,
    onClose,

}) => {
    const ref = useRef(null);

    const [startDate, setStartDate] = useState(initialStartDate ? new Date(initialStartDate) : null);
    const [endDate, setEndDate] = useState(initialEndDate ? new Date(initialEndDate) : null);

    const onChange = (dates):void => {
        // These dates are in local time
        const [start, end] = dates;
        setStartDate(start);
        end?.setHours(23, 59, 59, 999);
        setEndDate(end);
        if (start && end) {
            onDateRangeChange(start, end);
        }
    };

    useOnClickOutside(ref, () => {
        onClose();
    });

    return isOpen ? (
        <div className={mergeClassNames('flex gap-4 flex-col', className)} ref={ref}>
            <DatePicker
                renderCustomHeader={({
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                    monthDate,

                }):JSX.Element => (
                    <div className="flex items-center justify-between">
                        <Typography variant="LG-1" weight="font-medium" className="text-gray-700 pl-3.5">{formatIsoStringToUtcDate(monthDate.toISOString(), 'MMMM, YYY')}</Typography>
                        <div className="flex gap-3 ml-auto mr-2">
                            <Button className="p-0"
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                                variant="ICON"
                                size="NO_BACKGROUND">
                                <ChevronDownIcon className="w-4 h-4 text-gray-600 rotate-90"/>
                            </Button>
                            <Button className="p-0"
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                variant="ICON"
                                size="NO_BACKGROUND">
                                <ChevronDownIcon className="w-4 h-4 text-gray-600 -rotate-90"/>
                            </Button>

                        </div>

                    </div>
                )}
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange

                inline/>

        </div>
    ) : null;
};
