import React, { useEffect, useMemo } from 'react';
import { AutocompletePopover, PopoverAutoCompleteItem } from '@notch-ordering/ui-components';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useInvoiceStore } from '@ar/stores/InvoicesStore';
import { FilterLabel } from '@ar/components/shared/FilterLabel/FilterLabel';
import { getGpoId } from '@v2/utils/GPOUtils';
import { FilterPopoverButton } from '@/components/shared/FilterButton';
import { useGetSuppliersInfinite } from '@/ar/hooks/queries/SupplierQueries.hook';
import { InvoiceFilterLoadingButton } from '@/ar/components/Invoices/InvoiceFilters/InvoiceFilterLoadingButton';

const DEFAULT_SUPPLIERS_LIMIT = 1000;
export const InvoiceSupplierFilterButton : React.FC = () => {
    // Store variables
    const { supplierLoginData } = useSupplierStore();
    const { searchParams, updateSearchParams, clearSelectedInvoices } = useInvoiceStore();

    const suppliersNotGpo = supplierLoginData.supplier_memberships ?? [];

    // API variables
    const supplierID = supplierLoginData?.supplier_id;
    const gpoId = getGpoId(supplierLoginData);
    // const gpoId = undefined;
    const { data: suppliersResults, hasNextPage, fetchNextPage, isFetching, isLoading } = useGetSuppliersInfinite({ supplier_id: supplierID, gpo_id: gpoId, limit: DEFAULT_SUPPLIERS_LIMIT });
    const suppliers = supplierLoginData.gpo ? suppliersResults?.pages?.flatMap((page) => page.suppliers) ?? [] : suppliersNotGpo.map((supplier) => ({ id: supplier.supplier_id, name: supplier.supplier_name }));

    // Other variables
    const hasSupplierSelected = !!searchParams?.supplier_id_filter;
    // Returns the name of the supplier with the specified ID, or an empty string if no supplier is found.
    const getSupplierName = (searchSupplierID:string):string => suppliers.find((currentSupplier) => currentSupplier.id === searchSupplierID)?.name ?? '';

    // List of supplier items that are filtered based on the search query and mapped to a list of PopoverItem objects with click handlers and keyboard navigation support.
    const supplierItems = useMemo<PopoverAutoCompleteItem[]>(() => suppliers
        .map((currentSupplier) => ({
            key: currentSupplier.id,
            searchableField: currentSupplier.name,
            label: <FilterLabel label={currentSupplier.name} isChecked={searchParams?.supplier_id_filter === currentSupplier.id}/>,
            onClick: (): void => {
                const newSearchParams = { supplier_id_filter: searchParams?.supplier_id_filter === currentSupplier.id ? undefined : currentSupplier.id };

                updateSearchParams(newSearchParams);
                clearSelectedInvoices();
            },
        })), [searchParams?.supplier_id_filter, suppliers]);

    // load all customer by splitting request into pages
    useEffect(() => {
        if (gpoId && hasNextPage && !isFetching) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetching]);
    if (gpoId && isLoading) {
        return <InvoiceFilterLoadingButton/>;
    }

    return (
        <AutocompletePopover items={supplierItems}
            placement={'bottom-start'}
            className="max-h-80 w-[200px] overflow-auto"
            button={<FilterPopoverButton
                isChecked={hasSupplierSelected}
                label={hasSupplierSelected ? `Supplier is ${getSupplierName(searchParams?.supplier_id_filter ?? '')}` : 'Supplier'}
                handleClearFilter={() => {
                    updateSearchParams({ supplier_id_filter: undefined });
                    clearSelectedInvoices();
                }}/>}/>

    );
};
