import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Banner, Button, EBannerType, Modal, Separator, toast, Typography } from '@notch-ordering/ui-components';
import { FileUploadInput, FileUploadInputState } from '@v2/components/Bills/AddBillForm/FileUploadInput';
import { AxiosError } from 'axios';
import WarningIcon from '@icons/warning-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import FileIcon from '@icons/file-icon.svg';
import CloseTimesIcon from '@icons/close-times-icon.svg';
import PendingIcon from '@icons/pending-icon.svg';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { BatchIngestionResult, BatchIngestionResultStatus, EBatchIngestionType, uploadInvoiceBatch } from '@/ar/network/AccountsReceivable.network';
import { SummaryBatchIngestionResultPanel } from './UploadInvoicesModalConstants';

export interface UploadInvoicesModalProps {
    ingestionType: EBatchIngestionType,
    title: React.ReactNode,
    subHeaderText: React.ReactNode,
    description?: React.ReactNode,
    isModalOpen?: boolean,
    errorTitle: React.ReactNode,
    errorDescription: React.ReactNode,
    onClose?: () => void,

}

// Enum for the steps of the modal
export enum UploadInvoicesModalStep {
    UPLOAD,
    ERROR
}

export const UploadInvoicesModal : React.FC<UploadInvoicesModalProps> = ({
    ingestionType,
    subHeaderText,
    title,
    description,
    isModalOpen,
    errorTitle,
    errorDescription,
    onClose,
}) => {
    const { supplierLoginData, supplier } = useSupplierStore();
    const initialInvoiceFileState = { files: [], valid: false };
    const [{ files: filesInvoice, valid: validInvoiceFile }, setFileInvoiceUploadInputState] = useState<FileUploadInputState>(initialInvoiceFileState);
    const [step, setStep] = useState<UploadInvoicesModalStep>(UploadInvoicesModalStep.UPLOAD);
    const [summaryBatchIngestionResults, setSummaryBatchIngestionResults] = useState<Record<EBatchIngestionType, BatchIngestionResult[]>>({
        invoice: [],
        customers: [],
    });

    const handleOnClose = async ():Promise<void> => {
        onClose?.();
        setFileInvoiceUploadInputState(initialInvoiceFileState);
        setStep(UploadInvoicesModalStep.UPLOAD);
    };
    // mutation to upload the invoices
    const invoiceBatchIngestionMutation = useMutation(uploadInvoiceBatch, {
        onSuccess: async (response) => {
            setSummaryBatchIngestionResults((prev) => ({ ...prev, [ingestionType]: response }));
            // if there are errors, show the error step
            const uploadInvoicesHasErrors = response.some((res) => res.status === BatchIngestionResultStatus.Error || res.status === BatchIngestionResultStatus.MissingValues);
            if (uploadInvoicesHasErrors) {
                setStep(UploadInvoicesModalStep.ERROR);
            } else {
                handleOnClose();
            }
            // show the toast
            toast.show({
                message: response[0]?.message,
                icon: uploadInvoicesHasErrors ? <WarningIcon/> : <CheckIcon/>,
            });
        },
        onError: (e:AxiosError<{ error: string }>) => {
            toast.show({
                message: `${e.response?.data?.error ?? JSON.stringify(e)}`,
            });
        },
    });
    // process the batch upload
    const handleBatchUpload = async (file: File) => {
        invoiceBatchIngestionMutation.mutate({ file,
            supplierID: supplierLoginData?.supplier_id,
            ingestionType: 'customers',
            type: ingestionType,
            accountId: supplier?.account_id });
    };
    // cleanup on unmount the component
    useEffect(() => () => {
        handleOnClose();
    }, []);

    return <Modal isOpen={isModalOpen}
        title={
            <Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="LG-2">
                {title}
            </Typography>

        }
        headerPadding="mt-0 mb-5"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL"
        desktopModalWidth="lg:w-[600px]" >
        {step === UploadInvoicesModalStep.UPLOAD && <>
            <div className="px-5 pt-5 pb-8">
                <div>
                    <Typography className="font-medium mb-1 ">{subHeaderText}</Typography>
                    {description && <Typography className="text-gray-600">{description}</Typography>}
                </div>
                <div>
                    <FileUploadInput files={filesInvoice}
                        multiple={false}
                        allowedFileTypes={['text/csv']}
                        thumbnailLabel="CSV"
                        onChange={(state) => {
                            setFileInvoiceUploadInputState(state);
                            if (!state.valid) {
                                setSummaryBatchIngestionResults((prev) => ({ ...prev, invoice: [] }));
                            }
                        }} />
                </div>
                {invoiceBatchIngestionMutation.isLoading && <div className="mt-4">
                    <Banner alertType={EBannerType.SUBDUED}
                        body={
                            <div className="flex items-center gap-5">
                                <div><PendingIcon className="w-4 h-4 text-gray-700" /></div>
                                <div className="space-y-1">
                                    <Typography className="font-medium m-0 text-gray-700" >
                                        Your CSV is being uploaded
                                    </Typography>
                                    <Typography className="text-gray-600 m-0" >
                                        Please wait while we process your file. This may take a few minutes.
                                    </Typography>
                                </div>
                            </div>
                        }/>
                </div>}
            </div>
            <Separator/>
            <div className="px-5 py-4">
                <div className="flex justify-end gap-3">
                    <Button
                        onClick={handleOnClose}
                        variant="TERTIARY_FILLED"
                        size="SMALL"
                        minWidth="w-auto"
                        type="submit">
                    Cancel
                    </Button>
                    <Button
                        loading={invoiceBatchIngestionMutation.isLoading}
                        onClick={() => handleBatchUpload(filesInvoice[0])}
                        disabled={!validInvoiceFile || invoiceBatchIngestionMutation.isLoading || !filesInvoice[0]}
                        variant={'SECONDARY'}
                        size="SMALL"
                        minWidth="w-auto"
                        type="submit">
                    Submit
                    </Button>
                </div>
            </div>
        </>}
        {step === UploadInvoicesModalStep.ERROR && <>
            <div className="p-5 space-y-2">
                <div>
                    <Typography className="font-medium mb-1 ">{errorTitle}</Typography>
                    {errorDescription && <Typography className="text-gray-600">{errorDescription}</Typography>}
                </div>
                <div>
                    <div
                        className="flex gap-3 items-start py-4 px-4 border border-gray-300 rounded-lg shadow-z2 mb-4">
                        <div className="relative">
                            <FileIcon className="w-4 h-4 text-gray-700" />
                            <div className="z-10 absolute bottom-0 flex items-center justify-center p-0 -right-1 w-3 h-3 bg-white">
                                <CloseTimesIcon className="w-2.5 h-2.5 text-red-300 font-bold" />
                            </div>
                        </div>
                        <Typography className="m-0 p-0 truncate" as="div">
                            {filesInvoice[0]?.name}
                            <Typography as="span" className="text-red-500 block">{summaryBatchIngestionResults[ingestionType][0]?.message
                            }</Typography>
                        </Typography>
                    </div>

                    <div className="max-h-[335px] overflow-y-auto pr-4">
                        <SummaryBatchIngestionResultPanel result={summaryBatchIngestionResults[ingestionType]?.slice(1)} />
                    </div>
                </div>

            </div>
            <Separator/>
            <div className="px-5 py-4">
                <div className="flex justify-end gap-3">
                    <Button
                        onClick={handleOnClose}
                        variant="SECONDARY"
                        size="SMALL"
                        minWidth="w-auto"
                        type="submit">
                    Close
                    </Button>
                </div>
            </div>
        </>}

    </Modal>;
};
