import React, { useState } from 'react';
import SearchIcon from '@icons/search-icon.svg';
import CloseIcon from '@icons/close-icon.svg';
import cx from 'classnames';
import { twMergeCustom } from '@notch-ordering/ui-components';

export interface SearchInputProps {
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>,
    onChange: (query: string | string[]) => void,
    value?: string | string[],
    className?: string,
    onFocus?: () => void,
}

export const SearchInput : React.FC<SearchInputProps> = ({ inputProps, value = '', onChange, className, onFocus }) => {
    const [isFocused, setIsFocused] = useState(false);
    const clearQuery = (): void => {
        setIsFocused(false);
        onChange('');
    };
    return (
        <div className={cx('flex flex-col grow', className)}>
            <div className={twMergeCustom(cx('relative rounded-lg lg:w-full h-8 border border-gray-400', { 'border-teal-250 border shadow-z6 outline-none': isFocused }))}>
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon className="inline-flex w-4 h-4 text-gray-600"/>
                </div>

                <input
                    onFocus={(): void => {
                        setIsFocused(true);
                        onFocus?.();
                    }}
                    type="text"
                    onChange={(e): void => {
                        onChange(e.target.value);
                    }}
                    value={value}
                    className="h-full focus:border-teal-250 outline-none block w-full pl-10 pr-10 lg:text-2 text-3 font-regular font-body border-gray-300 rounded-lg placeholder-gray-600"
                    onBlur={(): void => {
                        if (value === '' || value.length === 0) {
                            setTimeout(() => setIsFocused(false));
                        }
                    }}
                    {...inputProps}/>
                {isFocused && value?.length > 0 && (
                    <div
                        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer "
                        onClick={(): void => clearQuery()}
                        role={'button'}>
                        <CloseIcon className="inline-flex w-4 h-4 text-gray-600"/>
                    </div>
                )}
            </div>
        </div>
    );
};
