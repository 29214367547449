import { Address } from '@ar/network/AccountsReceivable.network';

type AddressFromPlace = Address & {
    state_code: string | null | undefined,
};

/**
 * Extracts address information from a Google Maps place result.
 *
 * @param place The Google Maps place result.
 * @returns The extracted address information.
 */
export const extractAddressFromPlace = (place: google.maps.places.PlaceResult): AddressFromPlace => {
    const address: AddressFromPlace = {
        city: '',
        country: '',
        line1: '',
        line2: '',
        postal_code: '',
        state: '',
        state_code: ''
    };

    place.address_components?.forEach((component) => {
        const { types, long_name: longName, short_name: shortName } = component;

        if (component) {
            switch (true) {
                case types.includes('street_number'):
                    address.line1 = longName;
                    break;
                case types.includes('route'):
                    address.line1 += ` ${longName}`;
                    break;
                case types.includes('postal_code'):
                    address.postal_code = longName;
                    break;
                case types.includes('locality'):
                    address.city = longName;
                    break;
                case types.includes('administrative_area_level_1'):
                    address.state = longName;
                    address.state_code = shortName ?? '';
                    break;
                case types.includes('country'):
                    address.country = longName;
                    break;
                default:
                    break;
            }
        }
    });
    return address;
};
