import { z, ZodFormattedError } from 'zod';
import { NONE_SELECTED } from '@notch-ordering/ui-components';

// Define the validation schema for the SupplierData
const supplierFormDataSchema = z.object({
    name: z.string().min(1),
    email: z.string().email().min(1),
    phone: z.string().min(1),
    line1: z.string().min(1),
    line2: z.string().optional(),
    city: z.string().min(1),
    state: z.string().min(1),
    postal_code: z.string().min(1),
    country: z.string().min(1).refine((val) => val !== NONE_SELECTED, {
        message: 'No country selected',
    }),
});

export type SettingsFormState = z.infer<typeof supplierFormDataSchema>;

export type SettingsFormErrors = Partial<ZodFormattedError<SettingsFormState>>;
export const validateFormState = (formState: Partial<SettingsFormState>):SettingsFormErrors => {
    const result = supplierFormDataSchema.safeParse(formState);
    if (result.success === false) {
        return result.error.format();
    }
    return {};
};
