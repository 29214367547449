import React from 'react';
import { Banner, Button, EBannerType, Loading, toast, Typography } from '@notch-ordering/ui-components';
import cx from 'classnames';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { logError } from '@v2/utils/logError';
import { useMutation } from '@tanstack/react-query';
import { createSetupIntent, Currency } from '@ar/network/AccountsReceivable.network';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import BuildingBalanceIcon from '@icons/building-balance-icon.svg';
import InfoIcon from '@icons/info-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import PendingIcon from '@icons/pending-icon.svg';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { getGPOSupplierAPIToken } from '@v2/utils/GPOUtils';

export interface PADButtonProps {
    customerID: string,
    supplierID: string,
    email: string,
    name: string,
    onSuccessfulSetup?: () => void,
}
export const PADButton : React.FC<PADButtonProps> = ({
    supplierID, customerID,
    name, email,
    onSuccessfulSetup
}) => {
    const supplierToken = getGPOSupplierAPIToken(supplierID);
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices.PayModal' });
    const createSetupIntentMutation = useMutation(createSetupIntent);
    const [errorIntentMessage, setErrorIntentMessage] = React.useState<string>('');
    const [isLoadingSetupIntent, setIsLoadingSetupIntent] = React.useState<boolean>(false);
    const { supplierLoginData } = useSupplierStore();
    const isUSD = supplierLoginData?.currency === Currency.usd;
    const setupStripePAD = async () => {
        try {
            setIsLoadingSetupIntent(true);
            const intentResponse = await createSetupIntentMutation.mutateAsync({
                customerID,
                supplierID,
                token: supplierToken
            });
            if (intentResponse.client_secret) {
                const stripe: Stripe | void = await loadStripe(isUSD ? process.env.STRIPE_US_API_KEY : process.env.STRIPE_API_KEY)
                    .catch((reason) => {
                        logError('Failed to initialize Stripe.js', reason, true);
                    });

                if (!stripe) {
                    return;
                }
                const { setupIntent, error } = await stripe.confirmAcssDebitSetup(
                    intentResponse.client_secret,
                    {
                        payment_method: {
                            billing_details: {
                                email,
                                name,
                            }
                        },
                    }
                );

                setIsLoadingSetupIntent(false);
                if (error) {
                    setErrorIntentMessage(error.message);

                    return;
                }
                switch (setupIntent.status) {
                    case 'succeeded':
                        toast.show({
                            icon: <CheckIcon/>,
                            message: t('padInfoSuccess')
                        });
                        if (onSuccessfulSetup) {
                            onSuccessfulSetup();
                        }

                        break;
                    case 'processing':
                    case 'requires_action':
                    case 'requires_confirmation':
                    case 'requires_payment_method':
                        toast.show({
                            icon: <PendingIcon/>,
                            message: t('padInfoPendingVerification')
                        });
                        break;
                    case 'canceled':
                    default:
                        toast.show({
                            message: t('transactionCanceled')
                        });
                        break;
                }
            }
        } catch (e) {
            setIsLoadingSetupIntent(false);
            toast.show({
                message: 'Failed to setup PAD'
            });
        }
    };
    return <>
        <Button
            className={cx('text-left flex gap-2 items-center px-4 py-3 text-gray-700 w-full')}
            variant="TERTIARY_OUTLINED"
            size="MEDIUM"
            onClick={(): void => {
                setupStripePAD();
            }}>
            <div className="flex gap-3 items-center w-full">
                <BuildingBalanceIcon
                    className="h-4 w-4"/> {isUSD ? 'Set up Canadian Bank Account' : `Set up ${t('preAuthorizedDebit')}`}
                {isLoadingSetupIntent && <Loading className="ml-auto" isDark/>}
            </div>
        </Button>
        {errorIntentMessage
            && <div className="w-full mb-4">
                <Banner alertType={EBannerType.ERROR}
                    timeoutMS={5000}
                    body={<Typography as="div" className="text-gray-600">
                        {errorIntentMessage}
                    </Typography>}
                    icon={<InfoIcon className="w-5 h-5 text-red-300"/>}
                    isDismissable={true}/>
            </div>
        }
    </>;
};
