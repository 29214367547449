import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Outlet } from 'react-router-dom';

import CartItemNotification from '../../components/cart/cart-item-notification';

import * as Intercom from '../../third-party/Intercom';
import { initLibrariesNotRequiringLogin } from '../../helpers/initThirdPartyLibraries';
import withUseIsMobileWidth from '../../domains/shared/hooks/withUseIsMobileWidth';
import {
  addBackButtonAndroidListener,
  addStatusBarTapEventListener,
} from '../../helpers/common/mobile';

const AppWrapper = ({ children }) => {
  const [isMobileWidth, setIsMobileWidth] = useState(false);
  const cartItemNotification = useSelector(
    (state) => state.cartReducer.cartItemNotification
  );
  const location = useLocation();

  useEffect(() => {
    // Set state, init libraries, init Intercom and setup resize event
    const { pathname = '' } = location;
    const isMobileWidth = window.innerWidth <= 768;
    setIsMobileWidth(isMobileWidth);
    Intercom.init();
    initLibrariesNotRequiringLogin();
    // adding listener to check if the window is resized
    window.addEventListener('resize', handleResizeWindow);
    addBackButtonAndroidListener();
    addStatusBarTapEventListener();

    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  const handleResizeWindow = () => {
    const isMobileWidth = window.innerWidth <= 768;
    setIsMobileWidth(isMobileWidth);
  };

  return (
    <div>
      <div className="app-wrapper">
        <div className="app-content">
          <Outlet/>
        </div>
      </div>
    </div>
  );
};

export default withUseIsMobileWidth(AppWrapper);
