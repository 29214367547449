import { z } from 'zod';

const emailSchema = z.object({
    email: z.string().email({ message: 'Please provide a valid email address.' }).optional(),
});

const additionalEmailSchemaValidation = z.array(emailSchema).optional().nullish();

export const validationSchema = z.object({
    terms: z.number().min(0).max(90, 'Terms must be between 0 and 90 days'),
    ccEmails: additionalEmailSchemaValidation,
});
