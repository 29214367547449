import React, { useState } from 'react';
import { PageTitle } from '@ar/components/PageTitle';
import { Tabs, Typography } from '@notch-ordering/ui-components';
import { useSearchParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { CustomerSettingsGeneral } from '@/ap/pages/SettingsPage/CustomerSettingsGeneral/CustomerSettingsGeneral';
import { PaymentMethods } from '@/ap/components/PaymentMethods/PaymentMethods';

export enum CustomerSettingsTabs {
    Wallet = 'Wallet',
    General = 'General',
}

/**
 * The AP Settings page
 *
 * @returns JSX Element
 */
export const CustomerSettingsPage = (): JSX.Element => {
    useDocumentTitle('Customer Settings - Notch');

    const ActiveTabKey = 'activeTab';
    const flags = useFlags();

    // if feature flag customerPortalPaymentMethodsSettings === false did not show page Wallet
    const tabs = Object.values(CustomerSettingsTabs).filter((currentTab) => flags.customerPortalPaymentMethodsSettings || (currentTab !== CustomerSettingsTabs.Wallet));

    const [searchParams, setSearchParams] = useSearchParams();
    const defaultTab = flags.customerPortalPaymentMethodsSettings ? CustomerSettingsTabs.Wallet : CustomerSettingsTabs.General;
    const [customerSettingsTab, setCustomerSettingsTab] = useState<CustomerSettingsTabs>(searchParams.get(ActiveTabKey) as CustomerSettingsTabs || defaultTab);
    const handleTabChange = (value: string): void => {
        setCustomerSettingsTab(CustomerSettingsTabs[value]);
        setSearchParams({ [ActiveTabKey]: value });
    };
    return (
        <>
            <PageTitle>Settings</PageTitle>
            <Typography className="mt-2 mb-10">Manage and update your account information and preferences</Typography>
            <div >
                <div className="px-2 mb-[-2px]">
                    <Tabs
                        onChange={handleTabChange}
                        tabs={tabs}
                        value={customerSettingsTab} />
                </div>
                <div className="w-full h-px bg-gray-200 -mt-[1px] mb-7" />

                {customerSettingsTab === CustomerSettingsTabs.Wallet && <PaymentMethods/>}
                {customerSettingsTab === CustomerSettingsTabs.General && <CustomerSettingsGeneral/>}

            </div>
        </>
    );
};
