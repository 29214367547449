import React from 'react';
import { Button, Popover, Typography } from '@notch-ordering/ui-components';
import { PopoverItem } from '@notch-ordering/ui-components/src/components/Popover/Popover';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import CheckIcon from '@icons/check-icon.svg';

export interface PayoutExportFormatsPopoverProps {
    selectedFormat: string,
    setSelectedFormat: (format: string) => void,
}

export const EXPORT_FORMATS = ['CSV'] as const;
export const PayoutExportFormatsPopover : React.FC<PayoutExportFormatsPopoverProps> = ({ setSelectedFormat, selectedFormat }) => {
    const formatOptions:PopoverItem[] = EXPORT_FORMATS.map((format) => {
        const isChecked = selectedFormat === format;
        return ({
            label: <div className="flex gap-3 items-center justify-between"><Typography>{format}  </Typography>
                {isChecked && <CheckIcon className="w-3.5 h-3.5 text-teal-400"/>}
            </div>,
            onClick: (): void => {
                setSelectedFormat(format);
            },
            active: selectedFormat === format,

        });
    });
    return <Popover
        popOverClassName="w-full [&>*:first-child]:w-full h-auto"
        buttonClassName={'w-full'}
        parentDivClassName="w-full"
        className=" overflow-auto w-48"
        placement="bottom-end"
        items={formatOptions}
        button={<Button
            as="div"
            type="button"
            variant="ICON"
            size="SMALL"
            className="cursor-pointer w-48"
            widthPadding="px-2.5"
            stopPropagation={false}>
            <div className="flex gap-3 items-center justify-end">
                <Typography>{selectedFormat}</Typography>
                <ChevronDownIcon className="w-4 h-4 text-gray-600"/>
            </div>
        </Button>}/>;
};
