import React, {Component} from "react";
import PropTypes from "prop-types";

import {Provider} from "react-redux";

import {DragDropContext} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import {IonApp} from "@ionic/react";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import ReactModal from "react-modal";
import CreateRoutes from "../../routes";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";


export const queryClient = new QueryClient();
class Root extends Component {
  componentDidMount() {
    /* Globally tells ReactModal to set aira-hidden on main app when modals are open
     *  This is benefecial for screenreaders and accessibility in general
     */
    ReactModal.setAppElement("#root");

    const el = document.getElementById("root-loader");
    if (el) {
      el.classList.add("root-loader-hide");
    }
  }

  render() {
    const { store } = this.props;
    return (
      <IonApp>
        <QueryClientProvider client={queryClient}>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.GOOGLE_RECAPTCHA_KEY}
            useEnterprise
          >
            <Provider store={store}>
              <CreateRoutes/>
            </Provider>
          </GoogleReCaptchaProvider>
        </QueryClientProvider>
      </IonApp>
    );
  }
}

Root.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DragDropContext(HTML5Backend)(Root);
