import { Button, Loading, Modal, Separator, toast, Typography } from '@notch-ordering/ui-components';
import React, { useEffect, useState } from 'react';
import { attachAdyenPaymentMethod } from '@ar/network/AccountsReceivable.network';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useGetSupplier } from '@ar/hooks/queries/SupplierQueries.hook';
import { useGetPaymentMethodSchema } from '@ar/hooks/queries/PaymentReceiverQueries.hook';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import './CreateAdyenPaymentMethod.scss';
import { FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY } from '@ar/hooks/queries/CustomerQueries.hook';
import { FETCH_BALANCES_QUERY_KEY } from '@ar/hooks/queries/BalanceQueries.hook';
import { FETCH_BULK_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY } from '@ar/components/Invoices/Modals/BulkManualChargeModal/BulkManualChargeModalConstants';
import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import { drawerInsideClassName } from '@ar/components/CustomerDetails/CustomerGeneralPopup/CustomerGeneralPopup';
import { AxiosError } from 'axios';

export interface AddNewPaymentMethodProps {
    isOpen?: boolean,
    handleClose: () => void,
    customerID: string,
    subTitle?: string,
    supplierID?: string,
}

type CreatePaymentMethodFormState = {
    component: UIElement,
    isValid: boolean,
    errorMessage: string,
    hasFormSubmitted: boolean,
};

type AttachPaymentMethodError = { error: string };

export const CreateAdyenPaymentMethod = ({ isOpen, handleClose, customerID, subTitle, supplierID }: AddNewPaymentMethodProps): JSX.Element => {
    const { supplierLoginData, supplier } = useSupplierStore();
    const queryClient = useQueryClient();
    const [formState, setFormState] = useState<CreatePaymentMethodFormState>({
        component: null,
        isValid: false,
        errorMessage: null,
        hasFormSubmitted: false,
    });
    const supplierIDToUse = supplierID ?? supplierLoginData?.supplier_id;

    useGetSupplier({ supplierID: supplierIDToUse, customerId: supplierLoginData?.customer_id });
    const paymentReceiverID = supplier?.payment_receiver_id;
    const { data: paymentMethodsSchemaResponse } = useGetPaymentMethodSchema({ paymentReceiverID, supplierID: supplier.id, customerID: supplierLoginData?.customer_id });
    const attachPaymentMethodMutation = useMutation(attachAdyenPaymentMethod);
    const updateFormState = (payload: Partial<CreatePaymentMethodFormState>) => {
        setFormState((prevState) => ({
            ...prevState,
            ...payload,
        }));
    };

    const cleanupState = ():void => {
        updateFormState({
            errorMessage: null,
            hasFormSubmitted: false,
        });
    };
    const handleOnClose = ():void => {
        handleClose();
        cleanupState();
    };
    // thi is the reference to the div where the adyen payment method will be mounted
    const paymentMethodContainerRef = React.useRef(null);
    // the state is defined as any in the adyen library
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOnChange = (state:any, component:UIElement) => {
        updateFormState({
            component,
            isValid: state.isValid,
        });
    };
    const handleOnAdditionalDetails = () => {
        // handle additional details such as 3DS2 leave it as placeholder for now
    };

    // the state is defined as any in the adyen library
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOnSubmit = (state:any) => {
        updateFormState({
            hasFormSubmitted: true,
        });
        if (!state.isValid) {
            return;
        }
        const { encryptedCardNumber, encryptedExpiryMonth, encryptedExpiryYear, encryptedSecurityCode, } = state?.data?.paymentMethod ?? {
            encryptedCardNumber: '',
            encryptedExpiryMonth: '',
            encryptedExpiryYear: '',
            encryptedSecurityCode: '',
        };
        attachPaymentMethodMutation.mutate({
            body: {
                cardData: {
                    cardNumber: encryptedCardNumber,
                    expiryMonth: encryptedExpiryMonth,
                    expiryYear: encryptedExpiryYear,
                    securityCode: encryptedSecurityCode,
                },
                customerId: customerID,
                supplierId: supplier.id,
            }
        }, {
            onSuccess: async () => {
                handleOnClose();
                toast.show({
                    message: 'Payment method added successfully',
                });
                await queryClient.invalidateQueries([FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY]);
                await queryClient.invalidateQueries([FETCH_BALANCES_QUERY_KEY]);
                await queryClient.invalidateQueries([FETCH_BULK_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY]);
            },
            onError: (err: AxiosError<AttachPaymentMethodError>) => {
                const errorData = err?.response?.data;
                toast.show({
                    message: errorData?.error,
                });
                updateFormState({
                    errorMessage: errorData?.error
                });
            }
        });
    };
    useEffect(() => {
        if (!paymentMethodContainerRef.current && !paymentMethodsSchemaResponse?.paymentMethods?.length) {
            return;
        }

        const createCheckout = async () => {
            const configuration: CoreOptions = {
                locale: 'en_US',
                environment: process.env.ADYEN_ENVIRONMENT,
                clientKey: process.env.ADYEN_CLIENT_KEY,
                showPayButton: false,
                analytics: {
                    enabled: false
                },
                paymentMethodsResponse: paymentMethodsSchemaResponse,
                onChange: handleOnChange,
                onSubmit: handleOnSubmit,
                onAdditionalDetails: handleOnAdditionalDetails
            };
            const checkout = await AdyenCheckout(configuration);
            if (paymentMethodContainerRef.current) {
                checkout.create('card').mount(paymentMethodContainerRef.current);
            }
        };
        createCheckout();
    }, [paymentMethodsSchemaResponse?.paymentMethods, paymentMethodContainerRef.current]);

    const handleSubmit = async (event):Promise<void> => {
        event.preventDefault();
        formState?.component?.submit();
    };

    return <Modal isOpen={isOpen}
        title={
            <>
                <Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="LG-2">Create new payment method</Typography>
                {Boolean(subTitle) && <Typography variant="LG-1" weight="font-regular" className="text-gray-600">{subTitle}</Typography>}
            </>
        }
        className={drawerInsideClassName}
        headerPadding="mt-0 mb-5"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL"
        desktopModalWidth="lg:w-[372px]">

        <form onSubmit={handleSubmit}>
            <div className="p-5 min-w-0" ref={paymentMethodContainerRef} />
            <div className="p-5">
                <div className="flex-col flex gap-4">
                    <div className="flex flex-col w-full gap-2">
                        <Typography as="div" weight="font-medium" variant="LG-1" className="text-gray-700"/>
                    </div>

                </div>
                {formState.hasFormSubmitted && formState.errorMessage
                    && <Typography variant="BASE"
                        className="text-red-300 mt-4 h-4 mb-4 ">{formState.errorMessage}</Typography>}
            </div>

            <Separator/>
            <div className="pt-5 px-5 flex justify-end gap-3">
                <Button variant="TERTIARY_FILLED"
                    as={'span'}
                    className="cursor-pointer"
                    size="SMALL"
                    onClick={handleOnClose}>
                    <Typography as="span" weight="font-medium">
                        Cancel
                    </Typography>
                </Button>
                <Button variant="SECONDARY"
                    disabled={!formState.isValid}
                    loading={attachPaymentMethodMutation.isLoading}
                    size="SMALL">
                    <Typography as="span" weight="font-medium">
                        {attachPaymentMethodMutation.isLoading ? <Loading/> : 'Save '}
                    </Typography>
                </Button>
            </div>
        </form>
    </Modal>;
};
