import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Checkbox, Loading, Modal, Separator, toast, Typography } from '@notch-ordering/ui-components';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { CurrencyInput } from '@ar/components/shared/InputCurrency';

import { GetPaymentMethodResponse, payBills } from '@ar/network/AccountsReceivable.network';
import { FETCH_BILLS_QUERY_KEY } from '@ar/hooks/queries/InvoicesQueries.hook';
import { areAllInvoicesFromSameCustomer } from '@ar/components/Invoices/Modals/BulkManualChargeModal/BulkManualChargeModalConstants';
import { useBillStore } from '@ar/stores/BillsStore';
import { centsToDollars, formatAsCurrency } from '@/utils/Formatters';
import { PaymentMethodsPopover } from '@/ar/components/shared/PaymentMethodsPopover';
import { LoadingPaymentMethodBanner } from '@/ar/components/Invoices/Modals/BulkManualChargeModal/LoadingPaymentMethodBanner';
import { AmplitudeCharge, AmplitudeEventActions, useAmplitude } from '@/containers/app/AmplitudeContext';

export const BillsBulkManualChargeModal : React.FC = () => {
    const { modals, setModal, getReadyToBeChargedInvoices, clearSelectedInvoices } = useBillStore();
    const { supplierLoginData } = useSupplierStore();
    const queryClient = useQueryClient();
    const bills = getReadyToBeChargedInvoices();// check if all invoices to be charged have the same customer id
    const isSameCustomerID = areAllInvoicesFromSameCustomer(bills);

    // get the total amount to be charged
    const totalOutstandingAmount = bills.reduce((acc, invoice) => acc + invoice.outstanding_amount, 0);

    // state variables
    const [amount, setAmount] = useState(totalOutstandingAmount ?? 0);
    const [isLump, setIsLump] = useState(true);
    const [installmentDate, setInstallmentDate] = useState<string>('');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<GetPaymentMethodResponse>(null);

    const hasIncompletePaymentMethod = selectedPaymentMethod && !selectedPaymentMethod?.last4;
    const { trackAmplitudeEvent } = useAmplitude();

    // close the modal method cleaning up the state and invalidating the query
    const handleOnClose = ():void => {
        setSelectedPaymentMethod(null);
        setAmount(0);
        setIsLump(true);
        setModal('bulkManualCharge', false, null);
        setInstallmentDate('');
        queryClient.invalidateQueries([FETCH_BILLS_QUERY_KEY]);
    };

    // mutation to charge the invoices
    const payBillsMutation = useMutation(payBills, {
        onSuccess: (_data, variables) => {
            const paidSuccessful = _data.filter((data) => data.charged === true);
            const paidSuccessfulCount = paidSuccessful.length;
            const numberOfInvoicesCharged = variables.body.transaction_ids.length;
            let toastMessage;
            if (paidSuccessfulCount === numberOfInvoicesCharged) {
            // All bills paid successfully
                const messageText = `${formatAsCurrency(amount)} paid for ${numberOfInvoicesCharged > 1 ? `${numberOfInvoicesCharged} bills` : `${numberOfInvoicesCharged} bill`}`;
                toastMessage = isSameCustomerID ? messageText : `${numberOfInvoicesCharged > 1 ? `${numberOfInvoicesCharged} bills` : `${numberOfInvoicesCharged} bill`} paid`;
            } else if (paidSuccessfulCount === 0) {
            // No bills paid successfully
                toastMessage = `${numberOfInvoicesCharged > 1 ? 'All payments have failed' : 'Payment has failed'}`;
            } else if ((paidSuccessfulCount > 0) && (paidSuccessfulCount !== numberOfInvoicesCharged)) {
            // Only some bills paid successfully
                const textNumberFailed = `${numberOfInvoicesCharged - paidSuccessfulCount > 1 ? `${numberOfInvoicesCharged - paidSuccessfulCount} payments have failed` : `${numberOfInvoicesCharged - paidSuccessfulCount} payment has failed`}`;
                const textNumberSuccesful = `${paidSuccessfulCount > 1 ? `${paidSuccessfulCount} payments have succeeded` : `${paidSuccessfulCount} payment has succeeded`}`;
                toastMessage = `${textNumberFailed}, ${textNumberSuccesful}`;
            }
            toast.show({
                message: toastMessage
            });
            trackAmplitudeEvent(AmplitudeEventActions.charged, { type: AmplitudeCharge.bulkCharge, countInvoices: payBills.length });
            handleOnClose();
            // after the invoices have been charged, clear the selected invoices
            clearSelectedInvoices();
        },
        onError: (error) => {
            toast.show({
                message: 'Something went wrong while charging the bills',
            });
            console.error(error);
        },
    });

    const handleCharge = ():void => {
        // get the ids of the invoices to be charged
        const invoicesIDs = bills.map((invoice) => invoice.id);
        // if there is more than one invoice, use the user selection to determine if it should be lumped else don't lump
        const isLumped = bills.length > 1 ? isLump : false;
        trackAmplitudeEvent(AmplitudeEventActions.pushCharge, { type: AmplitudeCharge.bulkCharge, countInvoices: bills.length });
        payBillsMutation.mutate({
            customerID: supplierLoginData?.customer_id,
            body: {
                is_lumped: isLumped,
                payment_method_id: selectedPaymentMethod?.id,
                transaction_ids: invoicesIDs,
                installments_schedule: installmentDate ? [installmentDate] : null
            },
        });
    };
    useEffect(() => {
        if (modals.bulkManualCharge) {
            trackAmplitudeEvent(AmplitudeEventActions.pageChargeViewed, { type: AmplitudeCharge.bulkCharge, countInvoices: payBills.length });
        }
    }, [modals.bulkManualCharge]);
    // whenever the invoices selected change, update the amount to be charged
    useEffect(() => {
        setAmount(centsToDollars(totalOutstandingAmount));
    }, [bills]);

    if (!modals.bulkManualCharge) return null;

    return <Modal isOpen={modals.bulkManualCharge}
        title={
            <>{isSameCustomerID ? <><Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="LG-2">Confirm amount to be paid for {bills.length > 1 ? `${bills.length} bills` : ` ${bills.length} bill`} </Typography>
                <Typography variant="LG-1" weight="font-regular" className="text-gray-600">{bills[0]?.business_name}</Typography></>
                : <><Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="LG-2">Confirm pay for {bills.length > 1 ? `${bills.length} bill` : 'bills'} </Typography>
                    <Typography variant="LG-1" weight="font-regular" className="text-gray-600">The default payment method will be used for
                        each customer.</Typography></>

            }
            </>
        }
        headerPadding="mt-0 mb-5"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL"
        desktopModalWidth="lg:w-[372px]">
        <div className="p-5 flex flex-col gap-4">
            <div className="flex-col flex gap-4">
                <div className="flex flex-col w-full gap-2">
                    <Typography as="div" weight="font-medium" variant="LG-1" className="text-gray-700">
                        Payment method
                    </Typography>
                    <PaymentMethodsPopover
                        selectedPaymentMethod={selectedPaymentMethod}
                        setSelectedPaymentMethod={setSelectedPaymentMethod}
                        transaction={bills[0]}
                        isCustomerPortal />

                </div>
                <CurrencyInput
                    required
                    label={'Amount'}
                    variant="SMALL"
                    value={amount}
                    disabled={true}
                    helperText={`Total amount Remaining: ${formatAsCurrency(centsToDollars(totalOutstandingAmount))}`}
                    onChange={(e): void => {
                        setAmount(e.floatValue);
                    }}
                    isInvalid={amount > totalOutstandingAmount || !amount}
                    invalidMessage={'Charge amount cannot be greater than remaining balance on invoice.'}/>

                {bills.length > 1 && <div>
                    <Checkbox
                        className="items-start [&>*:first-child]:shrink-0"
                        label={isSameCustomerID ? 'Charge as one lump payment' : 'Charge multiple invoices as one payment for each customer'}
                        value={'isLump'}
                        checked={isLump}
                        onChange={(): void => {
                            setIsLump(!isLump);
                        }}/>
                </div>}

            </div>
            { /* TODO: CORE-495 - <SchedulePaymentInput installmentDate={installmentDate} setInstallmentDate={setInstallmentDate}/> */ }
        </div>
        {hasIncompletePaymentMethod && <LoadingPaymentMethodBanner/>}
        <Separator/>
        <div className="pt-5 px-5 flex justify-end gap-3">
            <Button variant="TERTIARY_FILLED"
                size="SMALL"
                onClick={handleOnClose}>
                <Typography as="span" weight="font-medium">
                    Cancel
                </Typography>
            </Button>
            <Button variant="SECONDARY"
                disabled={payBillsMutation.isLoading || hasIncompletePaymentMethod}
                loading={payBillsMutation.isLoading}
                size="SMALL"
                onClick={handleCharge}>
                <Typography as="span" weight="font-medium">
                    {payBillsMutation.isLoading ? <Loading/> : 'Charge '}
                </Typography>
            </Button>
        </div>
    </Modal>;
};
