import create from 'zustand';
import { GetCustomerSupplierMappingsParams } from '@ar/network/AccountsReceivable.network';

export type SearchParams = Partial<Omit<GetCustomerSupplierMappingsParams, 'customerID'>>;

type CustomerSuppliersStoreState = {
    searchParams: Omit<GetCustomerSupplierMappingsParams, 'customerID'>,
};

type CustomerSuppliersStoreActions = {
    updateSearchParams: (params: SearchParams) => void,
    clearFilter: (filter: keyof SearchParams) => void,
    reset: () => void,
};

type CustomerSuppliersStore = CustomerSuppliersStoreState & CustomerSuppliersStoreActions;

const CUSTOMER_SUPPLIERS_STORE_INITIAL_STATE: CustomerSuppliersStoreState = {
    searchParams: {
        has_auto_collection: undefined,
        has_payment_method: undefined,
        limit: 20,
        offset: 0,
        sort_by: undefined,
        supplier_id: undefined,
        name: undefined,
    },
};

export const useCustomerSuppliersStore = create<CustomerSuppliersStore>(
    (set): CustomerSuppliersStore => ({
        ...CUSTOMER_SUPPLIERS_STORE_INITIAL_STATE,

        updateSearchParams: (params: SearchParams) => set(
            (state) => ({
                searchParams: {
                    ...state.searchParams,
                    ...params,
                },
            }),
        ),

        clearFilter: (filterKey: keyof SearchParams) => set(
            (state) => ({
                searchParams: {
                    ...state.searchParams,
                    [filterKey]: CUSTOMER_SUPPLIERS_STORE_INITIAL_STATE.searchParams[filterKey],
                },
            }),
        ),

        reset: () => set(CUSTOMER_SUPPLIERS_STORE_INITIAL_STATE),
    }),
);
