import React from 'react';
import { ConfirmModal, ConfirmModalProps } from '@ar/components/shared/ConfirmModal';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTransaction } from '@ar/network/AccountsReceivable.network';
import { toast } from '@notch-ordering/ui-components';
import CheckIcon from '@icons/check-icon.svg';
import { FETCH_BILLS_QUERY_KEY } from '@ar/hooks/queries/InvoicesQueries.hook';
import { useBillStore } from '@ar/stores/BillsStore';

export type DoNotChargeModalProps = Partial<ConfirmModalProps>;

export const BillDoNotChargeModal : React.FC<DoNotChargeModalProps> = (props) => {
    const { modals, setModal } = useBillStore();
    const { supplierLoginData } = useSupplierStore();
    const queryClient = useQueryClient();
    const handleOnClose = ():void => {
        if (props?.onClose) props.onClose();
        setModal('doNotChargeInvoice', false);
    };
    const updateTransactionMutation = useMutation(updateTransaction, {
        onSuccess: () => {
            toast.show({
                message: 'Bill status updated',
                icon: <CheckIcon/>,
            });
            queryClient.invalidateQueries([FETCH_BILLS_QUERY_KEY]);
            handleOnClose();
        },
        onError: () => {
            toast.show({
                message: 'Failed to update invoice status',
            });
        },
    });

    const handleOnConfirm = ():void => {
        if (props?.onConfirm) props.onConfirm();
        updateTransactionMutation.mutate({
            customerID: supplierLoginData?.customer_id,
            transactionID: modals?.transaction.id,
            body: {
                do_no_charge: true,
            },
        });
    };
    return <ConfirmModal
        onClose={handleOnClose}
        isLoading={updateTransactionMutation.isLoading}
        isOpen={modals.doNotChargeInvoice}
        title={'Dispute Bill'}
        prompt={'Payments will be permanently disabled for this bill.'}
        onConfirm={handleOnConfirm}
        {...props}/>;
};
